import React, {FC, useEffect, useRef, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    TextField,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import {useTranslation} from 'react-i18next';
import {steps, useForgotPasswordForm} from '../../validation/auth/forgot-password-form';
import {useFormik} from 'formik';
import {useAppDispatch} from '../../hooks/redux';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {getVerificationCodeAPI} from '../../services/AuthService';

const startTime = 600; // 10 minutes

const formatTime = (seconds: number): string => {
    let formattedMinutes: number | string = Math.floor(seconds % 3600 / 60);
    let formattedSeconds: number | string = Math.floor(seconds % 60);

    if (formattedMinutes < 10) formattedMinutes = '0' + formattedMinutes;
    if (formattedSeconds < 10) formattedSeconds = '0' + formattedSeconds;

    return formattedMinutes + ':' + formattedSeconds;
}

export const ForgotPasswordForm: FC = () => {

    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const intervalRef = useRef<number>(0);

    const [currentStep, setCurrentStep] = useState<steps>('email');
    const [isCodeSending, setIsCodeSending] = useState<boolean>(false);
    const [timeLeft, setTimeLeft] = useState<number>(startTime);

    const handleNextStep = () => {
        setCurrentStep(prevState => {
            if (prevState === 'email') return 'code';
            return 'restore';
        });
    }

    const handleSetTimer = (timeLeft: number) => {
        clearInterval(intervalRef.current);
        setTimeLeft(timeLeft - 1);
        intervalRef.current = window.setInterval(() => {
            setTimeLeft(prevState => {
                if (prevState === 0) {
                    clearInterval(intervalRef.current);
                    return startTime;
                }
                return prevState - 1;
            });
        }, 1000);
    }

    const handleSendAgain = () => {
        setIsCodeSending(true);
        getVerificationCodeAPI(formik.values.email)
            .then(() => {
                dispatch(setSnackbar({
                    text: 'Verification code has been sent',
                    severity: 'success',
                    open: true
                }));
                clearInterval(intervalRef.current);
                setTimeLeft(startTime - 1);
                intervalRef.current = window.setInterval(() => {
                    setTimeLeft(prevState => {
                        if (prevState === 0) {
                            clearInterval(intervalRef.current);
                            return startTime;
                        }
                        return prevState - 1;
                    });
                }, 1000);
            })
            .catch(error => {
                const errorTime = error.response.data.errors.timeLeft;
                if (errorTime) {
                    dispatch(setSnackbar({
                        text: error.response.data.errors.msg,
                        severity: 'error',
                        open: true
                    }));
                    clearInterval(intervalRef.current);
                    setTimeLeft(errorTime - 1);
                    intervalRef.current = window.setInterval(() => {
                        setTimeLeft(prevState => {
                            if (prevState === 0) {
                                clearInterval(intervalRef.current);
                                return startTime;
                            }
                            return prevState - 1;
                        });
                    }, 1000);
                }
            })
            .finally(() => {
                setIsCodeSending(false);
            });
    }

    const {
        initialValues,
        validationSchema,
        onSubmit
    } = useForgotPasswordForm(currentStep, handleNextStep, handleSetTimer);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: onSubmit
    });

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        }
    }, []);

    return (
        <Card sx={{width: '100%', maxWidth: '540px', borderRadius: '0.625rem !important'}}>
            <CardContent sx={{padding: '2.5rem !important'}}>
                <Avatar sx={{m: 'auto', bgcolor: theme.palette.primary.main, zIndex: 999}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography
                    component="h1"
                    variant="h5"
                    sx={{
                        textAlign: 'center',
                        fontWeight: '700 !important',
                        mt: 1,
                        marginBottom: '32px'
                    }}
                >
                    {t('Restore password')}
                </Typography>
                <Box
                    component={'form'}
                    onSubmit={formik.handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '268px',
                        mt: 1
                    }}>
                    <Box>
                        <Box sx={{display: currentStep === 'email' ? '' : 'none'}}>
                            {t(`Enter your user account's verified email address and we will send you a verification code`)}
                            <TextField
                                margin='normal'
                                fullWidth
                                id="email"
                                name="email"
                                label={t("email")}
                                autoComplete="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                sx={{
                                    marginTop: '24px',
                                    marginBottom: '2rem !important',
                                    "& .MuiOutlinedInput-root": {
                                        padding: "0px 0px 0px 8px",
                                        "&:-webkit-autofill": {
                                            WebkitBoxShadow: "0 0 0 100px #000000 inset",
                                            WebkitTextFillColor: "default",
                                        },
                                    }
                                }}
                                InputProps={{
                                    sx: {
                                        borderRadius: '0.75rem',
                                    },
                                    inputProps: {
                                        style: {
                                            padding: '0.775rem 1rem',

                                        }
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{display: currentStep === 'code' ? '' : 'none'}}>
                            {t(`Enter the code that we sent you by email, it is valid for 10 minutes`)}
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <TextField
                                    hidden={currentStep !== 'code'}
                                    margin='normal'
                                    fullWidth
                                    id="code"
                                    name="code"
                                    label={t("Verification code")}
                                    value={formik.values.code}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.code && Boolean(formik.errors.code)}
                                    helperText={formik.touched.code && formik.errors.code}
                                    sx={{
                                        marginTop: '24px',
                                        marginBottom: '2rem !important',
                                        "& .MuiOutlinedInput-root": {
                                            padding: "0px 0px 0px 8px",
                                            "&:-webkit-autofill": {
                                                WebkitBoxShadow: "0 0 0 100px #000000 inset",
                                                WebkitTextFillColor: "default",
                                            },
                                        }
                                    }}
                                    InputProps={{
                                        sx: {
                                            borderRadius: '0.75rem',
                                        },
                                        inputProps: {
                                            style: {
                                                padding: '0.775rem 1rem',

                                            }
                                        }
                                    }}
                                />
                                <Tooltip title={t('send code again')}>
                                    <Button
                                        disabled={isCodeSending || timeLeft < startTime}
                                        variant='outlined'
                                        onClick={handleSendAgain}
                                        sx={{
                                            height: '47px',
                                            marginTop: '24px',
                                            marginLeft: '6px',
                                            borderRadius: '0.75rem',
                                            fontSize: '1.1rem',
                                            lineHeight: '1'
                                        }}
                                    >
                                        {isCodeSending
                                            ?
                                            <CircularProgress color='inherit' size={'1.5rem'}/>
                                            :
                                            (timeLeft < startTime
                                                    ?
                                                    formatTime(timeLeft)
                                                    :
                                                    <ReplayIcon/>
                                            )
                                        }
                                    </Button>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Box sx={{display: currentStep === 'restore' ? '' : 'none'}}>
                            <TextField
                                margin='normal'
                                fullWidth
                                id="newPassword"
                                name="newPassword"
                                label={t("new password")}
                                type='password'
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                helperText={formik.touched.newPassword && formik.errors.newPassword}
                                sx={{
                                    marginTop: '24px',
                                    "& .MuiOutlinedInput-root": {
                                        padding: "0px 0px 0px 8px",
                                        "&:-webkit-autofill": {
                                            WebkitBoxShadow: "0 0 0 100px #000000 inset",
                                            WebkitTextFillColor: "default",
                                        },
                                    }
                                }}
                                InputProps={{
                                    sx: {
                                        borderRadius: '0.75rem',
                                    },
                                    inputProps: {
                                        style: {
                                            padding: '0.775rem 1rem',

                                        }
                                    }
                                }}
                            />
                            <TextField
                                margin='normal'
                                fullWidth
                                id="newPasswordConfirmation"
                                name="newPasswordConfirmation"
                                label={t("new password confirmation")}
                                type='password'
                                value={formik.values.newPasswordConfirmation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.newPasswordConfirmation && Boolean(formik.errors.newPasswordConfirmation)}
                                helperText={formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation}
                                sx={{
                                    marginTop: '24px',
                                    "& .MuiOutlinedInput-root": {
                                        padding: "0px 0px 0px 8px",
                                        "&:-webkit-autofill": {
                                            WebkitBoxShadow: "0 0 0 100px #000000 inset",
                                            WebkitTextFillColor: "default",
                                        },
                                    }
                                }}
                                InputProps={{
                                    sx: {
                                        borderRadius: '0.75rem',
                                    },
                                    inputProps: {
                                        style: {
                                            padding: '0.775rem 1rem',

                                        }
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={formik.isSubmitting}
                            endIcon={
                                formik.isSubmitting
                                    ?
                                    <CircularProgress color='inherit' size={'1rem'}/>
                                    :
                                    undefined
                            }
                            sx={{
                                mt: 3,
                                mb: 2,
                                borderRadius: '0.75rem',
                                padding: 'calc(0.775rem + 1px) calc(1.5rem + 1px)',
                                textTransform: 'none',
                                fontSize: '1.1rem',
                                lineHeight: '1'
                            }}
                        >
                            {currentStep === 'restore' ? t('Save') : t('Next')}
                        </Button>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}
