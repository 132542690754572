import React, {FC, Fragment, useEffect, useState} from 'react';
import {FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {TLang} from '../../../models/utils/TLang';
import i18n from '../../../localization/i18n';
import classes from './LanguageSwitcher.module.css';
import {editProfile} from '../../../store/auth/authSlice';
import {getFromStorage, saveToStorage} from "../../../utils/storageHelpers";

interface IProps {
    fullWidth?: boolean,
    size?: 'small' | 'medium'
}

export const LanguageSwitcher: FC<IProps> = ({fullWidth = false, size = 'small', ...props}) => {

    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {isAuth, user} = useAppSelector(state => state.auth);

    const [currLang, setCurrLang] = useState<TLang>('en');

    useEffect(() => {
        const runUseEffect = async () => {
            if (isAuth) {
                setCurrLang(user.lang);
            } else {
                await getFromStorage('lang')
                    .then(lang => {
                        if (lang.value) {
                            setCurrLang(lang.value as TLang);
                        } else {
                            setCurrLang('en');
                        }
                    });
            }
        }
        runUseEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth]);

    const handleChange = (event: SelectChangeEvent) => {
        const newLang = event.target.value as TLang;
        i18n.changeLanguage(newLang)
            .then(async () => {
                if (isAuth) {
                    dispatch(editProfile({...user, lang: newLang}));
                }
                setCurrLang(newLang);
                await saveToStorage('lang', newLang);
            });
    };

    return (
        <Fragment>
            <FormControl
                fullWidth={fullWidth}
                className={isMobile ? '' : classes.langSwitchFormControl}
                sx={{m: isAuth ? 1 : 2}}
                size={size}
            >
                <Select
                    className={isMobile ? '' : classes.langSwitchSelect}
                    value={currLang}
                    onChange={handleChange}
                    variant='standard'
                    inputProps={{'aria-label': 'Without label'}}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                borderRadius: '0.75rem',
                                '& .MuiMenuItem-root': {
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                },
                            },
                        },
                    }}
                >
                    <MenuItem value={'en'}>
                        <img src={`/images/icons/languages/en.png`} alt="English" width={"20px"}/> &nbsp; English
                    </MenuItem>
                    <MenuItem value={'pl'}>
                        <img src={`/images/icons/languages/pl.png`} alt="English" width={"20px"}/> &nbsp; Polski
                    </MenuItem>
                    <MenuItem value={'ua'}>
                        <img src={`/images/icons/languages/ua.png`} alt="English" width={"20px"}/> &nbsp; Українська
                    </MenuItem>
                    <MenuItem value={'ru'}>
                        <img src={`/images/icons/languages/ru.png`} alt="English" width={"20px"}/> &nbsp; Русский
                    </MenuItem>
                </Select>
            </FormControl>
        </Fragment>
    )
}
