import {AxiosResponse} from "axios";
import $api from "../http";
import {IAccount} from "../models/IAccount";
import {IRelatedAccountRemoveResponse} from "../models/response/IRelatedAccountRemoveResponse";

const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;

export const listAccountsAPI = async (): Promise<AxiosResponse<IAccount[]>> => {
    return await $api.get<IAccount[]>(`${AUTH_API_URL}/auth/get-related-accounts`);
}

export const removeAccountAPI = async (
    accountId: number,
): Promise<AxiosResponse<IRelatedAccountRemoveResponse>> => {
    return await $api.post<IRelatedAccountRemoveResponse>(`${AUTH_API_URL}/auth/remove-related-account`, {
        accountId: accountId,
    });
}