import { useParams } from "react-router-dom";
import { ITabDay } from "../models/days/ITabDay";
import { changeTimezone, formatDateWithTimezone } from "../utils/helpers";
import { useAppSelector } from "./redux";

export const useDays = () => {

    const { date: paramsDate } = useParams();

    const { user } = useAppSelector(state => state.auth);

    const days: ITabDay[] = [];
    const dayNames: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    let date = changeTimezone(new Date(), user.timezoneName);

    //date = new Date(date.toLocaleDateString("us-US", { timeZone: timezone }).split('.').reverse().join('-'));
    // const offset = date.getTimezoneOffset();
    // date = new Date(date.getTime() + (offset * 60 * 1000));
    // console.log(new Date().toLocaleString("us-US", { timeZone: timezone }));

    days.push({
        name: 'Today',
        dayOfMonth: date.getDate(),
        dayOfWeek: date.getDay(),
        date: formatDateWithTimezone(date)
    });

    date.setDate(date.getDate() + 1);
    days.push({
        name: 'Tomorrow',
        dayOfMonth: date.getDate(),
        dayOfWeek: date.getDay(),
        date: formatDateWithTimezone(date)
    });

    for (let i = 2; i < dayNames.length; i++) {
        date.setDate(date.getDate() + 1);

        days.push({
            name: dayNames[date.getDay()],
            dayOfMonth: date.getDate(),
            dayOfWeek: date.getDay(),
            date: formatDateWithTimezone(date)
        });
    }

    let queryDayIndex: number = 0;
    if (paramsDate) {
        const dayIndex = days.findIndex(day => { return day.date === paramsDate });
        if (dayIndex === -1) {
            if (!isNaN(Date.parse(paramsDate))) {
                const formattedParamsDate = new Date(paramsDate);
                days.unshift({
                    name: dayNames[formattedParamsDate.getDay()],
                    dayOfMonth: formattedParamsDate.getDate(),
                    dayOfWeek: formattedParamsDate.getDay(),
                    date: paramsDate
                });
            }
        } else {
            queryDayIndex = dayIndex;
        }
    }

    return {
        days,
        queryDayIndex
    }
}