import {Box, Button, TextField} from '@mui/material';
import React, {FC, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {IGoal} from '../../models/goals/IGoal';
import {addGoal} from '../../store/goal/goalSlice';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {changeTimezone, flatArrayOfObjects, formatDateWithTimezone} from '../../utils/helpers';
import {setShowBuyPremiumDialogStatus} from "../../store/subscription/subscriptionSlice";

interface IGoalAddInputProps {
    setId: number,
    parentId?: number,
    isSubGoal?: boolean
}

export const GoalAddInput: FC<IGoalAddInputProps> = ({setId, parentId, isSubGoal = false, ...props}) => {

    const {t} = useTranslation();

    const dispatch = useAppDispatch();

    const {user} = useAppSelector(state => state.auth);
    const {isMobile} = useAppSelector(state => state.utils);
    const {sets} = useAppSelector(state => state.goal);


    const [newGoal, setNewGoal] = useState<IGoal>({title: ''} as IGoal);

    const inputRef = useRef<HTMLDivElement>(null);

    const newGoalHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewGoal({...newGoal, title: e.target.value});
    }

    const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') addNewGoal();
    }

    const addNewGoal = () => {
        if (newGoal.title.trim() !== '') {
            if (isSubGoal) {
                if (getSubGoalsCount() >= user.level.goalsMaxSubgoalsCount) {
                    dispatch(setShowBuyPremiumDialogStatus(true));
                    return;
                }
            } else {
                if (getGoalsCount() >= user.level.goalsMaxSetsCount) {
                    dispatch(setShowBuyPremiumDialogStatus(true));
                    return;
                }
            }

            dispatch(addGoal({
                goal: newGoal,
                setId: setId,
                parentId: parentId,
                date: formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    setNewGoal({...newGoal, title: ''});

                    const node = inputRef.current as any;
                    if (node) {
                        node.focus();
                    }
                });

        } else {
            dispatch(setSnackbar({
                text: 'Goal title cannot be empty',
                severity: 'error',
                open: true
            }));
        }
    }

    const getGoalsCount = () => {
        let result = 0;
        sets.map(set => {
            result = result + set.Goals.length;
            return set;
        });
        return result;
    }

    const getSubGoalsCount = () => {
        let result = 0;
        let goals: object[] = [];
        sets.map(set => {
            const flattedGoals = flatArrayOfObjects(set.Goals, 'SubGoals');
            goals.push(...flattedGoals);
        });
        goals.map(goal => {
            let g: IGoal = goal as IGoal;
            if (g.SubGoals) {
                result = result + g.SubGoals.length
            }
        });
        return result;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: isSubGoal ? '8px' : ''
            }}
        >
            <TextField
                inputRef={inputRef}
                autoFocus={isMobile ? false : true}
                fullWidth
                label={isSubGoal ? t('+ Add sub-goal') : t('+ Add goal')}
                size='small'
                variant="outlined"
                sx={{marginRight: '6px'}}
                InputProps={{
                    autoComplete: 'off',
                    sx: {
                        borderRadius: '0.75rem'
                    }
                }}
                value={newGoal.title}
                onChange={newGoalHandle}
                onKeyPress={handleEnterPress}
            />
            <Button
                variant="contained"
                onClick={addNewGoal}
            >
                {t('Add')}
            </Button>
        </Box>
    )
}
