import React, {FC, useEffect, useState} from 'react';
import {
    Box,
    Checkbox,
    CircularProgress,
    Collapse,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    useTheme
} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {useRecurringForm} from '../../validation/task/recurring-form';
import {useFormik} from 'formik';
import {IRecurringFormFields} from '../../models/validation/IRecurringFormFields';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {dayNames, dayOfWeek, daysOfMonth, monthNames, monthOfYear} from '../../utils/constants';
import {changeTimezone} from '../../utils/helpers';
import {getRecurrings} from "../../store/task/taskSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            borderRadius: '0.5rem',
            color: '#5E6278',
        },
    },
};

interface IProps {
    recurringConfigHandle: (config: IRecurringFormFields) => void,
    date?: string,
    validateCounter: number,
    sendCounter: number,
    modal?: boolean,
    initialValues?: IRecurringFormFields,
    isLoading?: boolean
}

export const RecurringAddForm: FC<IProps> = ({
                                                 recurringConfigHandle,
                                                 date,
                                                 validateCounter,
                                                 sendCounter,
                                                 modal = false,
                                                 initialValues,
                                                 isLoading,
                                                 ...props
                                             }) => {

    const theme = useTheme();
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {user} = useAppSelector(state => state.auth);
    const {recurrings, isTaskLoading} = useAppSelector(state => state.task);

    const {recurringInitialValues, recurringValidationSchema} = useRecurringForm(date ? new Date(date) : undefined);
    const [locale, setLocale] = useState<Locale>();

    const formik = useFormik({
        initialValues: initialValues ? initialValues : recurringInitialValues,
        validationSchema: recurringValidationSchema,
        onSubmit: (values: IRecurringFormFields) => {
            recurringConfigHandle(values);
        }
    });

    const disablePastDays = (day: Date): boolean => {
        const today = changeTimezone(new Date(), user.timezoneName).setHours(0, 0, 0, 0);
        if (day.getTime() >= today) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        formik.submitForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validateCounter])


    useEffect(() => {
        formik.resetForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendCounter]);

    useEffect(() => {
        recurringConfigHandle(formik.values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values]);

    useEffect(() => {
        switch (user.lang) {
            case 'en':
                import('date-fns/locale/en-US/index').then(locale => updateLocale(locale.default));
                break;
            case 'ru':
                import('date-fns/locale/ru/index').then(locale => updateLocale(locale.default));
                break;
            case 'pl':
                import('date-fns/locale/pl/index').then(locale => updateLocale(locale.default));
                break;
            case 'ua':
                import('date-fns/locale/uk/index').then(locale => updateLocale(locale.default));
                break;
            default:
                import('date-fns/locale/en-US/index').then(locale => updateLocale(locale.default));
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.lang, user.weekStartsOn]);

    const updateLocale = (locale: Locale) => {
        let loc = locale;
        if (loc && loc.options) {
            loc.options.weekStartsOn = user.weekStartsOn as 0 | 1 | 2 | 3 | 4 | 5 | 6;
        }
        setLocale(loc);
    }

    useEffect(() => {
        if (!isTaskLoading && !recurrings.fetched) {
            dispatch(getRecurrings());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Box
            component='form'
            onSubmit={formik.handleSubmit}
            sx={{
                position: 'relative',
                width: '100%',
                margin: 'auto',
                padding: '6px 8px 10px 6px',
                marginTop: modal ? '6px' : '8px',
                border: '1px solid',
                borderRadius: '0.75rem',
                borderColor: theme.palette.borderColor.main,
            }}
        >
            <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
                <FormGroup sx={{ml: '8px'}}>
                    <FormControlLabel
                        disabled={recurrings.tasks.length >= user.level.tasksRecurringMaxCount}
                        control={<Checkbox
                            name='isRecurring'
                            checked={formik.values.isRecurring}
                            onChange={formik.handleChange}
                            disabled={recurrings.tasks.length >= user.level.tasksRecurringMaxCount}
                            sx={{
                                padding: 0,
                                mr: '4px',
                                '& .MuiTypography-root': {
                                    fontWeight: '500',
                                }
                            }}
                        />}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'break-spaces',
                            userSelect: 'none',
                            color: theme.palette.textColorThird.dark,
                        }}
                        label={t('Make this task recurring')}
                    />
                </FormGroup>
                <FormControl size='small' sx={{mt: '12px', width: '100%'}} disabled={!formik.values.isRecurring}>
                    <InputLabel id="recurring-type">{t('Recurring type')}</InputLabel>
                    <Select
                        name='recurringType'
                        labelId="recurring-type-label"
                        id="recurring-type"
                        value={formik.values.recurringType}
                        label={t('Recurring type')}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.recurringType && Boolean(formik.errors.recurringType)}
                        sx={{
                            borderRadius: '0.75rem'
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    borderRadius: '0.5rem',
                                    color: theme.palette.textColorThird.dark,
                                },
                            },
                        }}
                    >
                        <MenuItem value={0}
                                  sx={{'&:hover': {color: theme.palette.primary.main}}}>{t('Daily')}</MenuItem>
                        <MenuItem value={1}
                                  sx={{'&:hover': {color: theme.palette.primary.main}}}>{t('Weekly')}</MenuItem>
                        <MenuItem value={2}
                                  sx={{'&:hover': {color: theme.palette.primary.main}}}>{t('Monthly')}</MenuItem>
                        <MenuItem value={3}
                                  sx={{'&:hover': {color: theme.palette.primary.main}}}>{t('Yearly')}</MenuItem>
                    </Select>
                </FormControl>
                <Collapse in={Number(formik.values.recurringType) === 1}>
                    <FormControl size='small' sx={{mt: '12px', width: '100%'}} disabled={!formik.values.isRecurring}>
                        <InputLabel id="day-of-week">{t('Day of week')}</InputLabel>
                        <Select
                            name="dayOfWeek"
                            labelId="day-of-week-label"
                            label={t('Day of week')}
                            id="day-of-week"
                            multiple
                            value={formik.values.dayOfWeek}
                            onChange={formik.handleChange}
                            input={<OutlinedInput label={t('Day of week')}/>}
                            SelectDisplayProps={{
                                style: {
                                    whiteSpace: 'break-spaces'
                                }
                            }}
                            renderValue={(selected) => (
                                selected.map(select => {
                                    if (select === 0) return t(dayNames[6]) + ', '
                                    return t(dayNames[select - 1]) + ', ';
                                })
                            )}
                            MenuProps={MenuProps}
                            onBlur={formik.handleBlur}
                            error={formik.touched.dayOfWeek && Boolean(formik.errors.dayOfWeek)}
                            sx={{
                                borderRadius: '0.75rem'
                            }}
                        >
                            {dayOfWeek.map((dayName, i) => (
                                <MenuItem value={dayName} key={dayName}>
                                    <Checkbox checked={formik.values.dayOfWeek.indexOf(dayName) > -1}/>
                                    <ListItemText primary={t(dayNames[i])}/>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error>
                            {formik.touched.dayOfWeek && formik.errors.dayOfWeek}
                        </FormHelperText>
                    </FormControl>
                </Collapse>
                <Collapse in={Number(formik.values.recurringType) === 3}>
                    <FormControl size='small'
                                 sx={{
                                     mt: '12px',
                                     width: '100%',
                                     '& .MuiOutlinedInput-root': {
                                         borderRadius: '0.75rem'
                                     }
                                 }}
                                 disabled={!formik.values.isRecurring}>
                        <InputLabel id="month-of-year">{t('Month of year')}</InputLabel>
                        <Select
                            name="monthOfYear"
                            labelId="month-of-year-label"
                            label={t('Month of year')}
                            id="month-of-year"
                            multiple
                            value={formik.values.monthOfYear}
                            onChange={formik.handleChange}
                            input={<OutlinedInput label={t('Month of year')}/>}
                            SelectDisplayProps={{
                                style: {
                                    whiteSpace: 'break-spaces'
                                }
                            }}
                            renderValue={(selected) => (
                                selected.map(select => {
                                    return t(monthNames[select]) + ', ';
                                })
                            )}
                            MenuProps={MenuProps}
                            onBlur={formik.handleBlur}
                            error={formik.touched.monthOfYear && Boolean(formik.errors.monthOfYear)}
                        >
                            {monthOfYear.map((monthName, i) => (
                                <MenuItem value={monthName} key={monthName}>
                                    <Checkbox checked={formik.values.monthOfYear.indexOf(monthName) > -1}/>
                                    <ListItemText primary={t(monthNames[i])}/>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error>
                            {formik.touched.monthOfYear && formik.errors.monthOfYear}
                        </FormHelperText>
                    </FormControl>
                </Collapse>
                <Collapse in={Number(formik.values.recurringType) === 3 || Number(formik.values.recurringType) === 2}>
                    <FormControl size='small'
                                 sx={{
                                     mt: '12px',
                                     width: '100%',
                                     '& .MuiOutlinedInput-root': {
                                         borderRadius: '0.75rem'
                                     }
                                 }}
                                 disabled={!formik.values.isRecurring}
                    >
                        <InputLabel id="day-of-month">{t('Day of month')}</InputLabel>
                        <Select
                            name="dayOfMonth"
                            labelId="day-of-month-label"
                            label={t('Day of month')}
                            id="day-of-month"
                            multiple
                            value={formik.values.dayOfMonth}
                            onChange={formik.handleChange}
                            input={<OutlinedInput label={t('Day of month')}/>}
                            SelectDisplayProps={{
                                style: {
                                    whiteSpace: 'break-spaces'
                                }
                            }}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            onBlur={formik.handleBlur}
                            error={formik.touched.dayOfMonth && Boolean(formik.errors.dayOfMonth)}
                        >
                            {daysOfMonth.map(dayOfMonth => (
                                <MenuItem value={dayOfMonth} key={dayOfMonth}>
                                    <Checkbox checked={formik.values.dayOfMonth.indexOf(dayOfMonth) > -1}/>
                                    <ListItemText primary={dayOfMonth}/>
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText error>
                            {formik.touched.dayOfMonth && formik.errors.dayOfMonth}
                        </FormHelperText>
                    </FormControl>
                </Collapse>
                <FormControl size='small'
                             sx={{
                                 mt: '12px',
                                 width: '100%',
                                 '& .MuiOutlinedInput-root': {
                                     borderRadius: '0.75rem'
                                 }
                             }}
                >
                    <DesktopDatePicker
                        disabled={!formik.values.isRecurring}
                        disableHighlightToday
                        shouldDisableDate={disablePastDays}
                        label={t('Start date')}
                        inputFormat="yyyy/MM/dd"
                        value={formik.values.startDate}
                        onChange={value => formik.setFieldValue('startDate', value, true)}
                        renderInput={(params) => (
                            <TextField
                                name="startDate"
                                size='small'
                                {...params}
                                onBlur={formik.handleBlur}
                                error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                helperText={formik.touched.startDate && formik.errors.startDate}
                                sx={{
                                    borderRadius: '0.5rem',
                                }}
                            />
                        )}
                    />
                </FormControl>
                <FormControl size='small'
                             sx={{
                                 mt: '12px',
                                 width: '100%',
                                 '& .MuiOutlinedInput-root': {
                                     borderRadius: '0.75rem'
                                 },
                             }}
                >
                    <DesktopDatePicker
                        disabled={!formik.values.isRecurring}
                        disableHighlightToday
                        shouldDisableDate={disablePastDays}
                        label={t('End date')}
                        inputFormat="yyyy/MM/dd"
                        value={formik.values.endDate}
                        onChange={value => formik.setFieldValue('endDate', value, true)}
                        renderInput={(params) => (
                            <TextField
                                name='endDate'
                                size='small'
                                {...params}
                                onBlur={formik.handleBlur}
                                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                helperText={formik.touched.endDate && formik.errors.endDate}
                            />
                        )}
                    />
                </FormControl>
            </LocalizationProvider>
            {isLoading &&
                <CircularProgress
                    sx={{
                        position: 'absolute',
                        top: '44%',
                        left: '44%',
                        color: '#a6a6a6'
                    }}
                    size={'2rem'}
                />
            }
        </Box>
    )
}
