import React, {FC, Fragment, useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {deleteAllDeletedTasks, deleteDayTasks, getDeletedTasks} from '../../store/task/taskSlice';
import {AppObserver} from '../utilities/AppObserver';
import {DeletedTask} from './DeletedTask';
import {NavLink} from 'react-router-dom';
import {TransitionGroup} from 'react-transition-group';
import {TGetTasksResponse} from '../../models/response/TGetTasksResponse';
import {changeTimezone, formatDateWithTimezone, getDayName, sortDaysByDate} from '../../utils/helpers';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {DnDDays} from "../UI/Calendar/DnDDays";
import {DnDPicker} from "../UI/Calendar/DnDPicker";


let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export const DeletedTasksList: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {deleted, isTaskLoading} = useAppSelector(state => state.task);
    const {isMobile} = useAppSelector(state => state.utils);
    const {user} = useAppSelector(state => state.auth);

    const [days, setDays] = useState<TGetTasksResponse>({});
    const [isDeleteAllDialogOpen, setIsDeleteAllDialogOpen] = useState<boolean>(false);
    const [dialogOptions, setDialogOptions] = useState<{
        isOpen: boolean,
        date: string | null
    }>({
        isOpen: false,
        date: null
    });

    const handleDeleteAllDialogOpen = () => {
        setIsDeleteAllDialogOpen(true);
    }

    const handleDeleteAllDialogClose = () => {
        setIsDeleteAllDialogOpen(false);
    }

    const handleDeleteAllTasks = () => {
        dispatch(deleteAllDeletedTasks());
        handleDeleteAllDialogClose();
    }

    const handleDeleteDayTasks = () => {
        if (dialogOptions.date && days.hasOwnProperty(dialogOptions.date)) {
            dispatch(deleteDayTasks(days[dialogOptions.date].id));
        }
        handleCloseDialog();
    }

    const handleOpenDialog = (e: React.MouseEvent<HTMLElement>, date: string) => {
        setDialogOptions({isOpen: true, date: date});
    }

    const handleCloseDialog = () => {
        setDialogOptions({isOpen: false, date: null});
    }

    const intersect = () => {
        dispatch(getDeletedTasks({page: deleted.page, limit: 15}));
    }

    useEffect(() => {
        if (JSON.stringify(days) !== JSON.stringify(deleted.days)) {
            setDays(deleted.days);
        }
    }, [deleted.days, days]);

    return (
        <div>
            <Box display="flex" flexDirection="row">
                <Typography
                    color={theme.palette.error.main}
                    variant='h5'
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '12px',
                        fontSize: '1.5rem',
                        fontWeight: '500 !important',
                        lineHeight: '1.2',
                    }}
                >
                    {t('Deleted')}
                </Typography>

                <Box sx={{ml: isMobile ? '8px' : '16px', display: 'flex',}}>
                    {!isMobile
                        ?
                        <Fragment>
                            <DnDDays
                                title={t('Today')}
                                dateTo={formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))}
                                style={{
                                    fontSize: '21px',
                                    height: '30px',
                                }}
                            />
                            <DnDDays
                                title={t('Tomorrow')}
                                dateTo={formatDateWithTimezone(changeTimezone(tomorrow, user.timezoneName))}
                                style={{
                                    fontSize: '21px',
                                    height: '30px',
                                    marginLeft: '4px'
                                }}
                            />
                            <DnDPicker
                                isFullBorder
                                style={{
                                    marginLeft: '4px'
                                }}
                            />
                        </Fragment>
                        :
                        <Fragment>
                            <DnDPicker
                                isFullBorder
                                style={{
                                    marginLeft: '4px'
                                }}
                            />
                        </Fragment>
                    }
                </Box>

                {Object.keys(days).length
                    ?
                    <Box sx={{mb: '-6px', width: '100%'}}>
                        {!isMobile
                            ?
                            <Button sx={{float: 'right'}} color='error' onClick={handleDeleteAllDialogOpen}>
                                {t('Delete all')}
                            </Button>
                            :
                            <Button sx={{float: 'right'}} color='error' onClick={handleDeleteAllDialogOpen}>
                                <DeleteIcon
                                    color='action'
                                    sx={{
                                        marginLeft: '6px',
                                        cursor: 'pointer',
                                        marginRight: '6px',
                                        color: theme.palette.error.main
                                    }}
                                />
                                {t('All')}
                            </Button>
                        }
                    </Box>
                    : ''
                }
            </Box>


            <Box sx={{overflowY: 'auto', maxHeight: !isMobile ? 'calc(100vh - 180px)' : 'calc(100vh - 180px)'}}>
                <List dense={true}>
                    <TransitionGroup>
                        {sortDaysByDate(Object.keys(days)).map(date => (
                            <Collapse key={date}>
                                <Fragment>
                                    <ListItem
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            padding: '8px 0 8px 0'
                                        }}
                                    >
                                        <Box>
                                            <ListItemIcon>
                                                <DeleteForeverIcon color='error'/>
                                            </ListItemIcon>
                                            <Tooltip title={t('Click to go') + ' ' + date} arrow>
                                                <Typography
                                                    component={NavLink}
                                                    to={`/tasks/${date}`}
                                                    color={theme.palette.error.main}
                                                    marginLeft='-16px'
                                                    fontSize='1rem'
                                                    fontWeight='500'
                                                    style={{
                                                        textDecoration: 'none',
                                                        textDecorationColor: theme.palette.error.main
                                                    }}
                                                >
                                                    {`${date} - ${t(getDayName(date))}`}
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                        <ListItemIcon
                                            sx={{
                                                justifyContent: 'flex-end',
                                                mr: '6px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={(e) => {
                                                handleOpenDialog(e, date)
                                            }}
                                        >
                                            <DeleteIcon sx={{
                                                "&:hover": {
                                                    opacity: '1',
                                                    color: theme.palette.error.main
                                                }
                                            }}/>
                                        </ListItemIcon>
                                    </ListItem>
                                    <Divider sx={{marginTop: '6px', marginBottom: '4px'}}/>
                                    <TransitionGroup>

                                        {
                                            deleted.days[date] && days[date].Tasks.length >= user.level.tasksDeletedMaxCount ?
                                                days[date].Tasks.slice(0, user.level.tasksDeletedMaxCount).map((task, i) => (
                                                    <Collapse key={task.id}
                                                              sx={{
                                                                  marginLeft: isMobile ? '0px' : '24px'
                                                              }}
                                                    >
                                                        <Fragment>
                                                            <DeletedTask
                                                                day={days[date]}
                                                                task={task}
                                                                index={i}
                                                            />
                                                            <Divider sx={{marginBottom: '6px'}}/>
                                                        </Fragment>
                                                    </Collapse>
                                                ))
                                                :
                                                days[date].Tasks.map((task, i) => (
                                                    <Collapse key={task.id}
                                                              sx={{
                                                                  marginLeft: isMobile ? '0px' : '24px'
                                                              }}
                                                    >
                                                        <Fragment>
                                                            <DeletedTask
                                                                day={days[date]}
                                                                task={task}
                                                                index={i}
                                                            />
                                                            <Divider sx={{marginBottom: '6px'}}/>
                                                        </Fragment>
                                                    </Collapse>
                                                ))
                                        }
                                        {
                                            deleted.days[date] && days[date].Tasks.length >= user.level.tasksDeletedMaxCount &&
                                            <Alert variant={"outlined"} severity="info"
                                                   sx={{
                                                       marginBottom: 2,
                                                       marginLeft: '24px'
                                                   }}
                                            >
                                                {t('You can see only _ elements of the list. To remove the restriction, change the subscription!', {
                                                    count: user.level.tasksDeletedMaxCount,
                                                })}
                                            </Alert>
                                        }
                                    </TransitionGroup>
                                </Fragment>
                            </Collapse>
                        ))}
                    </TransitionGroup>
                </List>
                {isTaskLoading &&
                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '6px 0 18px 0'}}>
                        <CircularProgress/>
                    </Box>
                }
                {!Object.keys(deleted.days).length && !isTaskLoading && !deleted.observable &&
                    <Alert variant={"outlined"} severity="info">
                        {t('You have no deleted tasks!')}
                    </Alert>
                }
                {!deleted.observable && Object.keys(deleted.days).length ?
                    <Alert variant={"outlined"} severity="info" sx={{marginBottom: 2}}>
                        {t('These are all deleted tasks!')}
                    </Alert>
                    :
                    ''
                }
                {deleted.observable &&
                    <AppObserver intersect={intersect} observing={deleted.observable} key={deleted.page}/>}
            </Box>
            <Dialog
                open={isDeleteAllDialogOpen}
                onClose={handleDeleteAllDialogClose}
                aria-labelledby="delete-all-dialog-title"
                aria-describedby="delete-all-dialog-description"
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '0.75rem'
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Are you sure you want to delete all deleted tasks?')}
                </DialogTitle>
                <DialogActions>
                    <Button variant='contained' onClick={handleDeleteAllDialogClose}>{t('Cancel')}</Button>
                    <Button variant='contained' onClick={handleDeleteAllTasks} autoFocus>
                        {t('Yes')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialogOptions.isOpen}
                onClose={handleCloseDialog}
                aria-labelledby="delete-day-dialog-title"
                aria-describedby="delete-day-dialog-description"
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '0.75rem'
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Are you sure you want to delete all tasks in this day?')}
                </DialogTitle>
                <DialogActions>
                    <Button variant='contained' onClick={handleCloseDialog}>{t('Cancel')}</Button>
                    <Button variant='contained' onClick={handleDeleteDayTasks} autoFocus>
                        {t('Yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
