import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';


interface IProps {
    path: string,
    element: React.ComponentType
}

export const GuardedRoute: FC<IProps> = ({ element: RouteComponent }) => {

    const { isAuth } = useAppSelector(state => state.auth);

    return isAuth ? <RouteComponent /> : <Navigate to="/login" />;
};
