import React, {FC, useEffect} from 'react';
import {Card, Container, Typography} from '@mui/material';
import CardContent from "@mui/material/CardContent";
import {useAppSelector} from "../../hooks/redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const RegistrationSuccess: FC = () => {

    const {t} = useTranslation();
    const {isAuth} = useAppSelector(state => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuth) {
            navigate(`/tasks`, {replace: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Container maxWidth="lg">
            <Card sx={{height: '160px', mt: 3, borderRadius: '0.625rem !important'}}>
                <CardContent sx={{padding: '2.5rem 2.5rem 2.5rem 2.5rem',}}>
                    <Typography variant="h5">
                        {t('Confirm your email.')}
                    </Typography>
                    <Typography variant="body1" sx={{mt: 3}}>
                        {t('To complete the registration, follow the link sent to your email.')}
                    </Typography>
                </CardContent>
            </Card>
        </Container>

    )
};
