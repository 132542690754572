import React, {FC, useEffect} from 'react';
import {Box, Card, CardContent, CardHeader, Divider,} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {getRelatedAccount} from "../../store/account/accountSlice";
import {RelatedAccountsItem} from "./RelatedAccountsItem";

export const RelatedAccounts: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {accounts} = useAppSelector(state => state.account);

    useEffect(() => {
        if (!accounts) {
            dispatch(getRelatedAccount());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{mt: 3}}>
            <Card sx={{mt: 2, paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '0.75rem'}}>
                <CardHeader
                    sx={{
                        display: "flex",
                        overflow: "hidden",
                        "& .MuiCardHeader-content": {
                            overflow: "hidden"
                        }
                    }}
                    title={t("Related accounts")}
                    titleTypographyProps={{noWrap: true}}
                />
                <Divider/>
                <CardContent>
                    {accounts && accounts.map((account) => (
                        <RelatedAccountsItem account={account} key={account.id}/>
                    ))}
                </CardContent>
            </Card>
        </Box>
    )
};
