import axios from "axios";
import {IAuthResponse} from "../models/response/IAuthResponse";
import {getFromStorage, saveToStorage} from "../utils/storageHelpers";

const $api = axios.create({
    withCredentials: true,
});

$api.interceptors.request.use(async (config) => {
    await getFromStorage('token')
        .then(token => {
            config.headers!.Authorization = `Bearer: ${token.value}`;
        });
    await getFromStorage('lang')
        .then(lang => {
            config.headers!['User-Language'] = lang.value || 'en';
        });

    return config;
});

$api.interceptors.response.use((config) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get<IAuthResponse>(`${process.env.REACT_APP_AUTH_API_URL}/auth/refresh`, {withCredentials: true});
            await saveToStorage('token', response.data.accessToken);
            return $api.request(originalRequest);
        } catch (e) {
            console.error('You are not logged in');
            window.location.reload();
        }
    }
    throw error;
});
export default $api;