import React, {FC} from 'react';
import {Button, CardContent, Grid, Stack, SvgIcon, Typography, useTheme} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import TableRow from "@mui/material/TableRow";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {ILevel} from "../../models/subscription/ILevel";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import {changeSubscription} from "../../store/subscription/subscriptionSlice";

interface IProps {
    level: ILevel,
    priceType: string
}

export const SubscriptionsPlansListItem: FC<IProps> = ({level, priceType, ...props}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {themeMode} = useAppSelector(state => state.utils);

    const changeLevel = () => {
        dispatch(changeSubscription(level.id))
            .unwrap()
            .then(originalPromiseResult => {
                if (originalPromiseResult.status) {
                    window.location.reload();
                }
            });
    }

    const convertLevelPrice = (price: number) => {
        return parseFloat(Number(price).toFixed(2));
    }

    const showInfinityCount = (count: number) => {
        return count === 1000000 ? <AllInclusiveIcon sx={{fontSize: '1rem'}}/> : count;
    }

    const capitalizeFirstLetter = (text: string) => {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    return (
        <Grid item xs={12} md={4}>
            <CardContent
                sx={{
                    borderRadius: '0.75rem !important',
                    backgroundColor: theme.palette.subscriptionCardBackground.main,
                }}>
                <Stack direction='row' justifyContent='center'>
                    <Typography
                        sx={{
                            fontWeight: '700 !important',
                            marginBottom: '1.25rem !important',
                            fontSize: '1.75rem'
                        }}
                    >
                        {level.title}
                    </Typography>
                </Stack>
                <Stack direction='row' justifyContent='center'>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontWeight: '500 !important',
                            marginBottom: '1.25rem !important',
                            color: theme.palette.textColorThird.main,
                            maxWidth: '160px',
                            fontSize: '13px !important'
                        }}
                    >
                        {level.description}
                    </Typography>
                </Stack>
                <Stack direction='row' justifyContent='center' alignItems="flex-end">
                    <Typography
                        sx={{
                            color: theme.palette.subscriptionPriceColor.main,
                            marginBottom: '0.5rem !important',
                            textAlign: 'center',
                            paddingBottom: '8px'
                        }}
                    >
                        $
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            color: theme.palette.subscriptionPriceColor.main,
                            fontSize: '3rem !important',
                            fontWeight: '600 !important',
                            marginLeft: '2px',
                            marginRight: '4px'
                        }}
                    >
                        {
                            priceType === 'month' ?
                                convertLevelPrice(level.monthlyPrice) :
                                convertLevelPrice(level.yearlyPrice)
                        }
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'left',
                            fontWeight: '500 !important',
                            opacity: '0.5',
                            fontSize: '0.95rem !important',
                            paddingBottom: '14px'
                        }}
                    >
                        /
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'left',
                            fontWeight: '500 !important',
                            opacity: '0.5',
                            fontSize: '0.95rem !important',
                            paddingBottom: '14px'
                        }}
                    >
                        {t(capitalizeFirstLetter(priceType))}*
                    </Typography>
                </Stack>
                <Table sx={{
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none"
                    }
                }}>
                    <TableBody>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                {t('L Planned tasks count')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                    }}
                                >
                                    {showInfinityCount(level.tasksPlannedMaxCount)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                {t('L Tasks per day')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                    }}
                                >
                                    {showInfinityCount(level.tasksPerDayMaxCount)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                {t('L Recurring tasks')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                    }}
                                >
                                    {showInfinityCount(level.tasksRecurringMaxCount)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                {t('L Lists')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                    }}
                                >
                                    {showInfinityCount(level.listsMaxCount)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                {t('L Notes')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                    }}
                                >
                                    {showInfinityCount(level.notesMaxCount)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                {t('L Journal message length')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                    }}
                                >
                                    {showInfinityCount(level.journalMessageMaxLength)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                {t('L Overdue tasks')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                    }}
                                >
                                    {showInfinityCount(level.tasksOverdueMaxCount)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                {t('L Completed tasks')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                    }}
                                >
                                    {showInfinityCount(level.tasksCompletedMaxCount)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                {t('L Deleted tasks')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                    }}
                                >
                                    {showInfinityCount(level.tasksDeletedMaxCount)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                {t('L Goals')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                    }}
                                >
                                    {showInfinityCount(level.goalsMaxSetsCount)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                {t('L Subgoals')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                    }}
                                >
                                    {showInfinityCount(level.goalsMaxSubgoalsCount)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{
                                           paddingTop: '4px',
                                           paddingBottom: '4px',
                                           color: level.emailNotification ? (themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff') : (themeMode === 'light' ? '#B5B5C3' : '#474761')
                                       }}>
                                {t('L Email notifications')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                        color: level.emailNotification ? (themeMode === 'light' ? '#50cd89' : '#50cd89') : (themeMode === 'light' ? '#B5B5C3' : '#474761')
                                    }}
                                >
                                    {level.emailNotification ?
                                        <SvgIcon sx={{color: '#50cd89'}}>
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                  fill="currentColor"></rect>
                                            <path
                                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                fill="currentColor"></path>
                                        </SvgIcon>
                                        :
                                        <SvgIcon>
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                  fill="currentColor"></rect>
                                            <rect x="7" y="15.3137" width="12" height="2" rx="1"
                                                  transform="rotate(-45 7 15.3137)" fill="currentColor"></rect>
                                            <rect x="8.41422" y="7" width="12" height="2" rx="1"
                                                  transform="rotate(45 8.41422 7)" fill="currentColor"></rect>
                                        </SvgIcon>
                                    }
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{
                                           paddingTop: '4px',
                                           paddingBottom: '4px',
                                           color: level.emailNotification ? (themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff') : (themeMode === 'light' ? '#B5B5C3' : '#474761')
                                       }}>
                                {t('L WebPush Notifications')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                        color: level.emailNotification ? (themeMode === 'light' ? '#50cd89' : '#50cd89') : (themeMode === 'light' ? '#B5B5C3' : '#474761')
                                    }}
                                >
                                    {level.webPushNotification ?
                                        <SvgIcon sx={{color: '#50cd89'}}>
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                  fill="currentColor"></rect>
                                            <path
                                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                fill="currentColor"></path>
                                        </SvgIcon>
                                        :
                                        <SvgIcon>
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                  fill="currentColor"></rect>
                                            <rect x="7" y="15.3137" width="12" height="2" rx="1"
                                                  transform="rotate(-45 7 15.3137)" fill="currentColor"></rect>
                                            <rect x="8.41422" y="7" width="12" height="2" rx="1"
                                                  transform="rotate(45 8.41422 7)" fill="currentColor"></rect>
                                        </SvgIcon>
                                    }
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                            <TableCell component="th" scope="row"
                                       sx={{
                                           paddingTop: '4px',
                                           paddingBottom: '4px',
                                           color: level.emailNotification ? (themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff') : (themeMode === 'light' ? '#B5B5C3' : '#474761')
                                       }}
                            >
                                {t('L Allow Drag and Drop')}
                            </TableCell>
                            <TableCell align="right" size={'small'}
                                       sx={{paddingTop: '4px', paddingBottom: '4px', minWidth: '100px'}}>
                                <Typography
                                    sx={{
                                        fontWeight: '500 !important',
                                        fontSize: '0.95rem !important',
                                        color: level.emailNotification ? (themeMode === 'light' ? '#50cd89' : '#50cd89') : (themeMode === 'light' ? '#B5B5C3' : '#474761')
                                    }}
                                >
                                    {level.tasksAllowDnD ?
                                        <SvgIcon sx={{color: '#50cd89'}}>
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                  fill="currentColor"></rect>
                                            <path
                                                d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                fill="currentColor"></path>
                                        </SvgIcon>
                                        :
                                        <SvgIcon>
                                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                  fill="currentColor"></rect>
                                            <rect x="7" y="15.3137" width="12" height="2" rx="1"
                                                  transform="rotate(-45 7 15.3137)" fill="currentColor"></rect>
                                            <rect x="8.41422" y="7" width="12" height="2" rx="1"
                                                  transform="rotate(45 8.41422 7)" fill="currentColor"></rect>
                                        </SvgIcon>
                                    }
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Stack direction='row' justifyContent='center'>
                    <Button
                        variant="contained"
                        onClick={changeLevel}
                        sx={{mt: 2, mb: 0, marginRight: '8px'}}
                    >
                        {t('Select')}
                    </Button>
                </Stack>
            </CardContent>
        </Grid>
    )
};
