import React, {FC} from 'react';
import {Box, Chip} from '@mui/material';

interface ITaskGoalList {
    goals: { id: number, title: string }[],
    component?: 'div' | 'span',
    size?: 'small' | 'medium',
    handleDelete?: (goalId: number) => void
}

export const TaskGoalsList: FC<ITaskGoalList> = ({
                                                     goals,
                                                     component = 'span',
                                                     size = 'small',
                                                     handleDelete
                                                 }) => {
    return (
        <Box component={component}>
            {handleDelete
                ?
                goals.map((goal, i) => (
                    <Chip
                        size={size}
                        label={goal.title}
                        variant='outlined'
                        onDelete={() => handleDelete(goal.id)}
                        sx={{
                            marginLeft: '4px',
                            borderRadius: '4px'
                        }}
                        key={goal.id}
                    />
                ))
                :
                goals.map((goal, i) => (
                    <Chip
                        size={size}
                        label={goal.title}
                        variant='outlined'
                        sx={{
                            marginLeft: '4px',
                            borderRadius: '4px'
                        }}
                        key={goal.id}
                    />
                ))
            }
        </Box>
    )
}
