import {AxiosResponse} from "axios";
import $api from "../http";
import {IGoal} from "../models/goals/IGoal";
import {ISet} from "../models/goals/ISet";
import {ITask} from "../models/ITask";
import {IGoalRemoveTaskResponse} from "../models/response/IGoalRemoveTaskResponse";

const GOAL_API_URL = process.env.REACT_APP_GOAL_API_URL;

export const goalsAPI = async (): Promise<AxiosResponse<ISet[]>> => {
    return await $api.get<ISet[]>(`${GOAL_API_URL}/goal/all`);
}

export const lastModifiedGoalsAPI = async (limit: number): Promise<AxiosResponse<IGoal[]>> => {
    return await $api.get<IGoal[]>(`${GOAL_API_URL}/goal/last-modified?limit=${limit}`);
}

export const goalsAddAPI = async (setId: number, parentId: number | undefined, goal: IGoal, date: string): Promise<AxiosResponse<ISet[]>> => {
    return await $api.post<ISet[]>(`${GOAL_API_URL}/goal/add`, {
        ...goal,
        setId,
        parentId: parentId,
        date: date
    });
}

export const goalsEditAPI = async (goal: IGoal, tasks?: ITask[], tagTitles?: string[], removedTags?: number[]): Promise<AxiosResponse<ISet[]>> => {
    return await $api.post<ISet[]>(`${GOAL_API_URL}/goal/edit/${goal.id}`, {...goal, tasks, tagTitles, removedTags});
}

export const goalsDeleteAPI = async (goalIds: number[]): Promise<AxiosResponse<ISet[]>> => {
    return await $api.post<ISet[]>(`${GOAL_API_URL}/goal/delete`, {goalIds});
}

export const goalsRemoveTaskAPI = async (goalId: number, taskId: number): Promise<AxiosResponse<IGoalRemoveTaskResponse>> => {
    return await $api.post<IGoalRemoveTaskResponse>(`${GOAL_API_URL}/goal/remove/task-from-goal`, {
        goalId: goalId,
        taskId: taskId
    });
}

export const goalsReorderAPI = async (goalId: number, newOrderId: number): Promise<AxiosResponse<ISet[]>> => {
    return await $api.post<ISet[]>(`${GOAL_API_URL}/goal/reorder/${goalId}`, {newOrderId});
}

export const goalsAddSetAPI = async (title: string, copySetId?: number): Promise<AxiosResponse<ISet>> => {
    return await $api.post<ISet>(`${GOAL_API_URL}/goal/sets/add`, {title, copySetId});
}

export const goalsEditSetAPI = async (setId: number, title: string): Promise<AxiosResponse<ISet>> => {
    return await $api.post<ISet>(`${GOAL_API_URL}/goal/sets/edit/${setId}`, {title});
}

export const goalsRemoveSetAPI = async (setId: number): Promise<AxiosResponse<number>> => {
    return await $api.post<number>(`${GOAL_API_URL}/goal/sets/delete/${setId}`);
}

export const goalsReorderSetsAPI = async (setId: number, newOrderId: number): Promise<AxiosResponse<ISet[]>> => {
    return await $api.post<ISet[]>(`${GOAL_API_URL}/goal/sets/reorder/${setId}`, {newOrderId: newOrderId});
}