import React, {ChangeEvent, FC, Fragment, useEffect, useState} from 'react';
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Chip,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    Link,
    List,
    ListItemIcon,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {Task} from '../tasks/Task';
import {ITabDay} from '../../models/days/ITabDay';
import {
    deleteList,
    editTag,
    getTagTasks,
    reorderTagTasks,
    reorderTasks,
    resetTasks,
    updateTags
} from '../../store/task/taskSlice';
import {AppObserver} from '../utilities/AppObserver';
import {TagListFilter} from './TagListFilter';
import {changeTimezone, flatArrayOfObjects, formatDateWithTimezone, showTask} from '../../utils/helpers';
import {TagTaskAddInput} from './TagTaskAddInput';
import {ITask} from '../../models/ITask';
import {TransitionGroup} from 'react-transition-group';
import {TagEditInput} from './TagEditInput';
import {DnDPicker} from '../UI/Calendar/DnDPicker';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {copyListAPI} from '../../services/TaskService';
import {getGoals, resetGoals} from '../../store/goal/goalSlice';
import {IGoal} from '../../models/goals/IGoal';
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const TagTasksList: FC = () => {

    const {t} = useTranslation();
    let navigate = useNavigate();
    const {tag} = useParams();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {user} = useAppSelector(state => state.auth);
    const {isMobile, themeMode} = useAppSelector(state => state.utils);
    const {tags, isTaskLoading, reordering} = useAppSelector(state => state.task);
    const {goals, isGoalLoading} = useAppSelector(state => state.goal);

    const [tasks, setTasks] = useState<ITask[]>([]);
    const [copyTasks, setCopyTasks] = useState<ITask[]>([]);
    const [filter, setFilter] = useState<string>('0');
    const [copyOptions, setCopyOptions] = useState<{
        isOpen: boolean,
        newTitle: string
    }>({
        isOpen: false,
        newTitle: ''
    });
    const [aliasesOptions, setAliasesOptions] = useState<{
        isOpen: boolean,
        newTitle: string,
        aliases: string[]
    }>({
        isOpen: false,
        newTitle: '',
        aliases: []
    });
    const [archiveOptions, setArchiveOptions] = useState<{
        isOpen: boolean,
        archiveType: 0 | 1 | 2
    }>({
        isOpen: false,
        archiveType: 0
    });
    const [goal, setGoal] = useState<IGoal | null>(null);
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleChangeFilter = (value: string) => {
        setFilter(value);
    }

    const handleOpenGoal = () => {
        if (!goals.length) {
            dispatch(getGoals());
        }
    }

    const handleChangeGoal = (e: any, selectedGoal: string | null) => {
        const storeGoal = flatArrayOfObjects(goals, 'SubGoals').find(goal => goal['title' as keyof typeof goal] === selectedGoal);
        const storedTag = tags.tagList.find(storedTag => storedTag.title === tag);
        if (storedTag) {
            dispatch(updateTags({
                tagFrom: storedTag.title,
                color: storedTag.color || null,
                goal: storeGoal as IGoal || null,
                aliases: aliasesOptions.aliases
            }));
            dispatch(editTag({
                tagTitle: storedTag.title,
                color: storedTag.color || undefined,
                // @ts-ignore
                goalId: storeGoal ? storeGoal.id : null
            }));
            dispatch(resetGoals());
            setGoal(storeGoal as IGoal || null);
        }
    }

    const handleClickDelete = () => {
        handleMenuClose();
        setIsOpenDeleteDialog(true);
    }

    const handleClickCopy = () => {
        handleMenuClose();
        setCopyOptions({isOpen: true, newTitle: ''});
    }

    const handleClickArchive = () => {
        handleMenuClose();
        setArchiveOptions(prevState => {
            return {
                ...prevState,
                isOpen: true
            }
        });
    }

    const handleArchiveList = () => {
        const storedTag = tags.tagList.find(storedTag => storedTag.title === tag);
        if (tag && storedTag) {
            dispatch(updateTags({
                tagFrom: tag,
                color: storedTag.color || null,
                goal: storedTag.Goal || null,
                archiveType: archiveOptions.archiveType,
                aliases: aliasesOptions.aliases
            }));
            dispatch(editTag({
                tagTitle: tag,
                isArchived: archiveOptions.archiveType
            }));
            setArchiveOptions(prevState => {
                return {
                    ...prevState,
                    isOpen: false
                }
            });
        } else {
            dispatch(setSnackbar({
                text: 'Unexpected error',
                severity: 'error',
                open: true
            }));
        }
    }

    const handleDeleteList = () => {
        const tagId = tags.tagList.find(storedTag => storedTag.title === tag)?.id;
        if (tagId) {
            dispatch(deleteList(tagId))
                .unwrap()
                .then(() => {
                    handleCloseDeleteDialog();
                    dispatch(resetTasks());
                    navigate('/lists');
                });
        }
    }

    const handleCopyList = async () => {
        if (copyOptions.newTitle.trim() !== '') {
            const tagId = tags.tagList.find(storedTag => storedTag.title === tag)?.id;
            if (tagId) {
                try {
                    await copyListAPI(tagId, copyOptions.newTitle);
                    handleCloseCopyDialog();
                    navigate(`/lists/${copyOptions.newTitle}`);
                    dispatch(resetTasks());
                } catch (e: any) {
                    if (e.response && e.response.data) {
                        dispatch(setSnackbar({
                            text: e.response.data.errors.msg,
                            severity: 'error',
                            open: true
                        }));
                    }
                }
            } else {
                dispatch(setSnackbar({
                    text: 'Unexpected error',
                    severity: 'error',
                    open: true
                }));
            }
        } else {
            dispatch(setSnackbar({
                text: 'List title cannot be empty',
                severity: 'error',
                open: true
            }));
        }
    }

    const handleCloseDeleteDialog = () => {
        setIsOpenDeleteDialog(false);
    }

    const handleChangeNewAliasesTitle = (e: ChangeEvent<HTMLInputElement>) => {
        setAliasesOptions(prevState => {
            return {
                ...prevState,
                newTitle: e.target.value
            }
        });
    }

    const handleOpenAliasesDialog = () => {
        setAliasesOptions(prevState => {
            return {
                ...prevState,
                isOpen: true
            }
        });
    }

    const handleCloseAliasesDialog = () => {
        setAliasesOptions(prevState => {
            return {
                ...prevState,
                isOpen: false
            }
        });
    }

    const handleClickAddAlias = () => {
        if (aliasesOptions.newTitle.trim() === '') {
            dispatch(setSnackbar({
                text: 'Alias title cannot be empty',
                severity: 'error',
                open: true
            }));
            return;
        }

        let isExist = false;
        tags.tagList.map(tag => {
            if (tag.title === aliasesOptions.newTitle.trim()) return isExist = true;
            if (tag.aliases && tag.aliases.length) {
                return tag.aliases.map(alias => {
                    if (alias === aliasesOptions.newTitle.trim()) return isExist = true;
                    return 1;
                });
            }
            return 1;
        });
        if (isExist) {
            dispatch(setSnackbar({
                text: 'You already have alias or tag with this title',
                severity: 'error',
                open: true
            }));
            return;
        }

        const allAliases = [...aliasesOptions.aliases, aliasesOptions.newTitle.trim()];
        const storedTag = tags.tagList.find(storedTag => storedTag.title === tag);
        if (storedTag) {
            dispatch(updateTags({
                tagFrom: storedTag.title,
                color: storedTag.color || null,
                goal: storedTag.Goal || null,
                aliases: allAliases
            }));
            dispatch(editTag({
                tagTitle: storedTag.title,
                color: storedTag.color || undefined,
                // @ts-ignore
                goalId: storedTag.Goal ? storedTag.Goal.id : null,
                aliases: allAliases
            }));

            setAliasesOptions(prevState => {
                return {
                    ...prevState,
                    newTitle: '',
                    aliases: allAliases
                }
            });
        }
    }

    const handleDeleteAlias = (alias: string) => {
        const allAliases = aliasesOptions.aliases.filter(storedAlias => storedAlias !== alias);
        const storedTag = tags.tagList.find(storedTag => storedTag.title === tag);
        if (storedTag) {
            dispatch(updateTags({
                tagFrom: storedTag.title,
                color: storedTag.color || null,
                goal: storedTag.Goal || null,
                aliases: allAliases
            }));
            dispatch(editTag({
                tagTitle: storedTag.title,
                color: storedTag.color || undefined,
                // @ts-ignore
                goalId: storedTag.Goal ? storedTag.Goal.id : null,
                aliases: allAliases
            }));

            setAliasesOptions(prevState => {
                return {
                    ...prevState,
                    newTitle: '',
                    aliases: allAliases
                }
            });
        } else {
            dispatch(setSnackbar({
                text: 'Unexpected error',
                severity: 'error',
                open: true
            }));
        }
    }

    const handleCloseArchiveDialog = () => {
        if (tag) {
            const storedTag = tags.tagList.find(obj => obj.title === tag)
            if (storedTag) {
                setArchiveOptions({isOpen: false, archiveType: storedTag.isArchived ? storedTag.isArchived : 0});
            }
        } else {
            setArchiveOptions(prevState => {
                return {
                    ...prevState,
                    isOpen: false
                }
            });
        }
    }

    const handleCloseCopyDialog = () => {
        setCopyOptions({isOpen: false, newTitle: ''});
    }

    const handleChangeNewTitle = (e: ChangeEvent<HTMLInputElement>) => {
        setCopyOptions(prevState => {
            return {
                ...prevState,
                newTitle: e.target.value
            }
        });
    }

    const handleChangeArchiveType = (e: ChangeEvent<HTMLInputElement>) => {
        setArchiveOptions(prevState => {
            return {
                ...prevState,
                archiveType: +(e.target as HTMLInputElement).value as 0 | 1 | 2
            }
        });
    }

    const moveTasks = (dragIndex: number, hoverIndex: number) => {
        const taskStateCopy = [...tasks];
        const [reorderedItem] = taskStateCopy.splice(dragIndex, 1);
        taskStateCopy.splice(hoverIndex, 0, reorderedItem);

        setTasks(taskStateCopy);
    }

    const dragEndHandle = (oldIndex: number, index: number) => {
        if (!reordering && tag) {
            let newPosition = copyTasks[index].Order.position;
            if (newPosition > copyTasks[oldIndex].Order.position) {
                newPosition += 1;
            }

            dispatch(reorderTagTasks({tagFrom: tag, oldIndex: oldIndex, newPosition: newPosition}));
            dispatch(reorderTasks({orderId: copyTasks[oldIndex].Order.id, newPosition: newPosition}));

            setCopyTasks(tasks);
        }
    }

    const intersect = () => {
        if (tag) {
            dispatch(getTagTasks({
                tagTitle: tag,
                page: tags.tagTasks[tag] ? tags.tagTasks[tag].page : 1,
                limit: 10
            }));
        }
    }

    useEffect(() => {
        if (tag) {
            const storedTag = tags.tagList.find(obj => obj.title === tag)
            if (storedTag) {
                setArchiveOptions({isOpen: false, archiveType: storedTag.isArchived ? storedTag.isArchived : 0});
                setGoal(storedTag && storedTag.Goal ? storedTag.Goal : null);
                setAliasesOptions(prevState => {
                    return {
                        ...prevState,
                        newTitle: '',
                        aliases: storedTag.aliases ? storedTag.aliases : []
                    }
                });
            }
            if (tags.tagTasks.hasOwnProperty(tag) && tags.tagTasks[tag].tasks.length) {
                setTasks(tags.tagTasks[tag].tasks);
                setCopyTasks(tags.tagTasks[tag].tasks);
            } else {
                setTasks([]);
                setCopyTasks([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag, tags.tagTasks]);


    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: isMobile ? 'space-evently' : 'space-between',
                    marginBottom: '12px',
                    marginTop: '-4px'
                }}
            >
                <Typography
                    color='primary'
                    variant='h5'
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        fontSize: '1.5rem',
                        fontWeight: '500 !important',
                        lineHeight: '1.2',
                    }}>
                    <Link
                        component={NavLink}
                        to='/lists'
                        color='inherit'
                        underline='none'
                    >
                        {t('Lists')}
                    </Link>
                    <EastIcon/>
                    #
                    {tag
                        ?
                        <TagEditInput tag={tag}/>
                        :
                        tag
                    }
                </Typography>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <DnDPicker isFullBorder/>
                    {
                        isMobile ?
                            <div>
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={menuOpen ? 'long-menu' : undefined}
                                    aria-expanded={menuOpen ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleMenuClick}
                                >
                                    <MoreVertIcon color="primary"/>
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={menuAnchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                backgroundColor: themeMode === 'light' ? 'rgb(255, 255, 255)' : "#121212",
                                                backgroundImage: themeMode === 'light' ? '' : "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                            backgroundColor: themeMode === 'light' ? '' : "#121212",
                                            backgroundImage: themeMode === 'light' ? '' : "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                                            borderRadius: '0.75rem',
                                            '& .MuiMenuItem-root': {
                                                minWidth: '120px',
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem
                                        key={0}
                                        onClick={handleClickCopy}
                                        sx={{
                                            "&:hover": {
                                                opacity: '1',
                                                color: theme.palette.primary.main,
                                                '& .MuiListItemIcon-root': {
                                                    color: theme.palette.primary.main,
                                                }
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            <ContentCopyIcon/>
                                        </ListItemIcon>
                                        <Typography textAlign="center">{t('Copy')}</Typography>
                                    </MenuItem>
                                    <MenuItem
                                        key={1}
                                        onClick={handleClickArchive}
                                        sx={{
                                            "&:hover": {
                                                opacity: '1',
                                                color: theme.palette.primary.main,
                                                '& .MuiListItemIcon-root': {
                                                    color: theme.palette.primary.main,
                                                }
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            {archiveOptions.archiveType !== 0
                                                ?
                                                <UnarchiveIcon/>
                                                :
                                                <ArchiveIcon/>
                                            }
                                        </ListItemIcon>
                                        <Typography textAlign="center">{t('Archive')}</Typography>
                                    </MenuItem>
                                    <MenuItem
                                        key={2}
                                        onClick={handleClickDelete}
                                        sx={{
                                            "&:hover": {
                                                opacity: '1',
                                                color: theme.palette.error.main,
                                                '& .MuiListItemIcon-root': {
                                                    color: theme.palette.error.main,
                                                }
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            <DeleteIcon/>
                                        </ListItemIcon>
                                        <Typography textAlign="center">{t('Delete')}</Typography>
                                    </MenuItem>
                                </Menu>
                            </div>
                            :
                            <Box>
                                <IconButton
                                    edge="start"
                                    aria-label="copy"
                                    color="primary"
                                    onClick={handleClickCopy}
                                    sx={{ml: '8px'}}
                                >
                                    <ContentCopyIcon/>
                                </IconButton>
                                <IconButton
                                    edge="start"
                                    aria-label="archive"
                                    color="primary"
                                    onClick={handleClickArchive}
                                    sx={{ml: '0px'}}
                                >
                                    {archiveOptions.archiveType !== 0
                                        ?
                                        <UnarchiveIcon/>
                                        :
                                        <ArchiveIcon/>
                                    }
                                </IconButton>
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    color="primary"
                                    onClick={handleClickDelete}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </Box>
                    }

                </Box>
            </Box>
            <Box
                sx={{
                    marginBottom: '12px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Autocomplete
                    id="combo-box-goals"
                    size="small"
                    value={goal ? goal.title : null}
                    options={flatArrayOfObjects(goals, 'SubGoals').map(goal => goal['title' as keyof typeof goal])}
                    loading={isGoalLoading}
                    // classes={{paper: classes.paper}}
                    onOpen={handleOpenGoal}
                    onChange={handleChangeGoal}
                    sx={{
                        width: '200px',
                        mb: isMobile ? '3px' : '',
                        '& .MuiInputBase-root': {
                            borderRadius: '0.5rem'
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("Goal")}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <Fragment>
                                        {isGoalLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </Fragment>
                                ),
                            }}
                        />
                    )}
                />
                <Button onClick={handleOpenAliasesDialog}>
                    {t('Aliases')}
                </Button>
            </Box>
            {tag && <TagTaskAddInput tag={tag}/>}
            <TagListFilter
                tasks={tag && tags.tagTasks[tag] ? tags.tagTasks[tag].tasks : []}
                tag={tag}
                filter={filter}
                handleChangeFilter={handleChangeFilter}
            />
            <Box sx={{overflowY: 'auto', maxHeight: !isMobile ? 'calc(100vh - 310px)' : 'calc(100vh - 310px)'}}>
                <List dense={true}>
                    <TransitionGroup>
                        {tasks.map((task, i) => (
                            showTask(filter, task) &&
                            <Collapse key={task.id}>
                                <Task
                                    task={task}
                                    day={{
                                        date: task.Day
                                            ?
                                            task.Day.date
                                            :
                                            formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))
                                    } as ITabDay}
                                    index={i}
                                    showDate
                                    moveTasks={moveTasks}
                                    dragEndHandle={dragEndHandle}
                                />
                            </Collapse>
                        ))
                        }
                    </TransitionGroup>
                    <Divider sx={{marginBottom: '4px'}}/>
                </List>
                {isTaskLoading &&
                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '6px 0 18px 0'}}>
                        <CircularProgress/>
                    </Box>
                }
                {tag && !isTaskLoading && (!tags.tagTasks[tag] || !tags.tagTasks[tag].tasks.length) &&
                    <Alert variant={"outlined"} severity="info">
                        {t('There is no task here! Plan your day and rush to the success with us!')}
                    </Alert>
                }
                {tag && (!tags.tagTasks[tag] || tags.tagTasks[tag].observable) &&
                    <AppObserver
                        intersect={intersect}
                        observing={tags.tagTasks[tag] && tags.tagTasks[tag].observable ? tags.tagTasks[tag].observable : true}
                        key={tags.tagTasks[tag] ? tags.tagTasks[tag].page : null}
                    />
                }
            </Box>
            <Dialog
                open={copyOptions.isOpen}
                onClose={handleCloseCopyDialog}
                aria-labelledby="copy-list-dialog-title"
                aria-describedby="copy-list-dialog-description"
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '0.75rem',
                        padding: '1rem'
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Are you sure you want to copy this list?')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('After copying this list, all links between tasks and goals will also be copied.')}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="newTitle"
                        label={t('New list title')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={copyOptions.newTitle}
                        onChange={handleChangeNewTitle}
                        InputProps={{
                            autoComplete: 'off',
                            sx: {
                                borderRadius: '0.75rem',
                                // background: themeMode === 'light' ? '#F9F9F9' : 'transparent',
                                "& .MuiFormHelperText-root": {
                                    margin: 0,
                                    paddingLeft: '14px',
                                    paddingRight: '14px',
                                },
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleCloseCopyDialog}>{t('Cancel')}</Button>
                    <Button variant='contained' onClick={handleCopyList} autoFocus>
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={archiveOptions.isOpen}
                onClose={handleCloseArchiveDialog}
                aria-labelledby="archive-list-dialog-title"
                aria-describedby="archive-list-dialog-description"
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '0.75rem',
                        padding: '1rem'
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Are you sure you want to archive the list?')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('Select archiving type')}
                    </DialogContentText>
                    <FormControl>
                        {/*<FormLabel>{t('Archive type')}</FormLabel>*/}
                        <RadioGroup
                            aria-labelledby="radio-archive-buttons-group-label"
                            name="radio-archive-buttons-group"
                            value={archiveOptions.archiveType}
                            onChange={handleChangeArchiveType}
                        >
                            <FormControlLabel
                                value={0}
                                control={<Radio/>}
                                label={t('normal list')}
                            />
                            <FormControlLabel
                                value={2}
                                control={<Radio/>}
                                sx={{position: 'relative'}}
                                label={t('auto archive')
                                }
                            />
                            <FormControlLabel
                                value={1}
                                control={<Radio/>}
                                sx={{position: 'relative'}}
                                label={t('archive')}
                            />
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleCloseArchiveDialog}>{t('Cancel')}</Button>
                    <Button variant='contained' onClick={handleArchiveList} autoFocus>
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isOpenDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="delete-list-dialog-title"
                aria-describedby="delete-list-dialog-description"
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '0.75rem',
                        padding: '1rem'
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Are you sure you want to delete the list?')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('After deleting the list, all links between this tag and the tasks will also be deleted.')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleCloseDeleteDialog}>{t('Cancel')}</Button>
                    <Button variant='contained' onClick={handleDeleteList} autoFocus>
                        {t('Yes')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={aliasesOptions.isOpen}
                onClose={handleCloseAliasesDialog}
                aria-labelledby="aliases-list-dialog-title"
                aria-describedby="aliases-list-dialog-description"
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '0.75rem',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        {t('Aliases')}
                        <IconButton onClick={handleCloseAliasesDialog}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                        <TextField
                            autoFocus
                            size='small'
                            id="newTitle"
                            label={t('New alias')}
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={aliasesOptions.newTitle}
                            onChange={handleChangeNewAliasesTitle}
                            sx={{mb: '12px', mr: '6px'}}
                            InputProps={{
                                autoComplete: 'off',
                                sx: {
                                    borderRadius: '0.75rem',
                                    // background: themeMode === 'light' ? '#F9F9F9' : 'transparent',
                                    "& .MuiFormHelperText-root": {
                                        margin: 0,
                                        paddingLeft: '14px',
                                        paddingRight: '14px',
                                    },
                                }
                            }}
                        />
                        <Button
                            size='small'
                            variant="contained"
                            sx={{height: '38px'}}
                            onClick={handleClickAddAlias}
                        >
                            {t('Add')}
                        </Button>
                    </Box>
                    {!aliasesOptions.aliases.length
                        ?
                        <Alert variant={"outlined"} severity="info">
                            {t('You don\'t have an alias for this tag yet')}
                        </Alert>
                        :
                        <DialogContentText id="alert-dialog-description">
                            {t('Your aliases: ')}
                            {aliasesOptions.aliases.map(alias => (
                                <Chip
                                    component='span'
                                    label={alias}
                                    size='small'
                                    variant='outlined'
                                    onDelete={() => handleDeleteAlias(alias)}
                                    key={alias}
                                />
                            ))}
                        </DialogContentText>
                    }
                </DialogContent>
            </Dialog>
        </div>
    )
}
