import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";
import {IValidationErrorsResponse} from "../../models/response/IValidationErrorsResponse";
import {IAccount} from "../../models/IAccount";
import {listAccountsAPI, removeAccountAPI} from "../../services/AccountService";
import {IRelatedAccountRemoveResponse} from "../../models/response/IRelatedAccountRemoveResponse";

interface IAccountState {
    accounts?: IAccount[] | null,
    isAccountLoading: boolean,
    isAccountChecking: boolean,
    accountError: IValidationErrorsResponse,
}

const initialState: IAccountState = {
    accounts: null,
    isAccountLoading: false,
    isAccountChecking: true,
    accountError: {} as IValidationErrorsResponse,
}

export const getRelatedAccount = createAsyncThunk(
    'account/getRelatedAccounts', async (_, {rejectWithValue}) => {
        try {
            const response = await listAccountsAPI();
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
)

export const removeRelatedAccount = createAsyncThunk(
    'account/removeRelatedAccount',
    async (
        {
            accountId,
        }: {
            accountId: number,
        },
        {rejectWithValue}
    ) => {
        try {
            const response = await removeAccountAPI(accountId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
)

export const authSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        resetAccounts: () => initialState
    },
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getRelatedAccount.pending, removeRelatedAccount.pending), (state) => {
            state.isAccountLoading = true;
        });
        builder.addMatcher(isAnyOf(getRelatedAccount.rejected, removeRelatedAccount.rejected), (state, action) => {
            state.isAccountLoading = false;
            if (action.payload === 'Unexpected error') {
                state.accountError = {
                    msg: 'Unexpected error',
                    showAlert: true
                };
            } else {
                state.accountError = {
                    msg: 'Unexpected error',
                    showAlert: false
                }
            }
        });
        builder.addMatcher(isAnyOf(getRelatedAccount.fulfilled), (state, action: PayloadAction<IAccount[]>) => {
            state.accountError = {
                msg: '',
                showAlert: false
            };
            state.accounts = action.payload;
            state.isAccountLoading = false;
        });
        builder.addMatcher(isAnyOf(removeRelatedAccount.fulfilled), (state, action: PayloadAction<IRelatedAccountRemoveResponse>) => {

            if (action.payload.status) {
                state.accountError = {
                    msg: '',
                    showAlert: false
                };

                state.accounts = state.accounts?.filter(account => account.id !== action.payload.accountId);

            } else {
                state.accountError = {
                    msg: 'Unexpected error',
                    showAlert: true
                };
            }

            state.isAccountLoading = false;
        });
    }
})

export default authSlice.reducer;
export const {
    resetAccounts
} = authSlice.actions;