import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import {store} from './store';
import './localization/i18n';
import {LoadingScreen} from './components/utilities/LoadingScreen';

// var Bugsnag = require('@bugsnag/js');
// var BugsnagPluginReact = require('@bugsnag/plugin-react');

// Bugsnag.start({
//     apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
//     plugins: [new BugsnagPluginReact()]
// });

// const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);


// ReactDOM.render(
//     <ErrorBoundary>
//         <Suspense fallback={<LoadingScreen/>}>
//             <Provider store={store}>
//                 <App/>
//             </Provider>
//         </Suspense>
//     </ErrorBoundary>,
//     document.getElementById('root')
// );


ReactDOM.render(

        <Suspense fallback={<LoadingScreen/>}>
            <Provider store={store}>
                <App/>
            </Provider>
        </Suspense>,
    document.getElementById('root')
);

