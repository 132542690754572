import React, {FC} from 'react';
import {Box, Card, CardContent, CardHeader, Container, Divider} from '@mui/material';
import {useAppSelector} from '../../hooks/redux';
import {useTranslation} from "react-i18next";
import {GetTrialFreeLevel} from "../../components/subscription/GetTrialFreeLevel";
import {SubscriptionsPlansList} from "../../components/subscription/SubscriptionsPlansList";
import {YourSubscription} from "../../components/subscription/YourSubscription";

export const Subscription: FC = () => {

    const {t} = useTranslation();
    const {isMobile} = useAppSelector(state => state.utils);

    return (
        <Container maxWidth='lg' disableGutters={!!isMobile}>
            <Box
                sx={{
                    marginTop: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflowY: 'auto',
                    maxHeight: '82vh',
                    paddingBottom: '16px'
                }}
            >
                <Box sx={{
                    transition: 'all .5s !important',
                    width: '100%',
                    maxWidth: isMobile ? '90%' : '100%',
                }}>
                    <Box sx={{mt: 3}}>
                        <Card sx={{mt: 2, paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '0.75rem'}}>
                            <CardHeader
                                sx={{
                                    display: "flex",
                                    overflow: "hidden",
                                    "& .MuiCardHeader-content": {
                                        overflow: "hidden"
                                    }
                                }}
                                title={t('Subscription')}
                                titleTypographyProps={{noWrap: true}}
                            />
                            <Divider/>
                            <CardContent>

                                <GetTrialFreeLevel/>
                                <YourSubscription/>
                                <Divider sx={{mt: 2}}/>
                                <SubscriptionsPlansList/>

                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
};
