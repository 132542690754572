import React, {FC, Fragment, useState} from 'react';
import {Box, Button, CircularProgress, Grid, TextField, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useChangePasswordForm} from '../../validation/auth/change-password-form';
import {useFormik} from 'formik';
import {useAppSelector} from "../../hooks/redux";

export const ChangePassword: FC = () => {

    const {t} = useTranslation();

    const {themeMode} = useAppSelector(state => state.utils);

    const [showChangePasswordForm, setShowChangePasswordForm] = useState<boolean>(false);

    const {
        changePasswordInitialValues,
        changePasswordValidationSchema,
        onSubmit
    } = useChangePasswordForm();

    const formik = useFormik({
        initialValues: changePasswordInitialValues,
        validationSchema: changePasswordValidationSchema,
        onSubmit: onSubmit
    });

    return (
        <Fragment>
            {
                !showChangePasswordForm ?
                    <Box>
                        <Grid container spacing={2} sx={{mt: 1}}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" sx={{fontWeight: '600 !important'}}>
                                    {t('Password')}
                                </Typography>
                                <Typography variant="body1">
                                    ******
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} style={{textAlign: "right"}}>
                                <Button
                                    variant="contained"
                                    sx={{mb: 0, marginRight: '8px'}}
                                    onClick={() => {
                                        setShowChangePasswordForm(true)
                                    }}
                                >
                                    {t('Change')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <Box component='form' onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2} sx={{mt: 1}}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" sx={{fontWeight: '600 !important'}}>
                                    {t("current password")}
                                </Typography>
                                <TextField
                                    margin='normal'
                                    id="currentPassword"
                                    name="currentPassword"
                                    type='password'
                                    fullWidth
                                    value={formik.values.currentPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                                    helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                                    sx={{
                                        background: themeMode === 'light' ? '#F9F9F9' : 'transparent',
                                        marginTop: '8px',
                                        "& .MuiInputBase-input": {
                                            padding: "8px 8px",
                                        },
                                        "& .MuiFormHelperText-root": {
                                            margin: 0,
                                            paddingLeft: '14px',
                                            paddingRight: '14px',
                                            backgroundColor: themeMode === 'light' ? '#FFFFFF' : 'transparent',
                                        },
                                    }}
                                    InputProps={{
                                        sx: {
                                            borderRadius: '0.625rem',
                                            padding: '0px'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" sx={{fontWeight: '600 !important'}}>
                                    {t("new password")}
                                </Typography>
                                <TextField
                                    margin='normal'
                                    id="newPassword"
                                    name="newPassword"
                                    type='password'
                                    fullWidth
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                                    sx={{
                                        background: themeMode === 'light' ? '#F9F9F9' : 'transparent',
                                        marginTop: '8px',
                                        "& .MuiInputBase-input": {
                                            padding: "8px 8px"
                                        },
                                        "& .MuiFormHelperText-root": {
                                            margin: 0,
                                            paddingLeft: '14px',
                                            paddingRight: '14px',
                                            backgroundColor: themeMode === 'light' ? '#FFFFFF' : 'transparent',
                                        },
                                    }}
                                    InputProps={{
                                        sx: {
                                            borderRadius: '0.625rem',
                                            padding: '0px'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="body1" sx={{fontWeight: '600 !important'}}>
                                    {t("new password confirmation")}
                                </Typography>
                                <TextField
                                    margin='normal'
                                    id="newPasswordConfirmation"
                                    name="newPasswordConfirmation"
                                    type='password'
                                    fullWidth
                                    value={formik.values.newPasswordConfirmation}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.newPasswordConfirmation && Boolean(formik.errors.newPasswordConfirmation)}
                                    helperText={formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation}
                                    sx={{
                                        background: themeMode === 'light' ? '#F9F9F9' : 'transparent',
                                        marginTop: '8px',
                                        "& .MuiInputBase-input": {
                                            padding: "8px 8px"
                                        },
                                        "& .MuiFormHelperText-root": {
                                            margin: 0,
                                            paddingLeft: '14px',
                                            paddingRight: '14px',
                                            backgroundColor: themeMode === 'light' ? '#FFFFFF' : 'transparent',
                                        },
                                    }}
                                    InputProps={{
                                        sx: {
                                            borderRadius: '0.625rem',
                                            padding: '0px'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} style={{textAlign: "right"}}>
                                <Button
                                    type='submit'
                                    variant="contained"
                                    sx={{mb: 0, marginRight: '8px'}}
                                    disabled={formik.isSubmitting}
                                    endIcon={
                                        formik.isSubmitting
                                            ?
                                            <CircularProgress color='inherit' size={'1rem'}/>
                                            :
                                            undefined
                                    }
                                >
                                    {t('Save')}
                                </Button>
                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => {
                                        setShowChangePasswordForm(false)
                                    }}
                                >
                                    {t('Cancel')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
            }
        </Fragment>
    )
}
