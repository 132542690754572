import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";
import {IUser} from "../../models/IUser";
import {IAuthResponse} from "../../models/response/IAuthResponse";
import {IValidationErrorsResponse} from "../../models/response/IValidationErrorsResponse";
import {
    accountActivationAPI,
    changePasswordAPI,
    checkAuthAPI,
    checkSocialLoginAPI,
    editProfileAPI,
    finalRegisterAPI,
    getSocialRedirectLinkAPI,
    loginAPI,
    logoutAllAPI,
    logoutAPI,
    registerAPI,
    saveRelatedAccountAPI
} from "../../services/AuthService";
import {IRegisterResponse} from "../../models/response/IRegisterResponse";
import {IActivationAccount} from "../../models/IActivationAccount";
import {IAccountActivationResponse} from "../../models/response/IAccountActivationResponse";
import {ICheckSocialLoginResponse} from "../../models/response/ICheckSocialLoginResponse";
import {removeItemFromStorage, saveToStorage} from "../../utils/storageHelpers";

interface IAuthState {
    user: IUser,
    isAuth: boolean,
    isAuthLoading: boolean,
    isAuthChecking: boolean,
    authError: IValidationErrorsResponse,
    accountForActivation: IActivationAccount,
    relatedAccounts: IActivationAccount[]
}

const initialState: IAuthState = {
    user: {} as IUser,
    isAuth: false,
    isAuthLoading: false,
    isAuthChecking: true,
    authError: {} as IValidationErrorsResponse,
    accountForActivation: {} as IActivationAccount,
    relatedAccounts: []
}

export const register = createAsyncThunk(
    'auth/register',
    async (
        {
            name,
            email,
            password,
            confirmPassword,
            timezoneName,
            timezoneOffset,
            referralCode,
            lang
        }: {
            name: string,
            email: string,
            password: string,
            confirmPassword: string,
            timezoneName: string,
            timezoneOffset: number,
            referralCode: string
            lang: string
        },
        {rejectWithValue}
    ) => {
        try {
            const response = await registerAPI(name, email, password, confirmPassword, timezoneName, timezoneOffset, referralCode, lang);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
)

export const finalRegister = createAsyncThunk(
    'auth/finalRegister',
    async (
        {
            id,
            name,
            provider,
            confirmHash,
            timezoneName,
            timezoneOffset,
            referralCode,
            lang
        }: {
            id: number,
            name: string,
            provider: string,
            confirmHash: string,
            timezoneName: string,
            timezoneOffset: number,
            referralCode: string,
            lang: string
        },
        {rejectWithValue}
    ) => {
        try {
            const response = await finalRegisterAPI(id, name, provider, confirmHash, timezoneName, timezoneOffset, referralCode, lang);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
)

export const saveRelatedAccount = createAsyncThunk(
    'auth/saveRelatedAccount',
    async (
        {
            id,
            name,
            provider,
            confirmHash,
        }: {
            id: number,
            name: string,
            provider: string,
            confirmHash: string,
        },
        {rejectWithValue}
    ) => {
        try {
            const response = await saveRelatedAccountAPI(id, name, provider, confirmHash);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
)

export const getSocialRedirectLink = createAsyncThunk(
    'auth/getSocialRedirectLink',
    async (
        {
            provider,
        }: {
            provider: string,
        },
        {rejectWithValue}
    ) => {
        try {
            const response = await getSocialRedirectLinkAPI(provider);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
)

export const accountActivation = createAsyncThunk(
    'auth/accountActivation',
    async (
        {
            confirmHash,
        }: {
            confirmHash: string,
        },
        {rejectWithValue}
    ) => {
        try {
            const response = await accountActivationAPI(confirmHash);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
)

export const checkSocialLogin = createAsyncThunk(
    'auth/checkSocialLogin',
    async (
        {
            confirmHash,
        }: {
            confirmHash: string,
        },
        {rejectWithValue}
    ) => {
        try {
            const response = await checkSocialLoginAPI(confirmHash);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
)

export const login = createAsyncThunk(
    'auth/login',
    async ({email, password}: { email: string, password: string }, {rejectWithValue}) => {
        try {
            const response = await loginAPI(email, password);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
);

export const checkAuth = createAsyncThunk(
    'auth/checkAuth',
    async (_, {rejectWithValue}) => {
        try {
            const response = await checkAuthAPI();
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
);

export const logout = createAsyncThunk(
    'auth/logout',
    async (_, {rejectWithValue}) => {
        try {
            const response = await logoutAPI();
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
);

export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async ({
               currentPassword,
               newPassword,
               newPasswordConfirmation
           }: {
        currentPassword: string,
        newPassword: string,
        newPasswordConfirmation: string
    }, {rejectWithValue}) => {
        try {
            const response = await changePasswordAPI(currentPassword, newPassword, newPasswordConfirmation);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
);

export const editProfile = createAsyncThunk(
    'auth/editProfile',
    async (user: IUser, {rejectWithValue}) => {
        try {
            const response = await editProfileAPI(user);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
);

export const logoutAll = createAsyncThunk(
    'auth/logoutAll',
    async (_, {rejectWithValue}) => {
        try {
            const response = await logoutAllAPI();
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response?.data?.errors || 'Unexpected error');
        }
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state, actions: PayloadAction<IUser>) {
            state.user = actions.payload;
        },
        setIsAuth(state, actions: PayloadAction<boolean>) {
            state.isAuth = actions.payload;
        },
        setIsAuthLoading(state, actions: PayloadAction<boolean>) {
            state.isAuthLoading = actions.payload
        },
        setIsAuthChecking(state, actions: PayloadAction<boolean>) {
            state.isAuthChecking = actions.payload;
        },
        setError(state, actions: PayloadAction<IValidationErrorsResponse>) {
            state.authError = actions.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(editProfile.fulfilled, (state, action: PayloadAction<IUser>) => {
            state.user = {
                ...action.payload,
                level: state.user.level
            }
        });
        builder.addMatcher(isAnyOf(register.pending, finalRegister.pending, checkSocialLogin.pending, login.pending, logout.pending, logoutAll.pending, changePassword.pending, accountActivation.pending), (state) => {
            state.isAuthLoading = true;
        });
        builder.addMatcher(isAnyOf(register.rejected, finalRegister.rejected, checkSocialLogin.rejected, login.rejected, logout.rejected, logoutAll.rejected, editProfile.rejected, changePassword.rejected, accountActivation.rejected), (state, action) => {
            state.isAuthLoading = false;
            if (action.payload === 'Unexpected error') {
                state.authError = {
                    msg: 'Unexpected error',
                    showAlert: true
                };
            } else {
                state.authError = {
                    msg: 'Unexpected error',
                    showAlert: false
                }
            }
        });
        builder.addMatcher(isAnyOf(login.fulfilled, finalRegister.fulfilled, checkAuth.fulfilled, changePassword.fulfilled), (state, action: PayloadAction<IAuthResponse>) => {
            state.authError = {
                msg: '',
                showAlert: false
            };
            state.isAuth = true;
            state.user = action.payload.user;
            state.accountForActivation = {} as IActivationAccount;
            state.isAuthLoading = false;

            //Save toke nto storage
            const saveTokenToStorage = async () => {
                await saveToStorage('token', action.payload.accessToken);
                await saveToStorage('refreshToken', action.payload.refreshToken);
            }
            saveTokenToStorage();
        });
        builder.addMatcher(isAnyOf(register.fulfilled), (state, action: PayloadAction<IRegisterResponse>) => {
            state.authError = {
                msg: '',
                showAlert: false
            };
            state.isAuthLoading = false;
        });
        builder.addMatcher(isAnyOf(checkSocialLogin.fulfilled), (state, action: PayloadAction<ICheckSocialLoginResponse>) => {
            state.authError = {
                msg: '',
                showAlert: false
            };

            if (action.payload.userExist) {
                state.isAuth = true;
                state.user = action.payload.authData.user;
                state.isAuthLoading = false;

                //Save toke nto storage
                const saveTokenToStorage = async () => {
                    await saveToStorage('token', action.payload.authData.accessToken);
                }
                saveTokenToStorage();
            } else {
                state.accountForActivation = action.payload.selectModeData;
            }

            state.isAuthLoading = false;
        });
        builder.addMatcher(isAnyOf(accountActivation.fulfilled), (state, action: PayloadAction<IAccountActivationResponse>) => {
            state.authError = {
                msg: '',
                showAlert: false
            };
            state.accountForActivation = action.payload;
            state.isAuthLoading = false;
        });
        builder.addMatcher(isAnyOf(logout.fulfilled, logoutAll.fulfilled, checkAuth.rejected), (state) => {
            const removeTokenFromStorage = async () => {
                await removeItemFromStorage('token');
                await removeItemFromStorage('refreshToken');
            }
            removeTokenFromStorage();

            state.isAuth = false;
            state.isAuthLoading = false;
            state.isAuthChecking = false;
            state.user = ({} as IUser);
        });
    }
})

export default authSlice.reducer;
export const {
    setUser,
    setIsAuth,
    setIsAuthLoading,
    setIsAuthChecking,
    setError,
} = authSlice.actions;