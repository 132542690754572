import React, {FC,} from 'react';
import {Box, Card, CardContent, Container, Stack, Typography} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {TaskList} from '../../components/tasks/TaskList';
import {DaysTabs} from '../../components/UI/DaysTabs/DaysTabs';
import {useTranslation} from 'react-i18next';
import {ITabDay} from '../../models/days/ITabDay';
import {DnDPicker} from '../../components/UI/Calendar/DnDPicker';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import {toggleEditable} from "../../store/task/taskSlice";

export const Tasks: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {isMobile} = useAppSelector(state => state.utils);
    const {isEditable} = useAppSelector(state => state.task);

    const handleEditClick = () => {
        dispatch(toggleEditable());
    }

    return (
        <Container maxWidth='lg' disableGutters={true}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingBottom: '16px',
                    // marginLeft: '5px',
                    // marginRight: '5px'
                }}
            >
                <Box sx={{
                    transition: 'all .5s !important',
                    width: '100%',
                    maxWidth: isMobile ? '96%' : '100%',
                }}>
                    <Box sx={{mt: isMobile ? 0 : 3}}>
                        <Card sx={{
                            mt: 2,
                            paddingLeft: isMobile ? '0rem' : '1rem',
                            paddingRight: isMobile ? '0rem' : '1rem',
                            borderRadius: '0.75rem'
                        }}
                        >
                            <CardContent sx={{
                                padding: '16px !important',
                                height: isMobile ? 'calc(100vh - 88px)' : 'calc(100vh - 104px)'
                            }}>
                                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                                    <Stack direction="row" spacing={0}>
                                        <Typography
                                            color='primary'
                                            variant='h5'
                                            display="inline"
                                            sx={{
                                                marginLeft: isMobile ? '6px' : '0px',
                                                marginBottom: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                fontSize: '1.5rem',
                                                fontWeight: '500 !important',
                                                lineHeight: '1.2',
                                            }}
                                        >
                                            {t('Tasks')}
                                        </Typography>
                                        {
                                            isEditable
                                                ?
                                                <RemoveRedEyeIcon
                                                    color='primary'
                                                    sx={{
                                                        ml: 2,
                                                        mt: '2px',
                                                        fontSize: '1.5rem',
                                                        cursor: 'pointer',
                                                        fontWeight: '500 !important',
                                                        lineHeight: '1.2',
                                                    }}
                                                    onClick={handleEditClick}
                                                />
                                                :
                                                <EditIcon
                                                    color='primary'
                                                    sx={{
                                                        ml: 2,
                                                        mt: '2px',
                                                        fontSize: '1.5rem',
                                                        cursor: 'pointer',
                                                        fontWeight: '500 !important',
                                                        lineHeight: '1.2',
                                                    }}
                                                    onClick={handleEditClick}
                                                />
                                        }
                                    </Stack>
                                    <Box sx={{ml: isMobile ? '8px' : '16px'}}>
                                        <DnDPicker isFullBorder copy/>
                                        <DnDPicker
                                            isFullBorder
                                            style={{
                                                marginLeft: '8px'
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <DaysTabs>
                                    <TaskList day={{} as ITabDay}/>
                                </DaysTabs>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}
