import React, {FC, ReactNode, SyntheticEvent, useEffect, useState} from 'react';
import {Box, styled, Tab, Tabs, TabScrollButton, useTheme} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {AddSetForm} from './AddSetForm,';
import {SetTabLabel} from './SetTabLabel';
import {IDragSet} from '../../../models/dnd/IDragSet';
import {ISet} from '../../../models/goals/ISet';
import {reorderSets} from '../../../store/goal/goalSlice';
import {buttonUnstyledClasses} from "@mui/base/ButtonUnstyled";

interface IGoalTabsProps {
    onTabChange?: (setId: number | null) => void,
    children?: ReactNode,
    selectedSetIndex?: number
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

const MyTabScrollButton = styled(TabScrollButton)({
    '&.Mui-disabled': {
        width: 0,
    },
    overflow: 'hidden',
    transition: 'width 0.5s',
    width: 28,
});

const CustomTab = styled(Tab)`
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 8px 8px;
  //margin: 6px 6px;
  border: none;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;


  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const GoalsTabs: FC<IGoalTabsProps> = ({onTabChange, children, selectedSetIndex = undefined, ...props}) => {

    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {sets, goalAction, isGoalLoading} = useAppSelector(state => state.goal);

    const [value, setValue] = useState<number>(0);
    const [copySets, setCopySets] = useState<ISet[]>([]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
        if (onTabChange !== undefined) {
            onTabChange(sets.length - 1 < newValue ? null : sets[newValue].id);
        }
    }

    const dragEndHandle = (item: IDragSet) => {
        const {set, oldIndex, index} = item;

        if (oldIndex === index) return;

        let newOrderId = copySets[index].orderId;
        if (newOrderId > copySets[oldIndex].orderId) {
            newOrderId += 1;
        }

        dispatch(reorderSets({
            setId: set.id,
            newOrderId: newOrderId
        }));
    }

    useEffect(() => {
        setCopySets(sets);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goalAction]);

    useEffect(() => {
        if (selectedSetIndex) {
            setValue(selectedSetIndex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSetIndex]);

    return (
        <Box style={{display: 'grid'}}>
            <Tabs
                ScrollButtonComponent={MyTabScrollButton}
                allowScrollButtonsMobile
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="goals tabs"
                sx={{
                    '& .MuiTypography-caption': {
                        fontSize: '12px'
                    },
                    '& .MuiTypography-body1': {
                        fontWeight: '500'
                    },
                }}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "transparent"
                    }
                }}
            >
                {sets.map((set, i) => (
                    <CustomTab
                        label={
                            <SetTabLabel
                                set={set}
                                index={i}
                                dragEndHandle={dragEndHandle}
                            />
                        }
                        {...a11yProps(i)}
                        sx={{
                            textTransform: 'none',
                            minWidth: 'fit-content',
                            flex: 1,
                            padding: 0,
                            color: theme.palette.primary.main,
                            borderLeft: value === i ? `1px solid ${theme.palette.borderColor.main}` : 'none',
                            borderTop: value === i ? `1px solid ${theme.palette.borderColor.main}` : 'none',
                            borderRight: value === i ? `1px solid ${theme.palette.borderColor.main}` : 'none',
                            borderBottom: value !== i ? `1px solid ${theme.palette.borderColor.main}` : 'none',
                            borderRadius: '0.75rem 0.75rem 0 0',
                            '&:hover': {
                                color: theme.palette.primary.dark,
                            }
                        }}
                        key={set.id}
                    />
                ))}
                {!isGoalLoading
                    ?
                    <CustomTab
                        label={<AddIcon/>}
                        {...a11yProps(sets.length)}
                        sx={{
                            textTransform: 'none',
                            minWidth: '60px',
                            flex: 1,
                            padding: 0,
                            borderLeft: value === sets.length ? `1px solid ${theme.palette.borderColor.main}` : 'none',
                            borderTop: value === sets.length ? `1px solid ${theme.palette.borderColor.main}` : 'none',
                            borderRight: value === sets.length ? `1px solid ${theme.palette.borderColor.main}` : 'none',
                            borderBottom: value !== sets.length ? `1px solid ${theme.palette.borderColor.main}` : 'none',
                            borderRadius: '0.75rem 0.75rem 0 0',
                            '&:hover': {
                                color: theme.palette.primary.dark,
                            }
                        }}
                        key={sets.length}
                    />
                    :
                    ''
                }
            </Tabs>
            {sets.map((set, i) => (
                <div
                    role="tabpanel"
                    hidden={value !== i}
                    id={`simple-tabpanel-${i}`}
                    aria-labelledby={`simple-tab-${i}`}
                    key={i}
                    {...props}
                    style={{
                        overflow: 'auto'
                    }}
                >
                    {value === i && (
                        <Box>
                            {React.cloneElement(children as React.ReactElement<any>, {setId: set.id})}
                        </Box>
                    )}
                </div>
            ))}
            {!isGoalLoading
                ?
                <div
                    role="tabpanel"
                    hidden={value !== sets.length}
                    id={`simple-tabpanel-${sets.length}`}
                    aria-labelledby={`simple-tab-${sets.length}`}
                    key={sets.length}
                    {...props}
                >
                    {value === sets.length && (
                        <AddSetForm onTabChange={onTabChange}/>
                    )}
                </div>
                :
                ''
            }
        </Box>
    )
}
