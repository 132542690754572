import {Button, useTheme} from '@mui/material';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

export const GoalDreamLabel: FC = () => {

    const {t} = useTranslation();
    const theme = useTheme();

    return (
        <Button
            sx={{
                color: theme.palette.goalLabelIsDreamTextColor.main,
                backgroundColor: theme.palette.goalLabelIsDreamBackgroundColor.main,
                fontWeight: '600 !important',
                fontSize: '0.85rem !important',
                paddingTop: '0.25rem !important',
                paddingBottom: '0.25rem !important',
                paddingRight: '0.75rem !important',
                paddingLeft: '0.75rem !important',
                marginLeft: '0.5rem !important',
                marginRight: '0.5rem !important',
                borderRadius: '0.75rem',
                textTransform: 'none',
                '&:hover': {
                    color: theme.palette.goalLabelIsDreamTextColor.main,
                    backgroundColor: theme.palette.goalLabelIsDreamBackgroundColor.main,
                },
            }}>
            {t('It is just dream')}
        </Button>
    )
}
