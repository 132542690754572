import {Box, styled, Typography} from '@mui/material';
import React, {FC} from 'react';
import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";
import {useAppSelector} from "../../../hooks/redux";


const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

interface ILinearProgressWithLabelProps {
    value?: number,
    displayValue?: boolean
}

export const LinearProgressWithLabel: FC<ILinearProgressWithLabelProps> = ({
                                                                               value = 0,
                                                                               displayValue = true,
                                                                               ...props
                                                                           }) => {
    const {isMobile} = useAppSelector(state => state.utils);
    return (
        <Box sx={{position: 'relative', display: 'inline-flex'}}>
            <BorderLinearProgress
                variant="determinate"
                value={value}
                sx={{
                    width: '80px',
                    marginTop: isMobile ? '4px' : '8px',
                }}
            />
            {
                displayValue &&
                <Box
                    sx={{
                        // top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingBottom: '4px'
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >{`${value}%`}</Typography>
                </Box>
            }

        </Box>
    )
}
