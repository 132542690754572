import React, {FC, useEffect, useState} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Slider,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {IGoal} from '../../models/goals/IGoal';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {changeTimezone, formatDateWithTimezone} from '../../utils/helpers';
import {getUserTags, resetTags} from '../../store/task/taskSlice';
import {ITag} from '../../models/tags/ITag';
import {editGoal} from '../../store/goal/goalSlice';

interface IGoalEditDialogProps {
    open: boolean,
    goal: IGoal,
    onClose: () => void
}

const filterTags = (tags: ITag[]): string[] => {
    const result: string[] = [];
    tags.filter(tag => tag.isArchived === 0 && tag.Goal === null).map(tag => {
        if (tag.aliases && tag.aliases.length) {
            result.push(tag.title);
            return tag.aliases.map(alias => result.push(alias));
        } else {
            return result.push(tag.title);
        }
    });

    return result;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export const GoalEditDialog: FC<IGoalEditDialogProps> = ({open, goal, onClose, ...props}) => {

    const theme = useTheme();
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {user} = useAppSelector(state => state.auth);
    const {tags} = useAppSelector(state => state.task);

    const [locale, setLocale] = useState<Locale>();
    const [editableGoal, setEditableGoal] = useState<IGoal>(goal);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
        setEditableGoal(prevState => {
            return {
                ...prevState,
                [field]: e.target.value
            }
        });
    }

    const handleCheckRemind = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditableGoal(prevState => {
            return {
                ...prevState,
                isRemind: e.target.checked
            }
        });
    }

    const handleCheckCompleted = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditableGoal(prevState => {
            return {
                ...prevState,
                isCompleted: e.target.checked
            }
        });
        if (!e.target.checked) {
            setEditableGoal(prevState => {
                return {
                    ...prevState,
                    completedAt: null
                }
            });
        } else {
            setEditableGoal(prevState => {
                return {
                    ...prevState,
                    completedAt: formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))
                }
            });
        }
    }

    const handleCheckDream = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditableGoal(prevState => {
            return {
                ...prevState,
                isDream: e.target.checked
            }
        });
    }

    const handleChangeStartDate = (value: Date | null) => {
        setEditableGoal(prevState => {
            return {
                ...prevState,
                startDate: value ? formatDateWithTimezone(value) : value
            }
        });
    }

    const handleChangeEndDate = (value: Date | null) => {
        setEditableGoal(prevState => {
            return {
                ...prevState,
                endDate: value ? formatDateWithTimezone(value) : value
            }
        });
    }

    const handleChangeArchivedDate = (value: Date | null) => {
        setEditableGoal(prevState => {
            return {
                ...prevState,
                completedAt: value ? formatDateWithTimezone(value) : value
            }
        });
    }

    const handleChangeProgress = (value: number | number[]) => {
        if (Array.isArray(value)) return;
        setEditableGoal(prevState => {
            return {
                ...prevState,
                progress: value
            }
        });
    }

    const handleCancelClick = () => {
        setEditableGoal(goal);
        // @ts-ignore
        setSelectedTags(goal.UserTags.map(userTag => {
            return userTag!.Tag!.title;
        }));

        onClose();
    }

    const onOpenTagsHandle = () => {
        if (!tags.fetched) {
            dispatch(getUserTags(false));
        }
    }

    const onTagSelectHandle = (event: any, value: string[]) => {
        setSelectedTags(value);
    }

    const handleSaveClick = () => {
        if (editableGoal.title.trim() === '') {
            dispatch(setSnackbar({
                text: 'Goal title cannot be empty',
                severity: 'error',
                open: true
            }));
            return;
        }

        const mapedGoalTagTitles = goal.UserTags.map(userTag => {
            return userTag!.Tag!.title;
        });
        const uniqueTagTitles = selectedTags.filter(selectedTag => !mapedGoalTagTitles.includes(selectedTag));
        const removedTags = goal.UserTags.filter(userTag => !selectedTags.includes(userTag!.Tag!.title)).map(userTag => userTag.tagId);

        dispatch(editGoal({
            goal: editableGoal,
            tagTitles: uniqueTagTitles,
            removedTags: removedTags
        }))
            .unwrap()
            .then(() => {
                if (uniqueTagTitles.length || removedTags.length) {
                    dispatch(resetTags());
                }
                onClose();
            });
    }

    useEffect(() => {
        // @ts-ignore
        setSelectedTags(goal.UserTags.map(userTag => {
            return userTag!.Tag!.title;
        }));
    }, [goal.UserTags]);

    useEffect(() => {
        switch (user.lang) {
            case 'en':
                import('date-fns/locale/en-US/index').then(locale => updateLocale(locale.default));
                break;
            case 'ru':
                import('date-fns/locale/ru/index').then(locale => updateLocale(locale.default));
                break;
            case 'pl':
                import('date-fns/locale/pl/index').then(locale => updateLocale(locale.default));
                break;
            case 'ua':
                import('date-fns/locale/uk/index').then(locale => updateLocale(locale.default));
                break;
            default:
                import('date-fns/locale/en-US/index').then(locale => updateLocale(locale.default));
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.lang, user.weekStartsOn]);

    const updateLocale = (locale: Locale) => {
        let loc = locale;
        if (loc && loc.options) {
            loc.options.weekStartsOn = user.weekStartsOn as 0 | 1 | 2 | 3 | 4 | 5 | 6;
        }
        setLocale(loc);
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '0.75rem'
                }
            }}
        >
            <DialogTitle>{t('Goal edit')}</DialogTitle>
            <DialogContent sx={{px: isMobile ? '8px' : '20px', py: '10px !important'}}>
                <TextField
                    fullWidth
                    label={t('Title')}
                    size='small'
                    variant='outlined'
                    InputProps={{
                        autoComplete: 'off',
                        sx: {
                            borderRadius: '0.75rem',
                            // background: themeMode === 'light' ? '#F9F9F9' : 'transparent',
                            "& .MuiFormHelperText-root": {
                                margin: 0,
                                paddingLeft: '14px',
                                paddingRight: '14px',
                            },
                        }
                    }}
                    value={editableGoal.title}
                    onChange={(e) => {
                        handleChange(e, 'title')
                    }}
                />
                <Box>
                    <Box sx={{display: !isMobile ? 'flex' : 'block', justifyContent: 'space-between'}}>
                        <Autocomplete
                            multiple
                            disableCloseOnSelect
                            id='tags'
                            size='small'
                            sx={{
                                width: '100%',
                                mt: '8px',
                                pr: isMobile ? '0px' : '6px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '0.75rem'
                                }
                            }}
                            onOpen={onOpenTagsHandle}
                            options={filterTags(tags.tagList)}
                            value={selectedTags}
                            onChange={onTagSelectHandle}
                            freeSolo
                            renderOption={(props, option, {selected}) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{marginRight: 8}}
                                        checked={selected}
                                    />
                                    {option}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label={t('Tags')}
                                    placeholder={t('+ Link tag to goal')}
                                />
                            )}
                            componentsProps={{
                                paper: {
                                    sx: {
                                        mt: '6px',
                                        borderRadius: '0.75rem'
                                    }
                                }
                            }}
                        />
                        <Box
                            sx={{
                                width: isMobile ? '100%' : '50%',
                                border: '1px solid',
                                borderRadius: '0.75rem',
                                borderColor: theme.palette.borderColor.main,
                                mt: '6px',
                                padding: '7px 0px'
                            }}
                        >
                            <FormGroup sx={{pl: '16px'}}>
                                <FormControlLabel
                                    control={<Checkbox
                                        name='isDream'
                                        checked={editableGoal.isDream}
                                        onChange={handleCheckDream}
                                        sx={{padding: 0, mr: '4px'}}
                                    />}
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        borderRadius: '0.75rem'
                                    }}
                                    label={t('Is dream')}
                                />
                            </FormGroup>
                        </Box>
                        <Box
                            sx={{
                                width: isMobile ? '100%' : '50%',
                                border: '1px solid',
                                borderRadius: '0.75rem',
                                borderColor: theme.palette.borderColor.main,
                                mt: '6px',
                                padding: '7px 0px',
                                paddingLeft: '16px',
                                marginLeft: isMobile ? '0px' : '4px'
                            }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        name='isRemindEveryDay'
                                        checked={editableGoal.isRemind}
                                        onChange={handleCheckRemind}
                                        sx={{padding: 0, mr: '4px'}}
                                    />}
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                    label={t('Remind me every day')}
                                />
                            </FormGroup>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: isMobile ? 'block' : 'flex',
                        justifyContent: 'space-between',
                        gap: '6px',
                        mt: '6px'
                    }}>
                        <TextField
                            fullWidth
                            multiline
                            minRows={6}
                            maxRows={Infinity}
                            label={`${t('Description')}`}
                            size='small'
                            variant="outlined"
                            value={editableGoal.description ? editableGoal.description : ''}
                            onChange={(e) => {
                                handleChange(e, 'description')
                            }}
                            InputProps={{
                                autoComplete: 'off',
                                sx: {
                                    borderRadius: '0.75rem'
                                }
                            }}
                        />
                        <Box
                            sx={{
                                width: isMobile ? '100%' : '50%',
                                padding: isMobile ? '' : '9px 8px 10px 8px',
                                marginTop: isMobile ? '6px' : '0px',
                                border: isMobile ? '' : '1px solid',
                                borderRadius: '0.75rem',
                                borderColor: theme.palette.borderColor.main,
                            }}
                        >
                            <Typography>
                                {t('The period of achievement of the goal')}
                            </Typography>
                            <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
                                <FormControl size='small'
                                             sx={{
                                                 mt: '10px',
                                                 width: '100%',
                                                 '& .MuiOutlinedInput-root': {
                                                     borderRadius: '0.75rem !important'
                                                 }
                                             }}
                                >
                                    <DesktopDatePicker
                                        disableHighlightToday
                                        label={t('Start date')}
                                        inputFormat="yyyy/MM/dd"
                                        value={editableGoal.startDate}
                                        onChange={handleChangeStartDate}
                                        renderInput={(params) => (
                                            <TextField
                                                name="startDate"
                                                size='small'
                                                {...params}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl size='small'
                                             sx={{
                                                 mt: '16px',
                                                 width: '100%',
                                                 '& .MuiOutlinedInput-root': {
                                                     borderRadius: '0.75rem !important'
                                                 }
                                             }}
                                >
                                    <DesktopDatePicker
                                        disableHighlightToday
                                        disablePast
                                        label={t('End date')}
                                        inputFormat="yyyy/MM/dd"
                                        value={editableGoal.endDate}
                                        onChange={handleChangeEndDate}
                                        renderInput={(params) => (
                                            <TextField
                                                name="endDate"
                                                size='small'
                                                {...params}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </LocalizationProvider>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{px: '4px'}}>
                    <Typography sx={{my: '6px'}}>
                        {t('Progress: ') + editableGoal.progress + '%'}
                    </Typography>
                    <Slider
                        valueLabelDisplay="auto"
                        value={editableGoal.progress}
                        onChange={(e, value) => handleChangeProgress(value)}
                    />
                </Box>
                <Box sx={{px: '4px'}}>
                    <Grid container
                          spacing={1}
                    >
                        <Grid item xs={12} md={6}>
                            <FormGroup>
                                <FormControl size='small'
                                             sx={{
                                                 width: '100%',
                                                 '& .MuiOutlinedInput-root': {
                                                     borderRadius: '0.75rem !important'
                                                 }
                                             }}
                                >
                                    <FormControlLabel
                                        control={<Checkbox
                                            name='isCompleted'
                                            checked={editableGoal.isCompleted}
                                            onChange={handleCheckCompleted}
                                        />}
                                        sx={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}
                                        label={t('The goal has been achieved')}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
                                <FormControl size='small'
                                             sx={{
                                                 width: '100%',
                                                 '& .MuiOutlinedInput-root': {
                                                     borderRadius: '0.75rem !important'
                                                 }
                                             }}
                                >
                                    <DesktopDatePicker
                                        disableHighlightToday
                                        disabled={!editableGoal.isCompleted}
                                        label={t('Date of achievement')}
                                        inputFormat="yyyy/MM/dd"
                                        value={editableGoal.completedAt}
                                        onChange={handleChangeArchivedDate}
                                        renderInput={(params) => (
                                            <TextField
                                                name="completedAt"
                                                size='small'
                                                {...params}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </LocalizationProvider>
                        </Grid>
                    </Grid>


                </Box>
            </DialogContent>
            <DialogActions>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                        px: isMobile ? '2px' : '12px',
                        py: '4px'
                    }}
                >
                    <Button variant='contained' onClick={handleCancelClick}>
                        {t('Cancel')}
                    </Button>
                    <Button variant='contained' onClick={handleSaveClick}>
                        {t('Save')}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
