import React, {FC} from 'react';
import {Box, Collapse, List} from '@mui/material';
import {IGoal} from '../../models/goals/IGoal';
import {Goal} from './Goal';
import {TransitionGroup} from 'react-transition-group';
import {IDragGoal} from '../../models/dnd/IDragGoal';

interface ISubGoalList {
    subgoals: IGoal[],
    isEditable: boolean,
    isGoalSending: boolean,
    dragEndHandle: (item: IDragGoal) => void,
}

export const SubGoalList: FC<ISubGoalList> = ({
                                                  subgoals,
                                                  isEditable,
                                                  isGoalSending,
                                                  dragEndHandle,
                                                  ...props
                                              }) => {
    return (
        <Box sx={{ml: 2}}>
            <List dense={true} sx={{paddingY: 0}}>
                <TransitionGroup>
                    {subgoals.map((subgoal, i) => (
                        <Collapse key={subgoal.id}>
                            <Goal
                                goal={subgoal}
                                isEditable={isEditable}
                                isSubGoal={true}
                                isGoalSending={isGoalSending}
                                dragEndHandle={dragEndHandle}
                                index={i}
                            />
                        </Collapse>
                    ))}
                </TransitionGroup>
            </List>
        </Box>
    )
}
