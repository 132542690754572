import React, {ChangeEvent, FC, Fragment, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {GoalList} from '../../components/goals/GoalList';
import {GoalsTabs} from '../../components/UI/GoalsTabs/GoalsTabs';
import {useTranslation} from 'react-i18next';
import {deleteSet, editSet, getGoals, removeSet, toggleEditable} from '../../store/goal/goalSlice';
import {checkIfGoalsExists} from '../../utils/helpers';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import {setSnackbar} from '../../store/utils/utilsSlice';

export const Goals: FC = () => {

    const {t} = useTranslation();

    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {sets, isEditable, isGoalLoading} = useAppSelector(state => state.goal);

    const [currentSetId, setCurrentSetId] = useState<number | null>(sets.length ? sets[0].id : 0);
    const [selectedSetIndex, setSelectedSetIndex] = useState<number>(0);
    const [setOptions, setSetOptions] = useState<{
        isEditOpen: boolean,
        isDeleteOpen: boolean,
        newTitle: string
    }>({
        isEditOpen: false,
        isDeleteOpen: false,
        newTitle: ''
    });

    const handleChangeCurrentSet = (setId: number | null) => {
        setCurrentSetId(setId);
    }

    const handleOpenDeleteDialog = () => {
        setSetOptions(prevState => {
            return {
                ...prevState,
                isDeleteOpen: true
            }
        });
    }

    const handleOpenEditDialog = () => {
        const set = sets.filter(set => set.id === currentSetId)[0];
        setSetOptions(prevState => {
            return {
                ...prevState,
                isEditOpen: true,
                newTitle: set.title
            }
        });
    }

    const handleCloseDeleteDialog = () => {
        setSetOptions(prevState => {
            return {
                ...prevState,
                isDeleteOpen: false
            }
        });
    }

    const handleCloseEditDialog = () => {
        setSetOptions(prevState => {
            return {
                ...prevState,
                isEditOpen: false
            }
        });
    }

    const handleChangeNewTitle = (e: ChangeEvent<HTMLInputElement>) => {
        setSetOptions(prevState => {
            return {
                ...prevState,
                newTitle: e.target.value
            }
        });
    }

    const handleEditSet = () => {
        if (currentSetId !== null) {
            if (setOptions.newTitle.trim() === '') {
                dispatch(setSnackbar({
                    text: 'Set title cannot be empty',
                    severity: 'error',
                    open: true
                }));

                return;
            }

            if (setOptions.newTitle.trim().length > 25) {
                dispatch(setSnackbar({
                    text: 'Title must be at least 1 and no more than 25 characters',
                    severity: 'error',
                    open: true
                }));

                return;
            }

            const setNames = sets.map(set => set.title);
            if (setNames.includes(setOptions.newTitle.trim())) {
                dispatch(setSnackbar({
                    text: 'You already have set with this title',
                    severity: 'error',
                    open: true
                }));

                return;
            }

            dispatch(editSet({setId: currentSetId, title: setOptions.newTitle}))
                .unwrap()
                .then(() => {
                    setSetOptions({
                        isEditOpen: false,
                        isDeleteOpen: false,
                        newTitle: ''
                    });
                });
        }
    }

    const handleDeleteSet = () => {
        if (currentSetId !== null) {
            dispatch(deleteSet({setId: currentSetId}))
                .unwrap()
                .then(() => {
                    dispatch(removeSet({setId: currentSetId}));
                    setSetOptions(prevState => {
                        return {
                            ...prevState,
                            isDeleteOpen: false
                        }
                    });

                    const currentSetIndex = sets.findIndex(set => set.id === currentSetId);
                    if (currentSetIndex !== -1 && sets[currentSetIndex + 1] !== undefined) {
                        setCurrentSetId(sets[currentSetIndex + 1].id);
                    } else {
                        setCurrentSetId(null);
                    }
                });
        }
    }

    const handleEditClick = () => {
        dispatch(toggleEditable());
    }

    useEffect(() => {
        if (!checkIfGoalsExists(sets)) {
            dispatch(getGoals())
                .unwrap()
                .then(originalPromiseResult => {
                    if (originalPromiseResult.length) {
                        setCurrentSetId(originalPromiseResult[0].id);
                    }
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const set = queryParams.get('set');
        if (set) {
            setSelectedSetIndex(Number(set));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth='lg' disableGutters={true}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflowY: 'auto',
                    paddingBottom: '16px',
                    // marginLeft: '5px',
                    // marginRight: '5px'
                }}
            >
                <Box sx={{
                    transition: 'all .5s !important',
                    width: '100%',
                    maxWidth: isMobile ? '96%' : '100%',
                }}>
                    <Box sx={{mt: isMobile ? 0 : 3}}>
                        <Card sx={{
                            mt: 2,
                            paddingLeft: isMobile ? '0rem' : '1rem',
                            paddingRight: isMobile ? '0rem' : '1rem',
                            borderRadius: '0.75rem'
                        }}
                        >
                            <CardContent sx={{height: isMobile ? 'calc(100vh - 88px)' : 'calc(100vh - 104px)'}}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    mb: '12px',
                                    height: '40px'
                                }}>
                                    <Box sx={{display: 'flex'}}>
                                        <Typography
                                            color='primary'
                                            variant='h5'
                                            sx={{
                                                marginLeft: isMobile ? '6px' : '0px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                marginBottom: '12px',
                                                fontSize: '1.5rem',
                                                fontWeight: '500 !important',
                                                lineHeight: '1.2',
                                            }}
                                        >
                                            {t('Goals')}
                                        </Typography>
                                        {isEditable
                                            ?
                                            <RemoveRedEyeIcon
                                                color='primary'
                                                sx={{
                                                    ml: 2,
                                                    fontSize: '1.5rem',
                                                    cursor: 'pointer',
                                                    fontWeight: '500 !important',
                                                    lineHeight: '1.2',
                                                }}
                                                onClick={handleEditClick}
                                            />
                                            :
                                            <EditIcon
                                                color='primary'
                                                sx={{
                                                    ml: 2,
                                                    fontSize: '1.5rem',
                                                    cursor: 'pointer',
                                                    fontWeight: '500 !important',
                                                    lineHeight: '1.2',
                                                }}
                                                onClick={handleEditClick}
                                            />
                                        }
                                    </Box>
                                    <Box>
                                        {isEditable && currentSetId !== null
                                            ?
                                            <Fragment>
                                                <IconButton
                                                    color='primary'
                                                    sx={{cursor: 'pointer'}}
                                                    onClick={handleOpenEditDialog}
                                                >
                                                    <BorderColorIcon/>
                                                </IconButton>
                                                <IconButton
                                                    color='primary'
                                                    sx={{cursor: 'pointer'}}
                                                    onClick={handleOpenDeleteDialog}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Fragment>
                                            :
                                            ''
                                        }
                                    </Box>
                                </Box>
                                <GoalsTabs onTabChange={handleChangeCurrentSet} selectedSetIndex={selectedSetIndex}>
                                    <GoalList setId={0}/>
                                </GoalsTabs>
                                {isGoalLoading &&
                                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '24px 0 18px 0'}}>
                                        <CircularProgress/>
                                    </Box>
                                }
                            </CardContent>
                        </Card>
                    </Box>
                </Box>

                <Dialog
                    open={setOptions.isEditOpen}
                    onClose={handleCloseEditDialog}
                    aria-labelledby="edit-set-dialog-title"
                    aria-describedby="edit-set-dialog-description"
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '0.75rem',
                            padding: '16px'
                        }
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        {t('Are you sure you want to edit this set?')}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus={isMobile ? false : true}
                            margin="dense"
                            id="newTitle"
                            label={t('New set title')}
                            type="text"
                            fullWidth
                            size='small'
                            variant="outlined"
                            value={setOptions.newTitle}
                            onChange={handleChangeNewTitle}
                            sx={{marginRight: '6px'}}
                            InputProps={{
                                autoComplete: 'off',
                                sx: {
                                    borderRadius: '0.75rem'
                                }
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleCloseEditDialog}>{t('Cancel')}</Button>
                        <Button variant="contained" onClick={handleEditSet} autoFocus>
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={setOptions.isDeleteOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="delete-set-dialog-title"
                    aria-describedby="delete-set-dialog-description"
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '0.75rem',
                            padding: '16px'
                        }
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        {t('Are you sure you want to delete this set?')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('After deleting the set, all goals in this set will also be deleted.')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleCloseDeleteDialog}>{t('Cancel')}</Button>
                        <Button variant="contained" onClick={handleDeleteSet} autoFocus>
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Container>
    )
}
