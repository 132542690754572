import React, {FC} from 'react';
import {Box} from '@mui/material';
import {useAppSelector} from '../../../hooks/redux';

interface ITabPanel {
    children?: React.ReactNode;
    index: number;
    value: number | boolean;
}

export const TabPanel: FC<ITabPanel> = ({children, value, index, ...props}) => {

    const {isMobile} = useAppSelector(state => state.utils);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tasks-tabpanel-${index}`}
            aria-labelledby={`tasks-tab-${index}`}
            style={{
                display: isMobile ? 'flex' : 'block',
                justifyContent: 'center',
                fontSize: '24px',
            }}
            {...props}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    )
}
