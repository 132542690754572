import React, {FC} from 'react';
import {Box, Container} from '@mui/material';
import {SocialLoginForm} from "../../components/auth/social/SocialLoginForm";

export const SocialLogin: FC = () => {
    return (
        <Container maxWidth="lg">
            <Box>
                <SocialLoginForm/>
            </Box>
        </Container>
    )
};
