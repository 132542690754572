import React, {FC, Fragment, useRef, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Typography,
    useTheme
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import {IGoal} from '../../models/goals/IGoal';
import {SubGoalList} from './SubGoalList';
import {GoalAddInput} from './GoalAddInput';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {deleteGoals, reorderDnD} from '../../store/goal/goalSlice';
import {useTranslation} from 'react-i18next';
import {GoalEditInput} from './GoalEditInput';
import {useDrag, useDrop} from 'react-dnd';
import {dndItemTypes} from '../../models/dnd/dndItemTypes';
import {IDragGoal} from '../../models/dnd/IDragGoal';
import type {Identifier, XYCoord} from 'dnd-core';
import {getGoalIds} from '../../utils/helpers';
import {GoalEditDialog} from './GoalEditDialog';
import {NavLink} from 'react-router-dom';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import {GoalCompleteLabel} from "./labels/GoalCompleteLabel";
import {LinearProgressWithLabel} from "./labels/LinearProgressWithLabel";
import {GoalDreamLabel} from "./labels/GoalDreamLabel";
import {GoalDatesLabel} from "./labels/GoalDatesLabel";

interface IGoalProps {
    goal: IGoal,
    isEditable: boolean,
    isSubGoal: boolean,
    isGoalSending: boolean,
    dragEndHandle: (item: IDragGoal) => void,
    index: number,
}

export const Goal: FC<IGoalProps> = ({
                                         goal,
                                         isEditable,
                                         isSubGoal,
                                         isGoalSending,
                                         dragEndHandle,
                                         index,
                                         ...props
                                     }) => {

    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const {isMobile} = useAppSelector(state => state.utils);
    const {sets} = useAppSelector(state => state.goal);

    const dragRef = useRef<HTMLSpanElement>(null); // drag handle
    const previewRef = useRef<HTMLLIElement>(null); // drag item

    const [isDeleteOpen, setIsDeleteOpen] = useState<{
        alert: boolean,
        modal: boolean
    }>({
        alert: false,
        modal: false
    });
    const [isInputOpen, setIsInputOpen] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const [{handlerId}, drop] = useDrop<IDragGoal, void, { handlerId: Identifier | null }>({
        accept: dndItemTypes.GOAL + goal.parentId,
        collect: monitor => {
            return {handlerId: monitor.getHandlerId()}
        },
        hover: (item, monitor) => {
            if (!previewRef.current) return;

            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) return;

            // Determine rectangle on screen
            const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

            // Time to actually perform the action
            dispatch(reorderDnD({
                setId: goal.setId,
                parentId: goal.parentId,
                dragIndex: dragIndex,
                hoverIndex: hoverIndex
            }));

            item.index = hoverIndex;
        }
    });

    const [{isDragging}, drag, preview] = useDrag({
        type: dndItemTypes.GOAL + goal.parentId,
        item: () => {
            return {
                goal: goal,
                oldIndex: index,
                index: index
            } as IDragGoal
        },
        canDrag: !isGoalSending,
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging()
        }),
        end: item => {
            dragEndHandle(item);
        }
    })

    const toggleIsInputOpen = () => {
        setIsInputOpen(prevState => !prevState);
    }

    const toggleIsOpen = () => {
        setIsOpen(prevState => !prevState);
    }

    const deleteGoalHandle = () => {
        if (!isDeleteOpen.alert) {
            return setIsDeleteOpen(prevState => {
                return {...prevState, alert: true}
            });
        }
        if (!isDeleteOpen.modal && goal.SubGoals?.length) {
            return setIsDeleteOpen(prevState => {
                return {...prevState, modal: true}
            });
        }
        dispatch(deleteGoals(getGoalIds(goal)));
    }

    const handleDeleteClose = () => {
        setIsDeleteOpen({
            alert: false,
            modal: false
        });
    }

    const handleEditOpen = () => {
        setIsEdit(true);
    }

    const handleEditClose = () => {
        setIsEdit(false);
    }

    const opacity = isDragging ? 0 : 1;
    drag(dragRef);
    drop(preview(previewRef));


    const createDatesLabel = () => {
        let label: string = '';
        let today = new Date();
        if (goal.startDate && goal.endDate) {
            let startDate = new Date(String(goal.startDate));
            let endDate = new Date(String(goal.endDate));

            if (startDate.getTime() < today.getTime() && endDate.getTime() > today.getTime()) {
                label = t('Should_be_done_in_days',
                    {
                        count: calculateDays(endDate.getTime(), today.getTime()),
                    }
                );
            } else if (startDate.getTime() > today.getTime()) {
                label = label = label = t('Started_in_days',
                    {
                        count: calculateDays(startDate.getTime(), today.getTime()),
                    }
                );
            } else if (endDate.getTime() < today.getTime()) {
                label = t('Expired');
            }
        } else if (goal.startDate) {
            let startDate = new Date(String(goal.startDate));
            if (startDate.getTime() > today.getTime()) {
                label = label = t('Started_in_days',
                    {
                        count: calculateDays(startDate.getTime(), today.getTime()),
                    }
                );
            } else {
                label = t('In progress');
            }
        } else if (goal.endDate) {
            let endDate = new Date(String(goal.endDate));
            if (endDate.getTime() > today.getTime()) {
                label = label = t('Should_be_done_in_days',
                    {
                        count: calculateDays(endDate.getTime(), today.getTime()),
                    }
                );
            } else {
                label = t('Expired');
            }
        }

        return label;
    }

    const calculateDays = (endDate: number, startDate: number) => {
        return Math.round((Number(endDate - startDate)) / (1000 * 60 * 60 * 24));
    }

    const getLink = () => {
        let link = '/goals/' + goal.id;
        sets.map(set => {
            if (set.id === goal.setId) {
                link = link + '/' + (set.orderId - 1);
            }
            return set;
        });
        return link;
    }

    return (
        <Box
            component={'li'}
            ref={previewRef}
            data-handler-id={handlerId}
            sx={{
                display: 'list-item',
                paddingY: isSubGoal ? '0px' : '4px',
                opacity: opacity
            }}
        >
            <Paper
                sx={{
                    width: '100%',
                    padding: isSubGoal ? '8px 0px 0px 8px' : '8px',
                    border: 'none',
                    boxShadow: 'none',
                }}
            >
                {isDeleteOpen.alert
                    ?
                    <Fragment>
                        <Alert
                            variant={"outlined"}
                            severity='error'
                            sx={{
                                width: '100%',
                                maxHeight: '32px',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        cursor: 'text',
                                        paddingRight: '12px'
                                    }}
                                >
                                    {
                                        isMobile ?
                                            t('Are you sure?')
                                            :
                                            t('Goal will be unrecoverably deleted. Are you sure?', {
                                                title: goal.title,
                                            })
                                    }
                                </Typography>
                                <Button
                                    color="inherit"
                                    size="small"
                                    onClick={handleDeleteClose}
                                >
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    color="inherit"
                                    size="small"
                                    onClick={deleteGoalHandle}
                                >
                                    {t('Yes')}
                                </Button>
                            </Box>
                        </Alert>
                        <Dialog
                            open={isDeleteOpen.modal}
                            onClose={handleDeleteClose}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '0.75rem'
                                }
                            }}
                        >
                            <DialogTitle id="alert-dialog-title">
                                {t('This goal has subgoals')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t('If you delete this goal, then all sub-goals will also be deleted.')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant={"contained"} onClick={handleDeleteClose}>{t('Cancel')}</Button>
                                <Button variant={"contained"} onClick={deleteGoalHandle} autoFocus>
                                    {t('Confirm')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Fragment>
                    :
                    <Fragment>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box
                                ref={dragRef}
                                component={'span'}
                                sx={{
                                    display: isEditable ? 'flex' : 'none',
                                    alignItems: 'center',
                                    paddingRight: '6px'
                                }}
                            >
                                <OpenWithIcon color='action'
                                              sx={{
                                                  cursor: 'grab',
                                                  opacity: '0.4',
                                                  "&:hover": {
                                                      opacity: '1'
                                                  }
                                              }}/>
                            </Box>
                            {isEditable
                                ?
                                <Box component={NavLink} to={getLink()} sx={{display: 'flex'}}>
                                    <OpenInNewIcon
                                        color='action'
                                        sx={{
                                            fontSize: '22px',
                                            mr: 1,
                                            cursor: 'pointer'
                                        }}
                                    />
                                </Box>
                                :
                                <Box component={NavLink} to={getLink()} sx={{display: 'flex'}}>
                                    <TrackChangesIcon
                                        color='action'
                                        sx={{
                                            fontSize: '22px',
                                            mr: 1,
                                            cursor: 'pointer'
                                        }}
                                    />
                                </Box>
                            }
                            <GoalEditInput
                                value={goal}
                                isSubGoal={isSubGoal}
                                showAsLink={!isEditable}
                            />
                            {!isMobile && goal.isCompleted &&
                                <GoalCompleteLabel/>
                            }
                            {!isMobile && goal.isDream &&
                                <GoalDreamLabel/>
                            }
                            {
                                !isMobile && !goal.isCompleted && !goal.isDream && (goal.startDate || goal.endDate) &&
                                <GoalDatesLabel label={createDatesLabel()}/>
                            }
                            {
                                !isMobile && !goal.isDream &&
                                <LinearProgressWithLabel value={goal.progress}/>
                            }

                            {isEditable &&
                                <Fragment>
                                    {isInputOpen
                                        ?
                                        <RemoveIcon
                                            color='action'
                                            sx={{
                                                cursor: 'pointer',
                                                "&:hover": {
                                                    opacity: '1',
                                                    color: theme.palette.primary.main
                                                }
                                            }}
                                            onClick={toggleIsInputOpen}
                                        />
                                        :
                                        <AddIcon
                                            color='action'
                                            sx={{
                                                cursor: 'pointer',
                                                "&:hover": {
                                                    opacity: '1',
                                                    color: theme.palette.primary.main
                                                }
                                            }}
                                            onClick={toggleIsInputOpen}
                                        />
                                    }
                                    <EditIcon
                                        color='action'
                                        sx={{
                                            cursor: 'pointer',
                                            marginLeft: '6px',
                                            "&:hover": {
                                                opacity: '1',
                                                color: theme.palette.primary.main
                                            }
                                        }}
                                        onClick={handleEditOpen}
                                    />
                                    <DeleteIcon
                                        color='action'
                                        sx={{
                                            marginLeft: '6px',
                                            cursor: 'pointer',
                                            marginRight: '6px',
                                            "&:hover": {
                                                opacity: '1',
                                                color: theme.palette.error.main
                                            }
                                        }}
                                        onClick={deleteGoalHandle}
                                    />
                                    <ExpandMoreIcon
                                        color='action'
                                        sx={{
                                            visibility: goal.SubGoals?.length ? 'visible' : 'hidden',
                                            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'all .25s ease',
                                            cursor: 'pointer',
                                            "&:hover": {
                                                opacity: '1',
                                                color: theme.palette.primary.main
                                            }
                                        }}
                                        onClick={toggleIsOpen}
                                    />
                                </Fragment>
                            }
                        </Box>
                        <Box>
                            <Collapse in={isOpen} unmountOnExit>
                                <SubGoalList
                                    subgoals={goal.SubGoals?.length ? goal.SubGoals : []}
                                    isEditable={isEditable}
                                    isGoalSending={isGoalSending}
                                    dragEndHandle={dragEndHandle}
                                />
                            </Collapse>
                            <Collapse in={isInputOpen && isEditable} collapsedSize={0} unmountOnExit>
                                <GoalAddInput
                                    setId={goal.setId}
                                    parentId={goal.id}
                                    isSubGoal={true}
                                />
                            </Collapse>
                        </Box>
                    </Fragment>
                }
            </Paper>
            <GoalEditDialog
                open={isEdit}
                goal={goal}
                onClose={handleEditClose}
            />
        </Box>
    )
}
