import React, {FC, useEffect, useState} from 'react';
import {Box, TextField} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {renameListAPI} from '../../services/TaskService';
import {useNavigate} from 'react-router-dom';
import {resetTasks} from '../../store/task/taskSlice';

interface ITagEditInputProps {
    tag: string
}

export const TagEditInput: FC<ITagEditInputProps> = ({tag, ...props}) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);

    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(tag);

    const handleEditable = () => {
        setIsEditable(true);
    }

    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    }

    const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') goalTitleEdit();
    }

    const goalTitleEdit = async () => {
        if (title !== tag) {
            if (title.trim() !== '') {
                try {
                    await renameListAPI(tag, title);
                    navigate(`/lists/${title}`);
                    dispatch(resetTasks());
                } catch (e: any) {
                    setTitle(tag);
                    if (e.response && e.response.data) {
                        dispatch(setSnackbar({
                            text: e.response.data.message,
                            severity: 'error',
                            open: true
                        }));
                    }
                }
            } else {
                setTitle(tag);
                dispatch(setSnackbar({
                    text: 'List title cannot be empty',
                    severity: 'error',
                    open: true
                }));
            }
        }

        setIsEditable(false);
    }

    useEffect(() => {
        setTitle(tag)
    }, [tag]);

    return (
        <Box
            component='span'
            onClick={handleEditable}
            sx={isMobile
                ?
                {
                    width: '84px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                }
                :
                {}
            }
        >
            {isEditable
                ?
                <TextField
                    variant="filled"
                    fullWidth
                    autoFocus
                    value={title}
                    inputProps={{
                        style: {
                            padding: '0 0 1px 0',
                            fontSize: '24px'
                        }
                    }}
                    onChange={handleTitle}
                    onKeyPress={handleEnterPress}
                    onBlur={goalTitleEdit}
                />
                :
                title
            }
        </Box>
    )
}
