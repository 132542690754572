import * as yup from "yup";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useNavigate} from "react-router-dom";
import {login} from "../../store/auth/authSlice";
import {useTranslation} from "react-i18next";
import {changeTimezone, formatDateWithTimezone} from "../../utils/helpers";
import {FormikHelpers} from 'formik';
import i18n from "../../localization/i18n";
import {getFromStorage, saveToStorage} from "../../utils/storageHelpers";

interface ILoginFormFields {
    email: string,
    password: string
}

export function useLoginForm() {

    const {t} = useTranslation();

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const {user} = useAppSelector(state => state.auth);

    const today = formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName));

    const loginInitialValues: ILoginFormFields = {
        email: '',
        password: ''
    }

    const loginValidationSchema = yup.object({
        email: yup
            .string()
            .trim()
            .email(t('Enter a valid email'))
            .required(t('Email is required')),
        password: yup
            .string()
            .trim()
            .min(4, t('Password must be at least 4 and no more than 16 characters'))
            .max(16, t('Password must be at least 4 and no more than 16 characters'))
            .required(t('Password is required'))
    });

    const onSubmit = async (values: ILoginFormFields, onSubmitProps: FormikHelpers<ILoginFormFields>): Promise<void> => {
        await dispatch(login({email: values.email.toLowerCase(), password: values.password}))
            .unwrap()
            .then(async (originalPromiseResult) => {
                i18n.changeLanguage(originalPromiseResult.user.lang);
                await saveToStorage('lang', originalPromiseResult.user.lang);
                await getFromStorage('relatedAccounts')
                    .then(relatedAccounts => {
                        if (relatedAccounts.value) {
                            navigate(`/related-accounts-mode`, {replace: true});
                        } else {
                            navigate(`/tasks/${today}`);
                        }
                    });
            })
            .catch((error) => {
                if (error.param) onSubmitProps.setErrors({[error.param]: error.msg});
            });
    }

    return {
        loginInitialValues,
        loginValidationSchema,
        onSubmit
    }
}