import React, {FC} from 'react';
import {Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Grid, Stack, Typography,} from '@mui/material';
import {useAppSelector} from '../../hooks/redux';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

export const ProfileOverview: FC = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {user} = useAppSelector(state => state.auth);
    const {isMobile} = useAppSelector(state => state.utils);

    return (
        <Box sx={{mt: 3}}>
            <Card sx={{mt: 2, paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '0.75rem'}}>
                <CardHeader
                    sx={{
                        display: "flex",
                        overflow: "hidden",
                        "& .MuiCardHeader-content": {
                            overflow: "hidden"
                        }
                    }}
                    title={t('Account overview')}
                    titleTypographyProps={{noWrap: true}}
                />
                <Divider/>
                <CardContent>
                    <Grid container spacing={2} sx={{mt: 1}}>
                        <Grid item xs={12} md={2}>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                style={{height: '100%'}}
                            >
                                <Grid item>
                                    <Avatar
                                        variant="square"
                                        alt={user.name}
                                        src={user.avatar}
                                        sx={{
                                            height: 120,
                                            width: 120,
                                            borderRadius: '0.75rem'
                                        }}/>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} md={10} textAlign={isMobile ? "center" : "left"}>
                            <Typography variant="h5"
                                        display="inline"
                                        sx={{
                                            fontWeight: '600 !important',
                                            marginRight: '0.25rem !important'
                                        }}>
                                {user.name}
                            </Typography>
                            {user.level.title !== 'Premium' &&
                                <Button
                                    onClick={() => {
                                        navigate(`/subscription`);
                                    }}
                                    sx={{
                                        color: '#50cd89',
                                        backgroundColor: '#e8fff3',
                                        fontWeight: '600 !important',
                                        fontSize: '0.85rem !important',
                                        paddingTop: '0.25rem !important',
                                        paddingBottom: '0.25rem !important',
                                        paddingRight: '0.75rem !important',
                                        paddingLeft: '0.75rem !important',
                                        marginLeft: '0.5rem !important',
                                        borderRadius: '0.75rem',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: '#50cd89',
                                            color: '#ffffff',
                                        },
                                    }}>
                                    {t('Upgrade to Premium')}
                                </Button>
                            }
                            <Stack direction="row" spacing={2}>
                                <Typography

                                    sx={{
                                        fontWeight: '600 !important',
                                        marginRight: '0.25rem !important',
                                        mt: 1
                                    }}>
                                    {t('Your subscription plan is')} {user.level.title} {t('until')}
                                </Typography>
                                {user.level.expiredAt ?
                                    <Typography
                                        sx={{
                                            fontWeight: '600 !important',
                                            marginRight: '0.25rem !important',
                                            marginTop: '8px !important',
                                            marginLeft: '4px !important'
                                        }}>
                                        {user.level.expiredAt}
                                    </Typography> :
                                    <AllInclusiveIcon
                                        sx={{marginLeft: '4px !important', marginTop: '8px !important',}}/>
                                }
                            </Stack>
                            <Stack direction="row" spacing={2} sx={{marginTop: '8px'}}>
                                <MailOutlineIcon sx={{
                                    color: '#B5B5C3',
                                    fontSize: '1.2rem'
                                }}/>
                                <Typography
                                    sx={{
                                        color: '#B5B5C3',
                                        fontSize: '0.8rem'
                                    }}>
                                    {user.email}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
};
