import React, {CSSProperties, FC} from 'react';
import {Box, ButtonBase, Typography, useTheme} from '@mui/material';

interface IYearPicker {
    currYear: number,
    toggleYearHandle: (newYear: number) => void
}

const styles: CSSProperties = {
    display: 'flex',
    padding: '6px 12px',
    borderRadius: '2px',
    cursor: 'pointer'
}

export const YearPicker: FC<IYearPicker> = ({currYear, toggleYearHandle, ...props}) => {

    const theme = useTheme();

    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <ButtonBase
                component='div'
                sx={styles}
                onClick={() => toggleYearHandle(currYear - 1)}
            >
                <Typography variant='h6' color={theme.palette.action.disabled}>
                    {currYear - 1}
                </Typography>
            </ButtonBase>
            <ButtonBase
                component='div'
                sx={styles}
                onClick={() => toggleYearHandle(currYear)}
            >
                <Typography
                    variant='h5'
                    color='primary'
                    sx={{
                        fontSize: '1.5rem',
                        fontWeight: '500 !important',
                        lineHeight: '1.2',
                    }}
                >
                    {currYear}
                </Typography>
            </ButtonBase>
            <ButtonBase
                component='div'
                sx={styles}
                onClick={() => toggleYearHandle(currYear + 1)}
            >
                <Typography variant='h6' color={theme.palette.action.disabled}>
                    {currYear + 1}
                </Typography>
            </ButtonBase>
        </Box>
    )
}