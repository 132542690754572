import {Box, ListItemText, TextField, Typography} from '@mui/material';
import React, {FC, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {IGoal} from '../../models/goals/IGoal';
import {editGoal} from '../../store/goal/goalSlice';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {NavLink} from "react-router-dom";
import {LinearProgressWithLabel} from "./labels/LinearProgressWithLabel";

interface IGoalEditInputProps {
    value: IGoal,
    isSubGoal: boolean,
    showAsLink?: boolean | null,
}

export const GoalEditInput: FC<IGoalEditInputProps> = ({
                                                           value,
                                                           isSubGoal,
                                                           showAsLink = false,
                                                       }) => {

    const dispatch = useAppDispatch();
    const {isMobile} = useAppSelector(state => state.utils);
    const {sets} = useAppSelector(state => state.goal);

    const [editable, setEditable] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(value.title);

    const handleEditable = () => {
        if (showAsLink) return;
        setEditable(true);
    }

    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    }

    const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') goalTitleEdit();
    }

    const goalTitleEdit = () => {
        if (title !== value.title) {
            if (title.trim() !== '') {
                dispatch(editGoal({goal: {...value, title: title}}));
                setTitle(title.trim());
            } else {
                setTitle(value.title);
                dispatch(setSnackbar({
                    text: 'Goal title cannot be empty',
                    severity: 'error',
                    open: true
                }));
            }
        }

        setEditable(false);
    }

    const getLink = () => {
        let link = '/goals/' + value.id;
        sets.map(set => {
            if (set.id === value.setId) {
                link = link + '/' + (set.orderId - 1);
            }
            return set;
        });
        return link;
    }

    return (
        <ListItemText sx={{display: 'grid'}} onClick={handleEditable}>
            {editable
                ?
                <TextField
                    variant="filled"
                    fullWidth
                    autoFocus
                    value={title}
                    inputProps={{
                        style: {
                            padding: 0,
                            paddingBottom: 1,
                            fontSize: isSubGoal ? '1rem' : '1.2rem',
                            fontWeight: isSubGoal ? '400 !important' : '500 !important',
                            lineHeight: isSubGoal ? '1' : '1.2',
                        }
                    }}
                    onChange={handleTitle}
                    onKeyPress={handleEnterPress}
                    onBlur={goalTitleEdit}
                />
                :
                (
                    showAsLink
                        ?
                        <Box sx={{
                            display: 'grid',
                            flexDirection: 'column'
                        }}>
                            <Box component={NavLink}
                                 to={getLink()}
                                 color="inherit"
                                 sx={{
                                     overflow: 'hidden',
                                     textOverflow: 'ellipsis',
                                     whiteSpace: 'nowrap',
                                     fontSize: isSubGoal ? '1rem' : '1.2rem',
                                     fontWeight: isSubGoal ? '400 !important' : '500 !important',
                                     lineHeight: isSubGoal ? '1' : '1.2',
                                     textDecoration: value.isCompleted ? 'line-through' : 'none'
                                 }}
                            >
                                {title}
                            </Box>
                            {
                                isMobile &&
                                <LinearProgressWithLabel value={value.progress} displayValue={false}/>
                            }

                        </Box>
                        :
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                cursor: 'text',
                                fontSize: isSubGoal ? '1rem' : '1.2rem',
                                fontWeight: isSubGoal ? '400 !important' : '500 !important',
                                lineHeight: isSubGoal ? '1' : '1.2',
                                textDecoration: value.isCompleted ? 'line-through' : 'none'
                            }}
                        >
                            {title}
                        </Typography>
                )
            }
        </ListItemText>
    )
}
