import React, {FC, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    useTheme
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from '../../hooks/redux';
import {getTimeSchedule} from '../../utils/helpers';
import {MAX_REMINDERS_COUNT} from '../../utils/constants';
import IconButton from '@mui/material/IconButton';
import {IRemindOptions} from '../../models/notifications/IRemindOptions';

interface IRemindOptionsDialogProps {
    open: boolean,
    sendCounter: number,
    initialRemindOptions: IRemindOptions,
    isRecurring: boolean,
    handleOptions: (options: IRemindOptions) => void,
    onClose: () => void
}

export const RemindOptionsDialog: FC<IRemindOptionsDialogProps> = ({
                                                                       open,
                                                                       sendCounter,
                                                                       initialRemindOptions,
                                                                       isRecurring,
                                                                       handleOptions,
                                                                       onClose,
                                                                       ...rest
                                                                   }) => {

    const {t} = useTranslation();
    const theme = useTheme();

    const {user} = useAppSelector(state => state.auth);
    const {isMobile} = useAppSelector(state => state.utils);
    const timeSchedule = getTimeSchedule();

    const [remindOptions, setRemindOptions] = useState<IRemindOptions>(initialRemindOptions);
    const [locale, setLocale] = useState<Locale>();

    const handleRemindCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRemindOptions(prevState => {
            return {
                ...prevState,
                isRemind: e.target.checked
            }
        });
    }

    const handleRemindMethod = (e: SelectChangeEvent<number[]>) => {
        const values = e.target.value;
        if (typeof values !== 'string') {
            setRemindOptions(prevState => {
                return {
                    ...prevState,
                    remindMethodId: values
                }
            });
        }
    }

    const handleClickRemoveRemind = (position: number) => {
        setRemindOptions(prevState => {
            return {
                ...prevState,
                remindersActive: prevState.remindersActive.filter(el => el !== position),
            }
        });
    }

    const handleClickAddRemind = () => {
        setRemindOptions(prevState => {
            return {
                ...prevState,
                remindersActive: [
                    ...prevState.remindersActive,
                    prevState.remindersActive[prevState.remindersActive.length - 1] + 1 > 3
                        ?
                        1
                        :
                        prevState.remindersActive[prevState.remindersActive.length - 1] + 1
                ]
            }
        });
    }

    const handleNotifyAt = (e: SelectChangeEvent, position: number) => {
        const updNotifyAt = remindOptions.notifyAt;
        updNotifyAt[position] = e.target.value;

        setRemindOptions(prevState => {
            return {
                ...prevState,
                notifyAt: updNotifyAt
            }
        });
    }

    const handleDate = (value: Date | null, position: number) => {
        if (!value) return;

        const updDate = remindOptions.date;
        updDate[position] = value;

        setRemindOptions(prevState => {
            return {
                ...prevState,
                date: updDate
            }
        });
    }

    const handleDayDiff = (e: SelectChangeEvent, position: number) => {
        const updDaysDiff = remindOptions.daysDiff;
        updDaysDiff[position] = Number(e.target.value);

        setRemindOptions(prevState => {
            return {
                ...prevState,
                daysDiff: updDaysDiff
            }
        });
    }

    const handleClickCancel = () => {
        setRemindOptions(initialRemindOptions);
        onClose();
    }

    useEffect(() => {
        handleClickCancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendCounter]);

    useEffect(() => {
        handleOptions(remindOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remindOptions]);

    useEffect(() => {
        setRemindOptions(initialRemindOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialRemindOptions]);

    useEffect(() => {
        switch (user.lang) {
            case 'en':
                import('date-fns/locale/en-US/index').then(locale => updateLocale(locale.default));
                break;
            case 'ru':
                import('date-fns/locale/ru/index').then(locale => updateLocale(locale.default));
                break;
            case 'pl':
                import('date-fns/locale/pl/index').then(locale => updateLocale(locale.default));
                break;
            case 'ua':
                import('date-fns/locale/uk/index').then(locale => updateLocale(locale.default));
                break;
            default:
                import('date-fns/locale/en-US/index').then(locale => updateLocale(locale.default));
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.lang, user.weekStartsOn]);

    const updateLocale = (locale: Locale) => {
        let loc = locale;
        if (loc && loc.options) {
            loc.options.weekStartsOn = user.weekStartsOn as 0 | 1 | 2 | 3 | 4 | 5 | 6;
        }
        setLocale(loc);
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
            onClose={onClose}
            aria-labelledby="remind-options-dialog-title"
            aria-describedby="remind-options-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '0.75rem'
                }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {t('Is remind me')}
            </DialogTitle>
            <DialogContent sx={{padding: isMobile ? '20px 16px' : '20px 24px'}}>
                <Grid container spacing={1} sx={{marginBottom: '8px'}}>
                    <Grid item xs={12} md={6}>
                        <FormGroup sx={{pl: '8px', width: '100%'}}>
                            <FormControlLabel
                                control={<Checkbox
                                    name='isRemind'
                                    checked={remindOptions.isRemind}
                                    onChange={handleRemindCheck}
                                    sx={{
                                        padding: 0,
                                        mr: '4px',
                                        '& .MuiTypography-root': {
                                            fontWeight: '500',
                                        }
                                    }}
                                />}
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                                label={t('Is remind me')}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl size='small' sx={{mt: '12px', width: '100%'}} disabled={!remindOptions.isRemind}>
                            <InputLabel id="remind-method-type">{t('Remind method')}</InputLabel>
                            <Select
                                multiple
                                name='remindMethod'
                                labelId="remind-method-label"
                                id="remind-method"
                                value={remindOptions.remindMethodId}
                                label={t('Remind method')}
                                onChange={handleRemindMethod}
                                input={<OutlinedInput label={t('Remind method')}/>}
                                renderValue={(selected) => (
                                    selected.map(select => {
                                        return t(select === 1 ? "WebPush" : "Email") + (selected.length > 1 ? ", " : "");
                                    })
                                )}
                                sx={{
                                    borderRadius: '0.75rem'
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: 240,
                                            borderRadius: '0.5rem',
                                            color: theme.palette.textColorThird.dark
                                        }
                                    }
                                }}
                            >
                                <MenuItem value={1} key={1} sx={{'&:hover': {color: theme.palette.primary.main}}}>
                                    <Checkbox checked={remindOptions.remindMethodId.indexOf(1) > -1}/>
                                    <ListItemText primary={t('WebPush')}/>
                                </MenuItem>
                                <MenuItem value={2} key={2} sx={{'&:hover': {color: theme.palette.primary.main}}}>
                                    <Checkbox checked={remindOptions.remindMethodId.indexOf(2) > -1}/>
                                    <ListItemText primary={t('Email')}/>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
                    <Collapse in={remindOptions.remindersActive.includes(1)}>
                        <Grid container spacing={1}>
                            <Grid item xs={7} md={6}>
                                {isRecurring
                                    ?
                                    <FormControl size='small' sx={{mt: '12px', width: '100%'}}
                                                 disabled={!remindOptions.isRemind}>
                                        <InputLabel id="remind-before-type">
                                            {remindOptions.daysDiff[0] === 0 ? t("Remind in") : t("Remind ") + remindOptions.daysDiff[0] + t(" days before")}
                                        </InputLabel>
                                        <Select
                                            name='remindBefore'
                                            labelId="remind-before-label"
                                            id="remind-before"
                                            value={String(remindOptions.daysDiff[0])}
                                            label={t('Remind day before')}
                                            onChange={(e) => handleDayDiff(e, 0)}
                                            sx={{
                                                borderRadius: '0.75rem'
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        maxHeight: 240,
                                                        borderRadius: '0.5rem',
                                                        color: theme.palette.textColorThird.dark,
                                                    }
                                                }
                                            }}
                                        >
                                            {Array.from(Array(9).keys()).map(day => (
                                                day === 0
                                                    ?
                                                    <MenuItem value={String(day)} key={day}
                                                              sx={{'&:hover': {color: theme.palette.primary.main}}}>{t('the same day')}</MenuItem>
                                                    :
                                                    <MenuItem value={String(day)} key={day}
                                                              sx={{'&:hover': {color: theme.palette.primary.main}}}>{day}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    :
                                    <FormControl size='small'
                                                 sx={{
                                                     mt: '12px',
                                                     width: '100%',
                                                     '& .MuiOutlinedInput-root': {
                                                         borderRadius: '0.75rem'
                                                     }
                                                 }}>
                                        <DesktopDatePicker
                                            disabled={!remindOptions.isRemind}
                                            label={t('Remind date')}
                                            inputFormat="yyyy/MM/dd"
                                            value={remindOptions.date[0]}
                                            onChange={(value) => handleDate(value, 0)}
                                            renderInput={(params) => (
                                                <TextField
                                                    name='endDate'
                                                    size='small'
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={5} md={6}>
                                <Stack direction="row" spacing={0}>
                                    <FormControl size='small' sx={{mt: '12px', width: '100%'}}
                                                 disabled={!remindOptions.isRemind}>
                                        <InputLabel id="recurring-type">{t('Remind time at')}</InputLabel>
                                        <Select
                                            name='remindTime'
                                            labelId="remind-time-label"
                                            id="remind-time"
                                            value={remindOptions.notifyAt[0]}
                                            label={t('Remind time at')}
                                            onChange={(e) => handleNotifyAt(e, 0)}
                                            sx={{
                                                borderRadius: '0.75rem'
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        maxHeight: 240,
                                                        borderRadius: '0.5rem',
                                                        color: theme.palette.textColorThird.dark,
                                                    }
                                                }
                                            }}
                                        >
                                            {timeSchedule.map(time => (
                                                <MenuItem value={time} key={time}
                                                          sx={{'&:hover': {color: theme.palette.primary.main}}}>
                                                    {time}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {!isMobile && remindOptions.remindersActive.length > 1
                                        ?
                                        <IconButton
                                            disabled={!remindOptions.isRemind}
                                            onClick={() => handleClickRemoveRemind(1)}
                                            sx={{
                                                '&:hover': {
                                                    color: theme.palette.error.main,
                                                },
                                                marginTop: '12px'
                                            }}
                                        >
                                            <ClearIcon/>
                                        </IconButton>
                                        :
                                        ''
                                    }
                                </Stack>
                            </Grid>
                            {
                                isMobile && remindOptions.remindersActive.length > 1 &&
                                <Grid item xs={12} md={12} style={{textAlign: "center"}}>
                                    <Button variant='contained'
                                            color={"error"}
                                            disabled={!remindOptions.isRemind}
                                            onClick={() => handleClickRemoveRemind(1)}
                                    >
                                        {t('Remove')}
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Collapse>
                    <Collapse in={remindOptions.remindersActive.includes(2)}>
                        {isMobile && <Divider sx={{margin: '8px'}}/>}
                        <Grid container spacing={1}>
                            <Grid item xs={7} md={6}>
                                {isRecurring
                                    ?
                                    <FormControl size='small' sx={{mt: '12px', width: '100%'}}
                                                 disabled={!remindOptions.isRemind}>
                                        <InputLabel id="remind-before-type">
                                            {remindOptions.daysDiff[1] === 0 ? "Remind in" : "Remind " + remindOptions.daysDiff[1] + " days before"}
                                        </InputLabel>
                                        <Select
                                            name='remindBefore'
                                            labelId="remind-before-label"
                                            id="remind-before"
                                            value={String(remindOptions.daysDiff[1])}
                                            label={t('Remind day before')}
                                            onChange={(e) => handleDayDiff(e, 1)}
                                            sx={{
                                                borderRadius: '0.75rem'
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        maxHeight: 240,
                                                        borderRadius: '0.5rem',
                                                        color: theme.palette.textColorThird.dark,
                                                    }
                                                }
                                            }}
                                        >
                                            {Array.from(Array(9).keys()).map(day => (
                                                day === 0
                                                    ?
                                                    <MenuItem value={String(day)}
                                                              key={day}
                                                              sx={{'&:hover': {color: theme.palette.primary.main}}}>
                                                        {t('the same day')}
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value={String(day)}
                                                              key={day}
                                                              sx={{'&:hover': {color: theme.palette.primary.main}}}>
                                                        {day}
                                                    </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    :
                                    <FormControl size='small'
                                                 sx={{
                                                     mt: '12px',
                                                     width: '100%',
                                                     '& .MuiOutlinedInput-root': {
                                                         borderRadius: '0.75rem'
                                                     }
                                                 }}
                                    >
                                        <DesktopDatePicker
                                            disabled={!remindOptions.isRemind}
                                            label={t('Remind date')}
                                            inputFormat="yyyy/MM/dd"
                                            value={remindOptions.date[1]}
                                            onChange={(value) => handleDate(value, 1)}
                                            renderInput={(params) => (
                                                <TextField
                                                    name='endDate'
                                                    size='small'
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={5} md={6}>
                                <Stack direction="row" spacing={0}>
                                    <FormControl size='small' sx={{mt: '12px', width: '100%'}}
                                                 disabled={!remindOptions.isRemind}>
                                        <InputLabel id="recurring-type">{t('Remind time at')}</InputLabel>
                                        <Select
                                            name='remindTime'
                                            labelId="remind-time-label"
                                            id="remind-time"
                                            value={remindOptions.notifyAt[1]}
                                            label={t('Remind time at')}
                                            onChange={(e) => handleNotifyAt(e, 1)}
                                            sx={{
                                                borderRadius: '0.75rem'
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        maxHeight: 240,
                                                        borderRadius: '0.5rem',
                                                        color: theme.palette.textColorThird.dark,
                                                    }
                                                }
                                            }}
                                        >
                                            {timeSchedule.map(time => (
                                                <MenuItem value={time}
                                                          key={time}
                                                          sx={{'&:hover': {color: theme.palette.primary.main}}}
                                                >
                                                    {time}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {!isMobile && remindOptions.remindersActive.length > 1
                                        ?
                                        <IconButton
                                            disabled={!remindOptions.isRemind}
                                            onClick={() => handleClickRemoveRemind(2)}
                                            sx={{
                                                '&:hover': {
                                                    color: theme.palette.error.main,
                                                },
                                                marginTop: '12px'
                                            }}
                                        >
                                            <ClearIcon/>
                                        </IconButton>
                                        :
                                        ''
                                    }
                                </Stack>
                            </Grid>
                            {
                                isMobile && remindOptions.remindersActive.length > 1 &&
                                <Grid item xs={12} md={12} style={{textAlign: "center"}}>
                                    <Button variant='contained'
                                            color={"error"}
                                            disabled={!remindOptions.isRemind}
                                            onClick={() => handleClickRemoveRemind(2)}
                                    >
                                        {t('Remove')}
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Collapse>
                    <Collapse in={remindOptions.remindersActive.includes(3)}>
                        {isMobile && <Divider sx={{margin: '8px'}}/>}
                        <Grid container spacing={1}>
                            <Grid item xs={7} md={6}>
                                {isRecurring
                                    ?
                                    <FormControl size='small' sx={{mt: '12px'}}
                                                 disabled={!remindOptions.isRemind}>
                                        <InputLabel id="remind-before-type">
                                            {remindOptions.daysDiff[2] === 0 ? "Remind in" : "Remind " + remindOptions.daysDiff[2] + " days before"}
                                        </InputLabel>
                                        <Select
                                            name='remindBefore'
                                            labelId="remind-before-label"
                                            id="remind-before"
                                            value={String(remindOptions.daysDiff[2])}
                                            label={t('Remind day before')}
                                            onChange={(e) => handleDayDiff(e, 2)}
                                            sx={{
                                                borderRadius: '0.75rem'
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        maxHeight: 240,
                                                        borderRadius: '0.5rem',
                                                        color: theme.palette.textColorThird.dark,
                                                    }
                                                }
                                            }}
                                        >
                                            {Array.from(Array(9).keys()).map(day => (
                                                day === 0
                                                    ?
                                                    <MenuItem value={String(day)}
                                                              key={day}
                                                              sx={{'&:hover': {color: theme.palette.primary.main}}}
                                                    >
                                                        {t('the same day')}
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value={String(day)}
                                                              key={day}
                                                              sx={{'&:hover': {color: theme.palette.primary.main}}}
                                                    >
                                                        {day}
                                                    </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    :
                                    <FormControl size='small'
                                                 sx={{
                                                     mt: '12px',
                                                     width: '100%',
                                                     '& .MuiOutlinedInput-root': {
                                                         borderRadius: '0.75rem'
                                                     }
                                                 }}
                                    >
                                        <DesktopDatePicker
                                            disabled={!remindOptions.isRemind}
                                            label={t('Remind date')}
                                            inputFormat="yyyy/MM/dd"
                                            value={remindOptions.date[2]}
                                            onChange={(value) => handleDate(value, 2)}
                                            renderInput={(params) => (
                                                <TextField
                                                    name='endDate'
                                                    size='small'
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                }
                            </Grid>
                            <Grid item xs={5} md={6}>
                                <Stack direction="row" spacing={0}>
                                    <FormControl size='small' sx={{mt: '12px', width: '100%',}}
                                                 disabled={!remindOptions.isRemind}>
                                        <InputLabel id="recurring-type">{t('Remind time at')}</InputLabel>
                                        <Select
                                            name='remindTime'
                                            labelId="remind-time-label"
                                            id="remind-time"
                                            value={remindOptions.notifyAt[2]}
                                            label={t('Remind time at')}
                                            onChange={(e) => handleNotifyAt(e, 2)}
                                            sx={{
                                                borderRadius: '0.75rem'
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        maxHeight: 240,
                                                        borderRadius: '0.5rem',
                                                        color: theme.palette.textColorThird.dark,
                                                    }
                                                }
                                            }}
                                        >
                                            {timeSchedule.map(time => (
                                                <MenuItem value={time}
                                                          key={time}
                                                          sx={{'&:hover': {color: theme.palette.primary.main}}}
                                                >
                                                    {time}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {!isMobile &&
                                        <Box>
                                            {remindOptions.remindersActive.length > 1
                                                ?
                                                <IconButton
                                                    disabled={!remindOptions.isRemind}
                                                    onClick={() => handleClickRemoveRemind(3)}
                                                    sx={{
                                                        '&:hover': {
                                                            color: theme.palette.error.main,
                                                        },
                                                        marginTop: '12px'
                                                    }}
                                                >
                                                    <ClearIcon/>
                                                </IconButton>
                                                :
                                                ''
                                            }
                                        </Box>
                                    }
                                </Stack>
                            </Grid>
                            {
                                isMobile && remindOptions.remindersActive.length > 1 &&
                                <Grid item xs={12} md={12} style={{textAlign: "center"}}>
                                    <Button variant='contained'
                                            color={"error"}
                                            disabled={!remindOptions.isRemind}
                                            onClick={() => handleClickRemoveRemind(3)}
                                    >
                                        {t('Remove')}
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Collapse>
                </LocalizationProvider>

                {remindOptions.remindersActive.length !== MAX_REMINDERS_COUNT
                    ?
                    <Button
                        sx={{
                            mt: '6px',
                            cursor: 'pointer'
                        }}
                        onClick={handleClickAddRemind}
                    >
                        {t('+ Add one more')}
                    </Button>
                    :
                    ''
                }
            </DialogContent>
            <DialogActions
                sx={{
                    paddingRight: '24px',
                    paddingBottom: '16px'
                }}
            >
                <Button variant='contained'
                        onClick={handleClickCancel}
                >
                    {t('Cancel')}
                </Button>
                <Button variant='contained'
                        onClick={onClose}
                        autoFocus
                >
                    {t('Save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
