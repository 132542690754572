import React, {FC} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import ReportIcon from '@mui/icons-material/Report';
import {useAppSelector} from "../../../hooks/redux";

interface IProps {
    isOpen: boolean,
    handleClose: () => void,
    handleRedirect: () => void
}

export const BuyPremiumSubscription: FC<IProps> = ({
                                                       isOpen,
                                                       handleClose,
                                                       handleRedirect,
                                                       ...props
                                                   }) => {

    const {t} = useTranslation();
    const {isMobile} = useAppSelector(state => state.utils);

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="edit-set-dialog-title"
            aria-describedby="edit-set-dialog-description"
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '0.75rem',
                    padding: '16px'
                }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                <Grid container direction="row" alignItems="center">
                    {
                        !isMobile &&
                        <Grid item sx={{marginTop: '10px'}}>
                            <ReportIcon color={"error"}/>
                        </Grid>
                    }
                    <Grid item sx={{marginLeft: '8px'}}>
                        {t('Subscription restriction')}
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Typography sx={{fontWeight: '500 !important'}}>
                    {t('Unfortunately, your subscription does not allow you to perform this action or the limit for this operation is exceeded. To remove this restriction, update your subscription.')}
                </Typography>
            </DialogContent>
            {
                !isMobile ?
                    <DialogActions>
                        <Button variant="contained" color="success" onClick={handleRedirect}>
                            {t('Change subscription')}
                        </Button>
                        <Button variant="contained" onClick={handleClose}>
                            {t('Cancel')}
                        </Button>
                    </DialogActions>
                    :
                    <DialogActions>
                        <Grid container direction="row" alignItems="center">
                            <Grid item sx={{marginTop: '8px', width: '100%'}}>
                                <Button variant="contained" color="success" fullWidth={true} onClick={handleRedirect}>
                                    {t('Change subscription')}
                                </Button>
                            </Grid>
                            <Grid item sx={{marginTop: '8px', width: '100%'}}>
                                <Button variant="contained" fullWidth={true} onClick={handleClose}>
                                    {t('Cancel')}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
            }
        </Dialog>
    )
}
