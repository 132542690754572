import React, {FC} from 'react';
import {Avatar, Box, CardContent, Grid, IconButton, Stack, SvgIcon, Typography, useTheme} from '@mui/material';
import {useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import TableRow from "@mui/material/TableRow";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

export const YourSubscription: FC = () => {

    const {t} = useTranslation();
    const theme = useTheme();

    const {themeMode} = useAppSelector(state => state.utils);
    const {user} = useAppSelector(state => state.auth);

    const convertLevelPrice = (price: number) => {
        return parseFloat(Number(price).toFixed(2));
    }

    const showInfinityCount = (count: number) => {
        return count === 1000000 ? <AllInclusiveIcon/> : count;
    }

    const moveToSubscriptionsList = () => {
        const anchorComment = document.getElementById('subscriptionListSection');
        if (anchorComment) {
            anchorComment.scrollIntoView({behavior: "smooth"});
        }
    }

    return (
        <Box sx={{mt: 2}}>
            <Grid container spacing={2} sx={{mt: 1}}>
                <Grid item xs={12} md={6}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{height: '100%'}}
                    >
                        <Grid item>
                            <Avatar variant="square"
                                    src={'/images/subscription-house.svg'}
                                    sx={{width: '100%', height: '100%'}}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CardContent
                        sx={{
                            borderRadius: '0.75rem !important',
                            backgroundColor: theme.palette.subscriptionCardBackground.main,
                        }}>
                        <Stack direction='row' justifyContent='center'>
                            <Typography
                                sx={{
                                    fontWeight: '700 !important',
                                    marginBottom: '1.25rem !important',
                                    fontSize: '1.75rem'
                                }}
                            >
                                {t('Current')} {user.level.title}
                                <IconButton color="primary"
                                            aria-label="Change subscription"
                                            component="label"
                                >
                                    <CurrencyExchangeIcon onClick={moveToSubscriptionsList}/>
                                </IconButton>
                            </Typography>
                        </Stack>
                        <Stack direction='row' justifyContent='center'>
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontWeight: '500 !important',
                                    marginBottom: '1.25rem !important',
                                    color: theme.palette.textColorThird.main,
                                    marginRight: '16px'
                                }}
                            >
                                {t('Expire')}
                            </Typography>
                            {user.level.expiredAt ?
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        fontWeight: '500 !important',
                                        marginBottom: '1.25rem !important',
                                        color: theme.palette.textColorThird.main,
                                    }}
                                >
                                    {user.level.expiredAt}
                                </Typography>
                                :
                                <AllInclusiveIcon/>
                            }
                        </Stack>
                        <Stack direction='row' justifyContent='center' alignItems="flex-end">
                            <Typography
                                sx={{
                                    color: theme.palette.subscriptionPriceColor.main,
                                    marginBottom: '0.5rem !important',
                                    textAlign: 'center',
                                    paddingBottom: '8px'
                                }}
                            >
                                $
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    color: theme.palette.subscriptionPriceColor.main,
                                    fontSize: '3rem !important',
                                    fontWeight: '600 !important',
                                    marginLeft: '2px',
                                    marginRight: '4px'
                                }}
                            >
                                {convertLevelPrice(user.level.price)}
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                    fontWeight: '500 !important',
                                    opacity: '0.5',
                                    fontSize: '0.95rem !important',
                                    paddingBottom: '14px'
                                }}
                            >
                                /
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                    fontWeight: '500 !important',
                                    opacity: '0.5',
                                    fontSize: '0.95rem !important',
                                    paddingBottom: '14px'
                                }}
                            >
                                {t(user.level.priceTitle)}
                            </Typography>
                        </Stack>
                        <Table sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none"
                            }
                        }}>
                            <TableBody>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row"
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        {t('L Planned tasks count')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                            }}
                                        >
                                            {showInfinityCount(user.level.tasksPlannedMaxCount)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row"
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        {t('L Tasks per day')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                            }}
                                        >
                                            {showInfinityCount(user.level.tasksPerDayMaxCount)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row"
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        {t('L Recurring tasks')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                            }}
                                        >
                                            {showInfinityCount(user.level.tasksRecurringMaxCount)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row"
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        {t('L Lists')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                            }}
                                        >
                                            {showInfinityCount(user.level.listsMaxCount)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row"
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        {t('L Notes')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                            }}
                                        >
                                            {showInfinityCount(user.level.notesMaxCount)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row"
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        {t('L Journal message length')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                            }}
                                        >
                                            {showInfinityCount(user.level.journalMessageMaxLength)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row"
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        {t('L Overdue tasks')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                            }}
                                        >
                                            {showInfinityCount(user.level.tasksOverdueMaxCount)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row"
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        {t('L Completed tasks')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                            }}
                                        >
                                            {showInfinityCount(user.level.tasksCompletedMaxCount)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row"
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        {t('L Deleted tasks')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                            }}
                                        >
                                            {showInfinityCount(user.level.tasksDeletedMaxCount)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row"
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        {t('L Goals')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                            }}
                                        >
                                            {showInfinityCount(user.level.goalsMaxSetsCount)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row"
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        {t('L Subgoals')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                            }}
                                        >
                                            {showInfinityCount(user.level.goalsMaxSubgoalsCount)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th"
                                               scope="row"
                                               sx={{
                                                   paddingTop: '4px',
                                                   paddingBottom: '4px',
                                                   color: user.level.emailNotification ? (themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff') : (themeMode === 'light' ? '#B5B5C3' : '#474761')
                                               }}
                                    >
                                        {t('L Email notifications')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                                color: user.level.emailNotification ? (themeMode === 'light' ? '#50cd89' : '#50cd89') : (themeMode === 'light' ? '#B5B5C3' : '#474761')
                                            }}
                                        >
                                            {user.level.emailNotification ?
                                                <SvgIcon sx={{color: '#50cd89'}}>
                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                          fill="currentColor"></rect>
                                                    <path
                                                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                        fill="currentColor"></path>
                                                </SvgIcon>
                                                :
                                                <SvgIcon>
                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                          fill="currentColor"></rect>
                                                    <rect x="7" y="15.3137" width="12" height="2" rx="1"
                                                          transform="rotate(-45 7 15.3137)" fill="currentColor"></rect>
                                                    <rect x="8.41422" y="7" width="12" height="2" rx="1"
                                                          transform="rotate(45 8.41422 7)" fill="currentColor"></rect>
                                                </SvgIcon>
                                            }
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th"
                                               scope="row"
                                               sx={{
                                                   paddingTop: '4px',
                                                   paddingBottom: '4px',
                                                   color: user.level.emailNotification ? (themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff') : (themeMode === 'light' ? '#B5B5C3' : '#474761')
                                               }}
                                    >
                                        {t('L WebPush Notifications')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                                color: user.level.emailNotification ? (themeMode === 'light' ? '#50cd89' : '#50cd89') : (themeMode === 'light' ? '#B5B5C3' : '#474761')
                                            }}
                                        >
                                            {user.level.webPushNotification ?
                                                <SvgIcon sx={{color: '#50cd89'}}>
                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                          fill="currentColor"></rect>
                                                    <path
                                                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                        fill="currentColor"></path>
                                                </SvgIcon>
                                                :
                                                <SvgIcon>
                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                          fill="currentColor"></rect>
                                                    <rect x="7" y="15.3137" width="12" height="2" rx="1"
                                                          transform="rotate(-45 7 15.3137)" fill="currentColor"></rect>
                                                    <rect x="8.41422" y="7" width="12" height="2" rx="1"
                                                          transform="rotate(45 8.41422 7)" fill="currentColor"></rect>
                                                </SvgIcon>
                                            }
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                    <TableCell component="th" scope="row"
                                               sx={{
                                                   paddingTop: '4px',
                                                   paddingBottom: '4px',
                                                   color: user.level.emailNotification ? (themeMode === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#fff') : (themeMode === 'light' ? '#B5B5C3' : '#474761')
                                               }}
                                    >
                                        {t('L Allow Drag and Drop')}
                                    </TableCell>
                                    <TableCell align="right" size={'small'}
                                               sx={{paddingTop: '4px', paddingBottom: '4px'}}>
                                        <Typography
                                            sx={{
                                                fontWeight: '500 !important',
                                                fontSize: '0.95rem !important',
                                                color: user.level.emailNotification ? (themeMode === 'light' ? '#50cd89' : '#50cd89') : (themeMode === 'light' ? '#B5B5C3' : '#474761')
                                            }}
                                        >
                                            {user.level.tasksAllowDnD ?
                                                <SvgIcon sx={{color: '#50cd89'}}>
                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                          fill="currentColor"></rect>
                                                    <path
                                                        d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                                                        fill="currentColor"></path>
                                                </SvgIcon>
                                                :
                                                <SvgIcon>
                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10"
                                                          fill="currentColor"></rect>
                                                    <rect x="7" y="15.3137" width="12" height="2" rx="1"
                                                          transform="rotate(-45 7 15.3137)" fill="currentColor"></rect>
                                                    <rect x="8.41422" y="7" width="12" height="2" rx="1"
                                                          transform="rotate(45 8.41422 7)" fill="currentColor"></rect>
                                                </SvgIcon>
                                            }
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Grid>
            </Grid>
        </Box>
    )
};
