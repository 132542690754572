import React, {FC, memo, ReactElement} from 'react';
import {ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import FlagIcon from '@mui/icons-material/Flag';
import AssignmentIcon from '@mui/icons-material/Assignment';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import EventNoteIcon from '@mui/icons-material/EventNote';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import {useDrop} from 'react-dnd';
import {useTranslation} from 'react-i18next';
import {NavLink, useMatch} from 'react-router-dom';
import {ILink} from '../../../models/ILink';
import {IDragTask} from '../../../models/dnd/IDragTask';
import {dndItemTypes} from '../../../models/dnd/dndItemTypes';
import {useAppDispatch} from '../../../hooks/redux';
import {setSnackbar} from '../../../store/utils/utilsSlice';

interface ISidebarLink {
    link: ILink,
    open: boolean,
    dropHandle: (item: IDragTask, link: ILink) => void
}

const dndAcceptedLinks = ['Completed', 'Deleted', 'Tasks', 'Planned'];
const exceptions = ['planned', 'overdue', 'completed', 'deleted'];

const chooseIcon = (linkName: string, color: string): ReactElement => {
    let icon: ReactElement = <HomeIcon/>;

    switch (linkName) {
        case 'Home':
            icon = <HomeIcon sx={{color: color}}/>
            break;
        case 'Goals':
            icon = <FlagIcon sx={{color: '#7239ea'}}/>
            break;
        case 'Planned':
            icon = <NextPlanIcon sx={{color: color}}/>
            break;
        case 'Tasks':
            icon = <AssignmentIcon sx={{color: '#009ef7'}}/>
            break;
        case 'Recurrings':
            icon = <EventRepeatIcon sx={{color: color}}/>
            break;
        case 'Agenda':
            icon = <EventNoteIcon sx={{color: color}}/>
            break;
        case 'Overdue':
            icon = <AssignmentLateIcon sx={{color: '#f1bc00'}}/>
            break;
        case 'Completed':
            icon = <AssignmentTurnedInIcon sx={{color: '#47be7d'}}/>
            break;
        case 'Deleted':
            icon = <DeleteForeverIcon sx={{color: '#f1416c'}}/>
            break;
        case 'Notes':
            icon = <StickyNote2Icon sx={{color: '#0dcaf0'}}/>
            break;
        case 'Lists':
            icon = <ListAltIcon sx={{color: color}}/>
            break;
        case 'Kicker':
            icon = <DateRangeIcon sx={{color: '#6f42c1'}}/>
            break;
        case 'Journal':
            icon = <ImportContactsIcon sx={{color: color}}/>
            break;
        default:
            break;
    }

    return icon;
}

export const SideBarLink: FC<ISidebarLink> = memo(({link, open, dropHandle, ...props}) => {

    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const linkPath = link.name === 'Tasks' ? '/tasks/:date' : link.path;
    const match = useMatch(linkPath);

    let selected = false;
    if (match !== null) {
        selected = true;
        if (match.params.date !== undefined && exceptions.includes(match.params.date)) {
            selected = false;
        }
    }

    const [{isOver, canDrop}, drop] = useDrop<IDragTask, void, any>({
        accept: dndItemTypes.TASK,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: () => {
            if (!dndAcceptedLinks.includes(link.name)) return false;
            return true;
        },
        drop: (item) => {
            if (!item.task.isCompleted) {
                dropHandle(item, link);
            } else {
                dispatch(setSnackbar({
                    text: 'You cannot moving completed and deleted tasks',
                    severity: 'error',
                    open: true
                }));
            }
        }
    });

    let backgroundColor = 'inherit';
    let border = '2px dashed transparent';
    let borderBottomColor = '';
    if (isOver && canDrop) {
        backgroundColor = theme.palette.dragAndDropBackgroundColor.main;
    }
    if (canDrop) {
        border = '2px dashed';
        if (link.name === 'Planned' || link.name === 'Completed') {
            borderBottomColor = 'transparent';
        }
    }

    return (
        <ListItem
            ref={drop}
            component={NavLink}
            to={link.path}
            disablePadding
            selected={selected}
            style={{
                textDecoration: 'none',
                color: selected ? theme.palette.sideBarItemSelectedColor.main : theme.palette.sideBarItemColor.main
            }}
            sx={{
                display: 'block',
                backgroundColor: backgroundColor,
                border: border,
                borderBottomColor: borderBottomColor,
                borderRadius: '0.75rem',
                marginLeft: '8px',
                marginRight: '8px',
                width: 'auto',
                '&.MuiListItem-root': {
                    "&:hover": {
                        backgroundColor: theme.palette.sideBarItemHoverBackgroundColor.main
                    },
                },
                "&.Mui-selected": {
                    backgroundColor: theme.palette.sideBarItemSelectedBackgroundColor.main,
                },
            }}
            key={link.name}
        >
            <ListItemButton
                sx={{
                    minHeight: 42,
                    height: 42,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    borderRadius: '0.75rem',
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    {chooseIcon(link.name, (selected ? theme.palette.sideBarItemIconSelectedColor.main : theme.palette.sideBarItemIconColor.main))}
                </ListItemIcon>
                <ListItemText
                    primary={t(link.name)}
                    sx={{
                        opacity: open ? 1 : 0,
                    }}
                    primaryTypographyProps={{
                        letterSpacing: 0,
                        fontWeight: '500 !important',
                    }}
                />
            </ListItemButton>
        </ListItem>
    )
})

