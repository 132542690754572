import React, { CSSProperties, FC, Fragment } from 'react';
import { Theme, Tooltip, Typography, useTheme } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux';
import classes from './KickerCalendar.module.css';
import { useTranslation } from 'react-i18next';
import { IYearUserDaysResponse } from "../../../models/response/IYearUserDaysResponse";
import { monthNames, weekNames } from '../../../utils/constants';
import { changeTimezone } from '../../../utils/helpers';

interface IKickerMonth {
    year: number,
    month: number,
    day: number,
    userDays: IYearUserDaysResponse
}

const rows: number = 6;
const cols: number = 7;

const dayStyleByType = (type: number, theme: Theme): CSSProperties => {
    let styles: CSSProperties = {};

    switch (type) {
        case -3: styles = { backgroundColor: '#000000', color: '#ffffff' }
            break;
        case -2: styles = { backgroundColor: theme.palette.error.main, color: '#ffffff' }
            break;
        case -1: styles = { backgroundColor: theme.palette.action.focus }
            break;
        case 0: styles = {}
            break;
        case 1: styles = {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.primary.main,
            color: '#ffffff'
        }
            break;
        case 2: styles = { backgroundColor: theme.palette.info.main, color: '#ffffff' }
            break;
        case 3: styles = { backgroundColor: theme.palette.success.main, color: '#ffffff' }
            break;
        default:
            break;
    }

    return styles;
}

export const KickerMonth: FC<IKickerMonth> = ({ year, month, day, userDays, ...props }) => {

    const { t: translate } = useTranslation();

    const theme = useTheme();

    const navigate = useNavigate();

    const { user } = useAppSelector(state => state.auth);
    const { isMobile } = useAppSelector(state => state.utils);

    const currentDate: any = new Date(year, month);
    const currentDays: any = new Date(year, month, day);

    const nextCurrentDate: any = new Date(year, month + 1);
    const prevCurrentDate: any = new Date(year, month - 1);
    const diff: number = Math.round((nextCurrentDate - currentDate) / (1000 * 3600 * 24));
    const diffPrev: number = Math.round((currentDate - prevCurrentDate) / (1000 * 3600 * 24));

    const index: number = (currentDate.getDay() + 6) % 7;
    const indexPrev: number = (currentDate.getDay() + 5) % 7;

    const dayClickHandle = (e: any) => {
        if (e.target.tagName !== 'P') return;

        let clickDate: any = e.target.innerHTML;
        let clickMonth: number = month + 1;
        let clickYear: number = year;

        let dayType: string = 'defaultDay';
        if (e.target.classList.contains('pastDay')) dayType = 'pastDay';
        if (e.target.classList.contains('futureDay')) dayType = 'futureDay';

        if (dayType === 'pastDay') {
            if (clickMonth === 1) {
                clickMonth = 12;
                clickYear = year - 1;
            } else {
                clickMonth = clickMonth - 1;
            }
        }

        if (dayType === 'futureDay') {
            if (clickMonth === 12) {
                clickMonth = 1;
                clickYear = year + 1;
            } else {
                clickMonth = clickMonth + 1;
            }
        }

        if (Number(clickDate) < 10) clickDate = `0${clickDate}`;
        if (clickMonth < 10) {
            navigate(`/tasks/${clickYear}-0${clickMonth}-${clickDate}`);
        } else {
            navigate(`/tasks/${clickYear}-${clickMonth}-${clickDate}`);
        }
    }

    const currentDay = (a: Date, b: Date) => {
        return (
            a.getFullYear() === b.getFullYear() &&
            a.getMonth() === b.getMonth() &&
            a.getDate() === b.getDate()
        );
    }

    let table = [];
    let tr: any;
    let k: number = 1 - index;
    let f: number = k - diff;
    let today: Date = changeTimezone(new Date(), user.timezoneName);
    let t: number = today.getDate();
    let p: number = diffPrev - (indexPrev);

    for (let i = 0; i < rows; i++) {
        tr = [];
        for (let j = 0; j < cols; j++) {
            const day = k > 0 && k <= diff ? k : (k > diff ? f : (k < diff ? p : ''));

            const tempDay = k < 10 ? `0${k}` : String(k);
            const tempMonth = (month + 1) < 10 ? `0${month + 1}` : String(month + 1);
            const fullDate = `${year}-${tempMonth}-${tempDay}`;
            let color: string = '';
            let classname: string = '';
            let today: boolean = false;
            let style: CSSProperties = { position: 'relative', borderRadius: '3px' };
            if (k > 0 && k <= diff) {
                color = '';
                if (currentDay(new Date(), currentDays) && t === k) {
                    style = { ...style, border: '1px solid' };
                    today = true;
                }

                if (userDays[year] && userDays[year][fullDate]) {
                    style = { ...style, ...dayStyleByType(userDays[year][fullDate].type, theme) };
                }

                if (new Date(fullDate).getTime() < new Date().setUTCHours(0, 0, 0, 0)) {
                    theme.palette.mode === 'light'
                        ?
                        classname += classes.crossedOutLight
                        :
                        classname += classes.crossedOutDark;
                }

            } else {
                // f - next month days
                // p - past month days
                if (day === f) classname += ' futureDay';
                if (day === p) classname += ' pastDay';
                color = theme.palette.action.focus;
            }
            tr.push(
                <td
                    className={theme.palette.mode === 'light' ? classes.hoverDayLight : classes.hoverDayDark}
                    id={today ? 'today' : ''}
                    style={style}
                    onClick={dayClickHandle}
                    key={day}
                >
                    {userDays[year] &&
                        userDays[year][fullDate] &&
                        userDays[year][fullDate].comment &&
                        userDays[year][fullDate].comment !== '' ?
                        <Tooltip title={userDays[year][fullDate].comment || ""} arrow>
                            <Typography
                                className={classname}
                                variant='body1'
                                color={color}
                                style={{
                                    fontSize: today ? "1.25rem" : "1rem"
                                }}
                            >
                                {day}
                            </Typography>
                        </Tooltip>
                        :
                        <Typography
                            className={classname}
                            variant='body1'
                            color={color}
                            style={{
                                fontSize: today ? "1.25rem" : "1rem"
                            }}
                        >
                            {day}
                        </Typography>
                    }
                    {userDays[year] && userDays[year][fullDate] && userDays[year][fullDate].Tasks.length
                        ?
                        <Tooltip
                            title={
                                <Fragment>
                                    <Typography>
                                        {translate('Total')}
                                        &ensp;
                                        {userDays[year][fullDate].Tasks.length}
                                        <br />
                                        {translate('Completed')}
                                        &ensp;
                                        {userDays[year][fullDate].Tasks.filter(task => task.isCompleted).length}
                                        <br />
                                        {translate('Deleted')}
                                        &ensp;
                                        {userDays[year][fullDate].Tasks.filter(task => task.isDeleted).length}
                                    </Typography>
                                </Fragment>
                            }
                            placement='right'
                            arrow
                        >
                            <AssignmentIcon
                                sx={{
                                    position: 'absolute',
                                    bottom: '-2px',
                                    right: '-1px',
                                    color: '#2e81c4',
                                    fontSize: '14px'
                                }}
                            />
                        </Tooltip>
                        :
                        ''
                    }
                </td>
            );

            k++;
            f++;
            p++;
        }
        table.push(<tr className='day' key={k}>{tr}</tr>);
    }

    return (
        <div className={classes.month} style={{ minWidth: isMobile ? '350px' : '300px' }}>
            <header>
                <Typography variant='h6' color={theme.palette.primary.main} style={{ marginLeft: '3%' }}>
                    {translate(monthNames[month])}
                </Typography>
            </header>
            <table>
                <thead>
                    <tr style={{ fontSize: '16px' }}>
                        {weekNames.map(weekName => (
                            <th key={weekName}>
                                <Typography color={theme.palette.action.disabled}>
                                    {translate(weekName)}
                                </Typography>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className={classes.day}>
                    {table}
                </tbody>
            </table>
        </div>
    )
}
