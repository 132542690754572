import React, {FC} from 'react';
import {Box, Container, Paper} from '@mui/material';
import {AgendaTasksList} from "../../components/agenda/AgendaTasksList";
import {OverdueTasksList} from "../../components/overdue/OverdueTasksList";
import {LastModifiedGoalsList} from "../../components/goals/LastModifiedGoalsList";
import {useAppSelector} from "../../hooks/redux";


export const Dashboard: FC = () => {

    const {isMobile} = useAppSelector(state => state.utils);

    return (
        <Container
            disableGutters
            maxWidth={false}
            sx={{px: isMobile ? '0px' : '12px'}}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'unset',
                    justifyContent: 'center',
                    gap: '12px',
                    px: isMobile ? '12px' : '0'
                }}
            >

                <Paper sx={{mt: 3, width: '100%', borderRadius: '0.75rem'}}>
                    <AgendaTasksList
                        isHideActions
                        style={{padding: '24px'}}
                        height={'calc(100vh - 220px)'}
                    />
                </Paper>
                <Paper sx={{mt: 3, width: '100%', borderRadius: '0.75rem'}}>
                    <OverdueTasksList
                        isHideActions
                        style={{padding: '24px'}}
                    />
                </Paper>
                <Paper sx={{mt: 3, width: '100%', borderRadius: '0.75rem'}}>
                    <LastModifiedGoalsList style={{padding: '24px'}}/>
                </Paper>
            </Box>
        </Container>
    )
};
