import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";
import {
    canGetTrialFreeSubscriptionAPI,
    changeSubscriptionAPI,
    getSubscriptionsAPI,
    getTrialFreeSubscriptionAPI
} from "../../services/SubscriptionService";
import {ICheckSubscriptionResponse} from "../../models/response/ICheckSubscriptionResponse";
import {IFreeTrialSubscriptionResponse} from "../../models/response/IFreeTrialSubscriptionResponse";
import {ILevel} from "../../models/subscription/ILevel";

interface ISubscriptionState {
    canGetTrialFreeSubscription: {
        canGetTrialFree: boolean,
        observable: boolean,
        isLoading: boolean,
        error: boolean,
    },
    subscriptionsList: {
        subscriptions: ILevel[],
        isLoadingSubscriptions: boolean,
        errorLoadingSubscriptions: boolean,
        isFetchSubscriptions: boolean
    },
    showBuyPremiumDialog: boolean
}

const initialState: ISubscriptionState = {
    canGetTrialFreeSubscription: {
        canGetTrialFree: false,
        observable: true,
        isLoading: false,
        error: false,
    },
    subscriptionsList: {
        subscriptions: [] as ILevel[],
        isLoadingSubscriptions: false,
        errorLoadingSubscriptions: false,
        isFetchSubscriptions: false
    },
    showBuyPremiumDialog: false
}

export const getCanGetTrialFreeSubscription = createAsyncThunk(
    'subscription/canGetTrialFreeSubscription',
    async (_, {rejectWithValue}) => {
        try {
            const response = await canGetTrialFreeSubscriptionAPI();
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const changeSubscription = createAsyncThunk(
    'subscription/changeSubscription',
    async (levelId: number, {rejectWithValue}) => {
        try {
            const response = await changeSubscriptionAPI(levelId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const getTrialFreeSubscription = createAsyncThunk(
    'subscription/getTrialFreeSubscription',
    async (_, {rejectWithValue}) => {
        try {
            const response = await getTrialFreeSubscriptionAPI();
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const getSubscriptions = createAsyncThunk(
    'subscription/getSubscriptions',
    async (_, {rejectWithValue}) => {
        try {
            const response = await getSubscriptionsAPI();
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)
export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: initialState,
    reducers: {
        resetSubscriptionData: () => initialState,
        setShowBuyPremiumDialogStatus(state, action: PayloadAction<boolean>) {
            state.showBuyPremiumDialog = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCanGetTrialFreeSubscription.fulfilled, (state, action: PayloadAction<ICheckSubscriptionResponse>) => {
            state.canGetTrialFreeSubscription.canGetTrialFree = action.payload.result;

            state.canGetTrialFreeSubscription.isLoading = false;
            state.canGetTrialFreeSubscription.error = false;
        });

        builder.addCase(getTrialFreeSubscription.fulfilled, (state, action: PayloadAction<IFreeTrialSubscriptionResponse>) => {
            if (action.payload.status) {
                state.canGetTrialFreeSubscription.canGetTrialFree = false;

            }
            state.canGetTrialFreeSubscription.isLoading = false;
            state.canGetTrialFreeSubscription.error = false;
        });

        builder.addCase(getSubscriptions.fulfilled, (state, action: PayloadAction<ILevel[]>) => {
            state.subscriptionsList.subscriptions = action.payload;

            state.subscriptionsList.isLoadingSubscriptions = false;
            state.subscriptionsList.errorLoadingSubscriptions = false;
            state.subscriptionsList.isFetchSubscriptions = true;
        });

        builder.addMatcher(isAnyOf(getCanGetTrialFreeSubscription.rejected,), (state, action) => {
            state.canGetTrialFreeSubscription.isLoading = false;
            state.canGetTrialFreeSubscription.error = true;
        });
        builder.addMatcher(isAnyOf(getSubscriptions.rejected,), (state, action) => {
            state.subscriptionsList.isLoadingSubscriptions = false;
            state.subscriptionsList.errorLoadingSubscriptions = true;
        });
    }
})

export default subscriptionSlice.reducer;
export const {
    resetSubscriptionData,
    setShowBuyPremiumDialogStatus
} = subscriptionSlice.actions;