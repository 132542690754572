import React, {FC, ReactNode, useEffect, useMemo, useState} from 'react';
import {
    Alert,
    Box,
    createTheme,
    CssBaseline,
    Slide,
    SlideProps,
    Snackbar,
    styled,
    ThemeProvider,
    useMediaQuery
} from '@mui/material';
import {NavBar} from './NavBar';
import {SideBar} from './SideBar';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {setMode, setSnackbar, setThemeMode} from '../../store/utils/utilsSlice';
import {SideBarMobile} from './SideBarMobile';
import {TMode} from '../../models/utils/TMode';
import {useTranslation} from 'react-i18next';
import {colorsSchemas} from "../../utils/colors";
import {IColorSchema} from "../../models/colorSchema/IColorSchema";
import {BuyPremiumSubscription} from "../UI/Subscription/BuyPremiumSubscription";
import {setShowBuyPremiumDialogStatus} from "../../store/subscription/subscriptionSlice";
import {useNavigate} from "react-router-dom";
import {getFromStorage, saveToStorage} from "../../utils/storageHelpers";

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const isPrefersDarkMode = () => {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}

const defineMode = (mode: TMode) => {
    if (mode === 'auto') {
        return isPrefersDarkMode() ? 'dark' : 'light';
    }

    return mode;
}

type TransitionProps = Omit<SlideProps, 'direction'>;
const TransitionRight = (props: TransitionProps) => {
    return <Slide {...props} direction="left"/>;
}

interface IProps {
    children: ReactNode
}


declare module '@mui/material/styles' {
    interface Palette {
        custom: Palette['primary'];
        borderColor: Palette['primary'];
        textColorThird: Palette['primary'];
        filterColorAll: Palette['primary'];
        filterColorInProgress: Palette['primary'];
        filterColorCompleted: Palette['primary'];
        subscriptionCardBackground: Palette['primary'];
        subscriptionPriceColor: Palette['primary'];
        dragAndDropColor: Palette['primary'];
        dragAndDropBackgroundColor: Palette['primary'];
        dragAndDropBorderColor: Palette['primary'];
        sideBarItemColor: Palette['primary'];
        sideBarItemSelectedColor: Palette['primary'];
        sideBarItemHoverBackgroundColor: Palette['primary'];
        sideBarItemSelectedBackgroundColor: Palette['primary'];
        sideBarItemIconColor: Palette['primary'];
        sideBarItemIconSelectedColor: Palette['primary'];
        goalLabelIsDreamTextColor: Palette['primary'];
        goalLabelIsDreamBackgroundColor: Palette['primary'];
        goalLabelCompletedTextColor: Palette['primary'];
        goalLabelCompletedBackgroundColor: Palette['primary'];
        goalLabelDateTextColor: Palette['primary'];
        goalLabelDateBackgroundColor: Palette['primary'];
        journalDayDateColor: Palette['primary'];
        journalDayDateHoverColor: Palette['primary'];

        journalDayBlackColor: Palette['primary'];
        journalDayTerribleColor: Palette['primary'];
        journalDayBadColor: Palette['primary'];
        journalDayTypicalColor: Palette['primary'];
        journalDayGoodColor: Palette['primary'];
        journalDayLuckyColor: Palette['primary'];
        journalDayPerfectColor: Palette['primary'];

    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        custom?: PaletteOptions['primary'];
        borderColor?: PaletteOptions['primary'];
        textColorThird?: PaletteOptions['primary'];
        filterColorAll?: PaletteOptions['primary'];
        filterColorInProgress?: PaletteOptions['primary'];
        filterColorCompleted?: PaletteOptions['primary'];
        subscriptionCardBackground?: PaletteOptions['primary'];
        subscriptionPriceColor?: PaletteOptions['primary'];
        dragAndDropColor?: PaletteOptions['primary'];
        dragAndDropBackgroundColor?: PaletteOptions['primary'];
        dragAndDropBorderColor?: PaletteOptions['primary'];
        sideBarItemColor?: PaletteOptions['primary'];
        sideBarItemSelectedColor?: PaletteOptions['primary'];
        sideBarItemHoverBackgroundColor?: PaletteOptions['primary'];
        sideBarItemSelectedBackgroundColor?: PaletteOptions['primary'];
        sideBarItemIconColor?: PaletteOptions['primary'];
        sideBarItemIconSelectedColor?: PaletteOptions['primary'];
        goalLabelIsDreamTextColor?: PaletteOptions['primary'];
        goalLabelIsDreamBackgroundColor?: PaletteOptions['primary'];
        goalLabelCompletedTextColor?: PaletteOptions['primary'];
        goalLabelCompletedBackgroundColor?: PaletteOptions['primary'];
        goalLabelDateTextColor?: PaletteOptions['primary'];
        goalLabelDateBackgroundColor?: PaletteOptions['primary'];
        journalDayDateColor?: PaletteOptions['primary'];
        journalDayDateHoverColor?: PaletteOptions['primary'];

        journalDayBlackColor?: PaletteOptions['primary'];
        journalDayTerribleColor?: PaletteOptions['primary'];
        journalDayBadColor?: PaletteOptions['primary'];
        journalDayTypicalColor?: PaletteOptions['primary'];
        journalDayGoodColor?: PaletteOptions['primary'];
        journalDayLuckyColor?: PaletteOptions['primary'];
        journalDayPerfectColor?: PaletteOptions['primary'];

    }
}

// Update the Button's color prop options
// declare module '@mui/material/Button' {
//     interface ButtonPropsColorOverrides {
//         custom: true;
//     }
// }


export const MainTheme: FC<IProps> = ({children}) => {

    const {t} = useTranslation();
    const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {isMobile, mode, snackbar, colorSchema} = useAppSelector(state => state.utils);
    const {isAuth} = useAppSelector(state => state.auth);
    const {showBuyPremiumDialog} = useAppSelector(state => state.subscription);

    const getDrawerOpt = (): boolean => {
        const runUseEffect = async () => {
            return await getFromStorage('drawer');
        }
        runUseEffect()
            .then(drawerOpt => {
                if (drawerOpt.value && drawerOpt.value === 'true' && !isMobile) {
                    return true;
                }
            })
        return false;
    }

    const [open, setOpen] = useState<boolean>(getDrawerOpt());

    const GetPropertyValue = (obj: IColorSchema, prop: string) => {
        prop = prop.replace(/\[["'`](.*)["'`]\]/g, ".$1")
        return prop.split('.').reduce(function (prev: any, curr: any) {
            return prev ? prev[curr] : undefined
        }, obj)
    }

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: defineMode(mode),
                    background: {
                        default: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.background.main`),
                    },
                    primary: {
                        light: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.primary.light`),
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.primary.main`),
                        dark: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.primary.dark`),
                        contrastText: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.primary.contrastText`),
                    },
                    secondary: {
                        light: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.secondary.light`),
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.secondary.main`),
                        dark: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.secondary.dark`),
                    },
                    error: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.error.main`),
                    },
                    warning: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.warning.main`),
                    },
                    info: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.info.main`),
                    },
                    success: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.success.main`),
                    },
                    custom: {
                        light: '#ffa726',
                        main: '#f57c00',
                        dark: '#ef6c00',
                    },
                    borderColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.borderColor.main`),
                    },
                    textColorThird: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.textColorThird.main`),
                        dark: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.textColorThird.dark`),
                    },
                    filterColorAll: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.filterColorAll.main`),
                    },
                    filterColorInProgress: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.filterColorInProgress.main`),
                    },
                    filterColorCompleted: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.filterColorCompleted.main`),
                    },
                    subscriptionCardBackground: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.subscriptionCardBackground.main`),
                    },
                    subscriptionPriceColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.subscriptionPriceColor.main`),
                    },
                    dragAndDropColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.dragAndDropColor.main`),
                    },
                    dragAndDropBackgroundColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.dragAndDropBackgroundColor.main`),
                    },
                    dragAndDropBorderColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.dragAndDropBorderColor.main`),
                    },
                    sideBarItemColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.sideBarItemColor.main`),
                    },
                    sideBarItemSelectedColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.sideBarItemSelectedColor.main`),
                    },
                    sideBarItemHoverBackgroundColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.sideBarItemHoverBackgroundColor.main`),
                    },
                    sideBarItemSelectedBackgroundColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.sideBarItemSelectedBackgroundColor.main`),
                    },
                    sideBarItemIconColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.sideBarItemIconColor.main`),
                    },
                    sideBarItemIconSelectedColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.sideBarItemIconSelectedColor.main`),
                    },
                    goalLabelIsDreamTextColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.goalLabelIsDreamTextColor.main`),
                    },
                    goalLabelIsDreamBackgroundColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.goalLabelIsDreamBackgroundColor.main`),
                    },
                    goalLabelCompletedTextColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.goalLabelCompletedTextColor.main`),
                    },
                    goalLabelCompletedBackgroundColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.goalLabelCompletedBackgroundColor.main`),
                    },
                    goalLabelDateTextColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.goalLabelDateTextColor.main`),
                    },
                    goalLabelDateBackgroundColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.goalLabelDateBackgroundColor.main`),
                    },
                    journalDayDateColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.journalDayDateColor.main`),
                    },
                    journalDayDateHoverColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.journalDayDateHoverColor.main`),
                    },
                    journalDayBlackColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.journalDayBlackColor.main`),
                    },
                    journalDayTerribleColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.journalDayTerribleColor.main`),
                    },
                    journalDayBadColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.journalDayBadColor.main`),
                    },
                    journalDayTypicalColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.journalDayTypicalColor.main`),
                    },
                    journalDayGoodColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.journalDayGoodColor.main`),
                    },
                    journalDayLuckyColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.journalDayLuckyColor.main`),
                    },
                    journalDayPerfectColor: {
                        main: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.journalDayPerfectColor.main`),
                    }
                },
                components: {
                    MuiCssBaseline: {
                        styleOverrides: {
                            body: {
                                backgroundImage: `url('/images/color-schemas/wallpaper-${defineMode(mode)}-${colorSchema}.jpg')`,
                            },
                            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                                backgroundColor: "transparent"
                            },
                            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                                borderRadius: 8,
                                backgroundColor: defineMode(mode) === 'light' ? '#959595' : "#6b6b6b",
                                minHeight: 24,
                                border: defineMode(mode) === 'light' ? `${isMobile ? 6 : 3}px solid #ffffff` : `${isMobile ? 6 : 3}px solid #121212`
                            },
                            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                                backgroundColor: defineMode(mode) === 'light' ? '#b2b2b2' : "#959595"
                            },
                            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                                backgroundColor: defineMode(mode) === 'light' ? '#b2b2b2' : "#959595"
                            },
                            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: defineMode(mode) === 'light' ? '#b2b2b2' : "#959595"
                            }
                        }
                    },
                    MuiTextField: {
                        styleOverrides: {
                            root: {
                                input: {
                                    borderRadius: '0.75rem',
                                    "&:-webkit-autofill": {
                                        WebkitBoxShadow: "0 0 0 100px transparent inset",
                                        WebkitTextFillColor: "default",
                                    },
                                },
                            },
                        },
                    },
                    MuiAlert: {
                        styleOverrides: {
                            root: {
                                borderRadius: '0.5rem',
                            },
                        },
                        variants: [
                            {
                                props: {variant: 'outlined', severity: 'info'},
                                style: {
                                    borderStyle: 'dashed !important',
                                    borderColor: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.alert.info.borderColor`),
                                    border: '1px',
                                    backgroundColor: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.alert.info.backgroundColor`),
                                },
                            },
                            {
                                props: {variant: 'outlined', severity: 'error'},
                                style: {
                                    borderStyle: 'dashed !important',
                                    borderColor: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.alert.error.borderColor`),
                                    border: '1px',
                                    backgroundColor: GetPropertyValue(colorsSchemas[colorSchema], `${defineMode(mode)}.alert.error.backgroundColor`),
                                },
                            },
                        ],
                    },
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                borderRadius: '0.5rem',
                                boxShadow: 'none',
                                textTransform: 'none'
                            },
                        },
                    }
                }
            }),
        [mode, isMobile, colorSchema]
    );

    const toggleTheme = async (mode: TMode) => {
        dispatch(setMode(mode));
        dispatch(setThemeMode(mode === 'auto' ? (isDarkModeEnabled ? 'dark' : 'light') : mode));
        await saveToStorage('mode', mode);
    }

    const handleDrawerOpen = async () => {
        setOpen(true);
        await saveToStorage('drawer', 'true');
    };

    const handleDrawerClose = async () => {
        setOpen(false);
        await saveToStorage('drawer', 'false');
    };

    const snackbarCloseHandle = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(setSnackbar({...snackbar, open: false}));
    }

    useEffect(() => {
        if (isMobile && open) setOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    const handleCloseBuyPremiumDialog = () => {
        dispatch(setShowBuyPremiumDialogStatus(false));
    }

    const handleRedirectToSubscriptionPage = () => {
        dispatch(setShowBuyPremiumDialogStatus(false));
        navigate(`/subscription`);
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <NavBar
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    toggleTheme={toggleTheme}
                />
                {isMobile
                    ?
                    <SideBarMobile
                        isAuth={isAuth}
                        open={open}
                        close={handleDrawerClose}
                        toggleTheme={toggleTheme}
                    />
                    :
                    <SideBar
                        open={open}
                        handleDrawerClose={handleDrawerClose}
                    />
                }
                <Box component="main" sx={{flexGrow: 1, p: 0}}>
                    <DrawerHeader/>
                    <Snackbar
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                        open={snackbar.open}
                        autoHideDuration={2500}
                        onClose={snackbarCloseHandle}
                        TransitionComponent={TransitionRight}
                        sx={{display: snackbar.open ? 'flex' : 'none'}}
                    >
                        <Alert onClose={snackbarCloseHandle} severity={snackbar.severity} sx={{width: '100%'}}>
                            {t(snackbar.text)}
                        </Alert>
                    </Snackbar>
                    {
                        <BuyPremiumSubscription isOpen={showBuyPremiumDialog}
                                                handleClose={handleCloseBuyPremiumDialog}
                                                handleRedirect={handleRedirectToSubscriptionPage}
                        />
                    }
                    {children}
                </Box>
            </Box>
        </ThemeProvider>
    )
}
