import React, {ChangeEvent, FC, Fragment, useRef, useState} from 'react';
import {
    Box,
    Checkbox,
    CircularProgress,
    Divider,
    IconButton,
    Link,
    ListItem,
    ListItemIcon,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import {ITask} from '../../models/ITask';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import RepeatIcon from '@mui/icons-material/Repeat';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {TaskEditInput} from './TaskEditInput';
import {
    addTask,
    completeTask,
    deleteRecurring,
    deleteTask,
    editRecurringTask,
    removeDateStatus,
    removeGoalFromTask,
    removeTag,
    removeTagFromTask,
    resetTasks,
    tagTaskAction,
    uncompleteTask
} from '../../store/task/taskSlice';
import {ITabDay} from '../../models/days/ITabDay';
import {DeleteAlert} from '../UI/DeleteAlert/DeleteAlert';
import {useDrag, useDrop} from 'react-dnd';
import type {Identifier, XYCoord} from 'dnd-core';
import {IDragTask} from '../../models/dnd/IDragTask';
import {dndItemTypes} from '../../models/dnd/dndItemTypes';
import {TagList} from '../UI/Tags/TagList';
import {ITag} from '../../models/tags/ITag';
import {NavLink, useParams} from 'react-router-dom';
import {TagTaskActionType} from '../../models/Types';
import {useTranslation} from 'react-i18next';
import {TaskGoalsList} from '../UI/TaskGoals/TaskGoalsList';
import {removeTaskFromGoal, replaceTaskInGoal} from '../../store/goal/goalSlice';
import {
    calculateNearestTimeFromSchedule,
    changeTimezone,
    formatDateWithTimezone,
    getInitialRemindOptions,
    getTimeSchedule
} from '../../utils/helpers';
import {resetKicker} from '../../store/kicker/kickerSlice';
import {TaskEditDialog} from './TaskEditDialog';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {TTaskActionsResponse} from "../../models/response/TTaskActionsResponse";
import {recurringAPI} from "../../services/TaskService";
import DescriptionIcon from '@mui/icons-material/Description';

interface IProps {
    task: ITask,
    day: ITabDay,
    index: number,
    showDate?: boolean,
    isHideActions?: boolean,
    moveTasks: (dragIndex: number, hoverIndex: number) => void,
    dragEndHandle: (oldIndex: number, index: number) => void,
    isHideTags?: boolean,
    isHideGoals?: boolean,
    isHideCheckbox?: boolean,
    isDisableEdit?: boolean,
}

const deleteVariants: string[] = ['Delete this task', 'Delete this task and all subsequent ones', 'Delete all tasks'];

export const Task: FC<IProps> = ({
                                     task,
                                     day,
                                     index,
                                     isHideActions = false,
                                     showDate,
                                     moveTasks,
                                     dragEndHandle,
                                     isHideTags = false,
                                     isHideGoals = false,
                                     isHideCheckbox = false,
                                     isDisableEdit = false,
                                     ...props
                                 }) => {

    const {t} = useTranslation();
    const theme = useTheme();
    const {tag: paramTag} = useParams();
    const dispatch = useAppDispatch();

    const {isEditable} = useAppSelector(state => state.task);
    const {user} = useAppSelector(state => state.auth);
    const {isMobile, themeMode} = useAppSelector(state => state.utils);

    const dragRef = useRef<HTMLSpanElement>(null); // drag handle
    const previewRef = useRef<HTMLDivElement>(null); // drag item

    const [deleteCounter, setDeleteCounter] = useState<number>(0);
    const [isCompliting, setIsCompliting] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const timeSchedule = getTimeSchedule();
    const nearestTimeFromSchedule = calculateNearestTimeFromSchedule(user.timezoneName, timeSchedule);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [{handlerId}, drop] = useDrop<IDragTask, void, { handlerId: Identifier | null }>({
        accept: dndItemTypes.TASK,
        collect: monitor => {
            return {handlerId: monitor.getHandlerId()}
        },
        hover: (item, monitor) => {
            if (!previewRef.current) return;

            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) return;

            // Determine rectangle on screen
            const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

            // Time to actually perform the action
            moveTasks(dragIndex, hoverIndex);

            item.index = hoverIndex;
        }
    });

    const [{isDragging}, drag, preview] = useDrag({
        type: dndItemTypes.TASK,
        item: () => {
            return {
                task: task,
                date: day.date,
                tagFrom: paramTag ? paramTag : undefined,
                oldIndex: index,
                index: index
            } as IDragTask
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging()
        }),
        end: item => {
            if (item.oldIndex === item.index) return;
            dragEndHandle(item.oldIndex, item.index);
        }
    });

    function handleCheck(e: ChangeEvent<HTMLInputElement>) {
        setIsCompliting(true);

        const editedTask = {...task, isCompleted: true};
        if (!task.id) {
            dispatch(addTask({
                task: editedTask,
                date: day.date,
                tagTitles: editedTask.Tags.map(tag => tag.title)
            }))
                .unwrap()
                .then(() => {
                    setIsCompliting(false)
                    dispatch(resetKicker());
                });
        } else {
            if (paramTag && !task.isPlanned && !task.dayId) {
                dispatch(tagTaskAction({
                    taskId: task.id,
                    tagFrom: paramTag,
                    actionType: TagTaskActionType.COMPLETE,
                    date: formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))
                }))
                    .unwrap()
                    .then(() => setIsCompliting(false));

                return;
            }

            if (e.target.checked) {
                dispatch(completeTask(task.id))
                    .unwrap()
                    .then((originalPromiseResult) => {
                        setIsCompliting(false)
                        dispatch(resetKicker());
                        sendDispatchToGoals(originalPromiseResult);
                    });
            } else {
                dispatch(uncompleteTask(task.id))
                    .unwrap()
                    .then((originalPromiseResult) => {
                        setIsCompliting(false)
                        dispatch(resetKicker());
                        sendDispatchToGoals(originalPromiseResult);
                    });
            }
        }
    }

    const sendDispatchToGoals = (originalPromiseResult: TTaskActionsResponse) => {
        let keys = Object.keys(originalPromiseResult);
        let savedTask = originalPromiseResult[keys[0]] as ITask;
        if (savedTask.Goals?.length) {
            const goalsIds = savedTask.Goals.map(goal => goal.id);
            dispatch(replaceTaskInGoal({goalIds: goalsIds, task: savedTask}));
        }
    }

    const deleteAlertHandle = () => {
        setDeleteCounter(0);
    }

    const deleteTaskHandle = () => {
        handleMenuClose();
        if (deleteCounter < 1) {
            setDeleteCounter(deleteCounter + 1);
        } else {
            if (paramTag && task.id && !task.isPlanned && !task.dayId) {
                dispatch(tagTaskAction({
                    taskId: task.id,
                    tagFrom: paramTag,
                    actionType: TagTaskActionType.DELETE,
                    date: formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))
                }));
                return;
            }

            const tagTitles = task.Tags.map(tag => tag.title);
            const deletedTask = {...task, isDeleted: true};
            !task.id
                ?
                dispatch(addTask({
                    task: deletedTask,
                    date: day.date,
                    tagTitles: tagTitles
                }))
                    .unwrap()
                    .then(() => {
                        dispatch(resetKicker());
                    })
                :
                dispatch(deleteTask(task.id))
                    .unwrap()
                    .then((originalPromiseResult) => {
                        let keys = Object.keys(originalPromiseResult);
                        let savedTask = originalPromiseResult[keys[0]] as ITask;
                        if (savedTask.Goals?.length) {
                            const goalsIds = savedTask.Goals.map(goal => goal.id);
                            dispatch(replaceTaskInGoal({goalIds: goalsIds, task: {...savedTask, isDeleted: true}}));
                        }
                        dispatch(resetKicker());
                    });
        }
    }

    const deleteRecurringHandle = (type: string) => {
        const deletedTask = {...task, isDeleted: true};

        if (type === 'Delete this task') {
            const tagTitles = task.Tags.map(tag => tag.title);
            !task.id
                ?
                dispatch(addTask({
                    task: deletedTask,
                    date: day.date,
                    tagTitles: tagTitles
                }))
                :
                dispatch(deleteTask(task.id));
        }
        if (type === 'Delete this task and all subsequent ones') {
            dispatch(deleteRecurring({
                recurringId: task.recurringId,
                dayId: task.dayId,
                deleteTask: false
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    if (originalPromiseResult) {
                        dispatch(resetTasks());
                        dispatch(resetKicker());
                    }
                });
        }
        if (type === 'Delete all tasks') {
            dispatch(deleteRecurring({
                recurringId: task.recurringId,
                dayId: task.dayId,
                deleteTask: true
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    if (originalPromiseResult) {
                        dispatch(resetTasks());
                        dispatch(resetKicker());
                    }
                });
        }
    }

    const tagDelete = (tag: ITag) => {
        dispatch(removeTag({
            date: day.date,
            index: index,
            task: task,
            tag: tag,
            paramTag: paramTag ? paramTag : undefined
        }));

        if (task.isRecurring) {
            recurringAPI(task.recurringId)
                .then(originalPromiseResult => {
                    if (originalPromiseResult.data) {
                        console.log(originalPromiseResult.data.Frequencies);

                        const remindOptions = getInitialRemindOptions(originalPromiseResult.data.EventNotifications, nearestTimeFromSchedule, true, day.date);

                        if (originalPromiseResult.data.Frequencies && originalPromiseResult.data.Frequencies[0]) {
                            dispatch(editRecurringTask({
                                task: {...task, recurringId: task.recurringId},
                                date: day.date,
                                recurringConfig: {
                                    isRecurring: true,
                                    recurringType: String(originalPromiseResult.data.Frequencies[0].type),
                                    dayOfWeek: originalPromiseResult.data.Frequencies[0].dayOfWeek ? [originalPromiseResult.data.Frequencies[0].dayOfWeek] : [],
                                    dayOfMonth: originalPromiseResult.data.Frequencies[0].dayOfMonth ? [originalPromiseResult.data.Frequencies[0].dayOfMonth] : [],
                                    monthOfYear: originalPromiseResult.data.Frequencies[0].monthOfYear ? [originalPromiseResult.data.Frequencies[0].monthOfYear] : [],
                                    startDate: new Date(originalPromiseResult.data.startDate),
                                    endDate: originalPromiseResult.data.endDate ? new Date(originalPromiseResult.data.endDate) : null,
                                },
                                tagTitles: undefined,
                                goalTitles: undefined,
                                removedTags: [tag.id],
                                removedGoals: undefined,
                                remindConfig: remindOptions.isRemind ? {...remindOptions} : undefined,
                            }))
                                .unwrap()
                                .then(() => {
                                    dispatch(resetKicker());
                                });
                        }
                    }
                });
            return;
        } else {
            dispatch(removeTagFromTask({
                foreignKey: task.id ? task.id : task.recurringId,
                tagId: tag.id,
                model: task.id ? 'Task' : 'Event'
            }));
        }
    }

    const goalDelete = (goalId: number) => {
        if (task.isCompleted || !task.id) return;

        dispatch(removeTaskFromGoal({
            goalId: goalId,
            taskId: task.id
        }))
            .unwrap()
            .then(() => {
                dispatch(removeGoalFromTask({
                    date: day.date,
                    goalId: goalId,
                    task: task
                }));
            });
    }

    const handleRemoveDateStatus = () => {
        if (task.isCompleted || !task.id) return;
        dispatch(removeDateStatus(task.id));
    }

    const handleEditOpen = () => {
        handleMenuClose();
        setIsEdit(true);
    }

    const handleEditClose = () => {
        setIsEdit(false);
    }

    const opacity = isDragging ? 0 : 1;
    drag(dragRef);
    drop(preview(previewRef));

    return (
        <Box
            ref={previewRef}
            sx={{height: '48px !important', opacity: opacity, ml: isEditable ? '0px' : '10px'}}
            data-handler-id={handlerId}
        >
            <Divider/>
            {deleteCounter === 1 && !isHideActions &&
                <ListItem sx={{padding: '0'}}>
                    <DeleteAlert
                        deleteCounter={deleteCounter}
                        isRecurring={task.isRecurring}
                        deleteVariants={deleteVariants}
                        deleteAlertHandle={deleteAlertHandle}
                        deleteRecurringHandle={deleteRecurringHandle}
                        deleteTaskHandle={deleteTaskHandle}
                    />
                </ListItem>
            }
            {!deleteCounter &&
                <Fragment>
                    <ListItem sx={{paddingRight: '0px', paddingLeft: '0px', paddingTop: isEditable ? '4px' : '6px'}}
                              disabled={task.isCompleted || task.isDeleted}>
                        {
                            !isHideActions &&
                            <Box
                                ref={dragRef}
                                component={'span'}
                                sx={{
                                    paddingTop: '6px',
                                    paddingRight: '8px',
                                    display: isEditable ? 'block' : 'none'
                                }}
                            >
                                <OpenWithIcon
                                    color='action'
                                    sx={{
                                        cursor: 'grab',
                                        opacity: '0.4',
                                        "&:hover": {
                                            opacity: '1'
                                        }
                                    }}/>
                            </Box>
                        }
                        {isCompliting
                            ?
                            <CircularProgress sx={{color: '#a6a6a6', marginRight: '6px'}} size={'1.5rem'}/>
                            :
                            <div>
                                {
                                    !isHideCheckbox &&
                                    <Checkbox
                                        checked={task.isCompleted}
                                        disabled={task.isDeleted}
                                        sx={{padding: '0', margin: '0px', marginRight: '6px'}}
                                        onChange={handleCheck}
                                    />
                                }
                            </div>

                        }
                        <TaskEditInput
                            disabled={isCompliting || task.isCompleted || task.isDeleted || isDisableEdit}
                            value={task}
                            day={day}
                        />
                        {!isMobile && task.Tags && !isHideTags &&
                            <TagList
                                tags={task.Tags}
                                hide={paramTag ? paramTag : undefined}
                                handleDelete={task.isCompleted || isHideActions ? undefined : tagDelete}
                            />
                        }
                        {!isMobile && task.Goals && task.Goals.length && !isHideGoals
                            ?
                            <TaskGoalsList
                                goals={task.Goals}
                                handleDelete={isHideActions ? undefined : goalDelete}
                            />
                            :
                            ''
                        }
                        {showDate &&
                            <Box sx={{display: 'flex', marginLeft: '8px', borderBottom: '1px dashed'}}>
                                {task.isPlanned
                                    ?
                                    <Fragment>
                                        <Tooltip title={t('Click to go') + ' ' + t('planned')} arrow>
                                            <Link
                                                component={NavLink}
                                                to={'/tasks/planned'}
                                                color='inherit'
                                                underline='none'
                                            >
                                                {t('planned')}
                                            </Link>
                                        </Tooltip>
                                        {!task.isCompleted &&
                                            <Box
                                                sx={{display: 'flex', ml: 1, cursor: 'pointer'}}
                                                onClick={handleRemoveDateStatus}
                                            >
                                                <ClearIcon/>
                                            </Box>
                                        }
                                    </Fragment>
                                    :
                                    (task.dayId
                                            ?
                                            <Fragment>
                                                <Tooltip title={t('Click to go') + ' ' + day.date} arrow>
                                                    <Link
                                                        component={NavLink}
                                                        to={'/tasks/' + day.date}
                                                        color='inherit'
                                                        underline='none'
                                                    >
                                                        {day.date}
                                                    </Link>
                                                </Tooltip>
                                                {!task.isCompleted &&
                                                    <Box
                                                        sx={{display: 'flex', ml: 1, cursor: 'pointer'}}
                                                        onClick={handleRemoveDateStatus}
                                                    >
                                                        <ClearIcon/>
                                                    </Box>
                                                }
                                            </Fragment>
                                            :
                                            ''
                                    )
                                }
                            </Box>
                        }
                        {task.isRecurring &&
                            <RepeatIcon
                                sx={{marginLeft: '8px'}}
                                color={isCompliting || task.isCompleted || task.isDeleted ? 'disabled' : 'action'}
                            />
                        }
                        {(task.TaskNotifications && task.TaskNotifications.length) || (task.EventNotifications && task.EventNotifications.length)
                            ?
                            <NotificationsIcon
                                sx={{ml: '8px', mr: '6px'}}
                                color={isCompliting || task.isCompleted || task.isDeleted ? 'disabled' : 'action'}
                            />
                            :
                            ''
                        }
                        {!isHideActions &&
                            (
                                isMobile ?
                                    (
                                        isEditable &&
                                        <div>
                                            <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-controls={open ? 'long-menu' : undefined}
                                                aria-expanded={open ? 'true' : undefined}
                                                aria-haspopup="true"
                                                onClick={handleMenuClick}
                                            >
                                                <MoreVertIcon/>
                                            </IconButton>
                                            <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'long-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleMenuClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        overflow: 'visible',
                                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                        mt: 1.5,
                                                        '& .MuiAvatar-root': {
                                                            width: 32,
                                                            height: 32,
                                                            ml: -0.5,
                                                            mr: 1,
                                                        },
                                                        '&:before': {
                                                            content: '""',
                                                            display: 'block',
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 14,
                                                            width: 10,
                                                            height: 10,
                                                            bgcolor: 'background.paper',
                                                            backgroundColor: themeMode === 'light' ? 'rgb(255, 255, 255)' : "#121212",
                                                            backgroundImage: themeMode === 'light' ? '' : "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                                                            transform: 'translateY(-50%) rotate(45deg)',
                                                            zIndex: 0,
                                                        },
                                                        backgroundColor: themeMode === 'light' ? '' : "#121212",
                                                        backgroundImage: themeMode === 'light' ? '' : "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                                                        borderRadius: '0.75rem',
                                                        '& .MuiMenuItem-root': {
                                                            minWidth: '120px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <MenuItem
                                                    key={0}
                                                    onClick={handleEditOpen}
                                                    sx={{
                                                        "&:hover": {
                                                            opacity: '1',
                                                            color: theme.palette.primary.main,
                                                            '& .MuiListItemIcon-root': {
                                                                color: theme.palette.primary.main,
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <EditIcon/>
                                                    </ListItemIcon>
                                                    <Typography textAlign="center">{t('Edit')}</Typography>
                                                </MenuItem>
                                                <MenuItem
                                                    key={1}
                                                    onClick={deleteTaskHandle}
                                                    sx={{
                                                        "&:hover": {
                                                            opacity: '1',
                                                            color: theme.palette.error.main,
                                                            '& .MuiListItemIcon-root': {
                                                                color: theme.palette.error.main,
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <DeleteIcon/>
                                                    </ListItemIcon>
                                                    <Typography textAlign="center">{t('Delete')}</Typography>
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    )
                                    :
                                    (
                                        isEditable &&
                                        <Fragment>
                                            {
                                                task.description &&
                                                <IconButton
                                                    disabled={isCompliting || task.isCompleted || task.isDeleted}
                                                    edge="end"
                                                    aria-label="description"
                                                    sx={{marginRight: '0px'}}
                                                    onClick={handleEditOpen}
                                                >
                                                    <DescriptionIcon sx={{
                                                        opacity: '0.4',
                                                        "&:hover": {
                                                            opacity: '1',
                                                            color: theme.palette.primary.main,
                                                        }
                                                    }}/>
                                                </IconButton>
                                            }
                                            <IconButton
                                                disabled={isCompliting || task.isCompleted || task.isDeleted}
                                                edge="end"
                                                aria-label="delete"
                                                sx={{marginRight: '0px'}}
                                                onClick={handleEditOpen}
                                            >
                                                <EditIcon sx={{
                                                    opacity: '0.4',
                                                    "&:hover": {
                                                        opacity: '1',
                                                        color: theme.palette.primary.main,
                                                    }
                                                }}/>
                                            </IconButton>
                                            <IconButton
                                                disabled={isCompliting || task.isCompleted || task.isDeleted}
                                                edge="end"
                                                aria-label="delete"
                                                sx={{marginRight: '0px'}}
                                                onClick={deleteTaskHandle}
                                            >
                                                <DeleteIcon sx={{
                                                    opacity: '0.4',
                                                    "&:hover": {
                                                        opacity: '1',
                                                        color: theme.palette.error.main
                                                    }
                                                }}/>
                                            </IconButton>
                                        </Fragment>
                                    )

                            )
                        }
                    </ListItem>
                    {/*{*/}
                    {/*    (task.isCompleted || task.isDeleted) &&*/}
                    {/*    <Divider sx={{marginTop: isHideActions ? '-20px' : '-25px'}}/>*/}
                    {/*}*/}
                </Fragment>
            }
            {
                !isHideActions &&
                <TaskEditDialog
                    open={isEdit}
                    task={task}
                    date={day.date}
                    onClose={handleEditClose}
                />
            }
        </Box>
    )
}
