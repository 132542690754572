import {AxiosResponse} from "axios";
import $api from "../http";
import {IUserDay} from "../models/days/IUserDay";
import {IOrder} from "../models/IOrder";
import {IRecurring} from "../models/recurring/IRecurring";
import {ITag} from "../models/tags/ITag";
import {ITask} from "../models/ITask";
import {IJournalResponse} from "../models/response/IJournalResponse";
import {IYearUserDaysResponse} from "../models/response/IYearUserDaysResponse";
import {TGetTagTasksResponse} from "../models/response/TGetTagTasksResponse";
import {TGetTasksResponse} from "../models/response/TGetTasksResponse";
import {TTaskActionsResponse} from "../models/response/TTaskActionsResponse";
import {IRecurringFormFields} from "../models/validation/IRecurringFormFields";
import {IUserTag} from "../models/tags/IUserTag";
import {IDay} from "../models/days/IDay";
import {IRemindOptions} from "../models/notifications/IRemindOptions";
import {TTasksActionsResponse} from "../models/response/TTasksActionsResponse";
import {IPlannedTasksResponse} from "../models/response/IPlannedTasksResponse";

const TASK_API_URL = process.env.REACT_APP_TASK_API_URL;
const GOAL_API_URL = process.env.REACT_APP_GOAL_API_URL;

export const dayRecurringTasksAPI = async (date: string): Promise<AxiosResponse<TGetTasksResponse>> => {
    return await $api.get<TGetTasksResponse>(`${TASK_API_URL}/task/day-recurring/day?date=${date}`);
}

export const recurringAPI = async (recurringId: number): Promise<AxiosResponse<IRecurring>> => {
    return await $api.get<IRecurring>(`${TASK_API_URL}/task/recurring/${recurringId}`);
}

export const recurringAllAPI = async (): Promise<AxiosResponse<IRecurring[]>> => {
    return await $api.get<IRecurring[]>(`${TASK_API_URL}/task/recurring-all`);
}

export const weekRecurringTasksAPI = async (date: string): Promise<AxiosResponse<TGetTasksResponse>> => {
    return await $api.get<TGetTasksResponse>(`${TASK_API_URL}/task/day-recurring/week?startDate=${date}`);
}

export const tasksAddAPI = async (
    task: ITask,
    date: string,
    notifyAt?: string[],
    notificationMethods?: number[],
    tagTitles?: string[],
    goalTitles?: string[]
): Promise<AxiosResponse<TTaskActionsResponse>> => {
    return await $api.post<TTaskActionsResponse>(`${TASK_API_URL}/task/add`, {
        ...task,
        tagTitles,
        goalTitles,
        notifyAt,
        notificationMethods,
        date
    });
}

export const tasksTagAddAPI = async (task: ITask, tagTitles: string[]): Promise<AxiosResponse<TTaskActionsResponse>> => {
    return await $api.post<TTaskActionsResponse>(`${TASK_API_URL}/task/tags/add-tag-task`, {...task, tagTitles});
}

export const taskTagActionAPI = async (taskId: number, tagFrom: string, actionType: number, date: string): Promise<AxiosResponse<TTaskActionsResponse>> => {
    return await $api.post<TTaskActionsResponse>(`${TASK_API_URL}/task/tags/tag-task-actions`, {
        taskId,
        tagFrom,
        actionType,
        date
    });
}

export const taskRemoveDateStatusAPI = async (taskId: number): Promise<AxiosResponse<ITask>> => {
    return await $api.post<ITask>(`${TASK_API_URL}/task/tags/remove-date-status/${taskId}`);
}

export const tasksEditAPI = async (
    task: ITask,
    notifyAt?: string[],
    notificationMethods?: number[],
    tagTitles?: string[],
    goalTitles?: string[],
    removedTags?: number[],
    removedGoals?: number[],
    recurringConfig?: IRecurringFormFields,
    remindConfig?: IRemindOptions
): Promise<AxiosResponse<TTaskActionsResponse>> => {
    return await $api.post<TTaskActionsResponse>(`${TASK_API_URL}/task/edit/${task.id}`, {
        ...task,
        notifyAt,
        notificationMethods,
        tagTitles,
        goalTitles,
        removedTags,
        removedGoals,
        recurringConfig,
        remindConfig
    });
}

export const tasksCompleteAPI = async (taskId: number | null): Promise<AxiosResponse<TTaskActionsResponse>> => {
    return await $api.post<TTaskActionsResponse>(`${TASK_API_URL}/task/complete/${taskId}`);
}

export const tasksUncompleteAPI = async (taskId: number | null): Promise<AxiosResponse<TTaskActionsResponse>> => {
    return await $api.post<TTaskActionsResponse>(`${TASK_API_URL}/task/uncomplete/${taskId}`);
}

export const tasksDeleteAPI = async (taskId: number | null): Promise<AxiosResponse<TTaskActionsResponse>> => {
    return await $api.post<TTaskActionsResponse>(`${TASK_API_URL}/task/delete/${taskId}`);
}

export const tasksUndeleteAPI = async (taskId: number | null, isPlanned: boolean, isCompleted: boolean, removeRecurring: boolean | null): Promise<AxiosResponse<TTaskActionsResponse>> => {
    return await $api.post<TTaskActionsResponse>(`${TASK_API_URL}/task/undelete/${taskId}`, {
        isPlanned,
        isCompleted,
        removeRecurring
    });
}

export const tasksDeleteDayTasksAPI = async (dayId: number): Promise<AxiosResponse<IDay>> => {
    return await $api.post<IDay>(`${TASK_API_URL}/task/delete-day-tasks/${dayId}`);
}

export const tasksDeleteDayOverdueTasksAPI = async (dayId: number): Promise<AxiosResponse<TTasksActionsResponse>> => {
    return await $api.post<TTasksActionsResponse>(`${TASK_API_URL}/task/delete-day-overdue-tasks/${dayId}`);
}

export const tasksDeleteAllDeletedAPI = async (): Promise<AxiosResponse<number>> => {
    return await $api.post<number>(`${TASK_API_URL}/task/delete-all-deleted`);
}

export const tasksTogglePlannedAPI = async (taskId: number | null, date: string): Promise<AxiosResponse<TTaskActionsResponse>> => {
    return await $api.post<TTaskActionsResponse>(`${TASK_API_URL}/task/toggle-planned/${taskId}`, {date: date});
}

export const recurringTaskAddAPI = async (task: ITask, recurringConfig: IRecurringFormFields, remindConfig?: IRemindOptions | null, tagTitles?: string[], currDate?: string): Promise<AxiosResponse<IRecurring>> => {
    return await $api.post<IRecurring>(`${TASK_API_URL}/task/recurring/add`, {
        type: recurringConfig.recurringType,
        title: task.title,
        description: task.description,
        dayOfWeek: recurringConfig.dayOfWeek,
        dayOfMonth: recurringConfig.dayOfMonth,
        monthOfYear: recurringConfig.monthOfYear,
        startDate: recurringConfig.startDate,
        endDate: recurringConfig.endDate,
        remindConfig: remindConfig,
        tagTitles: tagTitles,
        currDate: currDate
    });
}

export const recurringTaskEditAPI = async (
    task: ITask,
    date: string,
    recurringConfig: IRecurringFormFields,
    tagTitles?: string[],
    goalTitles?: string[],
    removedTags?: number[],
    removedGoals?: number[],
    remindConfig?: IRemindOptions
): Promise<AxiosResponse<IRecurring>> => {
    return await $api.post<IRecurring>(`${TASK_API_URL}/task/recurring/edit/${task.recurringId}`, {
        type: recurringConfig.recurringType,
        title: task.title,
        description: task.description,
        isRecurring: recurringConfig.isRecurring,
        date: date,
        dayOfWeek: recurringConfig.dayOfWeek,
        dayOfMonth: recurringConfig.dayOfMonth,
        monthOfYear: recurringConfig.monthOfYear,
        startDate: recurringConfig.startDate,
        endDate: recurringConfig.endDate,
        tagTitles,
        goalTitles,
        removedTags,
        removedGoals,
        remindConfig
    });
}

export const recurringDeleteAPI = async (recurringId: number, dayId: number | null, deleteTask: boolean): Promise<AxiosResponse<boolean>> => {
    return await $api.post<boolean>(`${TASK_API_URL}/task/recurring/delete/${recurringId}`, {
        dayId: dayId,
        deleteTask: deleteTask
    });
}

export const completedTasksAPI = async (page: number, limit: number): Promise<AxiosResponse<TGetTasksResponse>> => {
    return await $api.get<TGetTasksResponse>(`${TASK_API_URL}/task/completed?page=${page}&limit=${limit}`);
}

export const overdueTasksAPI = async (page: number, limit: number, today: string): Promise<AxiosResponse<TGetTasksResponse>> => {
    return await $api.get<TGetTasksResponse>(`${TASK_API_URL}/task/overdue?page=${page}&limit=${limit}&today=${today}`);
}

export const plannedTasksAPI = async (page: number, limit: number): Promise<AxiosResponse<IPlannedTasksResponse>> => {
    return await $api.get<IPlannedTasksResponse>(`${TASK_API_URL}/task/planned?page=${page}&limit=${limit}`);
}

export const deletedTasksAPI = async (page: number, limit: number): Promise<AxiosResponse<TGetTasksResponse>> => {
    return await $api.get<TGetTasksResponse>(`${TASK_API_URL}/task/deleted?page=${page}&limit=${limit}`);
}

export const reorderTasksAPI = async (orderId: number | null, newPosition: number): Promise<AxiosResponse<IOrder[]>> => {
    return await $api.post<IOrder[]>(`${TASK_API_URL}/task/reorder`, {orderId, newPosition});
}

export const changeTasksDayAPI = async (date: string, taskId: number, orderId: number, listType: number): Promise<AxiosResponse<ITask>> => {
    return await $api.post<ITask>(`${TASK_API_URL}/task/change-day`, {
        date: date,
        taskId: taskId,
        orderId: orderId,
        listType: listType
    });
}

// Kicker
export const yearUserDaysAPI = async (year: string): Promise<AxiosResponse<IYearUserDaysResponse>> => {
    return await $api.get<IYearUserDaysResponse>(`${TASK_API_URL}/task/user-day/year?year=${year}`);
}

export const getUserDayAPI = async (date: string): Promise<AxiosResponse<{ [date: string]: IUserDay }>> => {
    return await $api.get<{ [date: string]: IUserDay }>(`${TASK_API_URL}/task/user-day?date=${date}`);
}

export const userDaysAddAPI = async (date: string, type: number, comment: string): Promise<AxiosResponse<{
    [date: string]: IUserDay
}>> => {
    return await $api.post<{ [date: string]: IUserDay }>(`${TASK_API_URL}/task/user-day/add`, {
        date: date,
        type: type,
        comment: comment
    });
}

export const userDaysEditAPI = async (id: number, dayType: number, comment: string): Promise<AxiosResponse<{
    [date: string]: IUserDay
}>> => {
    return await $api.post<{ [date: string]: IUserDay }>(`${TASK_API_URL}/task/user-day/edit/${id}`, {
        type: dayType,
        comment: comment
    });
}

export const journalAPI = async (page: number, limit: number): Promise<AxiosResponse<IJournalResponse>> => {
    return await $api.get<IJournalResponse>(`${TASK_API_URL}/task/user-day/journal?page=${page}&limit=${limit}`);
}

// Tags
export const getUserTagsAPI = async (isArchived?: boolean): Promise<AxiosResponse<ITag[]>> => {
    return await $api.get<ITag[]>(`${TASK_API_URL}/task/tags${isArchived ? ('?isArchived=' + isArchived) : ''}`);
}

export const getTagTasksAPI = async (tagTitle: string, page: number, limit: number): Promise<AxiosResponse<TGetTagTasksResponse>> => {
    return await $api.get<TGetTagTasksResponse>(`${TASK_API_URL}/task/tags/list/${tagTitle}?page=${page}&limit=${limit}`);
}

export const addTagsAPI = async (titles: string[]): Promise<AxiosResponse<ITag[]>> => {
    return await $api.post<ITag[]>(`${TASK_API_URL}/task/tags/add`, {titles: titles});
}

export const editTagAPI = async (
    tagTitle: string,
    color?: string,
    icon?: string,
    goalId?: number | null,
    isArchived?: 0 | 1 | 2,
    aliases?: string[]
): Promise<AxiosResponse<IUserTag>> => {
    return await $api.post<IUserTag>(`${TASK_API_URL}/task/tags/edit`, {
        tagTitle: tagTitle,
        color: color,
        icon: icon,
        goalId: goalId,
        isArchived: isArchived,
        aliases: aliases
    });
}

export const removeTagAPI = async (foreignKey: number, tagId: number, model: 'Task' | 'Event'): Promise<AxiosResponse<number>> => {
    return await $api.post<number>(`${TASK_API_URL}/task/tags/remove`, {
        foreignKey: foreignKey,
        tagId: tagId,
        model: model
    });
}

export const renameListAPI = async (tagTitle: string, newTagTitle: string): Promise<AxiosResponse<IUserTag>> => {
    return await $api.post<IUserTag>(`${TASK_API_URL}/task/tags/list/rename`, {tagTitle, newTagTitle});
}

export const copyListAPI = async (tagId: number, newTagTitle: string): Promise<AxiosResponse<IUserTag>> => {
    return await $api.post<IUserTag>(`${TASK_API_URL}/task/tags/list/copy`, {tagId, newTagTitle});
}

export const deleteListAPI = async (tagId: number): Promise<AxiosResponse<Boolean>> => {
    return await $api.post<Boolean>(`${TASK_API_URL}/task/tags/list/delete/${tagId}`);
}

export const getRelatedTasksAPI = async (goalId: number): Promise<AxiosResponse<ITask[]>> => {
    return await $api.get<ITask[]>(`${GOAL_API_URL}/goal/get-related-tasks/${goalId}`);
}