import React, {FC, useEffect, useState} from 'react';
import {Box, Card, Container} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {KickerMonth} from '../../components/UI/Calendar/KickerMonth';
import {getYearUserDays} from '../../store/kicker/kickerSlice';
import {YearPicker} from '../../components/UI/Calendar/YearPicker';

const today = new Date();

export const Kicker: FC = () => {

    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {userDays} = useAppSelector(state => state.kicker);

    const [year, setYear] = useState<number>(today.getFullYear());

    const toggleYearHandle = (newYear: number) => {
        setYear(newYear);
    }

    useEffect(() => {
        if (!userDays.hasOwnProperty(String(year))) {
            dispatch(getYearUserDays(String(year)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year]);

    useEffect(() => {
        const today = document.getElementById('today');
        today?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });
    }, []);

    return (
        <Container maxWidth={false} disableGutters={true} sx={{maxWidth: '1480px'}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflowY: 'auto',
                    paddingBottom: '16px',
                    // marginLeft: '5px',
                    // marginRight: '5px'
                }}
            >
                <Box sx={{
                    transition: 'all .5s !important',
                    width: '100%',
                    maxWidth: isMobile ? '100%' : '100%',
                }}>
                    <Box sx={{mt: isMobile ? 0 : 0}}>
                        <Card
                            sx={{
                                marginTop: isMobile ? 2 : 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                transition: 'all .25s !important',
                                overflowY: isMobile ? '' : 'auto',
                                height: isMobile ? '' : 'calc(100vh - 110px)',
                                borderRadius: '0.75rem',
                            }}
                        >

                            <Box sx={{
                                transition: 'all .5s !important',
                                width: '100%',
                                maxWidth: isMobile ? '100%' : '86%'
                            }}>
                                <YearPicker currYear={year} toggleYearHandle={toggleYearHandle}/>
                                <Box
                                    sx={{
                                        tableLayout: 'fixed',
                                        display: 'flex',
                                        margin: 'auto',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {[...Array.from(Array(12).keys())].map(month => (
                                        <KickerMonth
                                            year={year}
                                            month={month}
                                            day={today.getDate()}
                                            userDays={userDays}
                                            key={month}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}
