import { ILink } from "../models/ILink";
import { changeTimezone, formatDateWithTimezone } from "../utils/helpers";

export const getLinks = (timezone: string): ILink[] => {
  return [
    {
      name: "Goals",
      path: "/goals",
      auth: true,
    },
    {
      name: "Planned",
      path: "/tasks/planned",
      auth: true,
    },
    {
      name: "Tasks",
      path: `/tasks/${formatDateWithTimezone(
        changeTimezone(new Date(), timezone)
      )}`,
      auth: true,
    },
    {
      name: "Agenda",
      path: "/agenda",
      auth: true,
    },
    {
      name: "Lists",
      path: "/lists",
      auth: true,
    },
    {
      name: "Recurrings",
      path: "/recurrings",
      auth: true,
    },
    {
      name: "Overdue",
      path: "/tasks/overdue",
      auth: true,
    },
    {
      name: "Completed",
      path: "/tasks/completed",
      auth: true,
    },
    {
      name: "Deleted",
      path: "/tasks/deleted",
      auth: true,
    },
    {
      name: "Notes",
      path: "/notes",
      auth: true,
    },
    {
      name: "Journal",
      path: "/journal",
      auth: true,
    },
    {
      name: "Kicker",
      path: "/kicker",
      auth: true,
    },
    // {
    //   name: "Habits",
    //   path: "/habits",
    //   auth: true,
    // },
  ];
};
export const getHomePageLink = (): ILink => {
  return {
    name: "Home",
    path: "/home",
    auth: false,
  };
};

export const getDashboardPageLink = (): ILink => {
  return {
    name: "Home",
    path: "/dashboard",
    auth: true,
  };
};

export const userLinks: ILink[] = [
  {
    name: "Sign Up",
    path: "/register",
    auth: false,
  },
  {
    name: "Log in",
    path: "/login",
    auth: false,
  },
  {
    name: "Profile",
    path: "/profile",
    auth: true,
  },
  {
    name: "Subscription",
    path: "/subscription",
    auth: true,
  },
];
