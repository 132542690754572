import React, {FC, Fragment, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {deleteNote, favoriteNote, removeTagFromNote} from '../../store/note/noteSlice';
import {INote} from "../../models/INote";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import {t} from "i18next";
import {TagList} from "../UI/Tags/TagList";
import {ITag} from "../../models/tags/ITag";
import {formatTitle} from "../../utils/helpers";

interface IProps {
    note: INote,
    openViewModal: (note: INote) => void,
    openEditModal: (note: INote) => void
}

export const Note: FC<IProps> = ({note, openViewModal, openEditModal, ...props}) => {

    const noteLength = 140;
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {isMobile} = useAppSelector(state => state.utils);
    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
    const [showTagsDialog, setShowTagDialog] = useState<boolean>(false);

    const deleteNoteHandle = () => {
        setShowDeleteAlert(true);
    }

    const favoriteNoteHandle = (note: INote) => {
        dispatch(favoriteNote(note));
    }

    const viewTaskHandle = () => {
        openViewModal(note);
    }

    const editTaskHandle = () => {
        openEditModal(note);
    }

    const handleDeleteClose = () => {
        setShowDeleteAlert(false);
    }

    const deleteGoalHandle = () => {
        const deletedNote = {...note, isDeleted: true};
        dispatch(deleteNote(deletedNote));
    }

    const showTagsDialogHandle = () => {
        setShowTagDialog(true);
    }

    const tagsDialogCloseHandle = () => {
        setShowTagDialog(false);
    }

    const tagDelete = (tag: ITag) => {
        dispatch(removeTagFromNote({
            foreignKey: note.id,
            tagId: tag.id,
            model: 'Note'
        }));
    }

    const clickTag = (tag: ITag) => {
        setShowTagDialog(false);
    }

    return (
        <Fragment>
            <Card sx={{
                height: '160px',
                border: '1px solid #DDDADA',
                boxShadow: 'none',
                borderRadius: '0.75rem'
            }}
            >

                <CardContent sx={{height: '100px'}}>
                    <Typography variant="body2" color="text.secondary"
                                sx={{
                                    wordWrap: 'anywhere'
                                }}>
                        {formatTitle({title: note.shortTitle, addSpaseBefore: true}).title.substring(0, noteLength)}
                    </Typography>
                </CardContent>
                {
                    !showDeleteAlert ?
                        <CardActions key={note.id} sx={{justifyContent: 'end'}}>
                            <IconButton
                                edge="end"
                                aria-label="view"
                                onClick={viewTaskHandle}
                                sx={{
                                    "&:hover": {
                                        color: theme.palette.primary.main,
                                    }
                                }}
                            >
                                <ZoomInIcon/>
                            </IconButton>
                            {
                                note.Tags.length > 0 &&
                                <IconButton
                                    edge="end"
                                    aria-label="tags"
                                    onClick={showTagsDialogHandle}
                                    sx={{
                                        "&:hover": {
                                            color: theme.palette.primary.main,
                                        }
                                    }}
                                >
                                    <BookmarkBorderIcon/>
                                </IconButton>

                            }
                            <IconButton
                                edge="end"
                                aria-label="favorite"
                                onClick={() => favoriteNoteHandle(note)}
                                sx={{
                                    "&:hover": {
                                        color: theme.palette.primary.main,
                                    }
                                }}
                            >
                                {note.favorite
                                    ?
                                    <StarIcon sx={{color: "yellow"}}/>
                                    :
                                    <StarBorderIcon/>
                                }
                            </IconButton>
                            <IconButton
                                edge="end"
                                aria-label="edit"
                                onClick={editTaskHandle}
                                sx={{
                                    "&:hover": {
                                        color: theme.palette.primary.main,
                                    }
                                }}
                            >
                                <EditIcon/>
                            </IconButton>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                color={'default'}
                                onClick={deleteNoteHandle}
                                sx={{
                                    margin: '0 8px',
                                    "&:hover": {
                                        color: theme.palette.error.main,
                                    }
                                }}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </CardActions>
                        :
                        <CardActions key={'c' + note.id}>
                            <Fragment>
                                <Alert
                                    variant={"outlined"}
                                    severity='error'
                                    sx={{
                                        width: '100%',
                                        maxHeight: '32px',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                cursor: 'text',
                                                paddingRight: '12px'
                                            }}
                                        >
                                            {t('')}
                                        </Typography>
                                        <Button
                                            color="inherit"
                                            size="small"
                                            onClick={handleDeleteClose}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                        <Button
                                            color="inherit"
                                            size="small"
                                            onClick={deleteGoalHandle}
                                        >
                                            {t('Delete')}
                                        </Button>
                                    </Box>
                                </Alert>
                            </Fragment>
                        </CardActions>
                }
            </Card>

            <Dialog open={showTagsDialog}
                    sx={{
                        borderRadius: '0.75rem'
                    }}
            >
                <DialogTitle>{t('Tags')}</DialogTitle>
                <DialogContent sx={{width: isMobile ? '100%' : '600px'}}>
                    <TagList
                        tags={note.Tags}
                        handleDelete={tagDelete}
                        redirectRoute={'notes'}
                        clickTag={clickTag}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={tagsDialogCloseHandle}>{t('Cancel')}</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
