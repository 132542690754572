import {combineReducers, configureStore} from '@reduxjs/toolkit';
import authSlice from './auth/authSlice';
import taskSlice from './task/taskSlice';
import goalSlice from './goal/goalSlice';
import utilsSlice from './utils/utilsSlice';
import noteSlice from "./note/noteSlice";
import kickerSlice from './kicker/kickerSlice';
import subscriptionSlice from "./subscription/subscriptionSlice";
import accountSlice from "./account/accountSlice";
import notificationSlice from "./notification/notificationSlice";

const rootReducer = combineReducers({
    auth: authSlice,
    task: taskSlice,
    goal: goalSlice,
    utils: utilsSlice,
    note: noteSlice,
    kicker: kickerSlice,
    subscription: subscriptionSlice,
    account: accountSlice,
    notification: notificationSlice
});

const setupStore = () => {
    return configureStore({
        reducer: rootReducer
    })
};

export const store = setupStore();
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];