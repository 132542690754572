import React, { FC } from 'react';
import { Box, Container } from '@mui/material';
import { ForgotPasswordForm } from '../../components/auth/ForgotPasswordForm';

export const ForgotPassword: FC = () => {
    return (
        <Container maxWidth='lg'>
            <Box
                sx={{
                    marginTop: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <ForgotPasswordForm />
            </Box>
        </Container>
    )
}
