import React, {CSSProperties, FC, Fragment, useEffect, useRef, useState} from 'react';
import {
    Alert,
    Box,
    CircularProgress,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useDays} from '../../hooks/days';
import {getAgendaTasks, toggleEditable} from '../../store/task/taskSlice';
import {NavLink} from 'react-router-dom';
import {TGetTasksResponse} from '../../models/response/TGetTasksResponse';
import {changeTimezone, formatDateWithTimezone, getDayName} from '../../utils/helpers';
import {IDay} from '../../models/days/IDay';
import {ITask} from '../../models/ITask';
import {Task} from '../tasks/Task';
import {TransitionGroup} from 'react-transition-group';
import {ITabDay} from '../../models/days/ITabDay';
import {DnDPicker} from "../UI/Calendar/DnDPicker";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import {DnDDays} from "../UI/Calendar/DnDDays";

interface IAgendaTasksListProps {
    isHideActions?: boolean,
    style?: CSSProperties,
    height?: CSSProperties['height']
}

let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const filterAgendaDays = (dates: string[], days: TGetTasksResponse): IDay<ITask[]>[] => {
    const resultedDays: IDay<ITask[]>[] = [];
    dates.forEach(date => {
        if (days.hasOwnProperty(date) && days[date].Tasks.length) resultedDays.push(days[date]);
    });

    return resultedDays;
}

export const AgendaTasksList: FC<IAgendaTasksListProps> = ({
                                                               isHideActions = false,
                                                               style,
                                                               height,
                                                               ...props
                                                           }) => {

    const {t} = useTranslation();

    const dispatch = useAppDispatch();

    const theme = useTheme();

    const {days} = useDays();

    const {user} = useAppSelector(state => state.auth);
    const {days: storedDays, isTaskLoading, isEditable} = useAppSelector(state => state.task);
    const {isMobile} = useAppSelector(state => state.utils);
    const executeRef = useRef<boolean>(false);

    const dates = days.map(day => day.date);

    const [daysWithTasks, setDaysWithTasks] = useState<IDay<ITask[]>[]>([]);

    useEffect(() => {
        days.forEach(day => {
            if (!storedDays.hasOwnProperty(day.date)) {
                executeRef.current = true;
            }
        });
        if (executeRef.current) {
            dispatch(getAgendaTasks(formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))))
                .unwrap()
                .then(originalPromiseResult => {
                    setDaysWithTasks(filterAgendaDays(dates, originalPromiseResult));
                });
        } else {
            setDaysWithTasks(filterAgendaDays(dates, storedDays));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!Object.keys(storedDays).length) {
            dispatch(getAgendaTasks(formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))))
                .unwrap()
                .then(originalPromiseResult => {
                    setDaysWithTasks(filterAgendaDays(dates, originalPromiseResult));
                });
        } else {
            setDaysWithTasks(filterAgendaDays(dates, storedDays));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedDays]);

    const handleEditClick = () => {
        dispatch(toggleEditable());
    }

    return (
        <div style={style}>
            <Box sx={{display: 'flex', justifyContent: 'start'}}>
                <Stack direction="row" spacing={0}>
                    <Typography
                        color='primary'
                        variant='h5'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '12px',
                            fontSize: '1.5rem',
                            fontWeight: '500 !important',
                            lineHeight: '1.2',
                        }}
                    >
                        {t('Agenda')}
                    </Typography>
                    {
                        isEditable
                            ?
                            <RemoveRedEyeIcon
                                color='primary'
                                sx={{
                                    ml: 2,
                                    mt: '4px',
                                    fontSize: '1.5rem',
                                    cursor: 'pointer',
                                    fontWeight: '500 !important',
                                    lineHeight: '1.2',
                                }}
                                onClick={handleEditClick}
                            />
                            :
                            <EditIcon
                                color='primary'
                                sx={{
                                    ml: 2,
                                    mt: '4px',
                                    fontSize: '1.5rem',
                                    cursor: 'pointer',
                                    fontWeight: '500 !important',
                                    lineHeight: '1.2',
                                }}
                                onClick={handleEditClick}
                            />
                    }
                </Stack>
                {!isHideActions &&
                    <Box sx={{display: 'flex', ml: isMobile ? '8px' : '16px'}}>
                        {!isMobile
                            &&
                            <Fragment>
                                <DnDDays
                                    title={t('Today')}
                                    dateTo={formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))}
                                    style={{
                                        fontSize: '21px',
                                        height: '30px',
                                    }}
                                />
                                <DnDDays
                                    title={t('Tomorrow')}
                                    dateTo={formatDateWithTimezone(changeTimezone(tomorrow, user.timezoneName))}
                                    style={{
                                        fontSize: '21px',
                                        height: '30px',
                                        marginLeft: '4px'
                                    }}
                                />
                            </Fragment>
                        }
                        <DnDPicker isFullBorder copy/>
                        <DnDPicker isFullBorder style={{marginLeft: '8px'}}/>
                    </Box>
                }
            </Box>

            <Box sx={{
                overflowY: 'auto',
                height: height ? height : (isMobile ? 'calc(100vh - 160px)' : 'calc(100vh - 160px)')
            }}>
                <List dense={true}>
                    <TransitionGroup>
                        {daysWithTasks.map((day, dayIndex) => (
                            <Collapse key={day.date}>
                                <ListItem
                                    component={NavLink}
                                    to={`/tasks/${day.date}`}
                                    sx={{width: 'fit-content', padding: '8px 0px'}}
                                    style={{
                                        textDecorationColor: theme.palette.primary.main
                                    }}
                                >
                                    <ListItemIcon>
                                        <TodayIcon color='primary'/>
                                    </ListItemIcon>
                                    <Tooltip title={t('Click to go') + ' ' + day.date} arrow>
                                        <ListItemText
                                            primary={`${day.date} - ${t(getDayName(day.date))}`}
                                            primaryTypographyProps={{
                                                marginLeft: '-16px',
                                                fontSize: '1rem',
                                                fontWeight: '500',
                                                color: 'primary'
                                            }}
                                        />
                                    </Tooltip>
                                </ListItem>
                                <TransitionGroup>
                                    {day.Tasks.map((task, i) => (
                                        <Collapse key={task.id}
                                                  sx={{
                                                      marginLeft: isMobile ? '0px' : '24px',
                                                  }}
                                        >
                                            <Task
                                                task={task}
                                                day={days.find(tabDay => tabDay.date === day.date) || {date: day.date} as ITabDay}
                                                index={i}
                                                isHideActions={isHideActions}
                                                moveTasks={() => {
                                                }}
                                                dragEndHandle={() => {
                                                }}
                                            />
                                        </Collapse>
                                    ))}
                                </TransitionGroup>
                                <Divider/>
                            </Collapse>
                        ))}
                    </TransitionGroup>
                </List>
                {isTaskLoading &&
                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '6px 0 18px 0'}}>
                        <CircularProgress/>
                    </Box>
                }
                {!isTaskLoading && !daysWithTasks.length &&
                    <Alert variant={"outlined"} severity="info">
                        {t('There is no task here! Plan your day and rush to the success with us!')}
                    </Alert>
                }
            </Box>
        </div>
    )
}
