import React, { FC } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

export const LoadingScreen: FC = () => {
    return (
        <Box
            style={{
                width: '100%',
                height: '100vh',
                backgroundColor: '#121212',
                color: 'white'
            }}
        >
            <Box
                style={{
                    position: 'absolute',
                    left: '50%',
                    top: '40%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Typography variant='h4'>
                    SetTasks
                </Typography>
                <LinearProgress style={{ marginTop: '12px' }} />
            </Box>
        </Box>
    )
}
