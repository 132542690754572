import React, {FC, useEffect, useState} from 'react';
import {Box, Button, CardContent, CircularProgress, Stack, Typography} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {getCanGetTrialFreeSubscription, getTrialFreeSubscription} from "../../store/subscription/subscriptionSlice";
import Grid from "@mui/material/Grid";

export const GetTrialFreeLevel: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {isMobile, themeMode} = useAppSelector(state => state.utils);
    const [disableButton, setDisableButton] = useState<boolean>(false);

    const {
        canGetTrialFree
    } = useAppSelector(state => state.subscription.canGetTrialFreeSubscription);

    useEffect(() => {
        dispatch(getCanGetTrialFreeSubscription());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTrialFreeLevelRequest = () => {
        setDisableButton(true);
        dispatch(getTrialFreeSubscription())
            .unwrap()
            .then(originalPromiseResult => {
                if (originalPromiseResult.status) {
                    window.location.reload();
                }
            });
    }

    return (
        <Box sx={{mt: 2}}>
            {
                canGetTrialFree &&
                <CardContent
                    sx={{
                        borderRadius: '0.75rem !important',
                        padding: '1.5rem !important',
                        backgroundColor: themeMode === 'light' ? '#f1faff' : '#212e48',
                        borderStyle: 'dashed !important',
                        borderColor: 'rgba(0, 158, 247, 1) !important',
                        border: '2px'
                    }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8} style={{textAlign: isMobile ? "center" : "left"}}>
                            <Stack direction="column" spacing={2}>
                                <Typography sx={{fontWeight: '600 !important', fontSize: '1.25rem'}}>
                                    {t('Personal proposal!')}
                                </Typography>
                                <Typography
                                    sx={{
                                        padding: 0,
                                        marginTop: '8px !important'
                                    }}
                                >
                                    {t('Get the Premium subscription absolutely free and use the service without restrictions of 7 days. Feel the power of our service!')}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4} style={{textAlign: "center"}}>
                            <Button
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                                onClick={getTrialFreeLevelRequest}
                                disabled={disableButton}
                                endIcon={
                                    disableButton
                                        ?
                                        <CircularProgress color='inherit' size={'1rem'}/>
                                        :
                                        undefined
                                }
                            >
                                {t('Get FREE Premium')}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            }
        </Box>
    )
};
