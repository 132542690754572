import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { DndProvider } from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import { MainTheme } from "./components/partials/MainTheme";
import { AppRouter } from "./components/utilities/AppRouter";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import { useWindowWidth } from "./hooks/window-width";
import { checkAuth, setIsAuthChecking } from "./store/auth/authSlice";
import {
  setColorSchema,
  setIsMobile,
  setMode,
  setThemeMode,
} from "./store/utils/utilsSlice";
import { LoadingScreen } from "./components/utilities/LoadingScreen";
import { TMode } from "./models/utils/TMode";
import { NotificationRequestDialog } from "./components/notification/NotificationRequestDialog";
import i18n from "./localization/i18n";
import "./styles/App.css";
import { CookieConsent } from "./components/utilities/CookieConsent";
import { useMediaQuery } from "@mui/material";
import { TLang } from "./models/utils/TLang";
import { getFromStorage, saveToStorage } from "./utils/storageHelpers";
import { Device } from "@capacitor/device";
import { NotificationRequestDialogMobile } from "./components/notification/NotificationRequestDialogMobile";
import { changeTimezone } from "./utils/helpers";
import { useTranslation } from "react-i18next";
import Bugsnag from "@bugsnag/js";
import { isMobile } from "react-device-detect";

function App() {
  const dispatch = useAppDispatch();

  const { isAuthChecking } = useAppSelector((state) => state.auth);
  const { isAuth, user } = useAppSelector((state) => state.auth);
  const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");
  const [platform, setPlatform] = useState<"ios" | "android" | "web">();
  const width = useWindowWidth();
  const { t } = useTranslation();

  // useEffect(() => {
  //     if (isAuth) {
  //         Bugsnag.setUser(String(user.id), user.email, user.name);
  //     }
  // }, [isAuth]);

  useEffect(() => {
    initializeThemeMode();
    const runUseEffect = async () => {
      dispatch(checkAuth())
        .unwrap()
        .then((originalPromiseResult) => {
          i18n
            .changeLanguage(originalPromiseResult.user.lang)
            .then(async () => {
              await saveToStorage("lang", originalPromiseResult.user.lang);
              dispatch(setIsAuthChecking(false));
            });
        })
        .catch(async () => {
          await getFromStorage("lang").then((lang) => {
            i18n.changeLanguage((lang.value as TLang) || "en");
          });
        });

      const queryParams = new URLSearchParams(window.location.search);
      const referral = queryParams.get("referral");
      if (referral) {
        await saveToStorage("referral", referral);
      }

      await getFromStorage("colorSchema").then(async (colorSchema) => {
        if (!colorSchema.value) {
          dispatch(setColorSchema(0));
          await saveToStorage("colorSchema", "0");
        } else {
          dispatch(setColorSchema(Number(colorSchema.value)));
        }
      });
    };
    runUseEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (width <= 500 || isMobile) {
      document.body.style.overflowY = "auto";
      dispatch(setIsMobile(true));
    } else {
      document.body.style.overflowY = "hidden";
      dispatch(setIsMobile(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    const runUseEffect = async () => {
      getFromStorage("mode").then((mode) => {
        if (!mode.value || mode.value === "auto") {
          dispatch(
            setMode(isDarkModeEnabled ? ("dark" as TMode) : ("light" as TMode))
          );
          dispatch(setThemeMode(isDarkModeEnabled ? "dark" : "light"));
        }
      });
    };
    runUseEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDarkModeEnabled]);

  useEffect(() => {
    const runUseEffect = async () => {
      const info = await Device.getInfo();
      setPlatform(info.platform);
    };
    runUseEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuth) {
      setInterval(function () {
        const userDateTimeNow = changeTimezone(new Date(), user.timezoneName);
        const userHours = ("0" + userDateTimeNow.getHours()).slice(-2);
        const userMinutes = ("0" + userDateTimeNow.getMinutes()).slice(-2);

        if (userHours === "00" && userMinutes === "00") {
          window.location.reload();
        }
      }, 60 * 1000);
    }
  }, [isAuth, user.timezoneName]);

  const initializeThemeMode = async () => {
    await getFromStorage("mode").then((mode) => {
      if (mode.value) {
        if (mode.value === "auto") {
          dispatch(
            setMode(isDarkModeEnabled ? ("dark" as TMode) : ("light" as TMode))
          );
          dispatch(setThemeMode(isDarkModeEnabled ? "dark" : "light"));
        } else {
          dispatch(setMode(mode.value as TMode));
          dispatch(setThemeMode(mode.value as string));
        }
      } else {
        dispatch(
          setMode(isDarkModeEnabled ? ("dark" as TMode) : ("light" as TMode))
        );
      }
    });
  };

  if (isAuthChecking) {
    return <LoadingScreen />;
  } else {
    return (
      <div className="App">
        <BrowserRouter>
          <DndProvider options={HTML5toTouch}>
            <MainTheme>
              <AppRouter />
              <CookieConsent message={t("Cookie message text")} />
              {isAuth && platform === "web" ? (
                <NotificationRequestDialog />
              ) : (
                <NotificationRequestDialogMobile />
              )}
            </MainTheme>
          </DndProvider>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
