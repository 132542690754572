import React, {FC, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from '@mui/material';
import {requestForToken} from "../../firebase/firebase";
import {saveNotificationToken} from "../../store/notification/notificationSlice";
import {FIREBASE_WEB_PUSH_DATA} from "../../utils/constants";
import {isSupported} from "firebase/messaging";
import {useTranslation} from "react-i18next";
import {IFirebaseWebPushData} from '../../models/IFirebaseWebPushData';
import {getFromStorage, saveToStorage} from "../../utils/storageHelpers";
import {setSnackbar} from "../../store/utils/utilsSlice";

const platform = require('platform');

export const NotificationRequestDialog: FC = () => {

    const {t} = useTranslation();
    const {user} = useAppSelector(state => state.auth);
    const {isMobile} = useAppSelector(state => state.utils);
    const dispatch = useAppDispatch();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        checkPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkPermission = () => {
        isSupported()
            .then(async (isAvailable) => {
                if (isAvailable) {
                    const storageFirebaseWebPushData = await getFromStorage(FIREBASE_WEB_PUSH_DATA);
                    if (storageFirebaseWebPushData.value) {
                        const firebaseWebPushDataArray = JSON.parse(storageFirebaseWebPushData.value) as IFirebaseWebPushData[];
                        const firebaseWebPushData = firebaseWebPushDataArray ? firebaseWebPushDataArray.filter(item => item.userId === user.id) : firebaseWebPushDataArray;
                        const permission = Notification.permission;

                        if (!firebaseWebPushData || !firebaseWebPushData[0]) {
                            setOpen(true);
                            return;
                        }

                        const firebaseWebPushDataItem = firebaseWebPushData[0];
                        if (firebaseWebPushDataItem.isFirebaseWebPushAllow && !firebaseWebPushDataItem.isFirebaseWebPushTokenSaved) {
                            if (permission === 'granted') {
                                getToken();
                            } else {
                                setOpen(true);
                            }
                        }
                    } else {
                        setOpen(true);
                    }
                }
            })
            .catch(console.error);
    }

    const handleAccept = () => {
        requestPermission();
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
        saveWebPushData({isFirebaseWebPushAllow: false});
        dispatch(setSnackbar({
            text: t('You can enable notifications on Profile page'),
            severity: 'error',
            open: true
        }));
    };

    const requestPermission = () => {
        Notification.requestPermission().then(function (status) {
            if (status === 'denied') {
                saveWebPushData({isFirebaseWebPushAllow: false});
                dispatch(setSnackbar({
                    text: t('You can enable notifications on Profile page'),
                    severity: 'error',
                    open: true
                }));
            } else if (status === 'granted') {
                getToken();
            }
        });
    }

    const getToken = () => {
        requestForToken()
            .then((currentToken) => {
                if (currentToken) {
                    saveWebPushData({isFirebaseWebPushAllow: true});
                    dispatch(saveNotificationToken({token: currentToken, platform: platform.description}))
                        .unwrap()
                        .then(originalPromiseResult => {
                            if (originalPromiseResult) {
                                saveWebPushData({
                                    isFirebaseWebPushAllow: true,
                                    isFirebaseWebPushTokenSaved: true,
                                    token: currentToken
                                });

                                dispatch(setSnackbar({
                                    text: t('Registration web push notification are successful'),
                                    severity: 'success',
                                    open: true
                                }));
                            }
                        });
                } else {
                    dispatch(setSnackbar({
                        text: t('No registration token available. Request permission to generate one.'),
                        severity: 'error',
                        open: true
                    }));
                    console.log('No registration token available. Request permission to generate one.');
                }
            })
            .catch((err) => {
                console.error('An error occurred while retrieving token. ', err);
            });
    }

    const saveWebPushData = async (
        {
            isFirebaseWebPushAllow,
            isFirebaseWebPushTokenSaved,
            token,
        }:
            {
                isFirebaseWebPushAllow?: boolean | undefined,
                isFirebaseWebPushTokenSaved?: boolean | undefined,
                token?: string | undefined,
            }
    ) => {
        const storageFirebaseWebPushData = await getFromStorage(FIREBASE_WEB_PUSH_DATA);
        if (storageFirebaseWebPushData.value) {
            let firebaseWebPushDataArray = JSON.parse(storageFirebaseWebPushData.value) as IFirebaseWebPushData[];
            const firebaseWebPushItem = firebaseWebPushDataArray ? firebaseWebPushDataArray.filter(item => item.userId === user.id) : firebaseWebPushDataArray;

            if (firebaseWebPushItem && firebaseWebPushItem[0]) {
                let newFirebaseWebPushData = firebaseWebPushDataArray.filter(item => item.userId !== user.id);
                newFirebaseWebPushData.push({
                    userId: user.id,
                    isFirebaseWebPushAllow: isFirebaseWebPushAllow !== undefined ? isFirebaseWebPushAllow : firebaseWebPushItem[0].isFirebaseWebPushAllow,
                    isFirebaseWebPushTokenSaved: isFirebaseWebPushTokenSaved !== undefined ? isFirebaseWebPushTokenSaved : firebaseWebPushItem[0].isFirebaseWebPushTokenSaved,
                    token: token !== undefined ? token : firebaseWebPushItem[0].token,
                });
                await saveToStorage(FIREBASE_WEB_PUSH_DATA, JSON.stringify(newFirebaseWebPushData));
            } else {
                let newFirebaseWebPushDataItem: IFirebaseWebPushData = {
                    userId: user.id,
                    isFirebaseWebPushAllow: isFirebaseWebPushAllow !== undefined ? isFirebaseWebPushAllow : false,
                    isFirebaseWebPushTokenSaved: isFirebaseWebPushTokenSaved !== undefined ? isFirebaseWebPushTokenSaved : false,
                    token: token !== undefined ? token : ''
                };
                if (firebaseWebPushDataArray) {
                    firebaseWebPushDataArray.push(newFirebaseWebPushDataItem);
                    await saveToStorage(FIREBASE_WEB_PUSH_DATA, JSON.stringify(firebaseWebPushDataArray));
                } else {
                    await saveToStorage(FIREBASE_WEB_PUSH_DATA, JSON.stringify([newFirebaseWebPushDataItem]));
                }
            }
        } else {
            let newFirebaseWebPushDataItem: IFirebaseWebPushData = {
                userId: user.id,
                isFirebaseWebPushAllow: isFirebaseWebPushAllow !== undefined ? isFirebaseWebPushAllow : false,
                isFirebaseWebPushTokenSaved: isFirebaseWebPushTokenSaved !== undefined ? isFirebaseWebPushTokenSaved : false,
                token: token !== undefined ? token : ''
            };
            await saveToStorage(FIREBASE_WEB_PUSH_DATA, JSON.stringify([newFirebaseWebPushDataItem]));
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Notifications')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {
                            !isMobile &&
                            <img src="/images/web_push_permission.png" width="500px" alt="Notification permition"/>
                        }

                        {t('Do you want to receive daily notifications about your tasks and be always up to date? Click Allow in the message from the browser.')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">{t('No')}</Button>
                    <Button onClick={handleAccept} variant="contained">{t('Yes')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
