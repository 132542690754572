import React, {FC} from 'react';
import {Box, Card, CardContent, CardHeader, Divider, Grid,} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ColorSchemaItem} from "./ColorSchemaItem";

export const ColorsSchema: FC = () => {

    const {t} = useTranslation();

    const colorSchemas = [
        {
            id: 0,
            title: 'Ocean'
        },
        {
            id: 1,
            title: 'Tree'
        },
        {
            id: 2,
            title: 'Sun'
        },
    ];

    return (
        <Box sx={{mt: 3}}>
            <Card sx={{mt: 2, paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '0.75rem'}}>
                <CardHeader
                    sx={{
                        display: "flex",
                        overflow: "hidden",
                        "& .MuiCardHeader-content": {
                            overflow: "hidden"
                        }
                    }}
                    title={t("Color schema")}
                    titleTypographyProps={{noWrap: true}}
                />
                <Divider/>
                <CardContent>
                    <Grid container spacing={2}>
                        {colorSchemas.map((colorSchema) => (
                            <Grid item xs={12} md={4} key={colorSchema.id}>
                                <ColorSchemaItem id={colorSchema.id} title={colorSchema.title} key={colorSchema.id}/>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
};
