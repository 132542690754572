import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FormikHelpers } from "formik";
import { confirmVerificationCodeAPI, getVerificationCodeAPI, restorePasswordAPI } from "../../services/AuthService";
import { useAppDispatch } from "../../hooks/redux";
import { setSnackbar } from "../../store/utils/utilsSlice";

export type steps = 'email' | 'code' | 'restore';

export function useForgotPasswordForm(
    step: steps,
    handleNextStep: () => void,
    handleSetTimer: (timeLeft: number) => void
) {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const initialValues = {
        email: '',
        code: '',
        newPassword: '',
        newPasswordConfirmation: ''
    }

    const emailValidationSchema = yup.object({
        email: yup
            .string()
            .trim()
            .email(t('Enter a valid email'))
            .required(t('Email is required')),
    });

    const codeValidationSchema = yup.object({
        code: yup
            .string()
            .trim()
            .required(t('Verification code is required')),
    });

    const resetValidationSchema = yup.object({
        newPassword: yup
            .string()
            .trim()
            .min(4, t('Password must be at least 4 and no more than 16 characters'))
            .max(16, t('Password must be at least 4 and no more than 16 characters'))
            .required(t('Password is required')),
        newPasswordConfirmation: yup
            .string()
            .trim()
            .oneOf([yup.ref('newPassword'), null], t('Password must match'))
            .required(t('Password must match'))
    });

    let validationSchema = emailValidationSchema;
    if (step === 'code') validationSchema = validationSchema.concat(codeValidationSchema);
    if (step === 'restore') validationSchema = validationSchema.concat(resetValidationSchema);

    let onSubmit = async (values: typeof initialValues, onSubmitProps: FormikHelpers<typeof initialValues>) => {
        if (step === 'email') {
            await getVerificationCodeAPI(values.email.toLowerCase())
                .then(handleNextStep)
                .catch(error => {
                    const errors = error.response.data.errors;
                    if (errors.timeLeft) {
                        handleSetTimer(errors.timeLeft);
                        handleNextStep()
                    } else if (errors.param) {
                        onSubmitProps.setErrors({ [errors.param]: errors.msg });
                    }
                });
            return;
        }

        if (step === 'code') {
            await confirmVerificationCodeAPI(values.code)
                .then(handleNextStep)
                .catch(error => {
                    const errors = error.response.data.errors;
                    if (errors.param) onSubmitProps.setErrors({ [errors.param]: errors.msg });
                });
            return;
        }

        await restorePasswordAPI(values.email, values.code, values.newPassword, values.newPasswordConfirmation)
            .then(() => {
                dispatch(setSnackbar({
                    text: 'Your password has been successfully changed',
                    severity: 'success',
                    open: true
                }));
                navigate('/login');
            })
            .catch(error => {
                const errors = error.response.data.errors;
                if (errors.param) onSubmitProps.setErrors({ [errors.param]: errors.msg });
            });
        return;
    };

    return {
        initialValues,
        validationSchema,
        onSubmit
    }
}