import React, { FC } from 'react';
import { LoginForm } from '../../components/auth/LoginForm';
import { Container, Box } from '@mui/material';

export const Login: FC = () => {
    return (
        <Container maxWidth="lg">
            <Box
                sx={{
                    marginTop: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <LoginForm />
            </Box>
        </Container>
    )
};
