import React, { FC } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

export interface IProps {
    variants: string[],
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

export const DeleteRecurringDialog: FC<IProps> = ({ variants, open, selectedValue, onClose, ...props }) => {

    const { t } = useTranslation();

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value: string) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{t('Delete recurring task')}</DialogTitle>
            <List sx={{ pt: 0 }}>
                {variants.map((variant) => (
                    <ListItem button onClick={() => handleListItemClick(variant)} key={variant}>
                        <ListItemText primary={t(variant)} />
                    </ListItem>
                ))}
                <ListItem autoFocus button onClick={() => handleListItemClick('Cancel')}>
                    <ListItemText primary={t('Cancel')} />
                </ListItem>
            </List>
        </Dialog>
    )
}
