import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";
import {INote} from "../../models/INote";
import {
    noteAPI,
    notesAddAPI,
    notesAPI,
    notesDeleteAPI,
    notesEditAPI,
    notesFavoriteAPI,
    notesGetFavoriteAPI,
    removeTagAPI,
    tagNotesAPI
} from "../../services/NotesService";
import {INoteTagsRemoveResponse} from "../../models/response/INoteTagsRemoveResponse";
import {INotesResponse} from "../../models/response/INotesResponse";

interface INoteState {
    notes: INote[],
    isNoteLoading: boolean,
    isNoteSending: boolean,
    noteError: boolean,
    page: number,
    totalCount: number,
    observable: boolean,
    favorite: {
        notes: INote[],
        page: number,
        observable: boolean,
    },
    tags: {
        notes: INote[],
        page: number,
        observable: boolean,
    }
}

const initialState: INoteState = {
    notes: [],
    isNoteLoading: false,
    isNoteSending: false,
    noteError: false,
    page: 1,
    observable: true,
    totalCount: 0,
    favorite: {
        notes: [],
        page: 1,
        observable: true,
    },
    tags: {
        notes: [],
        page: 1,
        observable: true,
    }
}

export const getNotes = createAsyncThunk(
    'note/notes',
    async ({page, limit}: { page: number, limit: number }, {rejectWithValue}) => {
        try {
            const response = await notesAPI(page, limit);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const getNote = createAsyncThunk(
    'note/getNote',
    async ({noteId}: { noteId: number }, {rejectWithValue}) => {
        try {
            const response = await noteAPI(noteId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const getTagNotes = createAsyncThunk(
    'note/getTagNotes',
    async ({tag, page, limit}: { tag: string, page: number, limit: number }, {rejectWithValue}) => {
        try {
            const response = await tagNotesAPI(tag, page, limit);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const getFavoriteNotes = createAsyncThunk(
    'note/getFavoriteNotes',
    async ({page, limit}: { page: number, limit: number }, {rejectWithValue}) => {
        try {
            const response = await notesGetFavoriteAPI(page, limit);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const addNote = createAsyncThunk(
    'note/addNote',
    async ({note, tagTitles}: { note: INote, tagTitles?: string[] }, {rejectWithValue}) => {
        try {
            const response = await notesAddAPI(note, tagTitles);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const editNote = createAsyncThunk(
    'note/editNote',
    async ({note, tagTitles}: { note: INote, tagTitles: string[] }, {rejectWithValue}) => {
        try {
            const response = await notesEditAPI(note, tagTitles);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const favoriteNote = createAsyncThunk(
    'note/favoriteNote',
    async (note: INote, {rejectWithValue}) => {
        try {
            const response = await notesFavoriteAPI(note);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const deleteNote = createAsyncThunk(
    'note/deleteNote',
    async (note: INote, {rejectWithValue}) => {
        try {
            const response = await notesDeleteAPI(note);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const removeTagFromNote = createAsyncThunk(
    'note/removeTagFromNote',
    async ({foreignKey, tagId, model}: {
        foreignKey: number,
        tagId: number,
        model: 'Note' | 'Event'
    }, {rejectWithValue}) => {
        try {
            const response = await removeTagAPI(foreignKey, tagId, model);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const noteSlice = createSlice({
    name: 'note',
    initialState: initialState,
    reducers: {
        setNotes(state, actions: PayloadAction<INote[]>) {
            state.notes = [...state.notes, ...actions.payload];
        },
        removeNote(state, action: PayloadAction<INote>) {
            state.notes = state.notes.filter(note => note.id !== action.payload.id);
        },
        resetNotes: () => initialState,
        resetTagNotes(state) {
            state.tags.notes = [];
            state.tags.page = 1;
            state.tags.observable = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(editNote.fulfilled, (state, action: PayloadAction<INote>) => {
            const index = state.notes.findIndex((note) => {
                return note.id === action.payload.id
            });
            if (index !== -1) {
                state.notes[index] = action.payload;
                state.notes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            }

            const favIndex = state.favorite.notes.findIndex(
                note => note.id === action.payload.id
            );
            if (favIndex !== -1) {
                state.favorite.notes[favIndex] = action.payload;
                state.favorite.notes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            }

            const tagIndex = state.tags.notes.findIndex(
                note => note.id === action.payload.id
            );
            if (tagIndex !== -1) {
                state.tags.notes[tagIndex] = action.payload;
                state.tags.notes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            }

            state.isNoteSending = false;
        });
        builder.addCase(favoriteNote.fulfilled, (state, action: PayloadAction<INote[]>) => {
            const index = state.notes.findIndex((note) => {
                return note.id === action.payload[0].id
            });

            if (index !== -1) {
                state.notes[index] = action.payload[0];
                state.notes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            }

            const favIndex = state.favorite.notes.findIndex(
                note => note.id === action.payload[0].id
            );
            if (favIndex !== -1) {
                state.favorite.notes[favIndex] = action.payload[0];
                state.favorite.notes = state.favorite.notes.filter(
                    note => {
                        return note.favorite === true;
                    }
                );
                state.favorite.notes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            } else {
                if (action.payload[0].favorite) {
                    state.favorite.notes.unshift(action.payload[0]);
                }
            }


            const tagIndex = state.tags.notes.findIndex(
                note => note.id === action.payload[0].id
            );
            if (tagIndex !== -1) {
                state.tags.notes[tagIndex] = action.payload[0];
                state.tags.notes.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
            }

            state.isNoteSending = false;
        });
        builder.addCase(getNote.fulfilled, (state, action: PayloadAction<INote>) => {
            for (let i = 0; i < state.notes.length; i++) {
                if (state.notes[i].id === action.payload.id) {
                    state.notes[i] = action.payload;
                }
            }
            state.isNoteSending = false;
            state.noteError = false;
        });
        builder.addCase(addNote.fulfilled, (state, action: PayloadAction<INote>) => {
            state.notes.unshift(action.payload);
            state.isNoteSending = false;
            state.totalCount = state.totalCount + 1;
        });
        builder.addCase(deleteNote.fulfilled, (state, action: PayloadAction<INote>) => {
            state.notes = state.notes.filter(note => {
                return note.id !== Number(action.payload.id);
            });

            const favIndex = state.favorite.notes.findIndex(
                note => note.id === Number(action.payload.id)
            );
            if (favIndex !== -1) {
                state.favorite.notes = state.favorite.notes.filter(note => {
                    return note.id !== Number(action.payload.id);
                });
            }

            const tagIndex = state.tags.notes.findIndex(
                note => note.id === Number(action.payload.id)
            );
            if (tagIndex !== -1) {
                state.tags.notes = state.tags.notes.filter(note => {
                    return note.id !== Number(action.payload.id);
                });
            }

            state.totalCount = state.totalCount - 1;
            state.isNoteSending = false;
        });
        builder.addCase(getNotes.fulfilled, (state, action: PayloadAction<INotesResponse>) => {
            state.notes = [...state.notes, ...action.payload.items];

            if (action.payload.items.length) {
                state.page = state.page + 1;
            } else {
                state.observable = false;
            }
            state.totalCount = action.payload.totalCount;
            state.isNoteLoading = false;
            state.noteError = false;
        });
        builder.addCase(getTagNotes.fulfilled, (state, action: PayloadAction<INote[]>) => {
            state.tags.notes = [...state.tags.notes, ...action.payload];

            if (action.payload.length) {
                state.tags.page = state.tags.page + 1;
            } else {
                state.tags.observable = false;
            }

            state.isNoteLoading = false;
            state.noteError = false;
        });
        builder.addCase(getFavoriteNotes.fulfilled, (state, action: PayloadAction<INotesResponse>) => {
            let commonSet = new Set([...state.favorite.notes, ...action.payload.items]);

            state.favorite.notes = Array.from(commonSet); //[...state.favorite.notes, ...action.payload];

            if (action.payload.items.length) {
                state.favorite.page = state.favorite.page + 1;
            } else {
                state.favorite.observable = false;
            }

            state.totalCount = action.payload.totalCount;
            state.isNoteLoading = false;
            state.noteError = false;
        });
        builder.addCase(removeTagFromNote.fulfilled, (state, action: PayloadAction<INoteTagsRemoveResponse>) => {
            if (action.payload.result === 1) {
                const noteIndex = state.notes.findIndex(
                    note => note.id === action.payload.foreignKey
                );
                if (noteIndex !== -1) {
                    state.notes[noteIndex].Tags = state.notes[noteIndex].Tags.filter(
                        tag => {
                            return tag.id !== action.payload.tagId;
                        }
                    );
                }

                const noteFavIndex = state.favorite.notes.findIndex(
                    note => note.id === action.payload.foreignKey
                );
                if (noteFavIndex !== -1) {
                    state.favorite.notes[noteFavIndex].Tags = state.favorite.notes[noteFavIndex].Tags.filter(
                        tag => {
                            return tag.id !== action.payload.tagId;
                        }
                    );
                }

                const tagIndex = state.tags.notes.findIndex(
                    note => note.id === Number(action.payload.foreignKey)
                );
                if (tagIndex !== -1) {
                    state.tags.notes[noteFavIndex].Tags = state.tags.notes[noteFavIndex].Tags.filter(
                        tag => {
                            return tag.id !== action.payload.tagId;
                        }
                    );
                }
            }
        });
        builder.addMatcher(isAnyOf(addNote.pending, editNote.pending, deleteNote.pending), state => {
            state.isNoteSending = true;
        });
        builder.addMatcher(isAnyOf(getNotes.pending), state => {
            state.isNoteLoading = true;
        });
        builder.addMatcher(isAnyOf(getNotes.rejected), state => {
            state.isNoteLoading = false;
            state.noteError = true;
        });
        builder.addMatcher(isAnyOf(addNote.rejected, editNote.rejected, deleteNote.rejected, favoriteNote.rejected), (state, action) => {
            state.isNoteSending = false;
            state.noteError = true;
        });
    }
})

export default noteSlice.reducer;
export const {
    setNotes,
    removeNote,
    resetNotes,
    resetTagNotes
} = noteSlice.actions;