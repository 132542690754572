import React, {FC} from 'react';
import {Box, Container} from '@mui/material';
import {useAppSelector} from '../../hooks/redux';
import {ProfileOverview} from "../../components/profile/ProfileOverview";
import {ProfileDetails} from "../../components/profile/ProfileDetails";
import {SignInMethod} from "../../components/profile/SignInMethod";
import {RelatedAccounts} from "../../components/profile/RelatedAccounts";
import {NotificationSettings} from "../../components/profile/NotificationSettings";
import {ColorsSchema} from "../../components/profile/ColorsSchema";

export const Profile: FC = () => {
    const {isMobile} = useAppSelector(state => state.utils);

    return (
        <Container maxWidth='lg' disableGutters={!!isMobile}>
            <Box
                sx={{
                    marginTop: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflowY: 'auto',
                    maxHeight: '82vh',
                    paddingBottom: '16px'
                }}
            >
                <Box sx={{
                    transition: 'all .5s !important',
                    width: '100%',
                    maxWidth: isMobile ? '90%' : '100%',
                }}>
                    <ProfileOverview/>
                    <ProfileDetails/>
                    <SignInMethod/>
                    <RelatedAccounts/>
                    <ColorsSchema/>
                    <NotificationSettings/>
                </Box>
            </Box>
        </Container>
    )
};
