import {Preferences} from "@capacitor/preferences";

export const getFromStorage = async (key: string) => {
    return await Preferences.get({key: key});
};

export const saveToStorage = async (key: string, value: string) => {
    await Preferences.set({
        key: key,
        value: value,
    });
};

export const removeItemFromStorage = async (key: string) => {
    await Preferences.remove({
        key: key,
    });
};