import React, {FC} from 'react';
import {Box, Card, CardContent, Container} from '@mui/material';
import {CompletedTasksList} from '../../components/completed/CompletedTasksList';
import {useAppSelector} from '../../hooks/redux';

export const CompletedTasks: FC = () => {

    const {isMobile} = useAppSelector(state => state.utils);

    return (
        <Container maxWidth='lg' disableGutters={true}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflowY: 'auto',
                    paddingBottom: '16px',
                    // marginLeft: '5px',
                    // marginRight: '5px'
                }}
            >
                <Box sx={{
                    transition: 'all .5s !important',
                    width: '100%',
                    maxWidth: isMobile ? '96%' : '100%',
                }}>
                    <Box sx={{mt: isMobile ? 0 : 3}}>
                        <Card sx={{
                            mt: 2,
                            paddingLeft: isMobile ? '0rem' : '1rem',
                            paddingRight: isMobile ? '0rem' : '1rem',
                            borderRadius: '0.75rem'
                        }}>
                            <CardContent sx={{height: isMobile ? 'calc(100vh - 88px)' : 'calc(100vh - 104px)'}}>
                                <CompletedTasksList/>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}
