import React, {CSSProperties, FC, useEffect, useState} from 'react';
import {
    Badge,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {ITabDay} from '../../models/days/ITabDay';
import {addUserDay, editUserDay} from '../../store/kicker/kickerSlice';
import {changeDayType} from '../../store/task/taskSlice';
import {ITask} from '../../models/ITask';
import {setShowBuyPremiumDialogStatus} from "../../store/subscription/subscriptionSlice";

interface IFilterBar {
    day: ITabDay,
    filter: string,
    handleChangeFilter: (value: string) => void
}

const filterTypographyStyles: CSSProperties = {
    marginRight: '24px',
    cursor: 'pointer',
    fontWeight: '500'
}

const inProgressTasksCount = (tasks: ITask[]): number => {
    return tasks.filter(task => !task.isCompleted && !task.isDeleted).length;
}

const completedTasksCount = (tasks: ITask[]): number => {
    return tasks.filter(task => task.isCompleted).length;
}

export const FilterBar: FC<IFilterBar> = ({day, filter, handleChangeFilter, ...props}) => {

    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const {user} = useAppSelector(state => state.auth);
    const {days} = useAppSelector(state => state.task);
    const {isMobile} = useAppSelector(state => state.utils);

    const [dayComment, setDayComment] = useState<{
        open: boolean,
        comment: string
    }>({
        open: false,
        comment: ''
    });
    const [userDay, setUserDay] = useState<{
        all: number,
        isProgress: number,
        completed: number,
        userDayId: number | null,
        dayType: string
    }>({
        all: 0,
        isProgress: 0,
        completed: 0,
        userDayId: null,
        dayType: '0'
    });

    const dayCommentHandle = () => {
        setDayComment(prevState => {
            return {...prevState, open: !prevState.open,}
        });
    }

    const dayCommentChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDayComment(prevState => {
            return {...prevState, comment: e.target.value}
        });
    }

    const hadleFilterSelect = (event: SelectChangeEvent) => {
        handleChangeFilter(event.target.value);
    }

    const handleFilterClick = (value: string) => {
        handleChangeFilter(value);
    }

    const saveDayCommentHandle = () => {
        if (dayComment.comment.length >= user.level.journalMessageMaxLength) {
            dispatch(setShowBuyPremiumDialogStatus(true));
            return;
        }

        setDayComment(prevState => {
            return {...prevState, open: !prevState.open,}
        });
        saveDay(Number(userDay.dayType), dayComment.comment);
    }

    const handleChangeDayType = (event: SelectChangeEvent) => {
        setUserDay({...userDay, dayType: event.target.value});
        saveDay(Number(event.target.value), dayComment.comment);
    }

    const saveDay = (type: number, comment: string) => {
        if (!userDay.userDayId) {
            dispatch(addUserDay({
                date: day.date,
                type: type,
                comment: comment
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    const date: string = Object.keys(originalPromiseResult)[0];
                    dispatch(changeDayType({
                        userDayId: originalPromiseResult[date].id,
                        date: day.date,
                        type: type,
                        comment: comment
                    }));
                });
        } else {
            dispatch(editUserDay({
                id: userDay.userDayId,
                dayType: type,
                comment: comment
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    const date: string = Object.keys(originalPromiseResult)[0];
                    dispatch(changeDayType({
                        userDayId: originalPromiseResult[date].id,
                        date: day.date,
                        type: type,
                        comment: comment
                    }));
                });
        }
    }

    useEffect(() => {
        const currDay = days[day.date];
        if (currDay) {
            setUserDay({
                all: currDay.Tasks.length,
                isProgress: inProgressTasksCount(currDay.Tasks),
                completed: completedTasksCount(currDay.Tasks),
                userDayId: currDay.userDayId,
                dayType: String(currDay.dayType)
            });
            setDayComment({open: false, comment: currDay.comment});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [days]);

    return (
        <Box>
            <Grid container
                  spacing={1}
                  sx={{
                      marginTop: isMobile ? '12px' : '16px'
                  }}
            >
                <Grid item xs={6} md={6}>
                    {isMobile
                        ?
                        <FormControl sx={{width: '100%', marginRight: '6px'}}>
                            <InputLabel id='filters'>{t('Filter')}</InputLabel>
                            <Select
                                value={filter}
                                onChange={hadleFilterSelect}
                                variant='outlined'
                                size='small'
                                label={t('filters')}
                                labelId='day-type'
                                sx={{
                                    borderRadius: '0.75rem'
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            borderRadius: '0.5rem',
                                            color: theme.palette.textColorThird.dark,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value={'0'} sx={{'&:hover': {color: theme.palette.primary.main}}}>
                                    {t('All')}
                                    <Chip
                                        size='small'
                                        label={userDay.all}
                                        color='primary'
                                        sx={{
                                            marginLeft: '4px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px',
                                            color: '#ffffff',
                                            backgroundColor: theme.palette.filterColorAll.main,
                                        }}
                                    />
                                </MenuItem>
                                <MenuItem value={'1'} sx={{'&:hover': {color: theme.palette.primary.main}}}>
                                    {t('In progress')}
                                    <Chip
                                        size='small'
                                        label={userDay.isProgress}
                                        color='warning'
                                        sx={{
                                            marginLeft: '4px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px',
                                            color: '#ffffff',
                                            backgroundColor: theme.palette.filterColorInProgress.main,
                                        }}
                                    />
                                </MenuItem>
                                <MenuItem value={'2'} sx={{'&:hover': {color: theme.palette.primary.main}}}>
                                    {t('Completed')}
                                    <Chip
                                        size='small'
                                        label={userDay.completed}
                                        sx={{
                                            marginLeft: '4px',
                                            paddingLeft: '4px',
                                            paddingRight: '4px',
                                            color: '#ffffff',
                                            backgroundColor: theme.palette.filterColorCompleted.main,
                                        }}
                                    />
                                </MenuItem>
                            </Select>
                        </FormControl>
                        :
                        <Box
                            sx={{display: 'flex'}}
                        >
                            <Typography
                                sx={{marginRight: '8px', fontWeight: '500', color: theme.palette.textColorThird.dark}}>
                                {t('Filter')}:
                            </Typography>
                            <Typography
                                sx={{
                                    ...filterTypographyStyles,
                                    borderBottom: filter === '0' ? '2px solid' : '',
                                    color: theme.palette.primary.main,
                                    '& .MuiBadge-badge': {
                                        color: '#ffffff',
                                        backgroundColor: theme.palette.filterColorAll.main,
                                        right: '-8px'
                                    }
                                }}
                                onClick={() => handleFilterClick('0')}
                            >
                                <Badge
                                    color='primary'
                                    badgeContent={userDay.all}
                                >
                                    {t('All')}
                                </Badge>
                            </Typography>
                            <Typography
                                sx={{
                                    ...filterTypographyStyles,
                                    borderBottom: filter === '1' ? '2px solid' : '',
                                    color: theme.palette.filterColorInProgress.main,
                                    '& .MuiBadge-badge': {
                                        color: '#ffffff',
                                        backgroundColor: theme.palette.filterColorInProgress.main,
                                        right: '-8px'
                                    }
                                }}
                                onClick={() => handleFilterClick('1')}
                            >
                                <Badge
                                    color='warning'
                                    badgeContent={userDay.isProgress}
                                >
                                    {t('In progress')}
                                </Badge>
                            </Typography>
                            <Typography
                                sx={{
                                    ...filterTypographyStyles,
                                    borderBottom: filter === '2' ? '2px solid' : '',
                                    color: theme.palette.filterColorCompleted.main,
                                    '& .MuiBadge-badge': {
                                        color: '#ffffff',
                                        backgroundColor: theme.palette.filterColorCompleted.main,
                                        right: '-8px'
                                    }
                                }}
                                onClick={() => handleFilterClick('2')}
                            >
                                <Badge
                                    badgeContent={userDay.completed}
                                >
                                    {t('Completed')}
                                </Badge>
                            </Typography>
                        </Box>
                    }
                </Grid>
                <Grid item xs={6} md={6}>
                    <Box
                        sx={{
                            width: isMobile ? '100%' : 'auto',
                            display: 'flex',
                            gap: '6px',
                            justifyContent: 'end',
                            alignItems: 'flex-end'
                        }}>
                        {!isMobile &&
                            <Typography
                                onClick={dayCommentHandle}
                                sx={{paddingBottom: '3px', borderBottom: '1px dashed', cursor: 'pointer'}}
                            >
                                {t('Comment')}
                            </Typography>
                        }
                        <FormControl sx={{width: isMobile ? '100%' : '140px'}}>
                            {isMobile && <InputLabel id='day-type'>{t('Day type')}</InputLabel>}
                            <Select
                                value={userDay.dayType}
                                onChange={handleChangeDayType}
                                variant={isMobile ? 'outlined' : 'standard'}
                                size={isMobile ? 'small' : 'medium'}
                                label={t('Day type')}
                                labelId='day-type'
                                inputProps={{'aria-label': 'Without label'}}
                                sx={{
                                    borderRadius: '0.75rem'
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            borderRadius: '0.5rem',
                                            color: theme.palette.textColorThird.dark,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value={'-3'}
                                          sx={{'&:hover': {color: theme.palette.journalDayBlackColor.main}}}>
                                    {t('Black day')}
                                </MenuItem>
                                <MenuItem value={'-2'}
                                          sx={{'&:hover': {color: theme.palette.journalDayTerribleColor.main}}}>
                                    {t('Awful day')}
                                </MenuItem>
                                <MenuItem value={'-1'} sx={{'&:hover': {color: theme.palette.journalDayBadColor.main}}}>
                                    {t('Bad day')}
                                </MenuItem>
                                <MenuItem value={'0'}
                                          sx={{'&:hover': {color: theme.palette.journalDayTypicalColor.main}}}>
                                    {t('Usual day')}
                                </MenuItem>
                                <MenuItem value={'1'} sx={{'&:hover': {color: theme.palette.journalDayGoodColor.main}}}>
                                    {t('Good day')}
                                </MenuItem>
                                <MenuItem value={'2'}
                                          sx={{'&:hover': {color: theme.palette.journalDayLuckyColor.main}}}>
                                    {t('Successful day')}
                                </MenuItem>
                                <MenuItem value={'3'}
                                          sx={{'&:hover': {color: theme.palette.journalDayPerfectColor.main}}}>
                                    {t('Perfect day')}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>

            <Dialog open={dayComment.open}
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '0.75rem'
                        }
                    }}
            >
                <DialogTitle>{t('Add a comment for this day')}</DialogTitle>
                <DialogContent sx={{width: '600px'}}>
                    <TextField
                        fullWidth
                        multiline
                        minRows={8}
                        maxRows={Infinity}
                        label={`+ ${t('Add a comment for this day')}`}
                        size='small'
                        variant="outlined"
                        value={dayComment.comment ? dayComment.comment : ''}
                        onChange={dayCommentChangeHandle}
                        sx={{
                            mt: 1,
                            '& .MuiInputBase-root': {
                                borderRadius: '0.5rem'
                            }
                        }}
                        InputProps={{
                            autoComplete: 'off'
                        }}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        paddingRight: '24px',
                        paddingBottom: '16px'
                    }}
                >
                    <Button
                        onClick={dayCommentHandle}
                        variant='contained'
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        onClick={saveDayCommentHandle}
                        variant='contained'
                    >
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>

    )
}
