import React, {CSSProperties, FC, Fragment, useEffect, useState} from 'react';
import {LocalizationProvider, MobileDatePicker} from '@mui/x-date-pickers';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {useDrop} from 'react-dnd';
import {IDragTask} from '../../../models/dnd/IDragTask';
import {dndItemTypes} from '../../../models/dnd/dndItemTypes';
import {useTheme} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {
    addTask,
    changeTaskDayDnD,
    changeTasksDay,
    deleteTask,
    getDayRecurringTasks,
    tagTaskAction,
    togglePlanned,
    undeleteTask
} from '../../../store/task/taskSlice';
import {useParams} from 'react-router-dom';
import {setSnackbar} from '../../../store/utils/utilsSlice';
import {ActionBar} from './ActionBar';
import {format} from 'date-fns';
import {changeTimezone} from '../../../utils/helpers';
import {TagTaskActionType} from '../../../models/Types';
import {setShowBuyPremiumDialogStatus} from "../../../store/subscription/subscriptionSlice";
import {ITask} from "../../../models/ITask";
import {replaceTaskInGoal} from "../../../store/goal/goalSlice";

interface IDnDPickerProps {
    isDisableInitialDate?: boolean,
    isFullBorder?: boolean,
    copy?: boolean,
    style?: CSSProperties
}

const initialDate = (date: string | undefined, timezone: string, isDisableInitialDate: boolean): Date | null => {
    if (isDisableInitialDate) return null;
    if (date !== undefined) return changeTimezone(new Date(date), timezone);

    return new Date();
}

export const DnDPicker: FC<IDnDPickerProps> = ({
                                                   isDisableInitialDate = false,
                                                   isFullBorder = false,
                                                   copy = false,
                                                   style = {}
                                               }) => {

    const theme = useTheme();
    const dispatch = useAppDispatch();
    const {date} = useParams();

    const {user} = useAppSelector(state => state.auth);
    const {days} = useAppSelector(state => state.task);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [dragTask, setDragTask] = useState<IDragTask | null>(null);
    const [value, setValue] = useState<Date | null>(null);
    const [locale, setLocale] = useState<Locale>();

    const [{isOver, canDrop}, drop] = useDrop<IDragTask, void, any>({
        accept: dndItemTypes.TASK,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: () => {
            return true;
        },
        drop: (item) => {
            if (item.task.isCompleted) {
                dispatch(setSnackbar({
                    text: 'You cannot moving completed and deleted tasks',
                    severity: 'error',
                    open: true
                }));
                return;
            }
            setDragTask(item);
            setIsOpen(true);
        }
    });

    const disablePastDays = (day: Date): boolean => {
        const today = changeTimezone(new Date(), user.timezoneName).setHours(0, 0, 0, 0);
        if (day.getTime() >= today) {
            return false;
        }
        return true;
    }

    const handleClose = () => {
        setValue(null);
        setIsOpen(false);
    }

    const handleAccept = () => {
        if (!value || !dragTask || !dragTask.task.id) return;

        if (!user.level.tasksAllowDnD) {
            dispatch(setShowBuyPremiumDialogStatus(true));
            return;
        }

        const formattedValue = format(value, 'yyyy-MM-dd');

        const currDay = days[formattedValue];
        if (!currDay) {
            dispatch(getDayRecurringTasks(formattedValue))
                .unwrap()
                .then(() => {
                    acceptContinue(formattedValue);
                });
        } else {
            acceptContinue(formattedValue);
        }
    }

    const acceptContinue = (formattedValue: string) => {
        if (!value || !dragTask || !dragTask.task.id) return;


        if (days[formattedValue] && days[formattedValue].Tasks.length >= user.level.tasksPerDayMaxCount) {
            dispatch(setShowBuyPremiumDialogStatus(true));
            return;
        }

        if (copy) {
            if (dragTask && dragTask.date !== formattedValue) {
                if (copy) {
                    const tagTitles = dragTask.task.Tags.map(tag => tag.title);
                    dispatch(addTask({
                        task: dragTask.task,
                        date: formattedValue,
                        tagTitles: tagTitles
                    }))
                        .unwrap()
                        .then(() => {
                            dispatch(setSnackbar({
                                text: 'Task copied successfully',
                                severity: 'success',
                                open: true
                            }));
                        });
                } else {
                    dispatch(changeTaskDayDnD({
                        oldDate: dragTask.date,
                        task: dragTask.task,
                        newDayDate: formattedValue,
                    }));
                    dispatch(changeTasksDay({
                        date: formattedValue,
                        taskId: dragTask.task.id,
                        orderId: dragTask.task.Order.id,
                        listType: 0
                    }));
                }
            }
        } else {
            if (dragTask.task.isDeleted) {
                if (dragTask.task.isRecurring) {
                    dispatch(undeleteTask({
                        taskId: dragTask.task.id,
                        isCompleted: false,
                        isPlanned: false,
                        removeRecurring: true
                    }))
                        .unwrap()
                        .then((res) => {
                            dispatch(changeTaskDayDnD({
                                oldDate: dragTask.date,
                                task: res[dragTask.date],
                                newDayDate: formattedValue,
                            }));
                            dispatch(changeTasksDay({
                                date: formattedValue,
                                taskId: Number(dragTask.task.id),
                                orderId: dragTask.task.Order.id,
                                listType: dragTask.tagFrom !== undefined ? 2 : 0
                            }));
                        })
                        .finally(() => {
                            dispatch(setSnackbar({
                                text: 'Task moved successfully',
                                severity: 'success',
                                open: true
                            }));
                        });

                    return;
                }

                if (dragTask.task.isPlanned) {
                    return;
                }

                if (!dragTask.task.isPlanned && !dragTask.task.dayId && dragTask.task.id && dragTask.tagFrom) {
                    dispatch(undeleteTask({
                        taskId: dragTask.task.id,
                        isCompleted: false,
                        isPlanned: false,
                    }))
                        .unwrap()
                        .then(() => {
                            dispatch(tagTaskAction({
                                taskId: Number(dragTask.task.id),
                                tagFrom: String(dragTask.tagFrom),
                                actionType: TagTaskActionType.DAY,
                                date: formattedValue
                            }));
                        });

                    return;
                }

                dispatch(undeleteTask({
                    taskId: dragTask.task.id,
                    isCompleted: false,
                    isPlanned: false,
                }))
                    .unwrap()
                    .then((res) => {
                        dispatch(changeTaskDayDnD({
                            oldDate: dragTask.date,
                            task: res[dragTask.date],
                            newDayDate: formattedValue,
                        }));
                        dispatch(changeTasksDay({
                            date: formattedValue,
                            taskId: Number(dragTask.task.id),
                            orderId: dragTask.task.Order.id,
                            listType: 0
                        }));
                    });

            } else {
                if (dragTask.task.isRecurring) {
                    const tagTitles = dragTask.task.Tags.map(tag => tag.title);
                    dispatch(addTask({
                        task: {
                            ...dragTask.task,
                            isRecurring: false,
                            frequencyId: 0,
                            recurringId: 0
                        },
                        date: formattedValue,
                        tagTitles: tagTitles
                    }))
                        .unwrap()
                        .then(() => {
                            dispatch(deleteTask(dragTask.task.id))
                                .unwrap()
                                .then(originalPromiseResult => {
                                    let keys = Object.keys(originalPromiseResult);
                                    let savedTask = originalPromiseResult[keys[0]] as ITask;
                                    if (savedTask.Goals?.length) {
                                        const goalsIds = savedTask.Goals.map(goal => goal.id);
                                        dispatch(replaceTaskInGoal({
                                            goalIds: goalsIds,
                                            task: {...savedTask, isDeleted: true}
                                        }));
                                    }
                                })
                                .then(() => {
                                    dispatch(setSnackbar({
                                        text: 'Task moved successfully',
                                        severity: 'success',
                                        open: true
                                    }));
                                });
                        });
                    return;
                }

                if (dragTask.task.isPlanned) {
                    if (copy) {
                        const tagTitles = dragTask.task.Tags.map(tag => tag.title);
                        dispatch(addTask({
                            task: {
                                ...dragTask.task,
                                isPlanned: false
                            },
                            date: formattedValue,
                            tagTitles: tagTitles
                        }))
                            .unwrap()
                            .then(() => {
                                dispatch(setSnackbar({
                                    text: 'Task copied successfully',
                                    severity: 'success',
                                    open: true
                                }));
                            });
                        return;
                    } else {
                        dispatch(togglePlanned({
                            taskId: dragTask.task.id,
                            date: formattedValue
                        }));
                        return;
                    }
                }

                if (!dragTask.task.isPlanned && !dragTask.task.dayId && dragTask.task.id && dragTask.tagFrom) {
                    dispatch(tagTaskAction({
                        taskId: dragTask.task.id,
                        tagFrom: dragTask.tagFrom,
                        actionType: TagTaskActionType.DAY,
                        date: formattedValue
                    }));
                    return;
                }

                dispatch(changeTaskDayDnD({
                    oldDate: dragTask.date,
                    task: dragTask.task,
                    newDayDate: formattedValue,
                }));
                dispatch(changeTasksDay({
                    date: formattedValue,
                    taskId: dragTask.task.id,
                    orderId: dragTask.task.Order.id,
                    listType: 0
                }));
            }
        }

    }

    useEffect(() => {
        if (date && new Date(date) !== value) {
            // setValue(new Date(date));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    useEffect(() => {
        switch (user.lang) {
            case 'en':
                import('date-fns/locale/en-US/index').then(locale => updateLocale(locale.default));
                break;
            case 'ru':
                import('date-fns/locale/ru/index').then(locale => updateLocale(locale.default));
                break;
            case 'pl':
                import('date-fns/locale/pl/index').then(locale => updateLocale(locale.default));
                break;
            case 'ua':
                import('date-fns/locale/uk/index').then(locale => updateLocale(locale.default));
                break;
            default:
                import('date-fns/locale/en-US/index').then(locale => updateLocale(locale.default));
                break;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.lang, user.weekStartsOn]);

    const updateLocale = (locale: Locale) => {
        let loc = locale;
        if (loc && loc.options) {
            loc.options.weekStartsOn = user.weekStartsOn as 0 | 1 | 2 | 3 | 4 | 5 | 6;
        }
        setLocale(loc);
    }

    let opacity = 0;
    let backgroundColor = 'inherit';
    let border = `2px dashed transplarent`;
    if (isOver && canDrop) {
        backgroundColor = theme.palette.dragAndDropBackgroundColor.main;
    }
    if (canDrop) {
        opacity = 1;
        border = `2px dashed ${theme.palette.dragAndDropBorderColor.main}`;
    }

    return (
        <Fragment>
            <LocalizationProvider
                adapterLocale={locale}
                dateAdapter={AdapterDateFns}
            >
                <MobileDatePicker
                    toolbarTitle=''
                    disableHighlightToday
                    value={value}
                    open={isOpen}
                    shouldDisableDate={disablePastDays}
                    onClose={handleClose}
                    onChange={(newValue) => setValue(newValue)}
                    onAccept={handleAccept}
                    renderInput={() => <span/>}
                    components={{
                        ActionBar: ActionBar
                    }}
                />
            </LocalizationProvider>
            {copy
                ?
                <ContentCopyIcon
                    ref={drop}
                    sx={{
                        width: '60px',
                        height: '30px',
                        border: border,
                        borderRadius: '0.75rem',
                        borderBottomColor: isFullBorder ? '' : 'transparent',
                        backgroundColor: backgroundColor,
                        color: theme.palette.dragAndDropColor.main,
                        opacity: opacity,
                        ...style
                    }}
                />
                :
                <CalendarMonthIcon
                    ref={drop}
                    sx={{
                        width: '60px',
                        height: '30px',
                        border: border,
                        borderRadius: '0.75rem',
                        borderBottomColor: isFullBorder ? '' : 'transparent',
                        backgroundColor: backgroundColor,
                        color: theme.palette.dragAndDropColor.main,
                        opacity: opacity,
                        ...style
                    }}
                />
            }
        </Fragment>
    )
}
