import React, {FC, Fragment, ReactElement, useState} from 'react';
import {
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    styled,
    Toolbar,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import BrightnessAutoOutlinedIcon from '@mui/icons-material/BrightnessAutoOutlined';
import {NavLink} from 'react-router-dom';
import {useAppSelector} from '../../hooks/redux';
import {NavUser} from './NavUser';
import {LanguageSwitcher} from '../UI/LanguageSwitcher/LanguageSwitcher';
import {TMode} from '../../models/utils/TMode';
import {useTranslation} from "react-i18next";
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';

const drawerWidth = 260;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
    zIndex: window.innerWidth > 500 ? theme.zIndex.drawer + 1 : 0,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: window.innerWidth > 500 ? `calc(100% - ${drawerWidth}px)` : '',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const chooseMode = (mode: TMode): { icon: ReactElement, mode: TMode } => {
    // dark -> auto -> light
    if (mode === 'dark') return {icon: <DarkModeOutlinedIcon/>, mode: 'auto'};
    if (mode === 'light') return {icon: <WbSunnyOutlinedIcon/>, mode: 'dark'};

    return {icon: <BrightnessAutoOutlinedIcon/>, mode: 'light'};
}

interface IProps {
    open: boolean,
    handleDrawerOpen: () => void,
    toggleTheme: (mode: TMode) => void
}

export const NavBar: FC<IProps> = ({open, handleDrawerOpen, toggleTheme}) => {

    const {t} = useTranslation();
    const theme = useTheme();

    const {isAuth, user} = useAppSelector(state => state.auth);
    const {isMobile, mode} = useAppSelector(state => state.utils);
    const {themeMode} = useAppSelector(state => state.utils);

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenThemeSwitcherMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseThemeSwitcherMenu = () => {
        setAnchorElUser(null);
    };

    const switchTheme = (selectedMode: TMode) => {
        toggleTheme(selectedMode);
        setAnchorElUser(null);
    }

    return (
        <AppBar position='fixed' open={open} sx={{zIndex: isMobile ? 1100 : ''}}>
            <Toolbar sx={{justifyContent: "space-between"}}>
                <Stack direction="row">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    {open
                        ?
                        ''
                        :
                        <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1, margin: 'auto'}}>
                            <NavLink to="/home" style={{textDecoration: 'none', color: '#ffffff'}}>
                                SetTasks
                            </NavLink>
                        </Typography>
                    }
                </Stack>
                <Stack direction="row">
                    {!isMobile
                        ?
                        <Fragment>
                            <LanguageSwitcher/>
                            <Tooltip title={t("Theme switcher")}>
                                <IconButton
                                    onClick={handleOpenThemeSwitcherMenu}
                                    sx={{ml: 1, mr: 1}}
                                    color="inherit">
                                    {chooseMode(mode).icon}
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{mt: '45px'}}
                                id="menu-theme-switcher"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseThemeSwitcherMenu}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            backgroundColor: themeMode === 'light' ? 'rgb(255, 255, 255)' : "#121212",
                                            backgroundImage: themeMode === 'light' ? '' : "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                        backgroundColor: themeMode === 'light' ? '' : "#121212",
                                        backgroundImage: themeMode === 'light' ? '' : "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                                        borderRadius: '0.75rem',
                                        '& .MuiMenuItem-root': {
                                            // paddingTop: 1,
                                            // paddingBottom: 1,
                                            // paddingLeft: 3,
                                            // paddingRight: 3,
                                            minWidth: '120px',
                                        },
                                    },
                                }}
                            >
                                <MenuItem
                                    onClick={() => switchTheme('light')}
                                    sx={{
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <WbSunnyOutlinedIcon/>
                                    </ListItemIcon>
                                    <Typography textAlign="center">{t('Light')}</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => switchTheme('dark')}
                                    sx={{
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <DarkModeOutlinedIcon/>
                                    </ListItemIcon>
                                    <Typography textAlign="center">{t('Dark')}</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => switchTheme('auto')}
                                    sx={{
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                        },
                                    }}
                                >
                                    <ListItemIcon>
                                        <ImportantDevicesIcon/>
                                    </ListItemIcon>
                                    <Typography textAlign="center">{t('Auto')}</Typography>
                                </MenuItem>
                            </Menu>
                        </Fragment>
                        :
                        ''
                    }
                    <NavUser isAuth={isAuth} user={user}/>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}