import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../hooks/redux";
import {register} from "../../store/auth/authSlice";
import {useTranslation} from "react-i18next";
import {getOffset} from "../../utils/helpers";
import {FormikHelpers} from 'formik';
import i18next from "i18next";

interface IRegisterFormFields {
    name: string,
    email: string,
    password: string,
    confirmPassword: string,
    referralCode: string
}

export function useRegisterForm() {

    const {t} = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const registerInitialValues: IRegisterFormFields = {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        referralCode: ''
    }

    const registerValidationSchema = yup.object({
        name: yup
            .string()
            .trim()
            .min(4, t('Name must be at least 4 and no more than 16 characters'))
            .max(16, t('Name must be at least 4 and no more than 16 characters'))
            .required(),
        email: yup
            .string()
            .trim()
            .email(t('Enter a valid email'))
            .required(t('Email is required')),
        password: yup
            .string()
            .trim()
            .min(4, t('Password must be at least 4 and no more than 16 characters'))
            .max(16, t('Password must be at least 4 and no more than 16 characters'))
            .required(t('Password is required')),
        confirmPassword: yup
            .string()
            .trim()
            .oneOf([yup.ref('password'), null], t('Password must match'))
            .required(t('Password must match')),
    })

    const onSubmit = async (values: IRegisterFormFields, onSubmitProps: FormikHelpers<IRegisterFormFields>): Promise<void> => {
        const defaultClientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        await dispatch(register({
            name: values.name,
            email: values.email.toLowerCase(),
            password: values.password,
            confirmPassword: values.confirmPassword,
            timezoneName: defaultClientTimezone,
            timezoneOffset: getOffset(defaultClientTimezone),
            referralCode: values.referralCode,
            lang: i18next.language
        }))
            .unwrap()
            .then((originalPromiseResult) => {
                navigate('/register-success');
            })
            .catch((error) => {
                console.error(error.msg);
                if (error.param) onSubmitProps.setErrors({[error.param]: error.msg});
            });
    }

    return {
        registerInitialValues,
        registerValidationSchema,
        onSubmit
    }
}