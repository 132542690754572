import React, {FC, Fragment, useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    Container,
    Grid,
    List,
    styled,
    Typography,
    useTheme
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import {useTranslation} from 'react-i18next';
import {NavLink, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {IGoal} from '../../models/goals/IGoal';
import {
    changeTimezone,
    checkIfGoalsExists,
    flatArrayOfObjects,
    formatDate,
    formatDateWithTimezone
} from '../../utils/helpers';
import {getGoals} from '../../store/goal/goalSlice';
import {GoalEditDialog} from '../../components/goals/GoalEditDialog';
import {Goal} from '../../components/goals/Goal';
import {TransitionGroup} from 'react-transition-group';
import EditIcon from "@mui/icons-material/Edit";
import {TagList} from "../../components/UI/Tags/TagList";
import {ITag} from "../../models/tags/ITag";
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {GoalCompleteLabel} from "../../components/goals/labels/GoalCompleteLabel";
import {Task} from "../../components/tasks/Task";
import {ITabDay} from "../../models/days/ITabDay";
import {getRelatedTasks} from '../../store/task/taskSlice';

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));


export const GoalsView: FC = () => {

    const {t} = useTranslation();
    const {id} = useParams();
    const {setId} = useParams();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {isMobile} = useAppSelector(state => state.utils);
    const {user} = useAppSelector(state => state.auth);
    const {goals, sets, goalAction} = useAppSelector(state => state.goal);
    const {tasksRelatedToGoal} = useAppSelector(state => state.task);

    const [goal, setGoal] = useState<IGoal>({} as IGoal);
    const [subGoals, setSubGoals] = useState<IGoal[]>([]);
    const [goalTags, setGoalTags] = useState<ITag[]>([]);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);

    const handleEditOpen = () => {
        setIsEditDialogOpen(true);
    }

    const handleEditClose = () => {
        setIsEditDialogOpen(false);
    }

    useEffect(() => {
        if (id !== undefined) {
            if (!checkIfGoalsExists(sets)) {
                dispatch(getGoals());
            } else {

                const flattedGoals = flatArrayOfObjects(goals, 'SubGoals');
                const currGoal = flattedGoals.find(goal => goal['id' as keyof typeof goal] === Number(id)) as IGoal;
                if (currGoal !== undefined) {
                    // @ts-ignore
                    setGoal(currGoal);
                    // console.log(currGoal);

                    // @ts-ignore


                    // setGoalTasks(goal.Tasks);
                    // @ts-ignore
                    // setSelectedTags(currGoal.UserTags.map(userTag => {
                    //     return userTag!.Tag!.title;
                    // }));
                    if (currGoal.hasOwnProperty('SubGoals')) {
                        setSubGoals(currGoal.SubGoals);
                        // @ts-ignore
                        // const parent = findNestedObj(goals, 'id', currGoal.id);
                        // if (parent !== undefined) setSubGoals(parent.SubGoals);
                    } else {
                        setSubGoals([]);
                    }
                }
            }


        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goals, id]);

    useEffect(() => {
        if (goal.UserTags) {
            setGoalTags(
                // [goal.UserTags[0]!.Tag!]
                goal.UserTags.map(userTag => {
                    return userTag!.Tag!;

                    // // let tag = JSON.parse(JSON.stringify(userTag!.Tag!));
                    // let tag = userTag!.Tag!;
                    // // tag.color = userTag!.color!;
                    // return tag;
                })
            );

            // console.log(goal.UserTags.map(userTag => {
            //     return userTag!.Tag!;
            // }));
        }


    }, [goal]);

    useEffect(() => {
        if (id !== undefined) {
            // setIsRelatedTasksLoading(true);
            dispatch(getRelatedTasks({goalId: Number(id)}))
                .unwrap()
                .catch((error) => {
                    console.error(error.msg);
                });
        }
    }, [id]);


    return (
        <Container maxWidth='lg' disableGutters={true}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflowY: 'auto',
                    height: 'calc(100vh - 120px)',
                    paddingBottom: '16px'
                }}
            >
                <Box sx={{
                    transition: 'all .5s !important',
                    width: '100%',
                    maxWidth: isMobile ? '96%' : '100%',

                }}>
                    <Box sx={{mt: isMobile ? 0 : 3}}>
                        <Card sx={{
                            mt: 2,
                            paddingLeft: isMobile ? '0rem' : '1rem',
                            paddingRight: isMobile ? '0rem' : '1rem',
                            borderRadius: '0.75rem'
                        }}>
                            <CardContent sx={{
                                overflowY: 'auto',
                                // height: isMobile ? 'calc(100vh - 88px)' : 'calc(100vh - 104px)'
                            }}>
                                <Box sx={{
                                    transition: 'all .5s !important',
                                    width: '100%',
                                    // maxWidth: isMobile ? '95%' : '90%'
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        mb: '12px',
                                        height: '40px'
                                    }}>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Typography
                                                component={NavLink}
                                                to={'/goals' + (setId ? '?set=' + setId : '')}
                                                color='primary'
                                                variant='h5'
                                                sx={{
                                                    marginLeft: isMobile ? '6px' : '0px',
                                                    textDecoration: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    fontSize: '1.5rem',
                                                    fontWeight: '500 !important',
                                                    lineHeight: '1.2',
                                                }}
                                            >
                                                {t('Goals')}
                                            </Typography>
                                            <EastIcon color='primary'
                                                      sx={{
                                                          fontWeight: '500 !important',
                                                          lineHeight: '1.2',
                                                          fontSize: '1.5rem',
                                                      }}
                                            />
                                            {goal.title
                                                ?
                                                <Typography
                                                    color='primary'
                                                    variant='h5'
                                                    sx={{
                                                        fontSize: '1.5rem',
                                                        fontWeight: '500 !important',
                                                        lineHeight: '1.2',
                                                    }}
                                                >
                                                    {goal.title}
                                                </Typography>
                                                :
                                                ''
                                            }
                                        </Box>
                                        <Box>
                                            <EditIcon
                                                color='primary'
                                                sx={{
                                                    ml: 2,
                                                    fontSize: '1.5rem',
                                                    cursor: 'pointer',
                                                    fontWeight: '500 !important',
                                                    lineHeight: '1.2',
                                                }}
                                                onClick={handleEditOpen}
                                            />
                                        </Box>
                                    </Box>
                                    {goal.id && (
                                        <Fragment>
                                            <Box>
                                                <Grid container spacing={2} sx={{mt: 1}}>
                                                    <Grid item xs={12} md={12}>
                                                        <Typography display="inline"
                                                                    sx={{
                                                                        fontSize: '1.5rem !important',
                                                                        fontWeight: '600 !important',
                                                                        marginRight: '0.25rem !important'
                                                                    }}>
                                                            {goal.title}
                                                        </Typography>

                                                        {goal.isCompleted &&
                                                            <GoalCompleteLabel/>
                                                        }

                                                        {goal.isDream &&
                                                            <Button
                                                                sx={{
                                                                    color: theme.palette.goalLabelIsDreamTextColor.main,
                                                                    backgroundColor: theme.palette.goalLabelIsDreamBackgroundColor.main,
                                                                    fontWeight: '600 !important',
                                                                    fontSize: '0.85rem !important',
                                                                    paddingTop: '0.25rem !important',
                                                                    paddingBottom: '0.25rem !important',
                                                                    paddingRight: '0.75rem !important',
                                                                    paddingLeft: '0.75rem !important',
                                                                    marginLeft: '0.5rem !important',
                                                                    borderRadius: '0.75rem',
                                                                    textTransform: 'none',
                                                                    '&:hover': {
                                                                        color: theme.palette.goalLabelIsDreamTextColor.main,
                                                                        backgroundColor: theme.palette.goalLabelIsDreamBackgroundColor.main,
                                                                    },
                                                                }}>
                                                                {t('So far, this is only a dream that wants to become a goal!')}
                                                            </Button>
                                                        }
                                                    </Grid>

                                                    {goalTags && goalTags.length > 0 &&
                                                        <Grid item xs={12} md={12}>
                                                            <TagList
                                                                grow
                                                                tags={goalTags}
                                                                size='medium'
                                                                withMargins
                                                                component='div'/>
                                                        </Grid>
                                                    }

                                                    {goal.description &&
                                                        <Grid item xs={12} md={12}>
                                                            <Typography display="inline"
                                                                        sx={{
                                                                            fontSize: '1.15rem !important',
                                                                            fontWeight: '500 !important',
                                                                            marginRight: '0.25rem !important'
                                                                        }}>
                                                                {goal.description || null}
                                                            </Typography>
                                                        </Grid>
                                                    }

                                                    {goal.isRemind
                                                        &&
                                                        <Grid item xs={12} md={12}>
                                                            <Typography display="inline"
                                                                        sx={{
                                                                            fontWeight: '500 !important',
                                                                            marginRight: '0.25rem !important'
                                                                        }}>
                                                                {t('Enable daily notification')}
                                                            </Typography>
                                                        </Grid>
                                                    }


                                                    <Grid item xs={12} md={12}>
                                                        {
                                                            goal.startDate && goal.endDate ?
                                                                (
                                                                    <Typography display="inline"
                                                                                sx={{
                                                                                    fontWeight: '500 !important',
                                                                                    marginRight: '0.25rem !important'
                                                                                }}>
                                                                        {t('The period of achievement of the goal')} {formatDate(goal.startDate)} - {formatDate(goal.endDate)}
                                                                    </Typography>

                                                                )
                                                                :
                                                                (
                                                                    goal.startDate ?
                                                                        <Typography display="inline"
                                                                                    sx={{
                                                                                        fontWeight: '500 !important',
                                                                                        marginRight: '0.25rem !important'
                                                                                    }}>
                                                                            {t('The time for starting the goal')} {formatDate(goal.startDate)}
                                                                        </Typography>
                                                                        :
                                                                        (
                                                                            goal.endDate ?
                                                                                <Typography display="inline"
                                                                                            sx={{
                                                                                                fontWeight: '500 !important',
                                                                                                marginRight: '0.25rem !important'
                                                                                            }}>
                                                                                    {t('The goal must be achieved before')} {formatDate(goal.endDate)}
                                                                                </Typography>
                                                                                :
                                                                                <Typography display="inline"
                                                                                            sx={{
                                                                                                fontWeight: '500 !important',
                                                                                                marginRight: '0.25rem !important'
                                                                                            }}>
                                                                                    {t('The deadlines for achieving the goal are not indicated')}
                                                                                </Typography>
                                                                        )
                                                                )
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12} md={12}>
                                                        <Box sx={{
                                                            display: 'flex'
                                                        }}>
                                                            <Typography
                                                                display="inline"
                                                                sx={{
                                                                    fontWeight: '500 !important',
                                                                }}
                                                            >
                                                                {t('Progress: ')}
                                                            </Typography>
                                                            <Box sx={{
                                                                width: '100%',
                                                                textAlign: 'center',
                                                                marginLeft: '16px'
                                                            }}
                                                            >
                                                                <Typography display="inline"
                                                                            sx={{
                                                                                fontWeight: '500 !important',
                                                                                marginRight: '0.25rem !important'
                                                                            }}>
                                                                    {goal.progress + '%'}
                                                                </Typography>

                                                                <BorderLinearProgress
                                                                    variant="determinate"
                                                                    value={goal.progress}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                            </Box>
                                        </Fragment>
                                    )
                                    }
                                </Box>
                                {goal.id &&
                                    <GoalEditDialog
                                        open={isEditDialogOpen}
                                        goal={goal}
                                        onClose={handleEditClose}
                                    />
                                }
                            </CardContent>
                        </Card>
                    </Box>

                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Card sx={{
                                    mt: 2,
                                    paddingLeft: isMobile ? '0rem' : '1rem',
                                    paddingRight: isMobile ? '0rem' : '1rem',
                                    borderRadius: '0.75rem'
                                }}>
                                    <CardContent sx={{
                                        overflowY: 'auto',
                                    }}>
                                        <Typography
                                            color='primary'
                                            variant='h5'
                                            sx={{
                                                marginLeft: isMobile ? '6px' : '0px',
                                                textDecoration: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                fontSize: '1.5rem',
                                                fontWeight: '500 !important',
                                                lineHeight: '1.2',
                                            }}
                                        >
                                            {t('Subgoals')}
                                        </Typography>
                                        {subGoals && subGoals.length
                                            ?
                                            <Box>
                                                <List dense={true} sx={{overflowY: isMobile ? '' : 'auto'}}
                                                      key={goalAction}>
                                                    <TransitionGroup>
                                                        {subGoals.map((subGoal, i) => (
                                                            <Collapse key={subGoal.id}>
                                                                <Goal
                                                                    goal={subGoal}
                                                                    isEditable={false}
                                                                    isSubGoal={true}
                                                                    isGoalSending={false}
                                                                    dragEndHandle={() => {
                                                                    }}
                                                                    index={i}
                                                                />
                                                            </Collapse>
                                                        ))}
                                                    </TransitionGroup>
                                                </List>
                                            </Box>
                                            :
                                            <Alert variant={"outlined"} severity="info" sx={{mt: 2}}>
                                                {t('You don\'t have subgoals for this goal yet!')}
                                            </Alert>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card sx={{
                                    mt: 2,
                                    paddingLeft: isMobile ? '0rem' : '1rem',
                                    paddingRight: isMobile ? '0rem' : '1rem',
                                    borderRadius: '0.75rem'
                                }}>
                                    <CardContent sx={{
                                        overflowY: 'auto',
                                    }}>
                                        <Typography
                                            color='primary'
                                            variant='h5'
                                            sx={{
                                                marginLeft: isMobile ? '6px' : '0px',
                                                textDecoration: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                fontSize: '1.5rem',
                                                fontWeight: '500 !important',
                                                lineHeight: '1.2',
                                            }}
                                        >
                                            {t('Tasks related to this goal')}
                                        </Typography>
                                        {
                                            tasksRelatedToGoal.tasks.length > 0 &&
                                            <TransitionGroup>
                                                {tasksRelatedToGoal.tasks.map((task, i) => (
                                                    <Collapse
                                                        key={task.createdAt}
                                                        sx={{
                                                            marginLeft: isMobile ? '0px' : '24px',
                                                        }}
                                                    >
                                                        <Task
                                                            task={task}
                                                            day={{
                                                                date: task.Day
                                                                    ?
                                                                    task.Day.date
                                                                    :
                                                                    formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))
                                                            } as ITabDay}
                                                            index={i}
                                                            isHideActions={true}
                                                            moveTasks={() => {
                                                            }}
                                                            dragEndHandle={() => {
                                                            }}
                                                            isHideGoals={true}
                                                            isHideTags={true}
                                                            isHideCheckbox={task.isRecurring}
                                                        />
                                                    </Collapse>
                                                ))}
                                            </TransitionGroup>
                                        }
                                        {
                                            !tasksRelatedToGoal.isTasksRelatedLoading && tasksRelatedToGoal.tasks.length === 0 &&
                                            <Alert variant={"outlined"} severity="info" sx={{mt: 2}}>
                                                {t('You don\'t have tasks for this goal yet!')}
                                            </Alert>
                                        }
                                        {
                                            tasksRelatedToGoal.isTasksRelatedLoading &&
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                margin: '6px 0 18px 0'
                                            }}>
                                                <CircularProgress/>
                                            </Box>
                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}
