import React, {FC, Fragment} from 'react';
import {Avatar, Button, Grid, Typography,} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {setColorSchema} from "../../store/utils/utilsSlice";
import {saveToStorage} from "../../utils/storageHelpers";

interface IProps {
    id: number,
    title: string
}

export const ColorSchemaItem: FC<IProps> = ({id, title, ...props}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {colorSchema} = useAppSelector(state => state.utils);

    const applyNewColorSchema = async () => {
        dispatch(setColorSchema(id));
        await saveToStorage('colorSchema', String(id));
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} style={{textAlign: "center"}}>
                    <Typography variant="body1" sx={{fontWeight: '600 !important'}}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} style={{textAlign: "center"}}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{height: '100%'}}
                    >
                        <Avatar alt={`Color schema ${id}`}
                                variant="square"
                                sx={{width: 'auto', height: 'auto'}}
                                src={`/images/color-schemas/color-schema-${id}.png`}/>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} style={{textAlign: "center"}}>
                    <Button
                        variant="contained"
                        onClick={() => applyNewColorSchema()}
                        disabled={colorSchema === id}
                    >
                        {t('Apply')}
                    </Button>
                </Grid>
            </Grid>
        </Fragment>
    )
};
