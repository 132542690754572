import React, {FC, Fragment, useState} from 'react';
import {Box, Divider, IconButton, ListItem, ListItemText, Typography, useTheme} from '@mui/material';
import RepeatIcon from '@mui/icons-material/Repeat';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {IRecurring} from '../../models/recurring/IRecurring';
import {TagList} from '../UI/Tags/TagList';
import {TaskEditDialog} from '../tasks/TaskEditDialog';
import {ITask} from '../../models/ITask';
import {DeleteAlert} from '../UI/DeleteAlert/DeleteAlert';
import {deleteRecurring, resetTasks} from '../../store/task/taskSlice';
import {resetKicker} from '../../store/kicker/kickerSlice';

interface IRecurringTaskProps {
    recurring: IRecurring
}

const deleteVariants: string[] = ['Delete this task and all subsequent ones', 'Delete all tasks'];

export const RecurringTask: FC<IRecurringTaskProps> = ({recurring}) => {

    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {isMobile} = useAppSelector(state => state.utils);

    const [deleteCounter, setDeleteCounter] = useState<number>(0);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const deleteAlertHandle = () => {
        setDeleteCounter(0);
    }

    const handleEditOpen = () => {
        setIsEdit(true);
    }

    const handleEditClose = () => {
        setIsEdit(false);
    }

    const handleClickDelete = () => {
        setDeleteCounter(prevState => prevState + 1);
    }

    const deleteRecurringHandle = (type: string) => {
        if (type === 'Delete this task and all subsequent ones') {
            dispatch(deleteRecurring({
                recurringId: recurring.id,
                dayId: null,
                deleteTask: false
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    if (originalPromiseResult) {
                        dispatch(resetTasks());
                        dispatch(resetKicker());
                    }
                });
        }
        if (type === 'Delete all tasks') {
            dispatch(deleteRecurring({
                recurringId: recurring.id,
                dayId: null,
                deleteTask: true
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    if (originalPromiseResult) {
                        dispatch(resetTasks());
                        dispatch(resetKicker());
                    }
                });
        }
    }

    return (
        <Box sx={{height: '48px !important'}}>
            {deleteCounter === 1
                ?
                <ListItem sx={{padding: '0'}}>
                    <DeleteAlert
                        deleteCounter={deleteCounter}
                        isRecurring={true}
                        deleteVariants={deleteVariants}
                        deleteAlertHandle={deleteAlertHandle}
                        deleteRecurringHandle={deleteRecurringHandle}
                        deleteTaskHandle={() => {
                        }}
                    />
                </ListItem>
                :
                <Fragment>
                    <Divider/>
                    <ListItem sx={{paddingRight: '0px'}}>
                        <ListItemText sx={{display: 'grid'}}>
                            <Typography
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}
                            >
                                {recurring.title}
                            </Typography>
                        </ListItemText>
                        {!isMobile && recurring.Tags &&
                            <TagList tags={recurring.Tags}/>
                        }
                        <RepeatIcon color={'action'}/>
                        {recurring.EventNotifications && recurring.EventNotifications.length
                            ?
                            <NotificationsIcon sx={{ml: '8px', mr: '6px'}}/>
                            :
                            ''
                        }
                        <IconButton
                            edge="end"
                            aria-label="edit"
                            sx={{marginRight: '0px'}}
                            onClick={handleEditOpen}
                        >
                            <EditIcon sx={{
                                opacity: '0.4',
                                "&:hover": {
                                    opacity: '1',
                                    color: theme.palette.primary.main,
                                }
                            }}/>
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            sx={{
                                marginRight: '0px',
                                opacity: '0.4',
                                "&:hover": {
                                    opacity: '1',
                                    color: theme.palette.error.main,
                                }
                            }}
                            onClick={handleClickDelete}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </ListItem>
                    <TaskEditDialog
                        open={isEdit}
                        task={{
                            title: recurring.title,
                            description: recurring.description,
                            isRecurring: true,
                            recurringId: recurring.id,
                            isImportant: false,
                            isCanceled: false,
                            Tags: recurring.Tags,
                            RecurringEvent: recurring
                        } as ITask}
                        date={''}
                        onClose={handleEditClose}
                    />
                </Fragment>
            }

        </Box>
    )
}
