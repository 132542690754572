import {AxiosResponse} from "axios";
import $api from "../http";
import {INote} from "../models/INote";
import {INoteResponse} from "../models/response/INoteResponse";
import {INoteTagsRemoveResponse} from "../models/response/INoteTagsRemoveResponse";
import {INotesResponse} from "../models/response/INotesResponse";

const NOTE_API_URL = process.env.REACT_APP_NOTE_API_URL;

export const notesAPI = async (page: number, limit: number): Promise<AxiosResponse<INotesResponse>> => {
    return await $api.get<INotesResponse>(`${NOTE_API_URL}/note/all?page=${page}&limit=${limit}`);
}

export const noteAPI = async (noteId: number): Promise<AxiosResponse<INote>> => {
    return await $api.get<INote>(`${NOTE_API_URL}/note/get/${noteId}`);
}

export const notesGetFavoriteAPI = async (page: number, limit: number): Promise<AxiosResponse<INotesResponse>> => {
    return await $api.get<INotesResponse>(`${NOTE_API_URL}/note/all?favorite=1&page=${page}&limit=${limit}`);
}

export const notesAddAPI = async (note: INote, tagTitles?: string[]): Promise<AxiosResponse<INote>> => {
    return await $api.post<INoteResponse>(`${NOTE_API_URL}/note/add`, {...note, tagTitles: tagTitles});
}

export const notesEditAPI = async (note: INote, tagTitles?: string[]): Promise<AxiosResponse<INoteResponse>> => {
    return await $api.post<INoteResponse>(`${NOTE_API_URL}/note/edit/${note.id}`, {...note, tagTitles: tagTitles});
}

export const notesDeleteAPI = async (note: INote): Promise<AxiosResponse<INote>> => {
    return await $api.post<INote>(`${NOTE_API_URL}/note/delete/${note.id}`);
}

export const notesFavoriteAPI = async (note: INote): Promise<AxiosResponse<INoteResponse[]>> => {
    return await $api.post<INoteResponse[]>(`${NOTE_API_URL}/note/favorite/${note.id}`);
}

export const removeTagAPI = async (foreignKey: number, tagId: number, model: 'Note' | 'Event'): Promise<AxiosResponse<INoteTagsRemoveResponse>> => {
    return await $api.post<INoteTagsRemoveResponse>(`${NOTE_API_URL}/note/tags/remove`, {
        foreignKey: foreignKey,
        tagId: tagId,
        model: model
    });
}

export const tagNotesAPI = async (tag: string, page: number, limit: number): Promise<AxiosResponse<INote[]>> => {
    return await $api.get<INote[]>(`${NOTE_API_URL}/note/tags/list/${tag}?page=${page}&limit=${limit}`);
}


