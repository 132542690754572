import React, {FC, Fragment, useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    Divider,
    List,
    Stack,
    Typography
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {getPlannedTasks, reorderPlannedTasks, reorderTasks, toggleEditable} from '../../store/task/taskSlice';
import {AppObserver} from '../utilities/AppObserver';
import {ITabDay} from '../../models/days/ITabDay';
import {Task} from '../tasks/Task';
import {PlannedTaskAddInput} from './PlannedTaskAddInput';
import {ITask} from '../../models/ITask';
import {TransitionGroup} from 'react-transition-group';
import {DnDPicker} from '../UI/Calendar/DnDPicker';
import {changeTimezone, formatDateWithTimezone} from '../../utils/helpers';
import {DnDDays} from '../UI/Calendar/DnDDays';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";

let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export const PlannedTasksList: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {user} = useAppSelector(state => state.auth);
    const {planned, isTaskLoading, isEditable} = useAppSelector(state => state.task);
    const {isMobile} = useAppSelector(state => state.utils);

    const [tasks, setTasks] = useState<ITask[]>([]);
    const [copyTasks, setCopyTasks] = useState<ITask[]>([]);

    const day: ITabDay = {
        name: '',
        dayOfMonth: new Date().getDate(),
        dayOfWeek: new Date().getDay(),
        date: formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))
    }

    const intersect = () => {
        dispatch(getPlannedTasks({page: planned.page, limit: 50}));
    }

    const moveTasks = (dragIndex: number, hoverIndex: number) => {
        const taskStateCopy = [...tasks];
        const [reorderedItem] = taskStateCopy.splice(dragIndex, 1);
        taskStateCopy.splice(hoverIndex, 0, reorderedItem);

        setTasks(taskStateCopy);
    }

    const dragEndHandle = (oldIndex: number, index: number) => {
        let newPosition = copyTasks[index].Order.position;
        if (newPosition > copyTasks[oldIndex].Order.position) {
            newPosition += 1;
        }

        dispatch(reorderPlannedTasks({oldIndex: oldIndex, newPosition: newPosition}));
        dispatch(reorderTasks({orderId: copyTasks[oldIndex].Order.id, newPosition: newPosition}));

        setCopyTasks(tasks);
    }

    useEffect(() => {
        setTasks(planned.tasks);
        setCopyTasks(planned.tasks);
    }, [planned]);

    const handleEditClick = () => {
        dispatch(toggleEditable());
    }


    return (
        <Card sx={{
            mt: 2,
            paddingLeft: isMobile ? '0rem' : '1rem',
            paddingRight: isMobile ? '0rem' : '1rem',
            borderRadius: '0.75rem'
        }}
        >
            <CardContent sx={{height: isMobile ? 'calc(100vh - 88px)' : 'calc(100vh - 104px)'}}>
                <Box sx={{display: 'flex', justifyContent: 'start'}}>
                    <Stack direction="row" spacing={0}>
                        <Typography
                            color='primary'
                            variant='h5'
                            sx={{
                                marginBottom: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontSize: '1.5rem',
                                fontWeight: '500 !important',
                                lineHeight: '1.2',
                            }}
                        >
                            {t('Planned')}
                        </Typography>
                        {
                            isEditable
                                ?
                                <RemoveRedEyeIcon
                                    color='primary'
                                    sx={{
                                        ml: 2,
                                        fontSize: '1.5rem',
                                        cursor: 'pointer',
                                        fontWeight: '500 !important',
                                        lineHeight: '1.2',
                                        mt: '2px'
                                    }}
                                    onClick={handleEditClick}
                                />
                                :
                                <EditIcon
                                    color='primary'
                                    sx={{
                                        ml: 2,
                                        fontSize: '1.5rem',
                                        cursor: 'pointer',
                                        fontWeight: '500 !important',
                                        lineHeight: '1.2',
                                        mt: '2px'
                                    }}
                                    onClick={handleEditClick}
                                />
                        }
                    </Stack>
                    <Box sx={{display: 'flex', ml: isMobile ? '8px' : '16px'}}>
                        {!isMobile &&
                            <Fragment>
                                <DnDDays
                                    title={t('Today')}
                                    dateTo={formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))}
                                    style={{
                                        fontSize: '21px',
                                        height: '30px',
                                    }}
                                />
                                <DnDDays
                                    title={t('Tomorrow')}
                                    dateTo={formatDateWithTimezone(changeTimezone(tomorrow, user.timezoneName))}
                                    style={{
                                        fontSize: '21px',
                                        height: '30px',
                                        marginLeft: '4px'
                                    }}
                                />
                            </Fragment>
                        }
                        <DnDPicker style={{marginLeft: '4px'}} isFullBorder copy/>
                        <DnDPicker style={{marginLeft: '4px'}} isFullBorder/>
                    </Box>
                </Box>

                <PlannedTaskAddInput day={day}/>
                <Box sx={{overflowY: 'auto', height: 'calc(100% - 88px)'}}>
                    <List dense={true}>
                        <TransitionGroup>
                            {tasks.map((task, i) => (
                                <Collapse key={task.id}>
                                    <Task
                                        task={task}
                                        day={day}
                                        index={i}
                                        moveTasks={moveTasks}
                                        dragEndHandle={dragEndHandle}
                                    />
                                </Collapse>
                            ))}
                        </TransitionGroup>
                        <Divider/>
                    </List>
                    {isTaskLoading &&
                        <Box sx={{display: 'flex', justifyContent: 'center', margin: '6px 0 18px 0'}}>
                            <CircularProgress/>
                        </Box>
                    }
                    {!planned.tasks.length && !isTaskLoading &&
                        <Alert variant={"outlined"} severity="info">
                            {t('You don\'t have planned tasks yet!')}
                        </Alert>
                    }
                    {!planned.observable && planned.tasks.length ?
                        <Alert variant={"outlined"} severity="info" sx={{marginBottom: 2}}>
                            {t('These are all planned tasks!')}
                        </Alert>
                        :
                        ''
                    }
                    {planned.observable &&
                        <AppObserver intersect={intersect} observing={planned.observable} key={planned.page}/>}
                </Box>
            </CardContent>
        </Card>
    )
}
