import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, isSupported} from 'firebase/messaging';
import {firebaseConfig} from "./firebaseConfig";

initializeApp(firebaseConfig);

let messaging = null;
isSupported()
    .then((isAvailable) => {
        if (isAvailable) {
            messaging = getMessaging();
        }
    })
    .catch(console.error);


export const requestForToken = () => {
    return getToken(messaging, {vapidKey: process.env.FIREBASE_VAPID_KEY});
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
// export const onMessageListener = () =>
//     new Promise((resolve) => {
//         console.log('1 onMessageListener');
//         onMessage(messaging, (payload) => {
//             console.log(payload);
//             resolve(payload);
//         });
//     });
