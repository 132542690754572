import React, {FC} from 'react';
import {Box, Chip, Stack, Typography, useTheme} from '@mui/material';

interface ITagList {
    tag: string,
    size?: 'small' | 'medium',
    handleDelete: (tag: string) => void,
}

export const TagAddList: FC<ITagList> = ({
                                             tag,
                                             size = 'small',
                                             handleDelete,
                                             ...props
                                         }) => {

    const theme = useTheme();

    const clickDelete = (tag: string) => {
        handleDelete(tag);
    }

    return (
        <Box
            sx={{
                // background: "#283240",
                height: "100%",
                display: "flex",
                // padding: "0.4rem",
                // margin: "0 0.5rem 0 0",
                justifyContent: "center",
                alignContent: "center",
                color: "#ffffff",
            }}
        >
            <Stack direction='row' gap={1}>
                <Chip
                    sx={{
                        // backgroundColor: "#c6c6c6",
                        borderColor: '#616161',
                        marginLeft: '4px',
                        '& .MuiChip-deleteIcon': {
                            color: '#9f999961',
                        },
                        '& .MuiChip-deleteIcon:hover': {
                            color: '#6d6d6d61',
                        },
                    }}
                    label={
                        <Typography
                        >
                            {tag}
                        </Typography>
                    }
                    variant='outlined'
                    size={"small"}
                    onDelete={() => clickDelete(tag)}
                />
                {/*<Typography>{data}</Typography>*/}
                {/*<Cancel*/}
                {/*    sx={{cursor: "pointer"}}*/}
                {/*    onClick={() => {*/}
                {/*        // handleDelete(data);*/}
                {/*    }}*/}
                {/*/>*/}
            </Stack>
        </Box>

        // <Box component={"span"}>
        //     <TransitionGroup
        //         component={null}
        //         appear={false}
        //         enter={false}
        //         exit={false}
        //     >
        //         {
        //             tags.map(tag => {
        //                 <Chip
        //                     sx={{
        //                         // backgroundColor: flatTag(tag).color,
        //                         borderColor: theme.palette.borderColor.main, // '#616161',
        //                         marginLeft: '4px',
        //                         marginBottom: '6px',
        //                         '& .MuiChip-deleteIcon': {
        //                             color: '#00000080',
        //                         },
        //                         '& .MuiChip-deleteIcon:hover': {
        //                             color: '#6d6d6d61',
        //                         },
        //                     }}
        //                     label={
        //                         <Typography>
        //                             {tag}
        //                         </Typography>
        //                     }
        //                     variant='outlined'
        //                     size={size}
        //                     onDelete={() => handleDelete(tag)}
        //                     key={tag}
        //                 />
        //             })}
        //     </TransitionGroup>
        // </Box>
    )
}
