import React from 'react';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { Button, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ActionBar = ({ onAccept, onCancel }: PickersActionBarProps) => {

    const { t } = useTranslation();

    return (
        <DialogActions>
            <Button onClick={onCancel}> {t('Cancel')} </Button>
            <Button onClick={onAccept}> {t('Save')} </Button>
        </DialogActions>
    )
}
