import {AxiosResponse} from "axios";
import $api from "../http";
import {INotificationSettings} from "../models/notifications/INotificationSettings";

const NOTIFICATION_API_URL = process.env.REACT_APP_NOTIFICATION_API_URL;

export const notificationAddToken = async (token: string, platform: string): Promise<AxiosResponse<boolean>> => {
    return await $api.post<boolean>(`${NOTIFICATION_API_URL}/notification/addWebPushToken`, {
        token: token,
        platform: platform
    });
}

export const notificationSettings = async (): Promise<AxiosResponse<INotificationSettings>> => {
    return await $api.get<INotificationSettings>(`${NOTIFICATION_API_URL}/notification/settings`);
}

export const notificationSaveSettings = async (settings: INotificationSettings): Promise<AxiosResponse<INotificationSettings>> => {
    return await $api.post<INotificationSettings>(`${NOTIFICATION_API_URL}/notification/settings`, {
        emailNotificationMorning: settings.emailNotificationMorning,
        emailNotificationEvening: settings.emailNotificationEvening,
        webPushNotificationMorning: settings.webPushNotificationMorning,
        webPushNotificationEvening: settings.webPushNotificationEvening
    });
}
