import React, {FC, Fragment, useState} from 'react';
import {Alert, Avatar, Box, Button, CircularProgress, Grid, Typography,} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {IAccount} from "../../models/IAccount";
import {setSnackbar} from "../../store/utils/utilsSlice";
import {removeRelatedAccount} from "../../store/account/accountSlice";

interface IProps {
    account: IAccount,
}

export const RelatedAccountsItem: FC<IProps> = ({account, ...props}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {user} = useAppSelector(state => state.auth);

    const [isAccountRemoving, setIsAccountRemoving] = useState<boolean>(false);
    const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);

    const removeRelatedAccountHandle = (account: IAccount) => {
        setIsAccountRemoving(true);
        dispatch(removeRelatedAccount({
            accountId: account.id,
        }))
            .unwrap()
            .then((originalPromiseResult) => {
            })
            .catch((error) => {
                console.error(error.msg);
                dispatch(setSnackbar({
                    text: error.msg,
                    severity: 'error',
                    open: true
                }));
            })
            .finally(() => {
                setIsAccountRemoving(false);
            });
    }

    return (
        <Fragment>
            {
                !showRemoveDialog ?
                    <Grid container spacing={2} sx={{mt: 1}}>
                        <Grid item xs={2} md={2}>
                            <Avatar alt="{account.provider}"
                                    variant="square"
                                    src={`/images/social-auth/${account.provider}.png`}/>
                        </Grid>
                        <Grid item xs={10} md={4}>
                            <Typography variant="body1" sx={{fontWeight: '600 !important'}}>
                                {account.name}
                            </Typography>
                            <Typography variant="body1">
                                {account.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box textAlign={isMobile ? "center" : "right"}>
                                <Button
                                    color="error"
                                    variant="contained"
                                    disabled={account.master !== 0 || account.id === user.accountId}
                                    onClick={() => setShowRemoveDialog(true)}
                                    endIcon={
                                        isAccountRemoving
                                            ?
                                            <CircularProgress color='inherit' size={'1rem'}/>
                                            :
                                            undefined
                                    }
                                >
                                    {t('Forbid')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    <Fragment>
                        <Alert
                            variant={"outlined"}
                            severity='error'
                            sx={{
                                width: '100%',
                                minHeight: '68px',
                                alignItems: 'center',
                                marginTop: '4px',
                            }}
                            action={
                                <Fragment>
                                    <Button
                                        color="inherit"
                                        size="small"
                                        onClick={() => setShowRemoveDialog(false)}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        color="inherit"
                                        size="small"
                                        onClick={() => removeRelatedAccountHandle(account)}>
                                        {t('Forbid')}
                                    </Button>
                                </Fragment>
                            }
                        >
                            <Typography
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    cursor: 'text',
                                    paddingRight: '12px',
                                }}
                            >
                                {isMobile ? t('?') : t('Do you really want to delete this account?')}
                            </Typography>
                        </Alert>
                    </Fragment>
            }
        </Fragment>
    )
};
