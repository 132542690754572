import React, {FC, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {isSupported} from "firebase/messaging";
import {FIREBASE_ANDROID_WEB_PUSH_DATA, FIREBASE_WEB_PUSH_DATA} from "../../utils/constants";
import {requestForToken} from "../../firebase/firebase";
import {
    getNotificationSettings,
    saveNotificationSettings,
    saveNotificationToken
} from "../../store/notification/notificationSlice";
import {getRelatedAccount} from "../../store/account/accountSlice";
import {INotificationSettings} from "../../models/notifications/INotificationSettings";
import {editProfile} from "../../store/auth/authSlice";
import {setShowBuyPremiumDialogStatus} from "../../store/subscription/subscriptionSlice";
import {IFirebaseWebPushData} from "../../models/IFirebaseWebPushData";
import {getFromStorage, saveToStorage} from "../../utils/storageHelpers";
import {Device} from "@capacitor/device";
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {setSnackbar} from "../../store/utils/utilsSlice";

const platform = require('platform');


export const NotificationSettings: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {user} = useAppSelector(state => state.auth);
    const {isMobile, themeMode} = useAppSelector(state => state.utils);
    const {notificationSettings} = useAppSelector(state => state.notification);
    const {accounts} = useAppSelector(state => state.account);
    const [currentEmailForNotification, setCurrentEmailForNotification] = useState<string>(user.emailForNotifications);
    const [disableNotificationButton, setDisableNotificationButton] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [emailNotificationMorning, setEmailNotificationMorning] = useState<boolean>(false);
    const [emailNotificationEvening, setEmailNotificationEvening] = useState<boolean>(false);
    const [webPushNotificationMorning, setWebPushNotificationMorning] = useState<boolean>(false);
    const [webPushNotificationEvening, setWebPushNotificationEvening] = useState<boolean>(false);


    useEffect(() => {
        if (!accounts) {
            dispatch(getRelatedAccount());
        }
        if (!notificationSettings.settings) {
            dispatch(getNotificationSettings())
                .unwrap()
                .then(response => {

                    setEmailNotificationMorning(response.emailNotificationMorning);
                    setEmailNotificationEvening(response.emailNotificationEvening);
                    setWebPushNotificationMorning(response.webPushNotificationMorning);
                    setWebPushNotificationEvening(response.webPushNotificationEvening);
                });
        } else {
            setEmailNotificationMorning(notificationSettings.settings.emailNotificationMorning);
            setEmailNotificationEvening(notificationSettings.settings.emailNotificationEvening);
            setWebPushNotificationMorning(notificationSettings.settings.webPushNotificationMorning);
            setWebPushNotificationEvening(notificationSettings.settings.webPushNotificationEvening);
        }
        checkNotificationStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeNotificationEmail = (event: SelectChangeEvent) => {
        if (user.level.emailNotification) {
            setCurrentEmailForNotification(event.target.value);
        } else {
            dispatch(setShowBuyPremiumDialogStatus(true));
        }
    };

    const checkNotificationStatus = () => {
        Device.getInfo()
            .then(async info => {
                if (info.platform === 'web') {
                    isSupported()
                        .then(async (isAvailable) => {
                            if (isAvailable) {
                                const storageFirebaseWebPushData = await getFromStorage(FIREBASE_WEB_PUSH_DATA);
                                if (storageFirebaseWebPushData.value) {
                                    const firebaseWebPushDataArray = JSON.parse(storageFirebaseWebPushData.value) as IFirebaseWebPushData[];
                                    const firebaseWebPushData = firebaseWebPushDataArray ? firebaseWebPushDataArray.filter(item => item.userId === user.id) : firebaseWebPushDataArray;
                                    const permission = Notification.permission;

                                    if (!firebaseWebPushData) {
                                        setDisableNotificationButton(false);
                                        return;
                                    }

                                    const firebaseWebPushDataItem = firebaseWebPushData[0];
                                    if (!firebaseWebPushDataItem.isFirebaseWebPushAllow || !firebaseWebPushDataItem.isFirebaseWebPushTokenSaved || permission !== 'granted') {
                                        setDisableNotificationButton(false);
                                    }
                                }
                            } else {
                                setDisableNotificationButton(true);
                            }
                        })
                        .catch(console.error);
                } else {
                    const storageFirebaseWebPushData = await getFromStorage(FIREBASE_ANDROID_WEB_PUSH_DATA);
                    if (storageFirebaseWebPushData.value) {
                        const firebaseWebPushDataArray = JSON.parse(storageFirebaseWebPushData.value) as IFirebaseWebPushData[];
                        const firebaseWebPushData = firebaseWebPushDataArray ? firebaseWebPushDataArray.filter(item => item.userId === user.id) : firebaseWebPushDataArray;

                        if (!firebaseWebPushData) {
                            setDisableNotificationButton(false);
                            return;
                        }

                        const firebaseWebPushDataItem = firebaseWebPushData[0];
                        if (!firebaseWebPushDataItem.isFirebaseWebPushAllow || !firebaseWebPushDataItem.isFirebaseWebPushTokenSaved) {
                            setDisableNotificationButton(false);
                        }
                    } else {
                        setDisableNotificationButton(false);
                    }
                }
            });
    }

    const onClickNotificationStatus = async () => {
        const info = await Device.getInfo();
        if (info.platform === 'web') {
            requestPermission();
        } else {
            enableMobileWebPush();
        }
    }

    const enableMobileWebPush = () => {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: Token) => {
                dispatch(saveNotificationToken({token: token.value, platform: platform.description}))
                    .unwrap()
                    .then(async originalPromiseResult => {
                        if (originalPromiseResult) {
                            await saveWebPushData({
                                isFirebaseWebPushAllow: true,
                                isFirebaseWebPushTokenSaved: true,
                                token: token.value,
                                key: FIREBASE_ANDROID_WEB_PUSH_DATA
                            });

                            dispatch(setSnackbar({
                                text: t('Registration web push notification are successful'),
                                severity: 'success',
                                open: true
                            }));

                            setDisableNotificationButton(true);
                        }
                    });
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                dispatch(setSnackbar({
                    text: 'Error on registration: ' + JSON.stringify(error),
                    severity: 'error',
                    open: true
                }));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                console.log('pushNotificationReceived');
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                console.log('pushNotificationActionPerformed');
            }
        );
    }

    const requestPermission = () => {
        console.log('1');
        Notification
            .requestPermission()
            .then(function (status) {
                console.log(status);
                if (status === 'denied') {
                    saveWebPushData({isFirebaseWebPushAllow: false, key: FIREBASE_WEB_PUSH_DATA});
                    dispatch(setSnackbar({
                        text: t('Allow notifications in your browser to continue'),
                        severity: 'error',
                        open: true
                    }));

                } else if (status === 'granted') {
                    getToken();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const getToken = () => {
        requestForToken()
            .then((currentToken) => {
                if (currentToken) {
                    dispatch(saveNotificationToken({token: currentToken, platform: platform.description}))
                        .unwrap()
                        .then(originalPromiseResult => {
                            if (originalPromiseResult) {
                                setDisableNotificationButton(true);
                                saveWebPushData({
                                    isFirebaseWebPushAllow: true,
                                    isFirebaseWebPushTokenSaved: true,
                                    token: currentToken,
                                    key: FIREBASE_WEB_PUSH_DATA
                                });
                                dispatch(setSnackbar({
                                    text: t('Registration web push notification are successful'),
                                    severity: 'success',
                                    open: true
                                }));
                            }
                        });
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            })
            .catch((err) => {
                console.error('An error occurred while retrieving token. ', err);
            });
    }

    const saveSettings = () => {
        setIsSubmitting(true);
        if (notificationSettings.settings) {
            let settings: INotificationSettings = {
                emailNotificationMorning: emailNotificationMorning,
                emailNotificationEvening: emailNotificationEvening,
                webPushNotificationMorning: webPushNotificationMorning,
                webPushNotificationEvening: webPushNotificationEvening
            };
            dispatch(saveNotificationSettings({settings: settings}))
                .unwrap()
                .then(() => {
                    dispatch(editProfile({...user, emailForNotifications: currentEmailForNotification}));
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }

    }

    const saveWebPushData = async (
        {
            isFirebaseWebPushAllow,
            isFirebaseWebPushTokenSaved,
            token,
            key
        }:
            {
                isFirebaseWebPushAllow?: boolean | undefined,
                isFirebaseWebPushTokenSaved?: boolean | undefined,
                token?: string | undefined,
                key: string
            }
    ) => {
        const storageFirebaseWebPushData = await getFromStorage(key);
        if (storageFirebaseWebPushData.value) {
            let firebaseWebPushDataArray = JSON.parse(storageFirebaseWebPushData.value) as IFirebaseWebPushData[];
            const firebaseWebPushItem = firebaseWebPushDataArray ? firebaseWebPushDataArray.filter(item => item.userId === user.id) : firebaseWebPushDataArray;

            if (firebaseWebPushItem) {
                let newFirebaseWebPushData = firebaseWebPushDataArray.filter(item => item.userId !== user.id);
                newFirebaseWebPushData.push({
                    userId: user.id,
                    isFirebaseWebPushAllow: isFirebaseWebPushAllow !== undefined ? isFirebaseWebPushAllow : firebaseWebPushItem[0].isFirebaseWebPushAllow,
                    isFirebaseWebPushTokenSaved: isFirebaseWebPushTokenSaved !== undefined ? isFirebaseWebPushTokenSaved : firebaseWebPushItem[0].isFirebaseWebPushTokenSaved,
                    token: token !== undefined ? token : firebaseWebPushItem[0].token,
                });
                await saveToStorage(key, JSON.stringify(newFirebaseWebPushData));
            } else {
                let newFirebaseWebPushDataItem: IFirebaseWebPushData = {
                    userId: user.id,
                    isFirebaseWebPushAllow: isFirebaseWebPushAllow !== undefined ? isFirebaseWebPushAllow : false,
                    isFirebaseWebPushTokenSaved: isFirebaseWebPushTokenSaved !== undefined ? isFirebaseWebPushTokenSaved : false,
                    token: token !== undefined ? token : ''
                };
                if (firebaseWebPushDataArray) {
                    firebaseWebPushDataArray.push(newFirebaseWebPushDataItem);
                    await saveToStorage(key, JSON.stringify(firebaseWebPushDataArray));
                } else {
                    await saveToStorage(key, JSON.stringify([newFirebaseWebPushDataItem]));
                }
            }
        } else {
            let newFirebaseWebPushDataItem: IFirebaseWebPushData = {
                userId: user.id,
                isFirebaseWebPushAllow: isFirebaseWebPushAllow !== undefined ? isFirebaseWebPushAllow : false,
                isFirebaseWebPushTokenSaved: isFirebaseWebPushTokenSaved !== undefined ? isFirebaseWebPushTokenSaved : false,
                token: token !== undefined ? token : ''
            };
            await saveToStorage(key, JSON.stringify([newFirebaseWebPushDataItem]));
        }
    }

    const handleWebPushNotificationEveningChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (user.level.webPushNotification) {
            setWebPushNotificationEvening(event.target.checked);
        } else {
            dispatch(setShowBuyPremiumDialogStatus(true));
        }
    }
    const handleWebPushNotificationMorningChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (user.level.webPushNotification) {
            setWebPushNotificationMorning(event.target.checked);
        } else {
            dispatch(setShowBuyPremiumDialogStatus(true));
        }
    }
    const handleEmailNotificationEveningChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (user.level.emailNotification) {
            setEmailNotificationEvening(event.target.checked);
        } else {
            dispatch(setShowBuyPremiumDialogStatus(true));
        }
    }
    const handleEmailNotificationMorningChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (user.level.emailNotification) {
            setEmailNotificationMorning(event.target.checked);
        } else {
            dispatch(setShowBuyPremiumDialogStatus(true));
        }
    }

    return (
        <Box sx={{mt: 3}}>
            <Card sx={{mt: 2, paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '0.75rem'}}>
                <CardHeader
                    sx={{
                        display: "flex",
                        overflow: "hidden",
                        "& .MuiCardHeader-content": {
                            overflow: "hidden"
                        }
                    }}
                    title={t("Notifications")}
                    titleTypographyProps={{noWrap: true}}
                />
                <Divider/>
                <CardContent>
                    <Grid container spacing={2} sx={{mt: 1}}>
                        <Grid item container
                              xs={12} md={4}
                              direction="column"
                              justifyContent="center"
                              alignItems="left">
                            <Typography variant="body1" sx={{fontWeight: '600 !important'}}>
                                {t('Notifications are sent to email')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormControl sx={{m: 1, minWidth: 120}} size="small">
                                <Select
                                    labelId="select-filled-label"
                                    id="notification-email-select-filled"
                                    value={`${currentEmailForNotification}`}
                                    onChange={handleChangeNotificationEmail}
                                    sx={{
                                        minWidth: "220px",
                                        padding: "0px",
                                        background: themeMode === 'light' ? '#F9F9F9' : 'transparent',
                                        marginTop: '0px',
                                        "& .MuiOutlinedInput-root": {
                                            padding: "0px 0px 0px 8px"
                                        },
                                        borderRadius: '0.625rem',
                                    }}
                                >
                                    {accounts && accounts.map((account) => (
                                        <MenuItem value={account.email} key={account.id}>{account.email}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item container
                              xs={12} md={4}
                              direction="column"
                              justifyContent="center"
                              alignItems="left">
                            <Typography variant="body1" sx={{fontWeight: '600 !important'}}>
                                {t('Web Push notifications on this device')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Button
                                variant="contained"
                                disabled={disableNotificationButton}
                                onClick={onClickNotificationStatus}
                                sx={{ml: 1}}
                            >
                                {t('Enable notification')}
                            </Button>
                        </Grid>
                    </Grid>

                    <Table sx={{minWidth: 250, mt: 2}} aria-label="table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Notifications')}</TableCell>
                                <TableCell align="right" size={'small'}>Email</TableCell>
                                <TableCell align="right" size={'small'}>WebPush</TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            notificationSettings.isLoading ?
                                <TableBody>
                                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell scope="row" colSpan={3}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                margin: '6px 0 18px 0'
                                            }}>
                                                <CircularProgress/>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                :
                                <TableBody>
                                    <TableRow
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            {t('Morning reminder')}
                                        </TableCell>
                                        <TableCell align="right" size={'small'}>
                                            <Checkbox
                                                id="emailNotificationMorning"
                                                onChange={handleEmailNotificationMorningChange}
                                                checked={emailNotificationMorning}
                                            />
                                        </TableCell>
                                        <TableCell align="right" size={'small'}>
                                            <Checkbox
                                                id="webPushNotificationMorning"
                                                onChange={handleWebPushNotificationMorningChange}
                                                checked={webPushNotificationMorning}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell component="th" scope="row">
                                            {t('Evening reminder')}
                                        </TableCell>
                                        <TableCell align="right" size={'small'}>
                                            <Checkbox
                                                id="emailNotificationEvening"
                                                checked={emailNotificationEvening}
                                                onChange={handleEmailNotificationEveningChange}
                                            />
                                        </TableCell>
                                        <TableCell align="right" size={'small'}>
                                            <Checkbox
                                                id="webPushNotificationEvening"
                                                checked={webPushNotificationEvening}
                                                onChange={handleWebPushNotificationEveningChange}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                        }
                    </Table>
                    <Box sx={{textAlign: isMobile ? "center" : "right"}}>
                        <Button
                            type='submit'
                            variant="contained"
                            sx={{mb: 0, marginRight: '8px'}}
                            disabled={isSubmitting}
                            endIcon={
                                isSubmitting
                                    ?
                                    <CircularProgress color='inherit' size={'1rem'}/>
                                    :
                                    undefined
                            }
                            onClick={() => {
                                saveSettings()
                            }}
                        >
                            {t('Save')}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
};
