import React, {FC} from 'react';
import {Avatar, Box, Card, CardContent, CardHeader, Divider, Grid, Typography} from '@mui/material';
import {useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import Badge from '@mui/material/Badge';
import {styled} from '@mui/material/styles';
import {ChangePassword} from "./ChangePassword";

export const SignInMethod: FC = () => {

    const {t} = useTranslation();

    const {user} = useAppSelector(state => state.auth);

    const getProviderName = (provider: string) => {
        return provider === 'local' ? 'settasks'.toUpperCase() : provider.toUpperCase();
    }

    const SmallAvatar = styled(Avatar)(({theme}) => ({
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
    }));

    return (
        <Box sx={{mt: 3}}>
            <Card sx={{mt: 2, paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '0.75rem'}}>
                <CardHeader
                    sx={{
                        display: "flex",
                        overflow: "hidden",
                        "& .MuiCardHeader-content": {
                            overflow: "hidden"
                        }
                    }}
                    title={t("SignIn method")}
                    titleTypographyProps={{noWrap: true}}
                />
                <Divider/>
                <CardContent>
                    <Grid container spacing={2} sx={{mt: 1}}>
                        <Grid item xs={2} md={2}>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                badgeContent={
                                    <SmallAvatar alt={user.name} src={user.avatar}/>
                                }
                            >
                                <Avatar alt={getProviderName(user.provider)}
                                        src={`/images/social-auth/${user.provider}.png`}/>
                            </Badge>
                        </Grid>
                        <Grid item xs={10} md={4}>
                            <Typography variant="body1" sx={{fontWeight: '600 !important'}}>
                                {user.name}
                            </Typography>
                            <Typography variant="body1">
                                {user.email}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{mt: 1, mb: 1}}/>
                    {
                        user.provider === 'local' ? <ChangePassword/> : ''
                    }

                </CardContent>
            </Card>
        </Box>
    )
};
