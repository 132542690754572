import React, {FC, useEffect, useState} from 'react';
import {useDrop} from 'react-dnd';
import {Box, LinearProgress, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {ITabDay} from '../../../models/days/ITabDay';
import {addTask, changeTaskDayDnD, changeTasksDay, deleteTask} from '../../../store/task/taskSlice';
import {useParams} from 'react-router-dom';
import {IDragTask} from '../../../models/dnd/IDragTask';
import {dndItemTypes} from '../../../models/dnd/dndItemTypes';
import {setSnackbar} from '../../../store/utils/utilsSlice';
import {ITask} from '../../../models/ITask';
import {replaceTaskInGoal} from "../../../store/goal/goalSlice";

interface ITabLabel {
    value: number | boolean,
    index: number,
    currIndex: number | boolean,
    days: ITabDay[]
}

export const TabLabel: FC<ITabLabel> = ({value, index, currIndex, days, ...props}) => {

    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const {date} = useParams();

    const {isTaskLoading} = useAppSelector(state => state.task);

    const [isShouldCopy, setIsShouldCopy] = useState<boolean>(false);

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Alt') {
            setIsShouldCopy(true);
        }
    }

    const handleKeyUp = (e: KeyboardEvent) => {
        if (e.key === 'Alt') {
            setIsShouldCopy(false);
        }
    }

    const [{isOver, canDrop}, drop] = useDrop<IDragTask, void, any>({
        accept: dndItemTypes.TASK,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: () => {
            if (date && date === days[index].date) return false;

            return true;
        },
        drop: item => {
            if (item.task.isCompleted || item.task.isDeleted) {
                dispatch(setSnackbar({
                    text: 'You cannot moving completed and deleted tasks',
                    severity: 'error',
                    open: true
                }));
                return;
            }
            if (item.task.isRecurring) {
                const tagTitles = item.task.Tags.map(tag => tag.title);
                dispatch(addTask({
                    task: {
                        ...item.task,
                        isRecurring: false,
                        frequencyId: 0,
                        recurringId: 0
                    },
                    date: days[index].date,
                    tagTitles: tagTitles
                }))
                    .unwrap()
                    .then(() => {
                        dispatch(deleteTask(item.task.id))
                            .unwrap()
                            .then(originalPromiseResult => {
                                let keys = Object.keys(originalPromiseResult);
                                let savedTask = originalPromiseResult[keys[0]] as ITask;
                                if (savedTask.Goals?.length) {
                                    const goalsIds = savedTask.Goals.map(goal => goal.id);
                                    dispatch(replaceTaskInGoal({
                                        goalIds: goalsIds,
                                        task: {...savedTask, isDeleted: true}
                                    }));
                                }
                            })
                            .then(() => {
                                dispatch(setSnackbar({
                                    text: 'Task moved successfully',
                                    severity: 'success',
                                    open: true
                                }));
                            });
                    });
                return;
            }
            if (isShouldCopy) {
                const tagTitles = item.task.Tags.map(tag => tag.title);
                const newTask = {
                    title: item.task.title,
                    description: item.task.description,
                    isCompleted: item.task.isCompleted,
                    isDeleted: item.task.isDeleted
                } as ITask;

                dispatch(addTask({
                    task: newTask,
                    date: days[index].date,
                    tagTitles: tagTitles
                }))
                    .unwrap()
                    .then(() => {
                        dispatch(setSnackbar({
                            text: 'Task copied successfully',
                            severity: 'success',
                            open: true
                        }));
                    });

                return;
            }
            if (item.task.id && date) {
                dispatch(changeTaskDayDnD({
                    oldDate: date,
                    task: item.task,
                    newDayDate: days[index].date,
                }));
                dispatch(changeTasksDay({
                    date: days[index].date,
                    taskId: item.task.id,
                    orderId: item.task.Order.id,
                    listType: 0
                }));
            }
        }
    });

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        }
    }, []);

    let backgroundColor = 'inherit';
    let border = '2px dashed transparent';
    let borderLeftColor = '';
    if (isOver && canDrop) {
        backgroundColor = theme.palette.dragAndDropBackgroundColor.main;
    }
    if (canDrop) {
        border = '2px dashed';
        if (index !== 0 && index !== (Number(currIndex) + 1)) {
            borderLeftColor = 'transparent';
        }
    }

    return (
        <Box
            ref={drop}
            sx={{
                width: '100%',
                padding: '8px 16px',
                backgroundColor: backgroundColor,
                border: border,
                borderLeftColor: borderLeftColor,
                borderRadius: '0.75rem'
            }}
        >
            <Typography>
                <LinearProgress
                    variant={isTaskLoading ? 'indeterminate' : 'determinate'}
                    value={100}
                    sx={{opacity: isTaskLoading && index === value ? 1 : 0}}
                />
                {t(days[index].name)}
            </Typography>
            <Typography variant='caption' sx={{fontSize: '9px'}}>
                {days[index].date}
            </Typography>
        </Box>
    )
}
