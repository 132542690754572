import React, {FC, Fragment, useState} from 'react';
import {
    Avatar,
    Button,
    ButtonBase,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import {Box} from '@mui/system';
import {Logout} from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import PersonIcon from '@mui/icons-material/Person';
import PaidIcon from '@mui/icons-material/Paid';
import {NavLink, useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {userLinks} from '../../router/links';
import {logoutAll} from '../../store/auth/authSlice';
import {resetTasks} from '../../store/task/taskSlice';
import {useTranslation} from 'react-i18next';
import {resetNotes} from '../../store/note/noteSlice';
import {resetGoals} from '../../store/goal/goalSlice';
import {resetKicker} from '../../store/kicker/kickerSlice';
import {IUser} from "../../models/IUser";
import Icon from '@mui/material/Icon';
import {TMode} from "../../models/utils/TMode";
import {resetAccounts} from "../../store/account/accountSlice";
import {resetNotification} from "../../store/notification/notificationSlice";
import {resetSubscriptionData} from "../../store/subscription/subscriptionSlice";

interface IProps {
    isAuth: boolean,
    user: IUser
}

export const NavUser: FC<IProps> = ({isAuth, user}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {pathname} = useLocation();
    const theme = useTheme();

    const {mode} = useAppSelector(state => state.utils);

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const isPrefersDarkMode = () => {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    const defineMode = (mode: TMode) => {
        if (mode === 'auto') {
            return isPrefersDarkMode() ? 'dark' : 'light';
        }

        return mode;
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const submitLogout = () => {
        try {
            handleCloseUserMenu();
            dispatch(logoutAll())
                .unwrap()
                .then(originalPromiseResult => {
                    if (originalPromiseResult) {
                        dispatch(resetTasks());
                        dispatch(resetGoals());
                        dispatch(resetNotes());
                        dispatch(resetKicker());
                        dispatch(resetKicker());
                        dispatch(resetNotification());
                        dispatch(resetAccounts());
                        dispatch(resetSubscriptionData());
                    }
                });
        } catch (e) {
            console.log(e);
        }
    }

    const getMenuIconName = (linkName: string) => {
        switch (linkName) {
            case 'Profile' :
                return <PersonIcon/>;
            case 'Subscription' :
                return <PaidIcon/>;
            default:
                return <StarOutlineIcon/>;
        }
    }

    return (
        <Fragment>
            {isAuth
                ?
                <Box sx={{flexGrow: 0}}>
                    <Tooltip title={t("Open settings")}>
                        <IconButton onClick={handleOpenUserMenu} sx={{p: 1}}>
                            <Avatar alt={user.name} src={user.avatar} sx={{width: 32, height: 32}}/>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{mt: '45px'}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    backgroundColor: defineMode(mode) === 'light' ? 'rgb(255, 255, 255)' : "#121212",
                                    backgroundImage: defineMode(mode) === 'light' ? '' : "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                                backgroundColor: defineMode(mode) === 'light' ? '' : "#121212",
                                backgroundImage: defineMode(mode) === 'light' ? '' : "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                                borderRadius: '0.75rem',
                                '& .MuiMenuItem-root': {
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                },
                            },
                        }}
                    >
                        <MenuItem>
                            <Avatar alt={user.name} src={user.avatar}/>
                            <Box>
                                <Typography noWrap
                                            variant="inherit"
                                            sx={{
                                                fontWeight: '600 !important',
                                                fontSize: '1.15rem !important'
                                            }}>
                                    {user.name}
                                </Typography>
                                <Typography variant="body1"
                                            color="text.secondary"
                                            sx={{
                                                fontSize: '0.7rem'
                                            }}>
                                    {user.email}
                                </Typography>
                            </Box>
                        </MenuItem>
                        <Divider/>
                        {userLinks.map(link => (
                            link.auth &&
                            <MenuItem
                                component={NavLink}
                                to={link.path}
                                selected={link.path === pathname}
                                onClick={handleCloseUserMenu}
                                key={link.name}
                                sx={{
                                    '&:hover': {
                                        color: theme.palette.primary.main,
                                    },
                                }}
                            >
                                <ListItemIcon>
                                    {getMenuIconName(link.name)}
                                </ListItemIcon>
                                <Typography textAlign="center">{t(link.name)}</Typography>
                            </MenuItem>
                        ))}
                        <Divider/>
                        <MenuItem
                            style={{textDecoration: 'none', color: 'inherit'}}
                            onClick={submitLogout}
                            key={'logout'}
                        >
                            <ListItemIcon>
                                <Logout fontSize="small"/>
                            </ListItemIcon>
                            <Typography textAlign="center"
                                        sx={{
                                            '&:hover': {
                                                color: theme.palette.primary.main,
                                            },
                                        }}
                            >{t('Logout')}</Typography>
                        </MenuItem>
                    </Menu>
                </Box>
                :
                <Box sx={{flexGrow: 0, display: 'flex'}}>
                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0, display: {xs: 'flex', md: 'none'}}}>
                        <Icon style={{color: '#ffffff'}}><MoreVertIcon/></Icon>
                    </IconButton>
                    <Menu
                        sx={{mt: '45px', display: {xs: 'flex', md: 'none'}}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {userLinks.map(link => (
                            !link.auth &&
                            <MenuItem
                                component={NavLink}
                                to={link.path}
                                selected={link.path === pathname}
                                style={{textDecoration: 'none', color: 'inherit'}}
                                onClick={handleCloseUserMenu}
                                key={link.name}
                            >
                                <Typography textAlign="center">{t(link.name)}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                    <Stack direction="row" sx={{display: {xs: 'none', md: 'flex'}}}>
                        {userLinks.map(link => (
                            !link.auth &&
                            <ButtonBase component='div' key={link.name}>
                                <NavLink
                                    to={link.path}
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                    key={link.name}
                                >
                                    <Button
                                        sx={{py: '21px', color: 'white', display: 'block'}}
                                        disableRipple={true}
                                    >
                                        {t(link.name)}
                                    </Button>
                                </NavLink>
                            </ButtonBase>
                        ))}
                    </Stack>
                </Box>
            }
        </Fragment>
    )
}
