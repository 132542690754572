import {AxiosResponse} from "axios";
import $api from "../http";
import {ICheckSubscriptionResponse} from "../models/response/ICheckSubscriptionResponse";
import {IFreeTrialSubscriptionResponse} from "../models/response/IFreeTrialSubscriptionResponse";
import {ILevel} from "../models/subscription/ILevel";

const PAYMENT_API_URL = process.env.REACT_APP_PAYMENT_API_URL;

export const canGetTrialFreeSubscriptionAPI = async (): Promise<AxiosResponse<ICheckSubscriptionResponse>> => {
    return await $api.get<ICheckSubscriptionResponse>(`${PAYMENT_API_URL}/subscription/can-get-trial-free-level`);
}

export const getTrialFreeSubscriptionAPI = async (): Promise<AxiosResponse<IFreeTrialSubscriptionResponse>> => {
    return await $api.get<IFreeTrialSubscriptionResponse>(`${PAYMENT_API_URL}/subscription/subscribe-trial-free-level`);
}

export const getSubscriptionsAPI = async (): Promise<AxiosResponse<ILevel[]>> => {
    return await $api.get<ILevel[]>(`${PAYMENT_API_URL}/subscription/subscriptions`);
}

export const changeSubscriptionAPI = async (levelId: number): Promise<AxiosResponse<IFreeTrialSubscriptionResponse>> => {
    return await $api.post<IFreeTrialSubscriptionResponse>(`${PAYMENT_API_URL}/subscription/change-subscribe-level`, {levelId: levelId,});
}
