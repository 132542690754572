import React, { FC, useEffect, useRef } from 'react';

interface IProps {
    intersect: () => void,
    observing: boolean
}

export const AppObserver: FC<IProps> = ({ intersect, observing, ...props }) => {

    const observableElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry && entry.isIntersecting) {
                intersect();
            }
        });

        const node = observableElement.current as any;
        observer.observe(node);
        
        return () => {
            observer.unobserve(node);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [observing]);


    return (
        <div
            ref={observableElement}
            style={{ height: '1px' }}
        >
        </div>
    )
}
