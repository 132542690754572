import React, {FC, useRef, useState} from 'react';
import {Box, Button, TextField} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {addTags} from '../../store/task/taskSlice';
import {setShowBuyPremiumDialogStatus} from "../../store/subscription/subscriptionSlice";

export const TagAddInput: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {tags} = useAppSelector(state => state.task);
    const {user} = useAppSelector(state => state.auth);

    const inputRef = useRef<HTMLDivElement>(null);
    const [title, setTitle] = useState<string>('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    }

    const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') handleAdd();
    }

    const handleAdd = () => {
        if (title.trim() === '') {
            dispatch(setSnackbar({
                text: 'Tag cannot be empty',
                severity: 'error',
                open: true
            }));

            return;
        }

        const tagTitles = tags.tagList.map(tag => tag.title);
        if (tagTitles.includes(title.trim().split(' ').join('-').toLowerCase())) {
            dispatch(setSnackbar({
                text: 'Tag with this title already exist',
                severity: 'error',
                open: true
            }));

            return;
        }

        if (tags.tagList.length >= user.level.listsMaxCount) {
            dispatch(setShowBuyPremiumDialogStatus(true));
            return;
        }

        dispatch(addTags([title]));
        setTitle('');
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <TextField
                inputRef={inputRef}
                autoFocus={isMobile ? false : true}
                fullWidth
                label={`${t('+ Add list')}`}
                size='small'
                variant="outlined"
                sx={{
                    marginRight: '6px',
                    '& .MuiInputBase-root': {
                        borderRadius: '0.5rem'
                    }
                }}
                InputProps={{
                    autoComplete: 'off'
                }}
                value={title}
                onChange={handleChange}
                onKeyPress={handleEnterPress}
            />
            <Button
                variant="contained"
                onClick={handleAdd}
            >
                {t('Add')}
            </Button>
        </Box>
    )
}
