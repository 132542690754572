import React, {FC, useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from '@mui/material';
import {saveNotificationToken} from "../../store/notification/notificationSlice";
import {FIREBASE_ANDROID_WEB_PUSH_DATA} from "../../utils/constants";
import {useTranslation} from "react-i18next";
import {IFirebaseWebPushData} from '../../models/IFirebaseWebPushData';
import {getFromStorage, saveToStorage} from "../../utils/storageHelpers";
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {setSnackbar} from "../../store/utils/utilsSlice";

const platform = require('platform');

export const NotificationRequestDialogMobile: FC = () => {

    const {t} = useTranslation();
    const {user, isAuth} = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (isAuth) {
            getFromStorage(FIREBASE_ANDROID_WEB_PUSH_DATA)
                .then(firebasePushAndroidAllow => {
                    if (!firebasePushAndroidAllow.value) {
                        setOpen(true);
                    }
                });
        }

    }, []);

    const handleClose = async () => {
        setOpen(false);
        await saveWebPushData({isFirebaseWebPushAllow: false});
    };

    const register = () => {
        setOpen(false);

        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: Token) => {
                dispatch(saveNotificationToken({token: token.value, platform: platform.description}))
                    .unwrap()
                    .then(async originalPromiseResult => {
                        if (originalPromiseResult) {
                            await saveWebPushData({
                                isFirebaseWebPushAllow: true,
                                isFirebaseWebPushTokenSaved: true,
                                token: token.value
                            });

                            dispatch(setSnackbar({
                                text: t('Registration web push notification are successful'),
                                severity: 'success',
                                open: true
                            }));
                        }
                    });
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error: any) => {
                dispatch(setSnackbar({
                    text: 'Error on registration: ' + JSON.stringify(error),
                    severity: 'error',
                    open: true
                }));
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                console.log('pushNotificationReceived');
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
                console.log('pushNotificationActionPerformed');
            }
        );
    }

    const saveWebPushData = async (
        {
            isFirebaseWebPushAllow,
            isFirebaseWebPushTokenSaved,
            token,
        }:
            {
                isFirebaseWebPushAllow?: boolean | undefined,
                isFirebaseWebPushTokenSaved?: boolean | undefined,
                token?: string | undefined,
            }
    ) => {
        const storageFirebaseWebPushData = await getFromStorage(FIREBASE_ANDROID_WEB_PUSH_DATA);
        if (storageFirebaseWebPushData.value) {
            let firebaseWebPushDataArray = JSON.parse(storageFirebaseWebPushData.value) as IFirebaseWebPushData[];
            const firebaseWebPushItem = firebaseWebPushDataArray ? firebaseWebPushDataArray.filter(item => item.userId === user.id) : firebaseWebPushDataArray;

            if (firebaseWebPushItem && firebaseWebPushItem[0]) {
                let newFirebaseWebPushData = firebaseWebPushDataArray.filter(item => item.userId !== user.id);
                newFirebaseWebPushData.push({
                    userId: user.id,
                    isFirebaseWebPushAllow: isFirebaseWebPushAllow !== undefined ? isFirebaseWebPushAllow : firebaseWebPushItem[0].isFirebaseWebPushAllow,
                    isFirebaseWebPushTokenSaved: isFirebaseWebPushTokenSaved !== undefined ? isFirebaseWebPushTokenSaved : firebaseWebPushItem[0].isFirebaseWebPushTokenSaved,
                    token: token !== undefined ? token : firebaseWebPushItem[0].token,
                });
                await saveToStorage(FIREBASE_ANDROID_WEB_PUSH_DATA, JSON.stringify(newFirebaseWebPushData));
            } else {
                let newFirebaseWebPushDataItem: IFirebaseWebPushData = {
                    userId: user.id,
                    isFirebaseWebPushAllow: isFirebaseWebPushAllow !== undefined ? isFirebaseWebPushAllow : false,
                    isFirebaseWebPushTokenSaved: isFirebaseWebPushTokenSaved !== undefined ? isFirebaseWebPushTokenSaved : false,
                    token: token !== undefined ? token : ''
                };
                if (firebaseWebPushDataArray) {
                    firebaseWebPushDataArray.push(newFirebaseWebPushDataItem);
                    await saveToStorage(FIREBASE_ANDROID_WEB_PUSH_DATA, JSON.stringify(firebaseWebPushDataArray));
                } else {
                    await saveToStorage(FIREBASE_ANDROID_WEB_PUSH_DATA, JSON.stringify([newFirebaseWebPushDataItem]));
                }
            }
        } else {
            let newFirebaseWebPushDataItem: IFirebaseWebPushData = {
                userId: user.id,
                isFirebaseWebPushAllow: isFirebaseWebPushAllow !== undefined ? isFirebaseWebPushAllow : false,
                isFirebaseWebPushTokenSaved: isFirebaseWebPushTokenSaved !== undefined ? isFirebaseWebPushTokenSaved : false,
                token: token !== undefined ? token : ''
            };
            await saveToStorage(FIREBASE_ANDROID_WEB_PUSH_DATA, JSON.stringify([newFirebaseWebPushDataItem]));
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Notifications')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('Хотите получать ежедневно уведомления о задачах и контролировать процесс их выполнения?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">{t('No')}</Button>
                    <Button onClick={register} variant="contained">{t('Yes')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
