import React, {FC, useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, Container, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {accountActivation} from "../../store/auth/authSlice";
import {useAppDispatch} from "../../hooks/redux";

export const AccountActivation: FC = (props) => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [showError, setShowError] = useState<boolean>(false);
    let {confirmHash} = useParams();

    useEffect(() => {
        if (confirmHash) {
            dispatch(accountActivation({
                confirmHash: confirmHash,
            }))
                .unwrap()
                .then((originalPromiseResult) => {
                    navigate(`/account-select-mode`, {replace: true});
                })
                .catch((error) => {
                    console.error(error.msg);
                    setShowError(true);
                });
        } else {
            navigate(`/`, {replace: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToHome = () => {
        navigate(`/`, {replace: true});
    }

    return (
        <Container maxWidth="lg">
            <Box>
                <Card sx={{mt: 3, borderRadius: '0.625rem !important'}}>
                    <CardContent>
                        {!showError &&
                            <Typography variant="h5" gutterBottom>
                                {t('Checking activation code...')}
                            </Typography>
                        }

                        {showError &&
                            <Box>
                                <Typography variant="h5" gutterBottom>
                                    {t('Account activation')}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {t('Unfortunately, the account of activation of the account is outdated or not valid. If you have difficulty registration, contact the support service.')}
                                </Typography>
                                <Box textAlign='center'>
                                    <Button
                                        variant="contained"
                                        onClick={goToHome}
                                        sx={{
                                            mt: 3,
                                            mb: 2,
                                            borderRadius: '0.75rem',
                                            padding: 'calc(0.775rem + 1px) calc(1.5rem + 1px)',
                                            textTransform: 'none',
                                            fontSize: '1.1rem',
                                            lineHeight: '1'
                                        }}
                                    >
                                        {t('Go to home page')}
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </CardContent>
                </Card>
            </Box>
        </Container>
    )
};
