import React, {FC, useEffect, useState} from 'react';
import {Avatar, Box, Button, Card, CircularProgress, Container, Grid, Typography} from '@mui/material';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {IActivationAccount} from "../../models/IActivationAccount";
import {saveRelatedAccount} from "../../store/auth/authSlice";
import CardContent from "@mui/material/CardContent";
import {setSnackbar} from "../../store/utils/utilsSlice";
import {getFromStorage, removeItemFromStorage, saveToStorage} from "../../utils/storageHelpers";

export const RelatedAccountMode: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {isAuthLoading} = useAppSelector(state => state.auth);
    const [relatedAccounts, setRelatedAccounts] = useState<IActivationAccount[]>([]);

    useEffect(() => {
        const runUseEffect = async () => {
            await getFromStorage('relatedAccounts')
                .then(storageRelatedAccounts => {
                    if (storageRelatedAccounts.value) {
                        let relatedAccounts = JSON.parse(storageRelatedAccounts.value) as IActivationAccount[];
                        setRelatedAccounts(relatedAccounts);
                    }
                });
        };
        runUseEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const allowRelatedAccount = (account: IActivationAccount) => {
        dispatch(saveRelatedAccount({
            id: account.id,
            name: account.name,
            provider: account.provider,
            confirmHash: account.confirmHash
        }))
            .unwrap()
            .then((originalPromiseResult) => {
                if (originalPromiseResult.status) {
                    removeRelatedAccount(account.id);
                }
            })
            .catch((error) => {
                console.error(error.msg);
                dispatch(setSnackbar({
                    text: error.msg,
                    severity: 'error',
                    open: true
                }));
            });
    }

    const removeRelatedAccount = async (accountId: number) => {
        await getFromStorage('relatedAccounts')
            .then(async storageRelatedAccounts => {
                if (storageRelatedAccounts.value) {
                    let relatedAccounts = JSON.parse(storageRelatedAccounts.value) as IActivationAccount[];
                    relatedAccounts = relatedAccounts.filter(ac => ac.id !== accountId);
                    setRelatedAccounts(relatedAccounts);
                    if (relatedAccounts.length) {
                        await saveToStorage('relatedAccounts', JSON.stringify(relatedAccounts));
                    } else {
                        await removeItemFromStorage('relatedAccounts');
                        navigate(`/tasks`, {replace: true});
                    }
                }
            });

    }

    const forbiddenRelatedAccount = (account: IActivationAccount) => {
        removeRelatedAccount(account.id);
    }

    const getProviderName = (provider: string) => {
        return provider === 'local' ? 'settasks'.toUpperCase() : provider.toUpperCase();
    }

    return (
        <Container maxWidth="lg">
            <Box>
                <Card sx={{mt: 3, borderRadius: '0.625rem !important'}}>
                    <CardContent sx={{padding: '1rem 2.5rem 2.5rem 2.5rem',}}>
                        <Typography variant="h5" gutterBottom>
                            {t('Bind the accounts')}
                        </Typography>
                        {relatedAccounts.map((account) => (
                            <Grid container spacing={2} sx={{mt: 1}} key={account.id}>
                                <Grid item xs={2} md={2}>
                                    <Avatar alt="{account.provider}"
                                            variant="square"
                                            src={`/images/social-auth/${account.provider}.png`}/>
                                </Grid>
                                <Grid item xs={10} md={4}>
                                    <Typography variant="body1">
                                        {getProviderName(account.provider)}
                                    </Typography>
                                    <Typography variant="body1">
                                        {account.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box textAlign={"right"}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={() => allowRelatedAccount(account)}
                                            endIcon={
                                                isAuthLoading
                                                    ?
                                                    <CircularProgress color='inherit' size={'1rem'}/>
                                                    :
                                                    undefined
                                            }
                                        >
                                            {t('Allow')}
                                        </Button>
                                        <Button
                                            color="error"
                                            variant="contained"
                                            sx={{ml: 1}}
                                            onClick={() => forbiddenRelatedAccount(account)}
                                            endIcon={
                                                isAuthLoading
                                                    ?
                                                    <CircularProgress color='inherit' size={'1rem'}/>
                                                    :
                                                    undefined
                                            }
                                        >
                                            {t('Forbid')}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        ))}
                    </CardContent>
                </Card>
            </Box>
        </Container>
    )
};
