import React, {FC, useEffect, useState} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    TextField,
    useTheme
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {ITask} from '../../models/ITask';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {editRecurringTask, editTask, getUserTags, setRecurring} from '../../store/task/taskSlice';
import {addTaskToGoal, getGoals} from '../../store/goal/goalSlice';
import {
    calculateNearestTimeFromSchedule,
    flatArrayOfObjects,
    formatDate,
    getTimeSchedule,
    hasDuplicates
} from '../../utils/helpers';
import {IRecurringFormFields} from '../../models/validation/IRecurringFormFields';
import {RecurringAddForm} from './RecurringAddForm';
import {recurringAPI} from '../../services/TaskService';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {resetKicker} from '../../store/kicker/kickerSlice';
import {RemindOptionsDialog} from './RemindOptionsDialog';
import {MAX_REMINDERS_COUNT} from '../../utils/constants';
import {IRemindOptions} from '../../models/notifications/IRemindOptions';
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

interface ITaskEditDialogProps {
    open: boolean,
    task: ITask,
    date: string,
    onClose: () => void
}

const isValidationNeeded = (recurringConfig: IRecurringFormFields): boolean => {
    if (Number(recurringConfig.recurringType) === 1 && !recurringConfig.dayOfWeek.length) return true;
    if (Number(recurringConfig.recurringType) === 2 && !recurringConfig.dayOfMonth.length) return true;
    if (Number(recurringConfig.recurringType) === 3 && (!recurringConfig.dayOfMonth.length || !recurringConfig.monthOfYear.length)) return true

    return false;
}

const getInitialRemindOptions = (notifications: any, nearestTimeFromSchedule: string, isEvent: boolean, date?: string): IRemindOptions => {
    const remindersActive = [];
    const notifyAt: string[] = [];
    const dates = [];
    const dayDiff: number[] = [];
    const notificationMethods: number[] = [];

    if (notifications && notifications.length) {
        if (isEvent && notifications[0].hasOwnProperty("notifyTime") && notifications[0].hasOwnProperty("dayDiff")) {
            for (let i = 0; i < notifications.length; i++) {
                const duplicate = notifyAt.find((time, index) => {
                    return (time + dayDiff[index]) === (notifications[i].notifyTime.slice(0, 5) + notifications[i].dayDiff)
                });
                if (duplicate === undefined) {
                    remindersActive.push(remindersActive.length + 1);
                    notifyAt.push(notifications[i].notifyTime.slice(0, 5));
                    dayDiff.push(notifications[i].dayDiff)
                }
            }
        } else {
            const duplicatesCheck = [];
            for (let i = 0; i < notifications.length; i++) {
                const duplicate = duplicatesCheck.find(time => {
                    return time === notifications[i].notifyAt;
                });
                if (duplicate === undefined) {
                    remindersActive.push(i + 1);
                    duplicatesCheck.push(new Date(notifications[i].notifyAt).toISOString());
                    notifyAt.push(new Date(notifications[i].notifyAt).toTimeString().slice(0, 5));
                    dates.push(new Date(notifications[i].notifyAt.split('T')[0]));
                }
            }
        }
    }

    if (notifications) {
        notifications.map((notification: any) => {
            return notificationMethods.push(notification.notificationMethodId);
        });
    }

    while (notifyAt.length !== MAX_REMINDERS_COUNT) {
        notifyAt.push(nearestTimeFromSchedule);
    }

    while (dates.length !== MAX_REMINDERS_COUNT) {
        if (date) {
            dates.push(new Date(date));
        } else {
            dates.push(new Date());
        }
    }

    while (dayDiff.length !== MAX_REMINDERS_COUNT) {
        dayDiff.push(0);
    }

    return {
        isRemind: notifications && notifications.length ? true : false,
        remindMethodId: notificationMethods.length ? Array.from(new Set(notificationMethods)) : [2],
        remindersActive: remindersActive.length ? remindersActive : [1],
        notifyAt: notifyAt,
        date: dates,
        daysDiff: dayDiff.length ? dayDiff : [0, 0, 0]
    }
}

const prepareInitialValues = (task: ITask) => {
    if (task.isRecurring && task.RecurringEvent && task.RecurringEvent.Frequencies?.length) {
        return {
            isRecurring: true,
            recurringType: String(task.RecurringEvent.Frequencies[0].type),
            dayOfWeek: task.RecurringEvent.Frequencies.filter(frequency => frequency.dayOfWeek).map(frequency => frequency.dayOfWeek) as number[],
            dayOfMonth: Array.from(new Set(task.RecurringEvent.Frequencies.filter(frequency => frequency.dayOfMonth).map(frequency => frequency.dayOfMonth) as number[])),
            monthOfYear: Array.from(new Set(task.RecurringEvent.Frequencies.filter(frequency => frequency.monthOfYear !== null).map(frequency => frequency.monthOfYear) as number[])),
            startDate: new Date(task.RecurringEvent.startDate),
            endDate: task.RecurringEvent.endDate ? new Date(task.RecurringEvent.endDate) : null
        }
    }

    return undefined;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export const TaskEditDialog: FC<ITaskEditDialogProps> = ({
                                                             open,
                                                             task,
                                                             date,
                                                             onClose,
                                                             ...props
                                                         }) => {

    const theme = useTheme();
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {user} = useAppSelector(state => state.auth);
    const {isMobile} = useAppSelector(state => state.utils);
    const {tags} = useAppSelector(state => state.task);
    const {goals} = useAppSelector(state => state.goal);

    const timeSchedule = getTimeSchedule();
    const nearestTimeFromSchedule = calculateNearestTimeFromSchedule(user.timezoneName, timeSchedule);

    const [editableTask, setEditableTask] = useState<ITask>(task);
    const [tagTitles, setTagTitles] = useState<string[]>([]);
    const [goalTitles, setGoalTitles] = useState<string[]>([]);
    const [recurringConfig, setRecurringConfig] = useState<IRecurringFormFields>({} as IRecurringFormFields);
    const [validateCounter, setValidateCounter] = useState<number>(0);
    const [sendCounter, setSendCounter] = useState<number>(0);
    const [formKey, setFormKey] = useState<number>(0);
    const [isRecurringLoading, setIsRecurringLoading] = useState<boolean>(task.isRecurring && !task.RecurringEvent);
    const [isOpenRemindDialog, setIsOpenRemindDialog] = useState<boolean>(false);
    const [remindOptions, setRemindOptions] = useState<IRemindOptions>(getInitialRemindOptions(task.TaskNotifications, nearestTimeFromSchedule, task.isRecurring, date));

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
        setEditableTask(prevState => {
            return {
                ...prevState,
                [field]: e.target.value
            }
        });
    }

    const handleChangeTagTitles = (event: any, value: string[]) => {
        setTagTitles(value);
    }

    const handleChangeGoalTitles = (event: any, value: string[]) => {
        setGoalTitles(value);
    }

    const handleChangeImportant = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditableTask(prevState => {
            return {
                ...prevState,
                isImportant: e.target.checked
            }
        });
    }

    const handleOpenTags = () => {
        if (!tags.fetched) {
            dispatch(getUserTags(false));
        }
    }

    const handleOpenGoals = () => {
        if (!goals.length) {
            dispatch(getGoals());
        }
    }

    const recurringConfigHandle = (config: IRecurringFormFields) => {
        if (!recurringConfig.isRecurring && config.isRecurring) {
            if (task.EventNotifications && task.EventNotifications.length) {
                setRemindOptions(getInitialRemindOptions(task.EventNotifications, nearestTimeFromSchedule, true, date));
            } else {
                setRemindOptions({
                    isRemind: false,
                    remindMethodId: [2],
                    remindersActive: [1],
                    notifyAt: [nearestTimeFromSchedule, nearestTimeFromSchedule, nearestTimeFromSchedule],
                    date: [new Date(), new Date(), new Date()],
                    daysDiff: [0, 0, 0]
                });
            }
        } else if (recurringConfig.isRecurring && !config.isRecurring) {
            if (task.TaskNotifications && task.TaskNotifications.length) {
                setRemindOptions(getInitialRemindOptions(task.TaskNotifications, nearestTimeFromSchedule, false, date));
            } else {
                setRemindOptions({
                    isRemind: false,
                    remindMethodId: [2],
                    remindersActive: [1],
                    notifyAt: [nearestTimeFromSchedule, nearestTimeFromSchedule, nearestTimeFromSchedule],
                    date: [new Date(), new Date(), new Date()],
                    daysDiff: [0, 0, 0]
                });
            }
        }
        setRecurringConfig(config);
    }

    const handleCancelClick = () => {
        setEditableTask(task);
        onClose();
    }

    const handleSaveClick = () => {
        if (editableTask.title.trim() === '') {
            dispatch(setSnackbar({
                text: 'Task title cannot be empty',
                severity: 'error',
                open: true
            }));
            return;
        }

        if (recurringConfig.isRecurring) {
            if (isValidationNeeded(recurringConfig)) {
                setValidateCounter(validateCounter + 1);
                return;
            }
        }

        let times: string[] = [];
        let notifyAt: string[] = [];
        if (!recurringConfig.isRecurring && remindOptions.isRemind) {
            if (!remindOptions.remindMethodId.length) {
                dispatch(setSnackbar({
                    text: 'You have not selected a reminder method',
                    severity: 'error',
                    open: true
                }));
                return;
            }
            for (let i = 0; i < MAX_REMINDERS_COUNT; i++) {
                if (remindOptions.remindersActive.includes(i + 1)) {
                    const temp = remindOptions.date[i];
                    temp.setMilliseconds(0);
                    temp.setSeconds(0);
                    temp.setMinutes(Number(remindOptions.notifyAt[i].slice(3, 5)));
                    temp.setHours(Number(remindOptions.notifyAt[i].slice(0, -3)));
                    notifyAt.push(temp.toString());
                }
            }
            if (hasDuplicates(notifyAt)) {
                dispatch(setSnackbar({
                    text: 'Your notification settings are duplicating',
                    severity: 'error',
                    open: true
                }));
                return;
            }
        } else if (recurringConfig.isRecurring && remindOptions.isRemind) {
            if (!remindOptions.remindMethodId.length) {
                dispatch(setSnackbar({
                    text: 'You have not selected a reminder method',
                    severity: 'error',
                    open: true
                }));
                return;
            }
            const duplicatesCheckArr: any = [];
            remindOptions.remindersActive.map((remind, i) => {
                return duplicatesCheckArr.push(remindOptions.daysDiff[i] + remindOptions.notifyAt[i]);
            });
            if (hasDuplicates(duplicatesCheckArr)) {
                dispatch(setSnackbar({
                    text: 'Your notification settings are duplicating',
                    severity: 'error',
                    open: true
                }));
                return;
            }

            times = remindOptions.notifyAt.map(time => {
                const date = new Date();
                date.setHours(Number(time[0] + time[1]));
                date.setMinutes(Number(time[3] + time[4]));
                date.setSeconds(0);
                return formatDate(String(date)).split(" ")[1];
            });
        }

        const mapedTaskTagTitles = task.Tags.map(tag => tag.title);
        const mapedTaskGoalTitles = task.Goals?.map(goal => goal.title) || [];

        const uniqueTagTitles = tagTitles.filter(tagTitle => !mapedTaskTagTitles.includes(tagTitle));
        const uniqueGoalTitles = goalTitles.filter(goalTitle => !mapedTaskGoalTitles.includes(goalTitle));

        const removedTags = task.Tags.filter(tag => !tagTitles.includes(tag.title)).map(tag => tag.id);
        const removedGoals = task.Goals?.filter(goal => !goalTitles.includes(goal.title)).map(goal => goal.id) || [];

        if (task.isRecurring) {
            dispatch(editRecurringTask({
                task: {...editableTask, recurringId: task.recurringId},
                date: date,
                recurringConfig: recurringConfig,
                tagTitles: uniqueTagTitles,
                goalTitles: uniqueGoalTitles,
                removedTags: removedTags,
                removedGoals: removedGoals,
                remindConfig: remindOptions.isRemind ? {...remindOptions, notifyAt: times} : undefined,
            }))
                .unwrap()
                .then(() => {
                    setSendCounter(sendCounter + 1);
                    dispatch(resetKicker());
                });
            onClose();
            return;
        }

        dispatch(editTask({
            task: editableTask,
            notifyAt: notifyAt,
            notificationMethods: remindOptions.remindMethodId,
            tagTitles: uniqueTagTitles,
            goalTitles: uniqueGoalTitles,
            removedTags: removedTags,
            removedGoals: removedGoals,
            recurringConfig: recurringConfig,
            remindConfig: remindOptions.isRemind ? {...remindOptions, notifyAt: times} : undefined,
        }))
            .unwrap()
            .then(originalPromiseResult => {
                let keys = Object.keys(originalPromiseResult);
                let savedTask = originalPromiseResult[keys[0]] as ITask;
                if (savedTask.Goals?.length) {
                    const goalsIds = savedTask.Goals.map(goal => goal.id);
                    dispatch(addTaskToGoal({goalIds: goalsIds, task: savedTask}));
                }
            })
            .then(onClose);
    }

    const handleRemindClick = () => {
        setIsOpenRemindDialog(true);
    }

    const handleRemindClose = () => {
        setIsOpenRemindDialog(false);
    }

    const handleRemindOptions = (options: IRemindOptions) => {
        setRemindOptions(options);
    }

    useEffect(() => {
        setTagTitles(task.Tags.map(tag => tag.title));
    }, [task.Tags]);

    useEffect(() => {
        setGoalTitles(task.Goals?.map(goal => goal.title) || []);
    }, [task.Goals]);

    useEffect(() => {
        if (!open || !task.isRecurring || task.RecurringEvent) return;
        recurringAPI(task.recurringId)
            .then(originalPromiseResult => {
                if (originalPromiseResult.data) {
                    dispatch(setRecurring({
                        task: task,
                        recurringEvent: originalPromiseResult.data
                    }));
                }
            })
            .finally(() => {
                setIsRecurringLoading(false);
                setFormKey(prevState => prevState + 1);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
            onClose={onClose}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '0.75rem'
                }
            }}
        >
            <DialogTitle>{t('Task edit')}</DialogTitle>
            <DialogContent sx={{px: isMobile ? '8px' : '20px', py: '10px !important'}}>
                <TextField
                    fullWidth
                    label={t('Title')}
                    size='small'
                    variant='outlined'
                    InputProps={{
                        autoComplete: 'off',
                        sx: {
                            borderRadius: '0.75rem',
                            // background: themeMode === 'light' ? '#F9F9F9' : 'transparent',
                            "& .MuiFormHelperText-root": {
                                margin: 0,
                                paddingLeft: '14px',
                                paddingRight: '14px',
                            },
                        }
                    }}
                    value={editableTask.title}
                    onChange={(e) => {
                        handleChange(e, 'title')
                    }}
                />
                <Box sx={{display: !isMobile ? 'flex' : 'block', justifyContent: 'space-between'}}>
                    <Box style={{marginTop: '8px', margin: isMobile ? 'auto' : '0px', width: '100%'}}>
                        <Box sx={{
                            display: !isMobile ? 'flex' : 'block',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end'
                        }}>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id='tags'
                                size='small'
                                sx={{
                                    width: '100%',
                                    mt: '6px',
                                    pr: isMobile ? '0px' : '6px',
                                    '& .MuiInputBase-root': {
                                        borderRadius: '0.75rem'
                                    }
                                }}
                                onOpen={handleOpenTags}
                                options={tags.tagList.map(tag => tag.title)}
                                value={tagTitles}
                                onChange={handleChangeTagTitles}
                                freeSolo
                                renderOption={(props, option, {selected}) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <Box>
                                        <TextField
                                            {...params}
                                            variant='outlined'
                                            label={t('Tags')}
                                            placeholder={t('+ Add new tag')}
                                        />
                                    </Box>
                                )}
                            />
                            <Autocomplete
                                disabled={recurringConfig.isRecurring}
                                multiple
                                disableCloseOnSelect
                                id='goals'
                                size='small'
                                sx={{
                                    width: '100%',
                                    mt: '6px',
                                    padding: isMobile ? '0 0 0 0' : '0 6px 0 0',
                                    '& .MuiInputBase-root': {
                                        borderRadius: '0.75rem'
                                    }
                                }}
                                onOpen={handleOpenGoals}
                                options={flatArrayOfObjects(goals, 'SubGoals').map(goal => goal['title' as keyof typeof goal])}
                                value={goalTitles}
                                onChange={handleChangeGoalTitles}
                                freeSolo
                                renderOption={(props, option, {selected}) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{marginRight: 8}}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <Box>
                                        <TextField
                                            {...params}
                                            variant='outlined'
                                            label={t('Goals')}
                                            placeholder={t('+ Add task to goal')}
                                        />
                                    </Box>
                                )}
                            />
                        </Box>
                        <TextField
                            fullWidth
                            multiline
                            minRows={isMobile ? 3 : 11}
                            maxRows={Infinity}
                            label={`${t('Description')}`}
                            size='small'
                            variant="outlined"
                            value={editableTask.description ? editableTask.description : ''}
                            onChange={(e) => {
                                handleChange(e, 'description')
                            }}
                            sx={{mt: '6px', pr: isMobile ? '0px' : '6px'}}
                            InputProps={{
                                autoComplete: 'off',
                                sx: {
                                    borderRadius: '0.75rem'
                                }
                            }}
                        />
                    </Box>
                    <Box sx={{width: isMobile ? '100%' : '50%'}}>
                        <Box
                            sx={{
                                width: '100%',
                                border: '1px solid',
                                borderRadius: '0.75rem',
                                borderColor: theme.palette.borderColor.main,
                                mt: '6px',
                                mr: isMobile ? '0px' : '6px',
                                padding: '7px 0px'
                            }}
                        >
                            <FormGroup sx={{pl: '16px'}}>
                                <FormControlLabel
                                    control={<Checkbox
                                        disabled={task.isRecurring}
                                        name='isImportant'
                                        checked={editableTask.isImportant}
                                        onChange={handleChangeImportant}
                                        sx={{padding: 0, mr: '4px'}}
                                    />}
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                    label={t('Is important task')}
                                />
                            </FormGroup>
                        </Box>
                        <RecurringAddForm
                            modal
                            date={date}
                            recurringConfigHandle={recurringConfigHandle}
                            validateCounter={validateCounter}
                            sendCounter={sendCounter}
                            initialValues={prepareInitialValues(task)}
                            isLoading={isRecurringLoading}
                            key={formKey}
                        />
                        <Button
                            variant='contained'
                            fullWidth={true}
                            onClick={handleRemindClick}
                            sx={{
                                width: '100%',
                                borderRadius: '0.75rem',
                                mt: '8px',
                                mr: isMobile ? '0px' : '6px',
                                padding: '8px 8px 7px 6px',
                            }}
                            startIcon={<NotificationsActiveIcon/>}
                        >
                            {t('Is remind me')}
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
            <RemindOptionsDialog
                open={isOpenRemindDialog}
                sendCounter={sendCounter}
                initialRemindOptions={remindOptions}
                isRecurring={recurringConfig.isRecurring}
                handleOptions={handleRemindOptions}
                onClose={handleRemindClose}
            />
            <DialogActions>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                        px: isMobile ? '2px' : '12px',
                        py: '4px'
                    }}
                >
                    <Button variant='contained' onClick={handleCancelClick}>
                        {t('Cancel')}
                    </Button>
                    <Button variant='contained' onClick={handleSaveClick}>
                        {t('Save')}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}
