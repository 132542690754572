import React, {CSSProperties, FC, useEffect, useState} from 'react';
import {
    Badge,
    Box,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    useTheme
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {ITask} from '../../models/ITask';
import {useDebouncedState} from '../../hooks/debounced-state';
import {editTag, getUserTags, updateTags} from '../../store/task/taskSlice';
import {IGoal} from '../../models/goals/IGoal';

interface IFilterBar {
    tasks: ITask[],
    filter: string,
    tag?: string,
    handleChangeFilter: (value: string) => void
}

const filterTypographyStyles: CSSProperties = {
    marginRight: '24px',
    cursor: 'pointer',
    fontWeight: '500'
}

const inProgressTasksCount = (tasks: ITask[]): number => {
    return tasks.filter(task => !task.isCompleted && !task.isDeleted).length;
}

const completedTasksCount = (tasks: ITask[]): number => {
    return tasks.filter(task => task.isCompleted).length;
}

export const TagListFilter: FC<IFilterBar> = ({tasks, filter, tag, handleChangeFilter, ...props}) => {

    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {tags} = useAppSelector(state => state.task);

    const [tasksCount, setTasksCount] = useState<{
        all: number,
        inProgress: number,
        completed: number
    }>({
        all: 0,
        inProgress: 0,
        completed: 0
    });
    const [color, setColor] = useDebouncedState<string>('#000000', 200);
    const [goal, setGoal] = useState<IGoal | null>(null);

    const hadleFilterSelect = (event: SelectChangeEvent) => {
        handleChangeFilter(event.target.value);
    }

    const handleFilterClick = (value: string) => {
        handleChangeFilter(value);
    }

    const handleChangeColor = (e: React.ChangeEvent<HTMLInputElement>) => {
        setColor(e.target.value);
    }

    const handleUpdateColor = (e: React.FocusEvent<HTMLInputElement>) => {
        if (tag) {
            dispatch(updateTags({
                tagFrom: tag,
                color: e.target.value,
                goal: goal
            }));
            dispatch(editTag({
                tagTitle: tag,
                color: e.target.value
            }));
        }
    }

    useEffect(() => {
        setTasksCount({
            all: tasks.length,
            inProgress: inProgressTasksCount(tasks),
            completed: completedTasksCount(tasks)
        })
    }, [tasks]);

    useEffect(() => {
        if (tag) {
            if (!tags.fetched) {
                dispatch(getUserTags(false))
                    .unwrap()
                    .then((originalPromiseResult) => {
                        const resTag = originalPromiseResult.data.find(res => res.title === tag);
                        setColor(resTag && resTag.color ? resTag.color : '#000000');
                        setGoal(resTag && resTag.Goal ? resTag.Goal : null);
                    });
            } else {
                const storeTag = tags.tagList.find(storeTag => storeTag.title === tag);
                setColor(storeTag && storeTag.color ? storeTag.color : '#000000');
                setGoal(storeTag && storeTag.Goal ? storeTag.Goal : null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag, tags.fetched]);

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: isMobile ? '12px' : '8px'
            }}>
            {isMobile
                ?
                <FormControl sx={{width: isMobile ? '60%' : '100%', marginRight: '6px'}}>
                    <InputLabel id='filters'>{t('Filters')}</InputLabel>
                    <Select
                        value={filter}
                        onChange={hadleFilterSelect}
                        variant='outlined'
                        size='small'
                        label={t('filters')}
                        labelId='day-type'
                        sx={{
                            borderRadius: '0.75rem'
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    borderRadius: '0.5rem',
                                    color: theme.palette.textColorThird.dark,
                                },
                            },
                        }}
                    >
                        <MenuItem value={'0'}>
                            {t('All')}
                            <Chip
                                size='small'
                                label={tasksCount.all}
                                color='primary'
                                sx={{
                                    marginLeft: '4px',
                                    paddingLeft: '4px',
                                    paddingRight: '4px',
                                    color: '#ffffff',
                                    backgroundColor: theme.palette.filterColorAll.main
                                }}
                            />
                        </MenuItem>
                        <MenuItem value={'1'}>
                            {t('In progress')}
                            <Chip
                                size='small'
                                label={tasksCount.inProgress}
                                color='warning'
                                sx={{
                                    marginLeft: '4px',
                                    paddingLeft: '4px',
                                    paddingRight: '4px',
                                    color: '#ffffff',
                                    backgroundColor: theme.palette.filterColorInProgress.main
                                }}
                            />
                        </MenuItem>
                        <MenuItem value={'2'}>
                            {t('Completed')}
                            <Chip
                                size='small'
                                label={tasksCount.completed}
                                color='success'
                                sx={{
                                    marginLeft: '4px',
                                    paddingLeft: '4px',
                                    paddingRight: '4px',
                                    color: '#ffffff',
                                    backgroundColor: theme.palette.filterColorCompleted.main
                                }}
                            />
                        </MenuItem>
                    </Select>
                </FormControl>
                :
                <Box sx={{display: 'flex'}}>
                    <Typography sx={{marginRight: '8px', fontWeight: '500', color: theme.palette.textColorThird.dark}}>
                        {t('Filter')}:
                    </Typography>
                    <Typography
                        sx={{
                            ...filterTypographyStyles,
                            borderBottom: filter === '0' ? '2px solid' : '',
                            color: theme.palette.filterColorAll.main,
                            '& .MuiBadge-badge': {
                                color: '#ffffff',
                                backgroundColor: theme.palette.filterColorAll.main,
                                right: '-8px'
                            }
                        }}
                        onClick={() => handleFilterClick('0')}
                    >
                        <Badge color='primary' badgeContent={tasksCount.all}>
                            {t('All')}
                        </Badge>
                    </Typography>
                    <Typography
                        sx={{
                            ...filterTypographyStyles,
                            borderBottom: filter === '1' ? '2px solid' : '',
                            color: theme.palette.filterColorInProgress.main,
                            '& .MuiBadge-badge': {
                                color: '#ffffff',
                                backgroundColor: theme.palette.filterColorInProgress.main,
                                right: '-8px'
                            }
                        }}
                        onClick={() => handleFilterClick('1')}
                    >
                        <Badge color='warning' badgeContent={tasksCount.inProgress}>
                            {t('In progress')}
                        </Badge>
                    </Typography>
                    <Typography
                        sx={{
                            ...filterTypographyStyles,
                            borderBottom: filter === '2' ? '2px solid' : '',
                            color: theme.palette.filterColorCompleted.main,
                            '& .MuiBadge-badge': {
                                color: '#ffffff',
                                backgroundColor: theme.palette.filterColorCompleted.main,
                                right: '-8px'
                            }
                        }}
                        onClick={() => handleFilterClick('2')}
                    >
                        <Badge color='success' badgeContent={tasksCount.completed}>
                            {t('Completed')}
                        </Badge>
                    </Typography>
                </Box>
            }
            <FormControl sx={{width: isMobile ? '50%' : 'auto', flexDirection: 'initial'}}>
                {!isMobile &&
                    <label htmlFor='tagColor'
                           style={{
                               marginRight: '10px',
                               fontWeight: '500',
                               color: theme.palette.textColorThird.dark
                           }}
                    >
                        {t('Tag color')}
                    </label>
                }
                <input
                    type='color'
                    id='tagColor'
                    value={color}
                    onChange={handleChangeColor}
                    onBlur={handleUpdateColor}
                    style={{
                        padding: 0,
                        margin: 0,
                        cursor: 'pointer',
                        border: 0,
                        background: 'none',
                        appearance: 'none',
                        WebkitAppearance: 'none',
                        MozAppearance: 'none',
                        width: isMobile ? '100%' : '64px',
                        height: isMobile ? '45px' : ''
                    }}
                />
            </FormControl>
        </Box>
    )
}
