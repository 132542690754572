import React, { FC } from 'react';
import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { RegistrationForm } from '../../components/auth/RegistrationForm';

export const Registration: FC = () => {
    return (
        <Container maxWidth="lg">
            <Box
                sx={{
                    marginTop: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <RegistrationForm />
            </Box>
        </Container>
    )
}
