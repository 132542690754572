import React, {FC, Fragment, useState} from 'react';
import {Alert, Box, Button, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from '../../../hooks/redux';

interface IProps {
    restoreCounter: number,
    restoreAlertHandle: () => void,
    restoreTaskHandle: () => void
}

export const RestoreAlert: FC<IProps> = ({
                                             restoreCounter,
                                             restoreAlertHandle,
                                             restoreTaskHandle,
                                             ...props
                                         }) => {

    const {t} = useTranslation();

    const {isMobile} = useAppSelector(state => state.utils);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>('Cancel');


    const confirmHandle = () => {
        setIsDeleting(true);
        restoreTaskHandle();
    }

    if (isMobile) {
        return (
            <Fragment>
                <Alert
                    variant={"outlined"}
                    severity='success'
                    sx={{
                        width: '100%',
                        maxHeight: '40px',
                        alignItems: 'center',
                        marginTop: '4px',
                    }}
                    action={
                        <Fragment>
                            <Button
                                disabled={isDeleting}
                                color="inherit"
                                size="small"
                                onClick={restoreAlertHandle}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                disabled={isDeleting}
                                color="inherit"
                                size="small"
                                onClick={confirmHandle}
                            >
                                {t('Yes')}
                            </Button>
                        </Fragment>
                    }
                >
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            cursor: 'text',
                            paddingRight: '12px',
                        }}
                    >
                        {t('Are you sure?')}
                    </Typography>
                </Alert>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Alert
                variant={"outlined"}
                severity='success'
                sx={{
                    width: '100%',
                    maxHeight: '40px',
                    alignItems: 'center',
                    marginTop: '4px',
                    justifyContent: 'space-between',
                    paddingRight: '0px'
                }}
            >
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            cursor: 'text',
                            paddingRight: '12px',
                        }}
                    >
                        {t('Restore this task?')}
                    </Typography>
                    <Button
                        disabled={isDeleting}
                        color="inherit"
                        size="small"
                        onClick={restoreAlertHandle}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={isDeleting}
                        color="inherit"
                        size="small"
                        onClick={confirmHandle}
                    >
                        {t('Yes')}
                    </Button>
                </Box>
            </Alert>
        </Fragment>
    )
}
