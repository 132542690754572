import React, {FC, Fragment, useState} from 'react';
import {
    Box,
    Checkbox,
    CircularProgress,
    Collapse,
    Divider,
    ListItem,
    ListItemText,
    Typography,
    useTheme
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {ITask} from '../../models/ITask';
import {DeleteAlert} from '../UI/DeleteAlert/DeleteAlert';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
    addTask,
    completeTask,
    deleteRecurring,
    deleteTask,
    removeTag,
    removeTagFromTask,
    resetTasks,
    uncompleteTask
} from '../../store/task/taskSlice';
import {TagList} from '../UI/Tags/TagList';
import {ITag} from '../../models/tags/ITag';
import {IDay} from '../../models/days/IDay';
import {useDrag} from 'react-dnd';
import {dndItemTypes} from '../../models/dnd/dndItemTypes';
import {IDragTask} from '../../models/dnd/IDragTask';
import {TaskGoalsList} from '../UI/TaskGoals/TaskGoalsList';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import {TTaskActionsResponse} from "../../models/response/TTaskActionsResponse";
import {replaceTaskInGoal} from "../../store/goal/goalSlice";
import RepeatIcon from "@mui/icons-material/Repeat";
import {resetKicker} from "../../store/kicker/kickerSlice";

interface IProps {
    day: IDay,
    task: ITask,
    isHideActions?: boolean,
    index: number
}

const deleteVariants: string[] = ['Delete this task', 'Delete this task and all subsequent ones', 'Delete all tasks'];

export const OverdueTask: FC<IProps> = ({
                                            day,
                                            task,
                                            isHideActions = false,
                                            index,
                                            ...props
                                        }) => {

    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {isMobile} = useAppSelector(state => state.utils);

    const [isCompliting, setIsCompliting] = useState<boolean>(false);
    const [deleteCounter, setDeleteCounter] = useState<number>(0);

    const [{isDragging}, drag, preview] = useDrag({
        type: dndItemTypes.TASK,
        item: () => {
            return {
                task: task,
                date: day.date,
                tagFrom: undefined,
                oldIndex: index,
                index: index
            } as IDragTask
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging()
        })
    });

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsCompliting(true);

        if (e.target.checked) {
            dispatch(completeTask(task.id))
                .unwrap()
                .then(originalPromiseResult => {
                    sendDispatchToGoals(originalPromiseResult);
                })
                .finally(() => {
                    setIsCompliting(false);
                });
        } else {
            dispatch(uncompleteTask(task.id))
                .unwrap()
                .then(originalPromiseResult => {
                    sendDispatchToGoals(originalPromiseResult);
                })
                .finally(() => {
                    setIsCompliting(false);
                });
        }
    }

    const sendDispatchToGoals = (originalPromiseResult: TTaskActionsResponse) => {
        let keys = Object.keys(originalPromiseResult);
        let savedTask = originalPromiseResult[keys[0]] as ITask;
        if (savedTask.Goals?.length) {
            const goalsIds = savedTask.Goals.map(goal => goal.id);
            dispatch(replaceTaskInGoal({goalIds: goalsIds, task: savedTask}));
        }
    }

    const deleteAlertHandle = () => {
        setDeleteCounter(0);
    }

    const deleteTaskHandle = () => {
        if (deleteCounter < 1) {
            setDeleteCounter(deleteCounter + 1);
        } else {
            dispatch(deleteTask(task.id))
                .unwrap()
                .then(originalPromiseResult => {
                    let keys = Object.keys(originalPromiseResult);
                    let savedTask = originalPromiseResult[keys[0]] as ITask;
                    if (savedTask.Goals?.length) {
                        const goalsIds = savedTask.Goals.map(goal => goal.id);
                        dispatch(replaceTaskInGoal({goalIds: goalsIds, task: {...savedTask, isDeleted: true}}));
                    }
                });
        }
    }

    const deleteRecurringHandle = (type: string) => {
        const deletedTask = {...task, isDeleted: true};

        if (type === 'Delete this task') {
            const tagTitles = task.Tags.map(tag => tag.title);
            !task.id
                ?
                dispatch(addTask({
                    task: deletedTask,
                    date: day.date,
                    tagTitles: tagTitles
                }))
                :
                dispatch(deleteTask(task.id));
        }
        if (type === 'Delete this task and all subsequent ones') {
            dispatch(deleteRecurring({
                recurringId: task.recurringId,
                dayId: task.dayId,
                deleteTask: false
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    if (originalPromiseResult) {
                        dispatch(resetTasks());
                        dispatch(resetKicker());
                    }
                });
        }
        if (type === 'Delete all tasks') {
            dispatch(deleteRecurring({
                recurringId: task.recurringId,
                dayId: task.dayId,
                deleteTask: true
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    if (originalPromiseResult) {
                        dispatch(resetTasks());
                        dispatch(resetKicker());
                    }
                });
        }
    }

    const tagDelete = (tag: ITag) => {
        dispatch(removeTag({
            date: day.date,
            index: 0,
            task: task,
            tag: tag
        }));
        dispatch(removeTagFromTask({
            foreignKey: task.id ? task.id : task.recurringId,
            tagId: tag.id,
            model: task.id ? 'Task' : 'Event'
        }));
    }

    const opacity = isDragging ? 0 : 1

    return (
        <Box ref={preview} sx={{height: '48px !important', opacity: opacity}}>
            {!isHideActions &&
                <ListItem sx={{padding: '0'}}>
                    <Collapse in={deleteCounter ? true : false} sx={{width: 'inherit'}}>
                        <DeleteAlert
                            deleteCounter={deleteCounter}
                            isRecurring={task.isRecurring}
                            deleteVariants={deleteVariants}
                            deleteAlertHandle={deleteAlertHandle}
                            deleteRecurringHandle={deleteRecurringHandle}
                            deleteTaskHandle={deleteTaskHandle}
                        />
                    </Collapse>
                </ListItem>
            }
            {!deleteCounter &&
                <Fragment>
                    <ListItem
                        disabled={task.isCompleted || task.isDeleted}
                        sx={{
                            paddingRight: '0px',
                            paddingLeft: '0px',
                            pointerEvents: isCompliting ? 'none' : ''
                        }}
                    >
                        {!isHideActions &&
                            <Box
                                ref={drag}
                                component={'span'}
                                sx={{paddingTop: '6px', paddingRight: '16px'}}
                            >
                                <OpenWithIcon color='action'
                                              sx={{
                                                  cursor: 'grab',
                                                  opacity: '0.4',
                                                  "&:hover": {
                                                      opacity: '1'
                                                  }
                                              }}/>
                            </Box>
                        }
                        {isCompliting
                            ?
                            <CircularProgress sx={{color: '#a6a6a6', marginRight: '6px'}} size={'1.5rem'}/>
                            :
                            <Checkbox
                                sx={{padding: '0', margin: '0px', marginRight: '6px'}}
                                checked={task.isCompleted}
                                disabled={task.isDeleted}
                                onChange={handleCheck}
                            />
                        }
                        <ListItemText sx={{display: 'grid'}}>
                            <Typography
                                sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: task.isImportant ? 'bold' : '400'
                                }}
                            >
                                {task.title}
                            </Typography>
                        </ListItemText>
                        {!isMobile &&
                            <TagList
                                tags={task.Tags}
                                handleDelete={isHideActions ? undefined : tagDelete}
                            />
                        }
                        {!isMobile && task.Goals && task.Goals.length
                            ?
                            <TaskGoalsList goals={task.Goals}/>
                            :
                            ''
                        }
                        {task.isRecurring &&
                            <RepeatIcon
                                sx={{marginLeft: '8px'}}
                                color={isCompliting || task.isCompleted || task.isDeleted ? 'disabled' : 'action'}
                            />
                        }
                        {!isHideActions &&
                            <DeleteIcon
                                color='action'
                                sx={{
                                    marginLeft: '6px',
                                    cursor: 'pointer',
                                    marginRight: '6px',
                                    opacity: '0.4',
                                    "&:hover": {
                                        opacity: '1',
                                        color: theme.palette.error.main
                                    }
                                }}
                                onClick={isCompliting || task.isCompleted || task.isDeleted ? () => {
                                } : deleteTaskHandle}
                            />
                        }
                    </ListItem>
                    {(task.isCompleted || task.isDeleted) &&
                        <Divider sx={{marginTop: isHideActions ? '-20px' : '-22px'}}/>
                    }
                </Fragment>
            }
        </Box>
    )
}
