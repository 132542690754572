import axios, {AxiosResponse} from "axios";
import $api from "../http";
import {IUser} from "../models/IUser";
import {IAuthResponse} from "../models/response/IAuthResponse";
import {ISocialRedirectResponse} from "../models/response/ISocialRedirectResponse";
import {IRegisterResponse} from "../models/response/IRegisterResponse";
import {IAccountActivationResponse} from "../models/response/IAccountActivationResponse";
import {ICheckSocialLoginResponse} from "../models/response/ICheckSocialLoginResponse";
import {ISaveRelatedAccountResponse} from "../models/response/ISaveRelatedAccountResponse";
import {Preferences} from "@capacitor/preferences";

const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;

export const registerAPI = async (
    name: string,
    email: string,
    password: string,
    confirmPassword: string,
    timezoneName: string,
    timezoneOffset: number,
    referralCode: string,
    lang: string
): Promise<AxiosResponse<IRegisterResponse>> => {
    return await $api.post<IRegisterResponse>(`${AUTH_API_URL}/auth/registration`, {
        name: name,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        timezoneName: timezoneName,
        timezoneOffset: timezoneOffset,
        referralCode: referralCode,
        lang: lang
    });
}

export const finalRegisterAPI = async (
    id: number,
    name: string,
    provider: string,
    confirmHash: string,
    timezoneName: string,
    timezoneOffset: number,
    referralCode: string,
    lang: string
): Promise<AxiosResponse<IAuthResponse>> => {
    return await $api.post<IAuthResponse>(`${AUTH_API_URL}/auth/final-registration`, {
        id: id,
        name: name,
        provider: provider,
        confirmHash: confirmHash,
        timezoneName: timezoneName,
        timezoneOffset: timezoneOffset,
        referralCode: referralCode,
        lang: lang
    });
}

export const saveRelatedAccountAPI = async (
    id: number,
    name: string,
    provider: string,
    confirmHash: string,
): Promise<AxiosResponse<ISaveRelatedAccountResponse>> => {
    return await $api.post<ISaveRelatedAccountResponse>(`${AUTH_API_URL}/auth/save-related-account`, {
        id: id,
        name: name,
        provider: provider,
        confirmHash: confirmHash,
    });
}

export const getSocialRedirectLinkAPI = async (
    provider: string,
): Promise<AxiosResponse<ISocialRedirectResponse>> => {
    return await $api.post<ISocialRedirectResponse>(`${AUTH_API_URL}/auth/get-social-redirect-link`, {
        provider: provider,
    });
}

export const accountActivationAPI = async (
    confirmHash: string,
): Promise<AxiosResponse<IAccountActivationResponse>> => {
    return await $api.post<IAccountActivationResponse>(`${AUTH_API_URL}/auth/activate`, {
        confirmHash: confirmHash,
    });
}

export const checkSocialLoginAPI = async (
    confirmHash: string,
): Promise<AxiosResponse<ICheckSocialLoginResponse>> => {
    return await $api.post<ICheckSocialLoginResponse>(`${AUTH_API_URL}/auth/check-social-login`, {
        confirmHash: confirmHash,
    });
}

export const loginAPI = async (email: string, password: string): Promise<AxiosResponse<IAuthResponse>> => {
    return await $api.post<IAuthResponse>(`${AUTH_API_URL}/auth/login`, {
        email: email,
        password: password
    });
}

export const logoutAPI = async (): Promise<AxiosResponse> => {
    return await $api.post(`${AUTH_API_URL}/auth/logout`);
}

export const logoutAllAPI = async (): Promise<AxiosResponse> => {
    return await $api.post(`${AUTH_API_URL}/auth/logout-all`);
}

export const changePasswordAPI = async (currentPassword: string, newPassword: string, newPasswordConfirmation: string): Promise<AxiosResponse<IAuthResponse>> => {
    return await $api.post<IAuthResponse>(`${AUTH_API_URL}/auth/change-password`, {
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmNewPassword: newPasswordConfirmation
    });
}

export const editProfileAPI = async (user: IUser): Promise<AxiosResponse<IUser>> => {
    return await $api.post<IUser>(`${AUTH_API_URL}/auth/edit-profile`, user);
}

export const checkAuthAPI = async (): Promise<AxiosResponse<IAuthResponse>> => {
    const lang = await Preferences.get({key: 'lang'});
    const refreshToken = await Preferences.get({key: 'refreshToken'});
    return await axios.get<IAuthResponse>(`${AUTH_API_URL}/auth/refresh`, {
        withCredentials: true,
        headers: {
            'User-Language': lang.value || 'en',
            'Refresh-Token': refreshToken.value || '',
        }
    });
}

export const getVerificationCodeAPI = async (email: string): Promise<AxiosResponse<boolean>> => {
    const lang = await Preferences.get({key: 'lang'});
    return await axios.post<boolean>(`${AUTH_API_URL}/auth/verification-code`, {email}, {
        withCredentials: true,
        headers: {
            'User-Language': lang.value || 'en'
        },
    });
}

export const confirmVerificationCodeAPI = async (code: string): Promise<AxiosResponse<boolean>> => {
    const lang = await Preferences.get({key: 'lang'});
    return await axios.post<boolean>(`${AUTH_API_URL}/auth/confirm-verification-code`, {code}, {
        withCredentials: true,
        headers: {
            'User-Language': lang.value || 'en'
        },
    });
}

export const restorePasswordAPI = async (
    email: string,
    code: string,
    newPassword: string,
    newPasswordConfirmation: string
): Promise<AxiosResponse<boolean>> => {
    const lang = await Preferences.get({key: 'lang'});
    return await axios.post<boolean>(`${AUTH_API_URL}/auth/restore-password`, {
            email: email,
            code: code,
            newPassword: newPassword,
            confirmNewPassword: newPasswordConfirmation
        }, {
            withCredentials: true,
            headers: {
                'User-Language': lang.value || 'en'
            },
        }
    );
}