import React, {FC} from 'react';
import {Box, Chip, Grow, Link, useTheme} from '@mui/material';
import {ITag} from '../../../models/tags/ITag';
import {NavLink} from 'react-router-dom';
import {pickColor} from '../../../utils/helpers';
import {TransitionGroup} from 'react-transition-group';

interface ITagList {
    tags: ITag[],
    hide?: string,
    size?: 'small' | 'medium',
    withMargins?: boolean,
    component?: 'span' | 'div',
    grow?: boolean,
    handleDelete?: (tag: ITag) => void,
    redirectRoute?: string,
    clickTag?: (tag: ITag) => void
}

const flatTag = (tag: ITag): ITag => {
    if (tag.settings) {
        return {
            id: tag.id,
            title: tag.title,
            color: tag.settings.color !== null && tag.settings.color !== '' ? tag.settings.color : '',
            icon: tag.settings.icon !== null && tag.settings.icon !== '' ? tag.settings.icon : '',
            Goal: tag.settings.Goal !== null ? tag.settings.Goal : null
        }
    }

    return tag;
}

export const TagList: FC<ITagList> = ({
                                          tags,
                                          hide,
                                          size = 'small',
                                          withMargins = false,
                                          component = 'span',
                                          grow = false,
                                          handleDelete,
                                          redirectRoute = 'lists',
                                          clickTag,
                                          ...props
                                      }) => {

    let tagsList = tags;
    if (hide !== undefined) {
        tagsList = tags.filter(tag => tag.title !== hide);
    }

    const theme = useTheme();

    return (
        <Box component={component}>
            {handleDelete
                ?
                <TransitionGroup
                    component={null}
                    appear={grow}
                    enter={grow}
                    exit={false}
                >
                    {tagsList.map((tag, i) => (
                        <Grow timeout={tagsList.length <= 10 ? i * 80 : i * (i > 30 ? 20 : 30)} key={tag.id}>
                            <Chip
                                sx={{
                                    backgroundColor: flatTag(tag).color,
                                    borderColor: theme.palette.borderColor.main, // '#616161',
                                    marginLeft: i !== 0 ? '4px' : '0px',
                                    marginBottom: withMargins ? '6px' : '0px',
                                    '& .MuiChip-deleteIcon': {
                                        color: pickColor(flatTag(tag).color) === '#000000' ? '#00000080' : '#9f999961',
                                    },
                                    '& .MuiChip-deleteIcon:hover': {
                                        color: pickColor(flatTag(tag).color) === '#000000' ? '' : '#6d6d6d61',
                                    },
                                }}
                                label={
                                    <Link
                                        component={NavLink}
                                        to={'/' + redirectRoute + '/' + tag.title}
                                        color={pickColor(flatTag(tag).color)}
                                        underline='none'
                                        onClick={() => clickTag ? clickTag(tag) : undefined}
                                    >
                                        {tag.title}
                                    </Link>
                                }
                                variant='outlined'
                                size={size}
                                onDelete={() => handleDelete(tag)}
                                key={tag.id}
                            />

                        </Grow>
                    ))}
                </TransitionGroup>
                :
                <TransitionGroup
                    component={null}
                    appear={grow}
                    enter={grow}
                    exit={false}
                >
                    {tagsList.map((tag, i) => (
                        <Grow timeout={tagsList.length <= 10 ? i * 80 : i * (i > 30 ? 20 : 30)} key={tag.id}>
                            <Chip
                                sx={{
                                    backgroundColor: flatTag(tag).color,
                                    border: '1.5px solid #3c3434',
                                    borderRadius: '0.5rem',
                                    marginLeft: i !== 0 ? '8px' : '0px',
                                    marginBottom: withMargins ? '8px' : '0px'
                                }}
                                label={
                                    <Link
                                        component={NavLink}
                                        to={'/' + redirectRoute + '/' + tag.title}
                                        color={pickColor(flatTag(tag).color)}
                                        underline='none'
                                        onClick={() => clickTag ? clickTag(tag) : undefined}
                                    >
                                        {tag.title}
                                    </Link>
                                }
                                variant='outlined'
                                size={size}
                                key={tag.id}
                            />
                        </Grow>
                    ))}
                </TransitionGroup>
            }
        </Box>
    )
}
