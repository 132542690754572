import React, {FC, useEffect} from 'react';
import {Alert, Box, Collapse, Divider, List, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {TransitionGroup} from 'react-transition-group';
import {getRecurrings} from '../../store/task/taskSlice';
import {RecurringTask} from './RecurringTask';

export const RecurringTaskList: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {isTaskLoading, recurrings} = useAppSelector(state => state.task);

    useEffect(() => {
        if (!isTaskLoading && !recurrings.fetched) {
            dispatch(getRecurrings());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recurrings.fetched]);

    return (
        <div>
            <Typography
                color='primary'
                variant='h5'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '12px',
                    fontSize: '1.5rem',
                    fontWeight: '500 !important',
                    lineHeight: '1.2',
                }}
            >
                {t('Recurrings')}
            </Typography>
            <Box sx={{overflowY: 'auto', maxHeight: !isMobile ? 'calc(100vh - 180px)' : 'calc(100vh - 180px)'}}>
                <List dense={true}>
                    <TransitionGroup>
                        {recurrings.tasks.map(recurring => (
                            <Collapse key={recurring.id}>
                                <RecurringTask recurring={recurring}/>
                            </Collapse>
                        ))}
                    </TransitionGroup>
                    <Divider/>
                </List>
                {!isTaskLoading && recurrings.fetched && !recurrings.tasks.length &&
                    <Alert variant={"outlined"} severity="info" sx={{marginBottom: 2}}>
                        {t('You don\'t have recurring tasks yet!')}
                    </Alert>
                }
            </Box>
        </div>
    )
}
