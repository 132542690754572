import React, {ChangeEvent, FC, KeyboardEvent, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {setSnackbar} from '../../../store/utils/utilsSlice';
import {addSet} from '../../../store/goal/goalSlice';

interface IAddSetFormProps {
    onTabChange?: (setId: number | null) => void
}

export const AddSetForm: FC<IAddSetFormProps> = ({onTabChange, ...props}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {isMobile} = useAppSelector(state => state.utils);
    const {sets} = useAppSelector(state => state.goal);

    const [newSetOptions, setNewSetOptions] = useState<{
        title: string,
        isCopy: boolean,
        copySetId: number | '',
        isError: boolean,
        errorMessage: string,
        errorParam: string
    }>({
        title: '',
        isCopy: false,
        copySetId: '',
        isError: false,
        errorMessage: '',
        errorParam: ''
    });

    const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        setNewSetOptions(prevState => {
            return {
                ...prevState,
                title: e.target.value
            };
        });
    }

    const handleChangeIsCopy = (e: ChangeEvent<HTMLInputElement>) => {
        setNewSetOptions(prevState => {
            return {
                ...prevState,
                isCopy: e.target.checked
            }
        });
    }

    const handleChangeCopySetId = (e: SelectChangeEvent<number>) => {
        setNewSetOptions(prevState => {
            return {
                ...prevState,
                copySetId: +e.target.value
            }
        });
    }

    const handleEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') handleSubmit();
    }

    const handleSubmit = () => {
        if (newSetOptions.title.trim() === '') {
            dispatch(setSnackbar({
                text: 'Set title cannot be empty',
                severity: 'error',
                open: true
            }));

            return;
        }

        if (newSetOptions.title.trim().length > 25) {
            setNewSetOptions(prevState => {
                return {
                    ...prevState,
                    isError: true,
                    errorMessage: t('Title must be at least 1 and no more than 25 characters'),
                    errorParam: 'title'
                }
            });

            return;
        }

        const setNames = sets.map(set => set.title);
        if (setNames.includes(newSetOptions.title.trim())) {
            dispatch(setSnackbar({
                text: 'You already have set with this title',
                severity: 'error',
                open: true
            }));

            return;
        }

        dispatch(addSet({
            title: newSetOptions.title.trim(),
            copySetId: newSetOptions.isCopy && newSetOptions.copySetId !== '' ? newSetOptions.copySetId : undefined
        }))
            .unwrap()
            .then((originalPromiseResult) => {
                setNewSetOptions({
                    title: '',
                    isCopy: false,
                    copySetId: '',
                    isError: false,
                    errorMessage: '',
                    errorParam: ''
                });
                if (onTabChange !== undefined) onTabChange(originalPromiseResult.id);
            })
            .catch(error => {
                if (error) {
                    setNewSetOptions(prevState => {
                        return {
                            ...prevState,
                            isError: true,
                            errorMessage: error.msg,
                            errorParam: error.param
                        }
                    });
                }
            });
    }

    return (
        <Box sx={{mt: '12px'}}>
            <Paper
                sx={{
                    p: 2,
                    border: '1px solid #DDDADA',
                    boxShadow: 'none',
                    borderRadius: '0.5rem'
                }}
            >
                <Typography
                    sx={{
                        mb: 2,
                        fontWeight: '430'
                    }}
                >
                    {t('Write a title to create a new set')}
                </Typography>
                <Grid container spacing={1} sx={{mt: 1}}>
                    <Grid item xs={12} md={10}>
                        <TextField
                            name='title'
                            autoFocus={isMobile ? false : true}
                            fullWidth
                            label={t('+ Add set')}
                            size='small'
                            variant="outlined"
                            sx={{marginRight: '6px'}}
                            InputProps={{
                                autoComplete: 'off',
                                sx: {
                                    borderRadius: '0.75rem'
                                }
                            }}
                            value={newSetOptions.title}
                            onChange={handleChangeTitle}
                            onKeyPress={handleEnterPress}
                            error={newSetOptions.isError}
                            helperText={newSetOptions.isError && newSetOptions.errorParam === 'title' && newSetOptions.errorMessage}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button
                            fullWidth={true}
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            {t('Add')}
                        </Button>
                    </Grid>
                </Grid>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox
                            name='isCopy'
                            checked={newSetOptions.isCopy}
                            onChange={handleChangeIsCopy}
                        />}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            userSelect: 'none',
                            marginTop: '8px',
                            '& .MuiTypography-root': {
                                fontSize: '0.9rem',
                                fontWeight: '420'
                            }
                        }}
                        label={t('Copy goals from another set?')}
                    />
                </FormGroup>
                <Collapse in={newSetOptions.isCopy}>
                    <FormControl size='small' sx={{mt: '12px', width: '100%',}}>
                        <InputLabel id="set-to-copy">{t('Set to copy')}</InputLabel>
                        <Select
                            name='setToCopy'
                            labelId="set-to-copy-label"
                            id="set-to-copy"
                            value={newSetOptions.copySetId}
                            label={t('Set to copy')}
                            onChange={handleChangeCopySetId}
                            sx={{
                                borderRadius: '0.75rem'
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        borderRadius: '0.5rem',
                                        color: theme.palette.textColorThird.main,
                                    },
                                },
                            }}
                        >
                            {!sets || !sets.length
                                ?
                                <MenuItem disabled value={0} key={0}>{t("You don't have any sets")}</MenuItem>
                                :
                                sets.map(set => (
                                    <MenuItem
                                        value={set.id}
                                        key={set.id}
                                        sx={{
                                            '&:hover': {
                                                color: theme.palette.primary.main,
                                            },
                                        }}
                                    >
                                        {set.title}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Collapse>
            </Paper>
        </Box>
    )
}
