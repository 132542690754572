import React, { FC } from 'react';
import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import { ITask } from '../../models/ITask';
import { useAppSelector } from '../../hooks/redux';
import { TagList } from '../UI/Tags/TagList';
import { IDay } from '../../models/days/IDay';
import { TaskGoalsList } from '../UI/TaskGoals/TaskGoalsList';

interface IProps {
    day: IDay,
    task: ITask
}

export const CompletedTask: FC<IProps> = ({ day, task, ...props }) => {

    const { isMobile } = useAppSelector(state => state.utils);

    return (
        <Box sx={{ height: '48px !important' }}>
            <ListItem sx={{ paddingRight: '0px' }}>
                <ListItemText sx={{ display: 'grid' }}>
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontWeight: task.isImportant ? 'bold' : '400'
                        }}
                    >
                        {task.title}
                    </Typography>
                </ListItemText>
                {!isMobile &&
                    <TagList tags={task.Tags} />
                }
                {!isMobile && task.Goals && task.Goals.length
                    ?
                    <TaskGoalsList goals={task.Goals} />
                    :
                    ''
                }
            </ListItem>
        </Box>
    )
}
