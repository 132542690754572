import React, {FC, useState} from 'react';
import {Button, CircularProgress} from '@mui/material';
import {useAppDispatch} from '../../../hooks/redux';
import {getSocialRedirectLink} from "../../../store/auth/authSlice";

interface IProps {
    provider: string,
    providerTitle: string
}

export const SocialLoginButton: FC<IProps> = ({provider, providerTitle, ...props}) => {

    const dispatch = useAppDispatch();

    const [buttonClicked, setButtonClicked] = useState<boolean>(false);

    const onClickButton = () => {
        setButtonClicked(true);
        dispatch(getSocialRedirectLink({
            provider: provider,
        }))
            .unwrap()
            .then((originalPromiseResult) => {
                if (originalPromiseResult.status === true) {
                    window.location.href = originalPromiseResult.link
                }
            })
            .catch((error) => {
                console.error(error.msg);
                setButtonClicked(false);
            });
    }

    return (
        <Button
            fullWidth
            variant="outlined"
            sx={{
                mt: 1,
                mb: 1,
                borderRadius: '0.75rem',
                padding: 'calc(0.775rem + 1px) calc(1.5rem + 1px)',
                textTransform: 'none',
                fontSize: '1.1rem',
                lineHeight: '1',
                height: '42px'
            }}
            disabled={buttonClicked}
            onClick={onClickButton}
            endIcon={
                buttonClicked
                    ?
                    <CircularProgress color='inherit' size={'1rem'}/>
                    :
                    undefined
            }
        >
            <img src={`/images/social-auth/${provider}.png`} width={"20px"} alt={provider}/> &nbsp;&nbsp;&nbsp;
            {providerTitle}
        </Button>
    )
};
