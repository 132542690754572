import React, {FC, Fragment, useEffect, useRef, useState} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    ClickAwayListener,
    Collapse,
    FormControlLabel,
    FormGroup,
    Grid,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TextField,
    useTheme
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SettingsIcon from '@mui/icons-material/Settings';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {ITask} from '../../models/ITask';
import {addRecurringTask, addTask, getUserTags} from '../../store/task/taskSlice';
import {useTranslation} from 'react-i18next';
import {ITabDay} from '../../models/days/ITabDay';
import {RecurringAddForm} from './RecurringAddForm';
import {IRecurringFormFields} from '../../models/validation/IRecurringFormFields';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {addTaskToGoal, getGoals} from '../../store/goal/goalSlice';
import {
    calculateNearestTimeFromSchedule,
    flatArrayOfObjects,
    formatDate,
    getTimeSchedule,
    hasDuplicates
} from '../../utils/helpers';
import {resetKicker} from '../../store/kicker/kickerSlice';
import {ITag} from '../../models/tags/ITag';
import {MAX_REMINDERS_COUNT} from '../../utils/constants';
import {IRemindOptions} from '../../models/notifications/IRemindOptions';
import {RemindOptionsDialog} from './RemindOptionsDialog';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {TagAddList} from "../UI/Tags/TagAddList";
import {setShowBuyPremiumDialogStatus} from "../../store/subscription/subscriptionSlice";

const isValidationNeeded = (recurringConfig: IRecurringFormFields): boolean => {
    if (Number(recurringConfig.recurringType) === 1 && !recurringConfig.dayOfWeek.length) return true;
    if (Number(recurringConfig.recurringType) === 2 && !recurringConfig.dayOfMonth.length) return true;
    if (Number(recurringConfig.recurringType) === 3 && (!recurringConfig.dayOfMonth.length || !recurringConfig.monthOfYear.length)) return true

    return false;
}

const filterTags = (tags: ITag[]): string[] => {
    const result: string[] = [];
    tags.filter(tag => tag.isArchived === 0).map(tag => {
        if (tag.aliases && tag.aliases.length) {
            result.push(tag.title);
            return tag.aliases.map(alias => result.push(alias));
        } else {
            return result.push(tag.title);
        }
    });

    return result;
}

interface IProps {
    day: ITabDay,
}

type OptionsToShow = 'TAGS' | 'GOALS';

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export const TaskAddInput: FC<IProps> = ({day, ...props}) => {

    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const {user} = useAppSelector(state => state.auth);
    const {isTaskSending, tags, days} = useAppSelector(state => state.task);
    const {goals} = useAppSelector(state => state.goal);
    const {isMobile} = useAppSelector(state => state.utils);

    const timeSchedule = getTimeSchedule();
    const nearestTimeFromSchedule = calculateNearestTimeFromSchedule(user.timezoneName, timeSchedule);

    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);
    const autocompleteRef = useRef<HTMLInputElement>(null);

    const [openSettings, setOpenSettings] = useState<boolean>(false);
    const [newTask, setNewTask] = useState<ITask>({title: '', description: ''} as ITask);
    const [isImportant, setIsImportant] = useState<boolean>(false);
    const [recurringConfig, setRecurringConfig] = useState<IRecurringFormFields>({} as IRecurringFormFields);
    const [validateCounter, setValidateCounter] = useState<number>(0);
    const [sendCounter, setSendCounter] = useState<number>(0);
    const [optionToShow, setOptionToShow] = useState<OptionsToShow>('TAGS');
    const [selectedOptions, setSelectedOptions] = useState<{
        [key in OptionsToShow]: string[]
    }>({
        'TAGS': [],
        'GOALS': []
    });
    const [isOpenRemindDialog, setIsOpenRemindDialog] = useState<boolean>(false);
    const [remindOptions, setRemindOptions] = useState<IRemindOptions>({
        isRemind: false,
        remindMethodId: [2],
        remindersActive: [1],
        notifyAt: [nearestTimeFromSchedule, nearestTimeFromSchedule, nearestTimeFromSchedule],
        date: [new Date(day.date), new Date(day.date), new Date(day.date)],
        daysDiff: [0, 0, 0]
    } as IRemindOptions);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isPopoverOpen = Boolean(anchorEl);
    const [fTags, setFTags] = useState<string[]>([]);
    const [selectedInputTag, setSelectedInputTag] = useState<string>('');

    const handleOpen = () => {
        setAnchorEl(ref.current);
        setSelectedInputTag('');
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSettings = () => {
        setOpenSettings(!openSettings);
    }

    const newTaskTitleHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTask(prevState => {
            return {
                ...prevState,
                title: e.target.value.replace(/@/g, '')
            }
        });
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (newTask.title.includes('#')) {
                handleClose();
                selectTagsListClick(selectedInputTag);
            } else {
                addNewTask();
            }
        }
        if (e.key === '#') {
            setOptionToShow('TAGS');
            handleOpenOptions();
            handleOpen();
        }

        if (e.key === ' ') {
            handleClose();
            extractTag();
        }
    }

    const extractTag = () => {
        let lastWord = getLastWord(newTask.title);
        if (lastWord.includes('#')) {
            if (!lastWord.includes(' #')) {
                let replacedTaskTitle = newTask.title.trim().replace('#', ' #');
                lastWord = getLastWord(replacedTaskTitle);
                removeLastWordInTask();

                let lastIndex: number = replacedTaskTitle.trim().lastIndexOf(" ");
                let nTaskTitle = replacedTaskTitle.trim().substring(0, lastIndex);

                let task = newTask;
                task.title = nTaskTitle;
                setNewTask(task);

                lastWord = lastWord.substring(1);
                if (lastWord.length) {
                    let selectedTags: string[] = selectedOptions.TAGS;
                    selectedTags.push(lastWord);
                    setSelectedOptions({TAGS: selectedTags, GOALS: selectedOptions.GOALS});
                }
            } else {
                removeLastWordInTask();
                lastWord = lastWord.substring(1);
                if (lastWord.length) {
                    let selectedTags: string[] = selectedOptions.TAGS;
                    selectedTags.push(lastWord);
                    setSelectedOptions({TAGS: selectedTags, GOALS: selectedOptions.GOALS});
                }
            }
        }
    }


    const newTaskTitleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        let lastWord = getLastWord(newTask.title);
        if (lastWord.includes('#')) {
            handleOpen();
            let replacedTaskTitle = newTask.title.trim().replace('#', ' #');
            lastWord = getLastWord(replacedTaskTitle).replace('#', '');
            let matchedTags = matchTags(lastWord);
            if (!matchedTags.includes(lastWord)) {
                if (lastWord) {
                    matchedTags.unshift(lastWord);
                }
            }
            setFTags(matchedTags);
            setSelectedInputTag(lastWord);
        }

        if (e.key === 'ArrowUp') {
            const index = fTags.findIndex(tag => tag === selectedInputTag);
            if (index > 0) {
                if (index < fTags.length + 1) {
                    let selectedTag = fTags[index - 1];
                    setSelectedInputTag(selectedTag);
                }
            } else {
                setSelectedInputTag(fTags[fTags.length - 1]);
            }
        }
        if (e.key === 'ArrowDown') {
            const index = fTags.findIndex(tag => tag === selectedInputTag);
            if (index < fTags.length - 1) {
                let selectedTag = fTags[index + 1];
                setSelectedInputTag(selectedTag);
            } else {
                setSelectedInputTag(fTags[0]);
            }
        }
    }

    const getLastWord = (text: string) => {
        let n = text.split(" ");
        return n[n.length - 1];
    }

    const removeLastWordInTask = () => {
        let lastIndex: number = newTask.title.trim().lastIndexOf(" ");
        let nTaskTitle = newTask.title.trim().substring(0, lastIndex);
        setNewTask({...newTask, title: nTaskTitle});
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Escape') {
            handleClose();
            inputRef.current?.focus();
        }
    }

    const handleOpenOptions = () => {
        if (optionToShow === 'TAGS' && !tags.fetched) {
            dispatch(getUserTags(false));
        }
        if (optionToShow === 'GOALS' && !goals.length) {
            dispatch(getGoals());
        }
    }

    const handleSelectOptions = (event: any, value: string[]) => {
        setSelectedOptions(prevState => {
            return {
                ...prevState,
                [optionToShow]: value
            }
        });
    }

    const handleImportant = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsImportant(e.target.checked);
    }

    const newTaskDescriptionHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTask({...newTask, description: e.target.value});
    }

    const recurringConfigHandle = (config: IRecurringFormFields) => {
        setRecurringConfig(config);
        setRemindOptions({
            isRemind: false,
            remindMethodId: [2],
            remindersActive: [1],
            notifyAt: [nearestTimeFromSchedule, nearestTimeFromSchedule, nearestTimeFromSchedule],
            date: [new Date(day.date), new Date(day.date), new Date(day.date)],
            daysDiff: [0, 0, 0]
        });
    }

    const addNewTask = () => {
        handleClose();

        if (newTask.title.trim() === '') {
            dispatch(setSnackbar({
                text: 'Task title cannot be empty',
                severity: 'error',
                open: true
            }));
            return;
        }

        if (recurringConfig.isRecurring) {
            if (isValidationNeeded(recurringConfig)) {
                setValidateCounter(validateCounter + 1);
                return;
            }

            if (!remindOptions.remindMethodId.length) {
                dispatch(setSnackbar({
                    text: 'You have not selected a reminder method',
                    severity: 'error',
                    open: true
                }));
                return;
            }
            const duplicatesCheckArr: any = [];
            remindOptions.remindersActive.map((remind, i) => {
                return duplicatesCheckArr.push(remindOptions.daysDiff[i] + remindOptions.notifyAt[i]);
            });
            if (hasDuplicates(duplicatesCheckArr)) {
                dispatch(setSnackbar({
                    text: 'Your notification settings are duplicating',
                    severity: 'error',
                    open: true
                }));
                return;
            }

            const times = remindOptions.notifyAt.map(time => {
                const date = new Date();
                date.setHours(Number(time[0] + time[1]));
                date.setMinutes(Number(time[3] + time[4]));
                date.setSeconds(0);
                return formatDate(String(date)).split(" ")[1];
            });

            //TODO limit for recurring

            dispatch(addRecurringTask({
                task: newTask,
                recurringConfig: recurringConfig,
                remindConfig: remindOptions.isRemind ? {...remindOptions, notifyAt: times} : null,
                tagTitles: selectedOptions.TAGS,
                currDate: day.date
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    setNewTask({...newTask, title: '', description: ''});
                    setRecurringConfig({} as IRecurringFormFields);
                    setSelectedOptions({TAGS: [], GOALS: []});
                    setSendCounter(sendCounter + 1);

                    dispatch(resetKicker());

                    const node = inputRef.current as any;
                    node.focus();
                });
        } else {
            let notifyAt: string[] = [];
            if (remindOptions.isRemind) {
                for (let i = 0; i < MAX_REMINDERS_COUNT; i++) {
                    if (remindOptions.remindersActive.includes(i + 1)) {
                        const temp = remindOptions.date[i];
                        temp.setMilliseconds(0);
                        temp.setSeconds(0);
                        temp.setMinutes(Number(remindOptions.notifyAt[i].slice(3, 5)));
                        temp.setHours(Number(remindOptions.notifyAt[i].slice(0, -3)));
                        notifyAt.push(temp.toString());
                    }
                }
                if (!remindOptions.remindMethodId.length) {
                    dispatch(setSnackbar({
                        text: 'You have not selected a reminder method',
                        severity: 'error',
                        open: true
                    }));
                    return;
                }
                if (hasDuplicates(notifyAt)) {
                    dispatch(setSnackbar({
                        text: 'Your notification settings are duplicating',
                        severity: 'error',
                        open: true
                    }));
                    return;
                }
            }

            const tagTitles: string[] = [];
            selectedOptions.TAGS.map(selectedTag => {
                const index = tags.tagList.findIndex(tag => tag.title === selectedTag);
                if (index !== -1) {
                    return tagTitles.push(selectedTag);
                } else {
                    return tags.tagList.map(tag => {
                        if (tag.aliases && tag.aliases.length) {
                            return tag.aliases.map(alias => {
                                if (alias === selectedTag) return tagTitles.push(tag.title);
                                return 1;
                            })
                        }
                        return 1;
                    });
                }
            });

            if (days[day.date].Tasks.length >= user.level.tasksPerDayMaxCount) {
                dispatch(setShowBuyPremiumDialogStatus(true));
                return;
            }

            let resRags = fTags;
            resRags.push(...selectedOptions.TAGS);
            let tagsLength = resRags.filter((item, i, ar) => ar.indexOf(item) === i).length;

            if (tagsLength >= user.level.listsMaxCount) {
                dispatch(setShowBuyPremiumDialogStatus(true));
                return;
            }

            const resGoals = flatArrayOfObjects(goals, 'SubGoals').map(goal => goal['title' as keyof typeof goal]) as string[];
            resGoals.push(...selectedOptions.GOALS);

            if (resGoals.length >= user.level.goalsMaxSetsCount) {
                dispatch(setShowBuyPremiumDialogStatus(true));
                return;
            }

            let nNweTask = newTask;

            setNewTask({...newTask, title: '', description: ''});
            setIsImportant(false);
            setSelectedOptions({TAGS: [], GOALS: []});
            setSendCounter(sendCounter + 1);
            setRemindOptions({
                isRemind: false,
                remindMethodId: [2],
                remindersActive: [1],
                notifyAt: [nearestTimeFromSchedule, nearestTimeFromSchedule, nearestTimeFromSchedule],
                date: [new Date(day.date), new Date(day.date), new Date(day.date)],
                daysDiff: [0, 0, 0]
            });
            dispatch(addTask({
                task: {
                    ...nNweTask,
                    isImportant: nNweTask.title.includes('!') || isImportant
                },
                notifyAt: notifyAt,
                notificationMethods: remindOptions.remindMethodId,
                date: day.date,
                tagTitles: selectedOptions.TAGS,
                goalTitles: selectedOptions.GOALS
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    let keys = Object.keys(originalPromiseResult);
                    let savedTask = originalPromiseResult[keys[0]] as ITask;
                    if (savedTask.Goals?.length) {
                        const goalsIds = savedTask.Goals.map(goal => goal.id);
                        dispatch(addTaskToGoal({goalIds: goalsIds, task: savedTask}));
                    }
                    const node = inputRef.current as any;
                    node.focus();
                    dispatch(resetKicker());
                });
        }
    }

    const onOpenGoalsHandle = () => {
        if (!goals.length) {
            dispatch(getGoals());
        }
    }

    const onOpenTagsHandle = () => {
        if (!tags.fetched) {
            dispatch(getUserTags(false));
        }
    }

    const onGoalSelectHandle = (event: any, value: string[]) => {
        setSelectedOptions(prevState => {
            return {
                ...prevState,
                GOALS: value
            }
        });
    }

    const onTagSelectHandle = (event: any, value: string[]) => {
        setSelectedOptions(prevState => {
            return {
                ...prevState,
                TAGS: value
            }
        });
    }

    const handleRemindClick = () => {
        setIsOpenRemindDialog(true);
    }

    const handleRemindClose = () => {
        setIsOpenRemindDialog(false);
    }

    const handleRemindOptions = (options: IRemindOptions) => {
        setRemindOptions(options);
    }

    useEffect(() => {
        if (autocompleteRef.current) {
            autocompleteRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocompleteRef.current]);

    useEffect(() => {
        if (tags.tagList) {
            setFTags(tags.tagList.filter(tag => tag.isArchived === 0).map(tag => tag.title));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags.tagList]);


    const tagDelete = (tag: string) => {
        let selectedTags: string[] = selectedOptions.TAGS.filter(tagInList => tagInList !== tag);
        setSelectedOptions({TAGS: selectedTags, GOALS: selectedOptions.GOALS});
    }

    const matchTags = (input: string) => {
        let reg = new RegExp(input.split('').join('\\w*').replace(/\W/, ""), 'i');
        return tags.tagList.filter(tag => tag.isArchived === 0).map(tag => tag.title).filter(tag => {
            if (tag.match(reg)) {
                return tag;
            }
        });
    }

    const selectTagsListClick = (tagTitle: string) => {
        if (!selectedOptions.TAGS.includes(tagTitle)) {
            let selectedTags: string[] = selectedOptions.TAGS;
            selectedTags.push(tagTitle);
            setSelectedOptions({TAGS: selectedTags, GOALS: selectedOptions.GOALS});
            setSelectedInputTag('');

            let lastWord = getLastWord(newTask.title);
            if (lastWord.includes('#')) {
                if (!lastWord.includes(' #')) {
                    let replacedTaskTitle = newTask.title.trim().replace('#', ' #');
                    removeLastWordInTask();

                    let lastIndex: number = replacedTaskTitle.trim().lastIndexOf(" ");
                    let nTaskTitle = replacedTaskTitle.trim().substring(0, lastIndex);
                    setNewTask({...newTask, title: nTaskTitle});
                } else {
                    removeLastWordInTask();
                }
            }

            handleClose();
        }
    }

    return (
        <Fragment>
            {!isMobile ?
                <Box sx={{display: 'flex', justifyContent: 'space-between', paddingTop: '16px'}}>
                    <TextField
                        ref={ref}
                        inputRef={inputRef}
                        autoFocus={isMobile ? false : true}
                        fullWidth
                        label={`${t('+ Add task for')} ${day?.name !== '' ? t(day?.name + '_1') : day?.date}`}
                        size='small'
                        variant='outlined'
                        sx={{marginRight: '6px'}}
                        InputProps={{
                            autoComplete: 'off',
                            sx: {
                                borderRadius: '0.75rem',
                                // background: themeMode === 'light' ? '#F9F9F9' : 'transparent',
                                "& .MuiFormHelperText-root": {
                                    margin: 0,
                                    paddingLeft: '14px',
                                    paddingRight: '14px',
                                },
                            },
                            endAdornment: (
                                <Box sx={{display: "flex"}}>
                                    {selectedOptions.TAGS.map((data, index) => {
                                        return (
                                            <TagAddList tag={data} key={data} handleDelete={tagDelete}/>
                                        );
                                    })}
                                </Box>
                            ),
                        }}
                        value={newTask.title}
                        onChange={newTaskTitleHandle}
                        onKeyPress={handleKeyPress}
                        onKeyUp={newTaskTitleKeyUp}
                    />
                    <ClickAwayListener onClickAway={handleClose}>
                        <Popper
                            aria-describedby='tags-goals-autocomplete'
                            open={isPopoverOpen}
                            anchorEl={anchorEl}
                            sx={{zIndex: 9999}}
                            transition
                        >
                            {({TransitionProps}) => (
                                <Grow {...TransitionProps} exit={false}>
                                    <Paper
                                        sx={{
                                            width: ref.current ? ref.current.offsetWidth : '',
                                            maxHeight: '300px',
                                            p: 1,
                                            overflow: 'auto'
                                        }}
                                    >
                                        <MenuList dense={false}>
                                            {
                                                fTags.map(tagTitle => {
                                                    return <MenuItem
                                                        value={tagTitle}
                                                        key={tagTitle}
                                                        onClick={() => selectTagsListClick(tagTitle)}
                                                        selected={selectedInputTag === tagTitle}
                                                    >
                                                        {'#' + tagTitle}
                                                    </MenuItem>
                                                })
                                            }
                                        </MenuList>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </ClickAwayListener>
                    <Button
                        variant='outlined'
                        sx={{marginRight: '6px'}}
                        onClick={handleSettings}
                    >
                        <SettingsIcon/>
                    </Button>
                    <Button
                        variant='contained'
                        onClick={addNewTask}
                        sx={{padding: '6px 22px', pointerEvents: isTaskSending ? 'none' : 'auto'}}
                    >
                        {t('Add')}
                    </Button>
                </Box>
                :
                <Grid container spacing={0} sx={{paddingTop: '16px'}}>
                    <Grid item xs={12} md={10}>
                        <TextField
                            ref={ref}
                            inputRef={inputRef}
                            autoFocus={isMobile ? false : true}
                            fullWidth
                            label={`${t('+ Add task for')} ${day?.name !== '' ? t(day?.name + '_1') : day?.date}`}
                            size='small'
                            variant='outlined'
                            sx={{marginRight: '6px'}}
                            InputProps={{
                                autoComplete: 'off',
                                sx: {
                                    borderRadius: '0.75rem'
                                },
                                endAdornment: (
                                    <Box sx={{display: "flex"}}>
                                        {selectedOptions.TAGS.map((data, index) => {
                                            return (
                                                <TagAddList tag={data} key={data} handleDelete={tagDelete}/>
                                            );
                                        })}
                                    </Box>
                                ),
                            }}
                            value={newTask.title}
                            onChange={newTaskTitleHandle}
                            onKeyPress={handleKeyPress}
                            onKeyUp={newTaskTitleKeyUp}
                        />
                        <ClickAwayListener onClickAway={handleClose}>
                            <Popper
                                aria-describedby='tags-goals-autocomplete'
                                open={isPopoverOpen}
                                anchorEl={anchorEl}
                                sx={{zIndex: 9999}}
                                transition
                            >
                                {({TransitionProps}) => (
                                    <Grow {...TransitionProps} exit={false}>
                                        <Paper
                                            sx={{
                                                width: ref.current ? ref.current.offsetWidth : '',
                                                maxHeight: '300px',
                                                p: 1,
                                                overflow: 'auto'
                                            }}
                                        >
                                            <MenuList dense={false}>
                                                {
                                                    fTags.map(tagTitle => {
                                                        return <MenuItem
                                                            value={tagTitle}
                                                            key={tagTitle}
                                                            onClick={() => selectTagsListClick(tagTitle)}
                                                            selected={selectedInputTag === tagTitle}
                                                        >
                                                            {'#' + tagTitle}
                                                        </MenuItem>
                                                    })
                                                }
                                            </MenuList>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </ClickAwayListener>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Grid container spacing={0} sx={{paddingTop: '8px', paddingBottom: '8px'}}>
                            <Grid item xs={3} md={3}>
                                <Button
                                    variant='outlined'
                                    sx={{marginRight: '6px'}}
                                    onClick={handleSettings}
                                >
                                    <SettingsIcon/>
                                </Button>
                            </Grid>
                            <Grid item xs={9} md={9} sx={{paddingLeft: '8px'}}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    onClick={addNewTask}
                                    sx={{
                                        pointerEvents: isTaskSending ? 'none' : 'auto'
                                    }}
                                >
                                    {t('Add')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Collapse in={openSettings} unmountOnExit>
                <Box sx={{display: !isMobile ? 'flex' : 'grid', justifyContent: 'space-between'}}>
                    <Box style={{marginTop: '8px', margin: isMobile ? 'auto' : '0px', width: '100%'}}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    id='tags'
                                    size='small'
                                    sx={{
                                        width: '100%', mt: '8px',
                                        pr: isMobile ? '0px' : '6px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '0.75rem !important'
                                        }
                                    }}
                                    onOpen={onOpenTagsHandle}
                                    options={filterTags(tags.tagList)}
                                    value={selectedOptions.TAGS}
                                    onChange={onTagSelectHandle}
                                    freeSolo
                                    renderOption={(props, option, {selected}) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{marginRight: 8}}
                                                checked={selected}
                                            />
                                            {option}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='outlined'
                                            label={t('Tags')}
                                            placeholder={t('+ Add new tag')}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    id='goals'
                                    size='small'
                                    sx={{
                                        width: '100%',
                                        mt: '8px',
                                        padding: isMobile ? '0 0 0 0px' : '0 6px 0 0',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '0.75rem !important'
                                        }
                                    }}
                                    onOpen={onOpenGoalsHandle}
                                    options={flatArrayOfObjects(goals, 'SubGoals').map(goal => goal['title' as keyof typeof goal])}
                                    value={selectedOptions.GOALS}
                                    onChange={onGoalSelectHandle}
                                    freeSolo
                                    renderOption={(props, option, {selected}) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{marginRight: 8}}
                                                checked={selected}
                                            />
                                            {option}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='outlined'
                                            label={t('Goals')}
                                            placeholder={t('+ Add task to goal')}
                                        />
                                    )}
                                    PaperComponent={({children}) => (
                                        <Paper
                                            style={{
                                                borderRadius: '0.5rem',
                                                color: theme.palette.textColorThird.dark,
                                            }}
                                        >
                                            {children}
                                        </Paper>
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            fullWidth
                            multiline
                            minRows={isMobile ? 3 : 10}
                            maxRows={Infinity}
                            disabled={isTaskSending}
                            label={`${t('+ Add description for task')}`}
                            size='small'
                            variant="outlined"
                            value={newTask.description}
                            onChange={newTaskDescriptionHandle}
                            sx={{mt: '8px', pr: isMobile ? '0px' : '6px'}}
                            InputProps={{
                                autoComplete: 'off',
                                sx: {
                                    borderRadius: '0.75rem'
                                }
                            }}
                        />
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                width: '100%',
                                border: '1px solid',
                                borderRadius: '0.75rem',
                                borderColor: theme.palette.borderColor.main,
                                mt: '8px',
                                mr: isMobile ? '0px' : '6px',
                                padding: '7px 0px'
                            }}
                        >
                            <FormGroup sx={{pl: '16px'}}>
                                <FormControlLabel
                                    control={<Checkbox
                                        name='isImportant'
                                        checked={isImportant}
                                        onChange={handleImportant}
                                        sx={{
                                            padding: 0,
                                            mr: '4px',
                                            '& .MuiTypography-root': {
                                                fontWeight: '500'
                                            }
                                        }}
                                    />}
                                    sx={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        userSelect: 'none',
                                        color: theme.palette.textColorThird.dark,
                                    }}
                                    label={t('Is important task')}
                                />
                            </FormGroup>
                        </Box>
                        <RecurringAddForm
                            recurringConfigHandle={recurringConfigHandle}
                            date={day.date}
                            validateCounter={validateCounter}
                            sendCounter={sendCounter}
                        />
                        <Button
                            variant='contained'
                            fullWidth={true}
                            onClick={handleRemindClick}
                            sx={{
                                width: '100%',
                                borderRadius: '0.75rem',
                                mt: '8px',
                                mr: isMobile ? '0px' : '6px',
                                padding: '8px 8px 7px 6px',
                            }}
                            startIcon={<NotificationsActiveIcon/>}
                        >
                            {t('Is remind me')}
                        </Button>
                    </Box>
                </Box>
                <RemindOptionsDialog
                    open={isOpenRemindDialog}
                    sendCounter={sendCounter}
                    initialRemindOptions={remindOptions}
                    isRecurring={recurringConfig.isRecurring}
                    handleOptions={handleRemindOptions}
                    onClose={handleRemindClose}
                />
            </Collapse>
        </Fragment>
    )
}