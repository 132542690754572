import React, {FC, Fragment, useEffect, useState} from 'react';
import {
    Alert,
    Box,
    CircularProgress,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {getCompletedTasks} from '../../store/task/taskSlice';
import {AppObserver} from '../utilities/AppObserver';
import {CompletedTask} from './CompletedTask';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import {TransitionGroup} from 'react-transition-group';
import {TGetTasksResponse} from '../../models/response/TGetTasksResponse';
import {getDayName, sortDaysByDate} from '../../utils/helpers';

export const CompletedTasksList: FC = () => {

    const {t} = useTranslation();

    const dispatch = useAppDispatch();

    const theme = useTheme();
    const {user} = useAppSelector(state => state.auth);
    const {completed, isTaskLoading} = useAppSelector(state => state.task);
    const {isMobile} = useAppSelector(state => state.utils);

    const [days, setDays] = useState<TGetTasksResponse>({});

    const intersect = () => {
        dispatch(getCompletedTasks({page: completed.page, limit: 15}));
    }

    useEffect(() => {
        if (JSON.stringify(days) !== JSON.stringify(completed.days)) {
            setDays(completed.days);
        }
    }, [completed.days, days]);

    return (
        <Box>
            <Typography color={theme.palette.success.main}
                        variant='h5'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '12px',
                            fontSize: '1.5rem',
                            fontWeight: '500 !important',
                            lineHeight: '1.2',
                        }}>
                {t('Completed')}
            </Typography>
            <Box sx={{overflowY: 'auto', maxHeight: !isMobile ? 'calc(100vh - 180px)' : 'calc(100vh - 180px)'}}>
                <List dense={true}>
                    <TransitionGroup>
                        {sortDaysByDate(Object.keys(days)).map(date => (
                            <Collapse key={date}>
                                <Fragment>
                                    <ListItem
                                        component={NavLink}
                                        to={`/tasks/${date}`}
                                        sx={{width: 'fit-content', padding: '8px 0px'}}
                                        style={{
                                            textDecorationColor: theme.palette.success.main
                                        }}
                                    >
                                        <ListItemIcon>
                                            <FactCheckIcon color='success'/>
                                        </ListItemIcon>
                                        <Tooltip title={t('Click to go') + ' ' + date} arrow>
                                            <ListItemText
                                                primary={`${date} - ${t(getDayName(date))}`}
                                                primaryTypographyProps={{
                                                    marginLeft: '-16px',
                                                    fontSize: '1rem',
                                                    fontWeight: '500',
                                                    color: theme.palette.success.main
                                                }}
                                            />
                                        </Tooltip>
                                    </ListItem>
                                    <Divider sx={{marginTop: '6px', marginBottom: '4px'}}/>
                                    <TransitionGroup>
                                        {
                                            days[date].Tasks.length >= user.level.tasksCompletedMaxCount ?
                                                days[date].Tasks.slice(0, 3).map(task => (
                                                    <Collapse key={task.id}
                                                              sx={{
                                                                  marginLeft: isMobile ? '0px' : '24px'
                                                              }}
                                                    >
                                                        <Fragment key={task.id}>
                                                            <CompletedTask day={days[date]} task={task}/>
                                                            <Divider sx={{marginBottom: '6px'}}/>
                                                        </Fragment>
                                                    </Collapse>
                                                ))
                                                :
                                                days[date].Tasks.map(task => (
                                                    <Collapse key={task.id}
                                                              sx={{
                                                                  marginLeft: isMobile ? '0px' : '24px'
                                                              }}
                                                    >
                                                        <Fragment key={task.id}>
                                                            <CompletedTask day={days[date]} task={task}/>
                                                            <Divider sx={{marginBottom: '6px'}}/>
                                                        </Fragment>
                                                    </Collapse>
                                                ))
                                        }
                                        {
                                            days[date].Tasks.length >= user.level.tasksCompletedMaxCount &&
                                            <Alert variant={"outlined"} severity="info"
                                                   sx={{
                                                       marginBottom: 2,
                                                       marginLeft: '24px'
                                                   }}
                                            >
                                                {t('You can see only _ elements of the list. To remove the restriction, change the subscription!', {
                                                    count: user.level.tasksCompletedMaxCount,
                                                })}
                                            </Alert>
                                        }
                                    </TransitionGroup>
                                </Fragment>
                            </Collapse>
                        ))}
                    </TransitionGroup>
                </List>
                {isTaskLoading &&
                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '6px 0 18px 0'}}>
                        <CircularProgress/>
                    </Box>
                }
                {!Object.keys(completed.days).length && !isTaskLoading && !completed.observable &&
                    <Alert variant={"outlined"} severity="info">
                        {t('You have not completed any task yet!')}
                    </Alert>
                }
                {!completed.observable && Object.keys(completed.days).length ?
                    <Alert variant={"outlined"} severity="info" sx={{marginBottom: 2}}>
                        {t('These are all completed tasks!')}
                    </Alert>
                    :
                    ''
                }


                {completed.observable &&
                    <AppObserver intersect={intersect} observing={completed.observable} key={completed.page}/>}
            </Box>
        </Box>
    )
}
