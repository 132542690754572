import React, {FC} from 'react';
import {Box, Container,} from '@mui/material';
import {useAppSelector} from '../../hooks/redux';
import {PlannedTasksList} from '../../components/planned/PlannedTasksList';

export const PlannedTasks: FC = () => {

    const {isMobile} = useAppSelector(state => state.utils);

    return (
        <Container
            maxWidth='lg'
            disableGutters={true}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflowY: 'auto',
                    paddingBottom: '16px',
                    marginLeft: '5px',
                    marginRight: '5px'
                }}
            >
                <Box sx={{
                    transition: 'all .5s !important',
                    width: '100%',
                    maxWidth: isMobile ? '96%' : '100%',
                }}>
                    <Box sx={{mt: isMobile ? 0 : 3}}>
                        <PlannedTasksList/>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}
