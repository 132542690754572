import React, {FC, useRef, useState} from 'react';
import {Box, Button, CircularProgress, TextField,} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {INote} from "../../models/INote";
import {addNote} from "../../store/note/noteSlice";
import {setSnackbar} from '../../store/utils/utilsSlice';
import {formatTitle} from "../../utils/helpers";
import {setShowBuyPremiumDialogStatus} from "../../store/subscription/subscriptionSlice";

export const NoteAddInput: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {isTaskSending} = useAppSelector(state => state.task);
    const {totalCount} = useAppSelector(state => state.note);
    const {user} = useAppSelector(state => state.auth);

    const [newNote, setNewNote] = useState<INote>({title: ''} as INote);
    const inputRef = useRef<HTMLDivElement>(null);

    const newNoteHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewNote({...newNote, title: e.target.value});
    }

    const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.ctrlKey && e.key === 'Enter') addNewNote();
    }

    const addNewNote = () => {
        if (newNote.title && newNote.title?.trim() !== '') {

            if (totalCount >= user.level.notesMaxCount) {
                dispatch(setShowBuyPremiumDialogStatus(true));
                return;
            }

            const formattedTitle = formatTitle({title: newNote.title, addSpaseBefore: true});

            dispatch(addNote({note: newNote, tagTitles: formattedTitle.tagTitles}))
                .unwrap()
                .then((originalPromiseResult) => {
                    setNewNote({...newNote, title: ''});
                    const node = inputRef.current as any;
                    node.focus();
                });
        } else {
            dispatch(setSnackbar({
                text: 'Note cannot be empty',
                severity: 'error',
                open: true
            }));
        }
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <TextField
                multiline
                fullWidth
                autoFocus={isMobile ? false : true}
                minRows={3}
                maxRows={Infinity}
                inputRef={inputRef}
                disabled={isTaskSending}
                label={`${t('+ Add note')}`}
                size='small'
                variant="outlined"
                sx={{
                    marginRight: '6px',
                    marginLeft: isMobile ? '6px' : '0px',
                    "& .MuiOutlinedInput-input": {
                        resize: "vertical",
                    },
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '0.75rem'
                    }
                }}
                InputProps={{
                    autoComplete: 'off'
                }}
                value={newNote.title}
                onChange={newNoteHandle}
                onKeyDown={handleEnterPress}
            />
            <Button
                disabled={isTaskSending}
                variant="contained"
                sx={{marginRight: isMobile ? '6px' : '0px'}}
                onClick={addNewNote}
                endIcon={
                    isTaskSending
                        ?
                        <CircularProgress sx={{color: '#a6a6a6'}} size={'1rem'}/>
                        :
                        undefined
                }
            >
                {t('Add')}
            </Button>
        </Box>
    )
}
