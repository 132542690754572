import React, {FC, useEffect} from 'react';
import {Card, CardContent, Container, Typography} from '@mui/material';
import {useAppDispatch} from '../../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import {checkSocialLogin} from "../../../store/auth/authSlice";
import i18n from '../../../localization/i18n';
import {getFromStorage, saveToStorage} from "../../../utils/storageHelpers";

export const SocialLoginForm: FC = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const confirmHash = queryParams.get('data');
        if (confirmHash) {
            dispatch(checkSocialLogin({
                confirmHash: confirmHash,
            }))
                .unwrap()
                .then(async (originalPromiseResult) => {
                    if (originalPromiseResult.userExist) {
                        i18n.changeLanguage(originalPromiseResult.authData.user.lang);
                        await saveToStorage('lang', originalPromiseResult.authData.user.lang);
                        await getFromStorage('relatedAccounts')
                            .then(relatedAccounts => {
                                if (relatedAccounts.value) {
                                    navigate(`/related-accounts-mode`, {replace: true});
                                } else {
                                    navigate(`/tasks`);
                                }
                            });
                    } else {
                        navigate(`/account-select-mode`, {replace: true});
                    }
                })
                .catch((error) => {
                    console.error(error.msg);
                });
        } else {
            navigate(`/tasks`, {replace: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth="lg">
            <Card sx={{mt: 3, borderRadius: '0.625rem !important'}}>
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        {t('Please wait, checking...')}
                    </Typography>
                </CardContent>
            </Card>
        </Container>
    )
};
