import React, {FC, useEffect, useRef, useState} from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    TextField,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {ITask} from '../../models/ITask';
import {useTranslation} from 'react-i18next';
import {ITabDay} from '../../models/days/ITabDay';
import {addTask, getUserTags} from '../../store/task/taskSlice';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {getGoals} from '../../store/goal/goalSlice';
import {flatArrayOfObjects} from '../../utils/helpers';
import {setShowBuyPremiumDialogStatus} from "../../store/subscription/subscriptionSlice";

interface IProps {
    day: ITabDay
}

type OptionsToShow = 'TAGS' | 'GOALS';

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export const PlannedTaskAddInput: FC<IProps> = ({day, ...props}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {planned, isTaskSending, tags} = useAppSelector(state => state.task);
    const {goals} = useAppSelector(state => state.goal);
    const {user} = useAppSelector(state => state.auth);

    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);
    const autocompleteRef = useRef<HTMLInputElement>(null);

    const [newTask, setNewTask] = useState<ITask>({title: '', isPlanned: true} as ITask);
    const [optionToShow, setOptionToShow] = useState<OptionsToShow>('TAGS');
    const [selectedOptions, setSelectedOptions] = useState<{
        [key in OptionsToShow]: string[]
    }>({
        'TAGS': [],
        'GOALS': []
    });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isPopoverOpen = Boolean(anchorEl);

    const handleOpen = () => {
        setAnchorEl(ref.current);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const newTaskHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTask(prevState => {
            return {
                ...prevState,
                title: e.target.value.replace(/#|@/g, '')
            }
        });
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') addNewTask();
        if (e.key === '#') {
            setOptionToShow('TAGS');
            handleOpen();
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Escape') {
            handleClose();
            inputRef.current?.focus();
        }
    }

    const handleOpenOptions = () => {
        if (optionToShow === 'TAGS' && !tags.fetched) {
            dispatch(getUserTags(false));
        }
        if (optionToShow === 'GOALS' && !goals.length) {
            dispatch(getGoals());
        }
    }

    const handleSelectOptions = (event: any, value: string[]) => {
        setSelectedOptions(prevState => {
            return {
                ...prevState,
                [optionToShow]: value
            }
        });
    }

    const handleTagRemove = (tagTitle: string) => {
        setSelectedOptions(prevState => {
            return {
                ...prevState,
                TAGS: prevState.TAGS.filter(tag => tag !== tagTitle)
            }
        });
    }

    const handleGoalRemove = (goalTitle: string) => {
        setSelectedOptions(prevState => {
            return {
                ...prevState,
                GOALS: prevState.GOALS.filter(goal => goal !== goalTitle)
            }
        });
    }

    const addNewTask = () => {
        if (newTask.title.trim() !== '') {

            if (planned.totalCount >= user.level.tasksPlannedMaxCount) {
                dispatch(setShowBuyPremiumDialogStatus(true));
                return;
            }

            dispatch(addTask({
                task: {
                    ...newTask,
                    title: newTask.title,
                    isImportant: newTask.title.includes('!')
                },
                date: day.date,
                tagTitles: selectedOptions.TAGS,
                goalTitles: selectedOptions.GOALS
            }))
                .unwrap()
                .then((originalPromiseResult) => {
                    setNewTask({...newTask, title: ''});
                    setSelectedOptions({TAGS: [], GOALS: []});

                    const node = inputRef.current as any;
                    node.focus();
                });
        } else {
            dispatch(setSnackbar({
                text: 'Task title cannot be empty',
                severity: 'error',
                open: true
            }));
        }
    }

    useEffect(() => {
        if (autocompleteRef.current) {
            autocompleteRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autocompleteRef.current]);

    return (
        <Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <TextField
                    id='tags-goals-autocomplete'
                    inputRef={inputRef}
                    ref={ref}
                    autoFocus={isMobile ? false : true}
                    fullWidth
                    label={`${t('+ Add planned task')}`}
                    size='small'
                    variant="outlined"
                    sx={{
                        marginRight: '6px',
                        '& .MuiInputBase-root': {
                            borderRadius: '0.5rem'
                        }
                    }}
                    InputProps={{
                        autoComplete: 'off'
                    }}
                    value={newTask.title}
                    onChange={newTaskHandle}
                    onKeyPress={handleKeyPress}
                />
                <ClickAwayListener onClickAway={handleClose}>
                    <Popper
                        aria-describedby='tags-goals-autocomplete'
                        open={isPopoverOpen}
                        anchorEl={anchorEl}
                        sx={{zIndex: 9999}}
                        transition
                    >
                        {({TransitionProps}) => (
                            <Grow {...TransitionProps} exit={false}>
                                <Paper
                                    sx={{
                                        width: ref.current ? ref.current.offsetWidth : '',
                                        maxHeight: '300px',
                                        p: 1
                                    }}
                                >
                                    <Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        openOnFocus
                                        freeSolo
                                        id={optionToShow}
                                        size='small'
                                        onOpen={handleOpenOptions}
                                        options={
                                            optionToShow === 'TAGS'
                                                ?
                                                tags.tagList.map(tag => tag.title)
                                                :
                                                flatArrayOfObjects(goals, 'SubGoals').map(goal => goal['title' as keyof typeof goal])
                                        }
                                        value={selectedOptions[optionToShow]}
                                        onChange={handleSelectOptions}
                                        renderOption={(props, option, {selected}) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{marginRight: 8}}
                                                    checked={selected}
                                                />
                                                {option}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                inputRef={autocompleteRef}
                                                variant='outlined'
                                                label={optionToShow === 'TAGS' ? t('Tags') : t('Goals')}
                                                placeholder={optionToShow === 'TAGS' ? t('+ Add new tag') : t('+ Add task to goal')}
                                                onKeyDown={handleKeyDown}
                                                {...params}
                                            />
                                        )}
                                        componentsProps={{
                                            paper: {
                                                sx: {
                                                    mt: '6px'
                                                }
                                            }
                                        }}
                                    />
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </ClickAwayListener>
                <Button
                    variant="contained"
                    sx={{pointerEvents: isTaskSending ? 'none' : 'auto'}}
                    onClick={addNewTask}
                >
                    {t('Add')}
                </Button>
            </Box>
            <Box>
                {selectedOptions.TAGS.map((selectedTag, i) => (
                    <Chip
                        variant='outlined'
                        size='small'
                        label={'#' + selectedTag}
                        sx={{m: '6px 4px 0px 0px'}}
                        onDelete={() => handleTagRemove(selectedTag)}
                        key={selectedTag + i}
                    />
                ))}
            </Box>
            <Box>
                {selectedOptions.GOALS.map((selectedGoal, i) => (
                    <Chip
                        variant='outlined'
                        size='small'
                        label={'@' + selectedGoal}
                        sx={{m: '6px 4px 0px 0px', borderRadius: '4px'}}
                        onDelete={() => handleGoalRemove(selectedGoal)}
                        key={selectedGoal + i}
                    />
                ))}
            </Box>
        </Box>
    )
}
