import React, {FC, Fragment, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Collapse,
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import {useAppSelector} from '../hooks/redux';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CheckIcon from '@mui/icons-material/Check';
import TodayIcon from '@mui/icons-material/Today';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const releaseNotes = [
    {
        date: '2022-12-09',
        notes: [
            'Added edit dialog to goal page',
            'Improved agenda functionality',
            'Added DnDPicker to agenda',
            'Added add and edit functionality to journal',
            'Now tag tasks not linking to days when completing',
            'Added aliases',
            'Added delete functionality to recurrings page',
            'Added edit dialog to recurrings page',
            'Added list archiving',
            'Added lists copying',
            'Added DnDPicker to lists',
            'Added scrolling containers to mobile view',
            'Changed palette default background color for light mode',
        ]
    },
    {
        date: '2022-11-03',
        notes: [
            'Added task edit',
            'Added deleting all tasks in day',
            'Added list renaming and removing',
            'Added days and planned dropzone to overdue',
            'Added change password form',
            'Added Restore password form',
            'Added recurring events to notifications',
            'Added hints',
            'Linked tags with goals',
            'Disabled access to auth pages for authorized users',
            'Made timezones suported to all browsers',
            'Made notifications clickable',
            'Fixed tag border color depending on tag color'
        ]
    },
    {
        date: '2022-10-05',
        notes: [
            'Added notifications',
            'Added tasks tooltip in kicker',
            'Added timezone name, offset and language to user entity in db',
            'Added autocomplete popper to planned and task add input',
            'Added selecting timezone',
            'Changed Notes list with favorite',
            'Added logger to to all microservices',
            'Made goals sortable',
            'Refactored goals from path to parentid',
            'Added tags page to Notes',
            'Changed Note\'s dialogs'
        ]
    },
    {
        date: '2022-09-07',
        notes: [
            'Added functionality for linking tasks to goals',
            'Added goal deleting functionality',
            'Added editable input to goals',
            'Added copy DnDPicker',
            'Added auto scroll to today in kicker',
            'Added view, edit and delete functionality for notes',
            'Added toggle for favorite notes',
            'Added tags for notes',
            'Fixed notes columns'
        ]
    },
    {
        date: '2022-08-30',
        notes: [
            'Added sub-goals to goals',
            'Added recurrings page',
            'Added functionality to delete date and planned in list',
            'Fixed invalid date on toggle planned and change task day by dnd',
            'Fixed tag color for recurring tasks'
        ]
    },
    {
        date: '2022-08-25',
        notes: [
            'Added dnd calendar to planned and overdue',
            'Added is important to task settings',
            'Added different tab for overdue days',
            'Added selection of tag text color depending on the background color',
            'Disabled remove tags in completed task list',
            'Fixed upd tag list on new tag when edit tasks'
        ]
    },
    {
        date: '2022-08-16',
        notes: [
            'Made orders for each list',
            'Added draggable and reorder functionality to tag tasks',
            'Added draggable functionality to overdue tasks',
            'Added important flag to tasks',
            'Added color edit to tags',
            'Now filter by default "all"',
            'Fixed area of visibility on can drop'
        ]
    },
    {
        date: '2022-08-03',
        notes: [
            'Added tag lists',
            'Added copy task on ctrl + dnd',
            'Added area of visibility on can drop',
            'Changed max length for notes, user day comments and task descriptions'
        ]
    },
    {
        date: '2022-07-29',
        notes: [
            'Added tags for tasks',
            'Added uncomplete functionality',
            'Added day comment functionality',
            'Added Journal',
            'Disabled unnecessary autoComplete on inputs'
        ]
    },
    {
        date: '2022-07-24',
        notes: [
            'Big code refactoring',
            'Added display of all, in progress and completed tasks',
            'Added planned task toggle by dnd',
            'Now planned tasks are added from top to bottom',
            'Made snackbar reusable'
        ]
    },
    {
        date: '2022-07-19',
        notes: [
            'Added Kicker and filter bar',
            'Added loading screen',
            'Added "auto" theme mode',
            'Changed task day query to route param',
            'Changed day popovers to titles',
            'Fixed update overdue and agenda on change task day',
            'Fixed styles for Firefox'
        ]
    },
    {
        date: '2022-07-14',
        notes: [
            'Added support touch events to dnd',
            'Removed autofocus on mobile devices',
            'Removed unnecessary collaps transitions',
            'Fixed width on mobile devices',
            'Fixed extra reorder on dnd actions'
        ]
    },
    {
        date: '2022-07-13',
        notes: [
            'Rewritten dnd with new library',
            'Added dnd on day tabs',
            'Added dnd on sidedar menu',
            'Optimized constants and sidebar icons'
        ]
    },
    {
        date: '2022-07-05',
        notes: [
            'Added changing task day',
            'Fix tasks scrolling container',
            'Fix planned tasks dnd placeholder',
            'Added missing translation'
        ]
    },
    {
        date: '2022-06-30',
        notes: [
            'Added task settings',
            'Added transition group',
            'Added missing translation',
            'Added scrolling container to tasks'
        ]
    },
    {
        date: '2022-06-29',
        notes: [
            'Added recurring add form',
            'Added day-link to agenda, overdue, completed, deleted',
            'Added logout from all devices'
        ]
    },
    {
        date: '2022-06-26',
        notes: [
            'Added multisession',
            'Extended validate refresh token',
            'Fixed store on logout',
            'Minor styles fixes'
        ]
    },
    {
        date: '2022-06-24',
        notes: [
            'Added agenda page',
            'Added scrolling containers',
            'Added page headers',
            'Added loading indicators in goals and notes and now in note you have a text area',
            'Now after login you are directed to tasks'
        ]
    },
    {
        date: '2022-06-23',
        notes: [
            'Added deleted page',
            'Impoved some styles',
            'Fixed reorder on complete and delete',
            'Added missing translation'
        ]
    },
    {
        date: '2022-06-22',
        notes: [
            'Added dnd on tasks and planned page',
            'Fix order in completed and overdue tasks',
            'Added autoFocus on inputs',
            'Added "date" query param and preselect tab on reload to tasks page',
            'New side menus',
            'Fixed padding'
        ]
    }
];

export const Home: FC = () => {

    const {isMobile} = useAppSelector(state => state.utils);

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(prevState => !prevState);
    }

    return (
        <Container
            disableGutters
            maxWidth={false}
            sx={{px: isMobile ? '0px' : '12px'}}
        >
            <Box
                sx={{
                    marginTop: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Box sx={{transition: 'all .5s !important', width: '100%', maxWidth: isMobile ? '90%' : '90%'}}>
                    <Alert variant={"outlined"} severity='warning'>This page is still in
                        developing!</Alert>
                    <Box sx={{
                        border: '2px solid #81c784',
                        borderRadius: '4px',
                        marginTop: '12px',
                        padding: '6px 16px',
                        overflowY: 'auto',
                        maxHeight: isMobile ? '' : '74vh',
                        marginBottom: isMobile ? '12px' : '0px'
                    }}>
                        <Typography variant='h5' sx={{display: 'flex', alignItems: 'center'}}>
                            <NewReleasesIcon color='success' sx={{marginRight: '12px'}}/>
                            Release notes
                        </Typography>
                        <List dense={true}>
                            <ListItem sx={{paddingRight: '0px', paddingLeft: '0px'}}>
                                <ListItemIcon>
                                    <TodayIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={releaseNotes[0].date}
                                    primaryTypographyProps={{marginLeft: '-16px'}}
                                />
                            </ListItem>
                            {releaseNotes[0].notes.map(note => (
                                <ListItem sx={{paddingRight: '0px', paddingLeft: '38px'}} key={note}>
                                    <ListItemIcon>
                                        <CheckIcon/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={note}
                                        primaryTypographyProps={{marginLeft: '-16px'}}
                                    />
                                </ListItem>
                            ))}
                            <Box sx={{display: 'flex', justifyContent: 'center', margin: '18px 0 18px 0'}}>
                                <Button
                                    variant="outlined"
                                    color='success'
                                    endIcon={open ? <ExpandLess/> : <ExpandMore/>}
                                    onClick={handleClick}
                                >
                                    {open
                                        ?
                                        'Hide previous release notes'
                                        :
                                        'Show previous release notes'
                                    }
                                </Button>
                            </Box>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                {releaseNotes.map((date, i) => (
                                    i !== 0 &&
                                    <Fragment key={date.date}>
                                        <ListItem sx={{paddingRight: '0px', paddingLeft: '0px'}}>
                                            <ListItemIcon>
                                                <TodayIcon/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={date.date}
                                                primaryTypographyProps={{marginLeft: '-16px'}}
                                            />
                                        </ListItem>
                                        {date.notes.map(note => (
                                            <ListItem sx={{paddingRight: '0px', paddingLeft: '38px'}} key={note}>
                                                <ListItemIcon>
                                                    <CheckIcon/>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={note}
                                                    primaryTypographyProps={{marginLeft: '-16px'}}
                                                />
                                            </ListItem>
                                        ))}
                                    </Fragment>
                                ))}
                            </Collapse>
                        </List>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
};
