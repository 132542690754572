import React, {FC, useEffect, useState} from 'react';
import {Box, styled, Tab, Tabs, TabScrollButton, useTheme} from "@mui/material";
import {TabPanel} from './TabPanel';
import {useNavigate, useParams} from 'react-router-dom';
import {useDays} from '../../../hooks/days';
import {TabLabel} from './TabLabel';
import {useAppSelector} from '../../../hooks/redux';
import {changeTimezone, formatDateWithTimezone} from '../../../utils/helpers';

interface IDaysTabs {
    children?: React.ReactNode;
}

function a11yProps(index: number) {
    return {
        id: `tasks-tab-${index}`,
        "aria-controls": `tasks-tabpanel-${index}`
    };
}

const MyTabScrollButton = styled(TabScrollButton)({
    '&.Mui-disabled': {
        width: 0,
    },
    overflow: 'hidden',
    transition: 'width 0.5s',
    width: 28,
    borderBottom: '1px solid #c6c6c6'
});

const CustomTab = styled(Tab)`
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 8px 8px;
  display: flex;
  justify-content: center;
`;

export const DaysTabs: FC<IDaysTabs> = ({children, ...props}) => {

    let navigate = useNavigate();
    const {date} = useParams();
    const theme = useTheme();
    const {days, queryDayIndex} = useDays();

    const {user} = useAppSelector(state => state.auth);

    const [value, setValue] = useState<number>(queryDayIndex);
    const today = formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName));

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (date && (date < today || date > days[6].date)) {
            setValue(newValue - 1);
        } else {
            setValue(newValue);
        }

        navigate('/tasks/' + days[newValue].date);
    };

    useEffect(() => {
        if (!date) {
            navigate('/tasks/' + today);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box style={{
            display: 'grid',
        }}>
            <Tabs
                ScrollButtonComponent={MyTabScrollButton}
                allowScrollButtonsMobile
                variant="scrollable"
                value={value}
                onChange={handleChange}
                sx={{
                    '& .MuiTypography-caption': {
                        fontSize: '12px'
                    },
                    '& .MuiTypography-body1': {
                        fontWeight: '500'
                    },
                }}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: "transparent"
                    }
                }}
            >
                {days.map((tabDay, i) => (
                    <CustomTab
                        label={
                            <TabLabel
                                value={value}
                                index={i}
                                currIndex={value}
                                days={days}
                            />
                        }
                        {...a11yProps(i)}
                        sx={{
                            color: theme.palette.primary.main,
                            minWidth: 'fit-content',
                            flex: 1,
                            padding: 0,
                            borderLeft: value === i ? `1px solid ${theme.palette.borderColor.main}` : 'none',
                            borderTop: value === i ? `1px solid ${theme.palette.borderColor.main}` : 'none',
                            borderRight: value === i ? `1px solid ${theme.palette.borderColor.main}` : 'none',
                            borderBottom: value !== i ? `1px solid ${theme.palette.borderColor.main}` : 'none',
                            borderRadius: '0.75rem 0.75rem 0 0',
                            '&:hover': {
                                color: theme.palette.primary.dark,
                            }
                        }}
                        key={tabDay.date}
                    />
                ))}
            </Tabs>
            {days.map((tabDay, i) => (
                <TabPanel
                    value={value}
                    index={i}
                    key={tabDay.date}
                >
                    {React.cloneElement(children as React.ReactElement<any>, {day: tabDay})}
                </TabPanel>
            ))}
        </Box>
    )
}
