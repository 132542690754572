import React, {FC, useEffect, useState} from 'react';
import {Link as routerLink} from 'react-router-dom';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Link,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {useRegisterForm} from '../../validation/auth/register-form';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import {SocialLoginButton} from "./social/SocialLoginButton";
import {getFromStorage} from "../../utils/storageHelpers";

export const RegistrationForm: FC = () => {

    const {t} = useTranslation();
    const theme = useTheme();
    const {registerInitialValues, registerValidationSchema, onSubmit} = useRegisterForm();
    const [referralCode, setReferralCode] = useState<string>('');

    useEffect(() => {
        const runUseEffect = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const referral = queryParams.get('referral');
            if (referral) {
                setReferralCode(referral);
            } else {
                await getFromStorage('referral')
                    .then(referral => {
                        if (referral.value) {
                            setReferralCode(referral.value);
                        }
                    });
            }
        };
        runUseEffect();
    }, []);

    const formik = useFormik({
        initialValues: registerInitialValues,
        validationSchema: registerValidationSchema,
        onSubmit: onSubmit
    });

    const setFieldValue = (hiddenField: string, dynamicVariable: string) => {
        formik.setFieldValue(hiddenField, dynamicVariable);
    }

    return (
        <Card sx={{maxWidth: '540px', borderRadius: '0.625rem !important'}}>
            <CardContent sx={{padding: '2.5rem !important'}}>
                <Avatar sx={{m: 'auto', bgcolor: theme.palette.primary.main}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1"
                            variant="h5"
                            sx={{
                                textAlign: 'center',
                                fontWeight: '700 !important',
                                mt: 1,
                                marginBottom: '32px'
                            }}
                >
                    {t('Sign Up')}
                </Typography>
                <Box component="form" onSubmit={formik.handleSubmit} sx={{mt: 1}}>
                    <TextField
                        margin='normal'
                        fullWidth
                        id="name"
                        name="name"
                        label={t("name")}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        sx={{
                            marginTop: '0px',
                            marginBottom: '2rem !important',
                            "& .MuiOutlinedInput-root": {
                                padding: "0px 0px 0px 8px",
                                "&:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0 100px #000000 inset",
                                    WebkitTextFillColor: "default",
                                },
                            }
                        }}
                        InputProps={{
                            sx: {
                                borderRadius: '0.75rem',
                            },
                            inputProps: {
                                style: {
                                    padding: '0.775rem 1rem',

                                }
                            }
                        }}
                    />
                    <TextField
                        margin='normal'
                        fullWidth
                        id="email"
                        name="email"
                        label={t("email")}
                        autoComplete="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        sx={{
                            marginTop: '0px',
                            marginBottom: '2rem !important',
                            "& .MuiOutlinedInput-root": {
                                padding: "0px 0px 0px 8px",
                                "&:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0 100px #000000 inset",
                                    WebkitTextFillColor: "default",
                                },
                            }
                        }}
                        InputProps={{
                            sx: {
                                borderRadius: '0.75rem',
                            },
                            inputProps: {
                                style: {
                                    padding: '0.775rem 1rem',

                                }
                            }
                        }}
                    />
                    <TextField
                        margin='normal'
                        fullWidth
                        id="password"
                        name="password"
                        label={t("Password")}
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        sx={{
                            marginTop: '0px',
                            marginBottom: '2rem !important',
                            "& .MuiOutlinedInput-root": {
                                padding: "0px 0px 0px 8px",
                                "&:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0 100px #000000 inset",
                                    WebkitTextFillColor: "default",
                                },
                            }
                        }}
                        InputProps={{
                            sx: {
                                borderRadius: '0.75rem',
                            },
                            inputProps: {
                                style: {
                                    padding: '0.775rem 1rem',

                                }
                            }
                        }}
                    />
                    <TextField
                        margin='normal'
                        fullWidth
                        id="confirmPassword"
                        name="confirmPassword"
                        label={t("Password confirmation")}
                        type="password"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        sx={{
                            marginTop: '0px',
                            marginBottom: '2rem !important',
                            "& .MuiOutlinedInput-root": {
                                padding: "0px 0px 0px 8px",
                                "&:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0 100px #000000 inset",
                                    WebkitTextFillColor: "default",
                                },
                            }
                        }}
                        InputProps={{
                            sx: {
                                borderRadius: '0.75rem',
                            },
                            inputProps: {
                                style: {
                                    padding: '0.775rem 1rem',

                                }
                            }
                        }}
                    />
                    <input
                        id="referralCode"
                        name="referralCode"
                        type="hidden"
                        value={formik.values.referralCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={formik.isSubmitting}
                        endIcon={
                            formik.isSubmitting
                                ?
                                <CircularProgress color='inherit' size={'1rem'}/>
                                :
                                undefined
                        }
                        onClick={() => {
                            setFieldValue("referralCode", referralCode);
                        }}
                        sx={{
                            mb: 1,
                            borderRadius: '0.75rem',
                            padding: 'calc(0.775rem + 1px) calc(1.5rem + 1px)',
                            textTransform: 'none',
                            fontSize: '1.1rem',
                            lineHeight: '1'
                        }}
                    >
                        {t('Sign Up 1')}
                    </Button>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Link component={routerLink}
                                  to="/login"
                                  variant="body2"
                                  sx={{
                                      fontWeight: '500 !important'
                                  }}
                            >
                                {t("Already have an account? Log in")}
                            </Link>
                        </Grid>
                    </Grid>
                    <Typography sx={{
                        marginTop: '16px',
                        textAlign: 'center',
                        fontSize: '0.95rem !important',
                        color: theme.palette.textColorThird.main,
                    }}>
                        {t('Or with social campaigns')}
                    </Typography>
                    <Grid container spacing={1} sx={{mt: 1, mb: 1}}>
                        <Grid item xs={12} md={6}>
                            <SocialLoginButton provider={"google"} providerTitle={"Google"}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SocialLoginButton provider={"facebook"} providerTitle={"Facebook"}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SocialLoginButton provider={"linkedin"} providerTitle={"LinkedIn"}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SocialLoginButton provider={"apple"} providerTitle={"Apple"}/>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Card>
    )
}
