import React, {CSSProperties, FC, useEffect} from 'react';
import {Alert, Box, CircularProgress, Collapse, List, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {getLastModifiedGoals} from '../../store/goal/goalSlice';
import {TransitionGroup} from 'react-transition-group';
import {Goal} from './Goal';
import {NavLink} from 'react-router-dom';

interface ILastModifiedGoalsListProps {
    style?: CSSProperties,
    height?: CSSProperties['height']
}

export const LastModifiedGoalsList: FC<ILastModifiedGoalsListProps> = ({
                                                                           style,
                                                                           height,
                                                                           ...props
                                                                       }) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {isMobile} = useAppSelector(state => state.utils);
    const {lastModifiedGoals, isGoalLoading} = useAppSelector(state => state.goal);

    useEffect(() => {
        if (!lastModifiedGoals.isFetched) {
            dispatch(getLastModifiedGoals({limit: 10}));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={style}>
            <Typography
                color='primary'
                variant='h5'
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '12px',
                    fontSize: '1.5rem',
                    fontWeight: '500 !important',
                    lineHeight: '1.2',
                }}
            >
                <NavLink
                    to={'/goals'}
                    style={{textDecoration: 'none', color: theme.palette.primary.main}}
                >
                    {t('Goals')}
                </NavLink>
            </Typography>
            <Box sx={{overflowY: 'auto', height: height ? height : '', maxHeight: !isMobile ? '76vh' : ''}}>
                <List dense={true}>
                    <TransitionGroup>
                        {lastModifiedGoals.goals.map((goal, i) => (
                            <Collapse key={goal.id}>
                                <Goal
                                    goal={goal}
                                    isEditable={false}
                                    isSubGoal={false}
                                    isGoalSending={false}
                                    dragEndHandle={() => {
                                    }}
                                    index={i}
                                />
                            </Collapse>
                        ))}
                    </TransitionGroup>
                </List>
                {isGoalLoading &&
                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '6px 0 18px 0'}}>
                        <CircularProgress/>
                    </Box>
                }
                {!isGoalLoading && !lastModifiedGoals.goals.length &&
                    <Alert variant={"outlined"} severity="info">
                        {t('There is no goal here! Plan your future and rush to the success with us!')}
                    </Alert>
                }
            </Box>
        </div>
    )
}
