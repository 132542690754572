import React, {FC} from 'react';
import {Box, IconButton, ListItem, Theme, Tooltip, Typography, useTheme} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EditIcon from '@mui/icons-material/Edit';
import {IUserDay} from '../../models/days/IUserDay';
import {getDayName} from '../../utils/helpers';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

const dayStyleByType = (type: number, theme: Theme): string => {
    let styles = '';

    switch (type) {
        case -3:
            styles = theme.palette.journalDayBlackColor.main; //'#000000'
            break;
        case -2:
            styles = theme.palette.journalDayTerribleColor.main; // theme.palette.error.main
            break;
        case -1:
            styles = theme.palette.journalDayBadColor.main; //theme.palette.action.focus
            break;
        case 0:
            styles = theme.palette.journalDayTypicalColor.main; //theme.palette.background.default
            break;
        case 1:
            styles = theme.palette.journalDayGoodColor.main; //theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.primary.main
            break;
        case 2:
            styles = theme.palette.journalDayLuckyColor.main; //theme.palette.info.main
            break;
        case 3:
            styles = theme.palette.journalDayPerfectColor.main; //theme.palette.success.main
            break;
        default:
            break;
    }

    return styles;
}

interface IJournalDay {
    day: {
        id: number,
        date: string,
        UserDays: IUserDay
    },
    handleOpenEdit: (date?: string) => void
}

export const JournalDay: FC<IJournalDay> = ({day, handleOpenEdit, ...props}) => {

    const {t} = useTranslation();

    const theme = useTheme();

    return (
        <Box sx={{marginBottom: '16px'}}>
            <ListItem sx={{padding: '0'}}>
                <Box
                    sx={{
                        width: '100%',
                        border: '1px solid',
                        borderRadius: '0.5rem',
                        borderColor: theme.palette.borderColor.main,
                    }}
                >
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignAtems: 'center'}}>
                        <Box
                            component={'span'}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '6px',
                                width: 'fit-content',
                                borderBottom: '1px solid',
                                borderRight: '1px solid',
                                borderColor: theme.palette.borderColor.main, //theme.palette.mode === 'dark' ? '#494949' : '#c4c4c4',
                                borderLeft: `8px solid ${dayStyleByType(day.UserDays.type, theme)}`,
                                borderTopLeftRadius: '0.5rem',
                                borderEndEndRadius: '0.5rem',
                                padding: '8px',
                                color: theme.palette.journalDayDateColor.main,
                            }}
                        >
                            <CalendarTodayIcon/>
                            <Tooltip title={t('Click to go') + ' ' + day.date} arrow>
                                <Typography
                                    component={NavLink}
                                    to={`/tasks/${day.date}`}
                                    sx={{
                                        fontWeight: '500',
                                        color: theme.palette.journalDayDateColor.main,
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: theme.palette.journalDayDateHoverColor.main,
                                        }
                                    }}
                                >
                                    {day.date + ' - ' + t(getDayName(day.date))}
                                </Typography>
                            </Tooltip>
                        </Box>
                        <IconButton onClick={() => handleOpenEdit(day.date)}>
                            <EditIcon/>
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: '70px',
                            padding: '20px'
                        }}>
                        {day.UserDays.comment}
                    </Box>
                </Box>
            </ListItem>
        </Box>
    )
}
