import React, {FC, ReactElement} from 'react';
import {Box, Divider, Drawer, IconButton, List, ListItem, styled, Typography} from '@mui/material';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import BrightnessAutoOutlinedIcon from '@mui/icons-material/BrightnessAutoOutlined';
import {getDashboardPageLink, getHomePageLink, getLinks} from '../../router/links';
import {LanguageSwitcher} from '../UI/LanguageSwitcher/LanguageSwitcher';
import ListItemIcon from '@mui/material/ListItemIcon';
import TranslateIcon from '@mui/icons-material/Translate';
import {SideBarLink} from '../UI/Links/SideBarLink';
import {TMode} from '../../models/utils/TMode';
import {useAppSelector} from '../../hooks/redux';

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '5%',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const chooseMode = (mode: TMode): { icon: ReactElement, mode: TMode } => {
    // dark -> auto -> light
    if (mode === 'dark') return {icon: <DarkModeOutlinedIcon/>, mode: 'auto'};
    if (mode === 'light') return {icon: <WbSunnyOutlinedIcon/>, mode: 'dark'};

    return {icon: <BrightnessAutoOutlinedIcon/>, mode: 'light'};
}

interface IProps {
    isAuth: boolean,
    open: boolean,

    close(): void,

    toggleTheme(mode: TMode): void
}

export const SideBarMobile: FC<IProps> = ({isAuth, open, close, toggleTheme}) => {

    const {user} = useAppSelector(state => state.auth);
    const {mode} = useAppSelector(state => state.utils);

    // const links = getLinks(user.timezoneName);

    const links = [
        isAuth ? getDashboardPageLink() : getHomePageLink(),
        ...getLinks(user.timezoneName)
    ];


    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={close}
        >
            <DrawerHeader>
                <Typography variant="h6" noWrap component="div">
                    SetTasks
                </Typography>
                <IconButton
                    sx={{ml: 1, mr: 1}}
                    onClick={() => toggleTheme(chooseMode(mode).mode)}
                    color="inherit"
                >
                    {chooseMode(mode).icon}
                </IconButton>
            </DrawerHeader>
            <Divider/>
            <List sx={{width: '220px'}}>
                {links.map(link => (
                    <Box onClick={close} key={link.name}>
                        <SideBarLink
                            link={link}
                            open={open}
                            dropHandle={() => {
                            }}
                        />
                    </Box>
                ))}
                <Divider/>
                <ListItem style={{textDecoration: 'none', paddingLeft: '20px'}} key='langSwitch'>
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 2 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <TranslateIcon/>
                    </ListItemIcon>
                    <LanguageSwitcher fullWidth size='medium'/>
                </ListItem>
            </List>
        </Drawer>
    )
}
