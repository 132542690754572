import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";
import {IDay} from "../../models/days/IDay";
import {IRecurring} from "../../models/recurring/IRecurring";
import {ITag} from "../../models/tags/ITag";
import {ITask} from "../../models/ITask";
import {TGetTagTasksResponse} from "../../models/response/TGetTagTasksResponse";
import {TGetTasksResponse} from "../../models/response/TGetTasksResponse";
import {TTaskActionsResponse} from "../../models/response/TTaskActionsResponse";
import {IRecurringFormFields} from "../../models/validation/IRecurringFormFields";
import {
    addTagsAPI,
    changeTasksDayAPI,
    completedTasksAPI,
    dayRecurringTasksAPI,
    deletedTasksAPI,
    deleteListAPI,
    editTagAPI,
    getRelatedTasksAPI,
    getTagTasksAPI,
    getUserTagsAPI,
    overdueTasksAPI,
    plannedTasksAPI,
    recurringAllAPI,
    recurringDeleteAPI,
    recurringTaskAddAPI,
    recurringTaskEditAPI,
    removeTagAPI,
    reorderTasksAPI,
    taskRemoveDateStatusAPI,
    tasksAddAPI,
    tasksCompleteAPI,
    tasksDeleteAllDeletedAPI,
    tasksDeleteAPI,
    tasksDeleteDayOverdueTasksAPI,
    tasksDeleteDayTasksAPI,
    tasksEditAPI,
    tasksTagAddAPI,
    tasksTogglePlannedAPI,
    tasksUncompleteAPI,
    tasksUndeleteAPI,
    taskTagActionAPI,
    weekRecurringTasksAPI
} from "../../services/TaskService";
import {IGoal} from "../../models/goals/IGoal";
import {IRemindOptions} from "../../models/notifications/IRemindOptions";
import {TTasksActionsResponse} from "../../models/response/TTasksActionsResponse";
import {IPlannedTasksResponse} from "../../models/response/IPlannedTasksResponse";

interface ITaskState {
    days: TGetTasksResponse,
    recurrings: {
        tasks: IRecurring[],
        fetched: boolean
    },
    completed: {
        page: number,
        observable: boolean,
        days: TGetTasksResponse
    },
    overdue: {
        page: number,
        observable: boolean,
        days: TGetTasksResponse
    },
    planned: {
        page: number,
        observable: boolean,
        tasks: ITask[],
        totalCount: number
    },
    deleted: {
        page: number,
        observable: boolean,
        days: TGetTasksResponse
    },
    tags: {
        fetched: boolean,
        archiveFetched: boolean,
        tagList: ITag[],
        tagTasks: {
            [tag: string]: {
                page: number,
                observable: boolean,
                tasks: ITask[]
            }
        }
    },
    reordering: boolean,
    isTaskLoading: boolean,
    isTaskSending: boolean,
    taskError: boolean,
    isEditable: boolean,
    tasksRelatedToGoal: {
        tasks: ITask[],
        isTasksRelatedLoading: boolean,
        tasksRelatedError: boolean
    }
}

const initialState: ITaskState = {
    days: {},
    recurrings: {
        tasks: [],
        fetched: false
    },
    completed: {
        page: 1,
        observable: true,
        days: {}
    },
    overdue: {
        page: 1,
        observable: true,
        days: {}
    },
    planned: {
        page: 1,
        observable: true,
        tasks: [],
        totalCount: 0
    },
    deleted: {
        page: 1,
        observable: true,
        days: {}
    },
    tags: {
        fetched: false,
        archiveFetched: false,
        tagList: [],
        tagTasks: {}
    },
    reordering: false,
    isTaskLoading: false,
    isTaskSending: false,
    taskError: false,
    isEditable: true,
    tasksRelatedToGoal: {
        tasks: [],
        isTasksRelatedLoading: true,
        tasksRelatedError: false,
    }
}

export const getDayRecurringTasks = createAsyncThunk(
    'task/getDayRecurringTasks',
    async (date: string, {rejectWithValue}) => {
        try {
            const response = await dayRecurringTasksAPI(date);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const getRecurrings = createAsyncThunk(
    'task/getRecurrings',
    async (_, {rejectWithValue}) => {
        try {
            const response = await recurringAllAPI();
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const getAgendaTasks = createAsyncThunk(
    'task/getAgendaTasks',
    async (date: string, {rejectWithValue}) => {
        try {
            const response = await weekRecurringTasksAPI(date);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const addTask = createAsyncThunk(
    'task/addTask',
    async ({
               task,
               date,
               notifyAt,
               notificationMethods,
               tagTitles,
               goalTitles
           }: {
        task: ITask,
        date: string,
        notifyAt?: string[],
        notificationMethods?: number[],
        tagTitles?: string[],
        goalTitles?: string[]
    }, {rejectWithValue}) => {
        try {
            const response = await tasksAddAPI(task, date, notifyAt, notificationMethods, tagTitles, goalTitles);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const addTagTask = createAsyncThunk(
    'task/addTagTask',
    async ({task, tagTitles}: { task: ITask, tagTitles: string[] }, {rejectWithValue}) => {
        try {
            const response = await tasksTagAddAPI(task, tagTitles);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const tagTaskAction = createAsyncThunk(
    'task/tagTaskAction',
    async ({
               taskId,
               tagFrom,
               actionType,
               date
           }: { taskId: number, tagFrom: string, actionType: number, date: string }, {rejectWithValue}) => {
        try {
            const response = await taskTagActionAPI(taskId, tagFrom, actionType, date);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const removeDateStatus = createAsyncThunk(
    'task/removeDateStatus',
    async (taskId: number, {rejectWithValue}) => {
        try {
            const response = await taskRemoveDateStatusAPI(taskId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const editTask = createAsyncThunk(
    'task/editTask',
    async ({
               task,
               notifyAt,
               notificationMethods,
               tagTitles,
               goalTitles,
               removedTags,
               removedGoals,
               recurringConfig,
               remindConfig
           }: {
        task: ITask,
        notifyAt?: string[],
        notificationMethods?: number[],
        tagTitles?: string[],
        goalTitles?: string[],
        removedTags?: number[],
        removedGoals?: number[],
        recurringConfig?: IRecurringFormFields,
        remindConfig?: IRemindOptions
    }, {rejectWithValue}) => {
        try {
            const response = await tasksEditAPI(task, notifyAt, notificationMethods, tagTitles, goalTitles, removedTags, removedGoals, recurringConfig, remindConfig);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const completeTask = createAsyncThunk(
    'task/completeTask',
    async (taskId: number | null, {rejectWithValue}) => {
        try {
            const response = await tasksCompleteAPI(taskId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const uncompleteTask = createAsyncThunk(
    'task/uncompleteTask',
    async (taskId: number | null, {rejectWithValue}) => {
        try {
            const response = await tasksUncompleteAPI(taskId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const deleteTask = createAsyncThunk(
    'task/deleteTask',
    async (taskId: number | null, {rejectWithValue}) => {
        try {
            const response = await tasksDeleteAPI(taskId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const undeleteTask = createAsyncThunk(
    'task/undeleteTask',
    async ({
               taskId,
               isPlanned,
               isCompleted,
               removeRecurring = false
           }: {
        taskId: number | null,
        isPlanned: boolean,
        isCompleted: boolean,
        removeRecurring?: boolean | null
    }, {rejectWithValue}) => {
        try {
            const response = await tasksUndeleteAPI(taskId, isPlanned, isCompleted, removeRecurring);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const deleteDayTasks = createAsyncThunk(
    'task/deleteDayTasks',
    async (dayId: number, {rejectWithValue}) => {
        try {
            const response = await tasksDeleteDayTasksAPI(dayId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const deleteDayOverdueTasks = createAsyncThunk(
    'task/deleteDayOverdueTasks',
    async (dayId: number, {rejectWithValue}) => {
        try {
            const response = await tasksDeleteDayOverdueTasksAPI(dayId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const deleteAllDeletedTasks = createAsyncThunk(
    'task/deleteAllDeletedTasks',
    async (_, {rejectWithValue}) => {
        try {
            const response = await tasksDeleteAllDeletedAPI();
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const togglePlanned = createAsyncThunk(
    'task/togglePlanned',
    async ({taskId, date}: { taskId: number | null, date: string }, {rejectWithValue}) => {
        try {
            const response = await tasksTogglePlannedAPI(taskId, date);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const addRecurringTask = createAsyncThunk(
    'task/addRecurringTask',
    async ({
               task,
               recurringConfig,
               remindConfig,
               tagTitles,
               currDate
           }: {
        task: ITask,
        recurringConfig: IRecurringFormFields,
        remindConfig?: IRemindOptions | null,
        tagTitles?: string[],
        currDate?: string
    }, {rejectWithValue}) => {
        try {
            const response = await recurringTaskAddAPI(task, recurringConfig, remindConfig, tagTitles, currDate);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const editRecurringTask = createAsyncThunk(
    'task/editRecurringTask',
    async ({
               task,
               date,
               recurringConfig,
               tagTitles,
               goalTitles,
               removedTags,
               removedGoals,
               remindConfig
           }: {
        task: ITask,
        date: string,
        recurringConfig: IRecurringFormFields,
        tagTitles?: string[],
        goalTitles?: string[],
        removedTags?: number[],
        removedGoals?: number[],
        remindConfig?: IRemindOptions
    }, {rejectWithValue}) => {
        try {
            const response = await recurringTaskEditAPI(task, date, recurringConfig, tagTitles, goalTitles, removedTags, removedGoals, remindConfig);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const deleteRecurring = createAsyncThunk(
    'task/deleteRecurring',
    async ({
               recurringId,
               dayId,
               deleteTask
           }: { recurringId: number, dayId: number | null, deleteTask: boolean }, {rejectWithValue}) => {
        try {
            const response = await recurringDeleteAPI(recurringId, dayId, deleteTask);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const getCompletedTasks = createAsyncThunk(
    'task/getCompletedTasks',
    async ({page, limit}: { page: number, limit: number }, {rejectWithValue}) => {
        try {
            const response = await completedTasksAPI(page, limit);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const getOverdueTasks = createAsyncThunk(
    'task/getOverdueTasks',
    async ({page, limit, today}: { page: number, limit: number, today: string }, {rejectWithValue}) => {
        try {
            const response = await overdueTasksAPI(page, limit, today);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const getPlannedTasks = createAsyncThunk(
    'task/getPlannedTasks',
    async ({page, limit}: { page: number, limit: number }, {rejectWithValue}) => {
        try {
            const response = await plannedTasksAPI(page, limit);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const getDeletedTasks = createAsyncThunk(
    'task/getDeletedTasks',
    async ({page, limit}: { page: number, limit: number }, {rejectWithValue}) => {
        try {
            const response = await deletedTasksAPI(page, limit);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const reorderTasks = createAsyncThunk(
    'task/reorderTasks',
    async ({orderId, newPosition}: { orderId: number | null, newPosition: number }, {rejectWithValue}) => {
        try {
            const response = await reorderTasksAPI(orderId, newPosition);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const changeTasksDay = createAsyncThunk(
    'task/changeTasksDay',
    async ({
               date,
               taskId,
               orderId,
               listType
           }: { date: string, taskId: number, orderId: number, listType: number }, {rejectWithValue}) => {
        try {
            const response = await changeTasksDayAPI(date, taskId, orderId, listType);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const getUserTags = createAsyncThunk(
    'task/getUserTags',
    async (isArchived: boolean, {rejectWithValue}) => {
        try {
            const response = await getUserTagsAPI(isArchived);
            return {
                data: response.data,
                url: response.config.url
            };
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const getTagTasks = createAsyncThunk(
    'task/getTagTasks',
    async ({tagTitle, page, limit}: { tagTitle: string, page: number, limit: number }, {rejectWithValue}) => {
        try {
            const response = await getTagTasksAPI(tagTitle, page, limit);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const addTags = createAsyncThunk(
    'task/addTags',
    async (titles: string[], {rejectWithValue}) => {
        try {
            const response = await addTagsAPI(titles);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const editTag = createAsyncThunk(
    'task/editTag',
    async ({
               tagTitle,
               color,
               icon,
               goalId,
               isArchived,
               aliases
           }: {
        tagTitle: string,
        color?: string,
        icon?: string,
        goalId?: number | null,
        isArchived?: 0 | 1 | 2,
        aliases?: string[]
    }, {rejectWithValue}) => {
        try {
            const response = await editTagAPI(tagTitle, color, icon, goalId, isArchived, aliases);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const deleteList = createAsyncThunk(
    'task/deleteList',
    async (tagId: number, {rejectWithValue}) => {
        try {
            const response = await deleteListAPI(tagId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const removeTagFromTask = createAsyncThunk(
    'task/removeTagFromTask',
    async ({
               foreignKey,
               tagId,
               model
           }: { foreignKey: number, tagId: number, model: 'Task' | 'Event' }, {rejectWithValue}) => {
        try {
            const response = await removeTagAPI(foreignKey, tagId, model);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const getRelatedTasks = createAsyncThunk(
    'goal/getRelatedTasks',
    async ({goalId}: { goalId: number }, {rejectWithValue}) => {
        try {
            const response = await getRelatedTasksAPI(goalId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const taskSlice = createSlice({
    name: 'task',
    initialState: initialState,
    reducers: {
        changeTaskDay(state, action: PayloadAction<{ currDate: string, nextDate: string, task: ITask }>) {
            //     const currDayIndex = state.days.findIndex(day => { return day.date === action.payload.currDate });
            //     if (currDayIndex !== -1) {
            //         state.days[currDayIndex].Tasks = state.days[currDayIndex].Tasks.filter(task => { return task.id !== action.payload.task.id });

            //         const nextDayIndex = state.days.findIndex(day => { return day.date === action.payload.nextDate });
            //         if (nextDayIndex !== -1) {
            //             const lastPosition = state.days[nextDayIndex].Tasks[state.days[nextDayIndex].Tasks.length - 1];
            //             state.days[nextDayIndex].Tasks.push({
            //                 ...action.payload.task,
            //                 dayId: state.days[nextDayIndex].id,
            //                 Order: {
            //                     id: action.payload.task.Order.id,
            //                     position: lastPosition === undefined ? 1 : lastPosition.Order.position + 1
            //                 }
            //             });
            //         }
            //     }

            state.overdue = initialState.overdue;
        },
        changeTaskDayDnD(state, action: PayloadAction<{ oldDate: string, task: ITask, newDayDate: string }>) {
            state.reordering = true;

            const {oldDate, task, newDayDate} = action.payload;

            // remove task from current day
            if (state.days.hasOwnProperty(oldDate)) {
                state.days[oldDate].Tasks = state.days[oldDate].Tasks.filter(stateTask => stateTask.id !== task.id);
            }

            // push task to the new day with new attributes
            if (state.days.hasOwnProperty(newDayDate)) {
                const lastPosition = state.days[newDayDate].Tasks[state.days[newDayDate].Tasks.length - 1];
                state.days[newDayDate].Tasks.push({
                    ...task,
                    dayId: state.days[newDayDate].id,
                    Order: {
                        id: task.Order.id,
                        position: lastPosition === undefined ? 1 : lastPosition.Order.position + 1
                    }
                });
            }

            // remove task from overdue
            if (state.overdue.days.hasOwnProperty(oldDate)) {
                const overdueTasks = state.overdue.days[oldDate].Tasks.filter(tagTask => tagTask.id !== task.id);
                if (!overdueTasks.length) {
                    const overdueDays = state.overdue.days;
                    delete overdueDays[oldDate];
                    state.overdue.days = overdueDays;
                } else {
                    state.overdue.days[oldDate].Tasks = overdueTasks;
                }
            }

            // upd tagTasks
            if (task.Tags.length) {
                state.tags.tagTasks = initialState.tags.tagTasks;
            }
        },
        reorderDayTasks(state, action: PayloadAction<{ oldIndex: number, newPosition: number, date: string }>) {
            const {oldIndex, newPosition, date} = action.payload;

            if (state.days.hasOwnProperty(date)) {
                // change task orders
                for (let i = 0; i < state.days[date].Tasks.length; i++) {
                    if (state.days[date].Tasks[i].Order.id === state.days[date].Tasks[oldIndex].Order.id) {
                        state.days[date].Tasks[i].Order.position = newPosition;
                    } else if (state.days[date].Tasks[i].Order.position >= newPosition) {
                        state.days[date].Tasks[i].Order.position += 1;
                    }
                }

                // sort tasks
                state.days[date].Tasks.sort((a, b) => {
                    return a.Order.position < b.Order.position ? -1 : 1;
                });
            }

            state.completed = initialState.completed;
            state.deleted = initialState.deleted;
        },
        reorderPlannedTasks(state, action: PayloadAction<{ oldIndex: number, newPosition: number }>) {
            const {oldIndex, newPosition} = action.payload;

            // change task orders
            for (let i = 0; i < state.planned.tasks.length; i++) {
                if (state.planned.tasks[i].Order.id === state.planned.tasks[oldIndex].Order.id) {
                    state.planned.tasks[i].Order.position = newPosition;
                } else if (state.planned.tasks[i].Order.position >= newPosition) {
                    state.planned.tasks[i].Order.position += 1;
                }
            }

            // sort tasks
            state.planned.tasks.sort((a, b) => {
                return b.Order.position < a.Order.position ? -1 : 1;
            });
        },
        reorderTagTasks(state, action: PayloadAction<{ tagFrom: string, oldIndex: number, newPosition: number }>) {
            const {tagFrom, oldIndex, newPosition} = action.payload;
            // change task orders
            for (let i = 0; i < state.tags.tagTasks[tagFrom].tasks.length; i++) {
                if (state.tags.tagTasks[tagFrom].tasks[i].Order.id === state.tags.tagTasks[tagFrom].tasks[oldIndex].Order.id) {
                    state.tags.tagTasks[tagFrom].tasks[i].Order.position = newPosition;
                } else if (state.tags.tagTasks[tagFrom].tasks[i].Order.position >= newPosition) {
                    state.tags.tagTasks[tagFrom].tasks[i].Order.position += 1;
                }
            }

            // sort tasks
            state.tags.tagTasks[tagFrom].tasks.sort((a, b) => {
                return a.Order.position < b.Order.position ? -1 : 1;
            });
        },
        changeDayType(state, action: PayloadAction<{
            userDayId: number,
            date: string,
            type: number,
            comment: string
        }>) {
            const {userDayId, date, type, comment} = action.payload;

            if (state.days.hasOwnProperty(date)) {
                state.days[date].dayType = type;
                state.days[date].comment = comment;
                if (!state.days[date].userDayId) {
                    state.days[date].userDayId = userDayId;
                }
            }
        },
        updateTags(state, action: PayloadAction<{
            tagFrom: string,
            color: string | null,
            goal: IGoal | null,
            archiveType?: 0 | 1 | 2,
            aliases?: string[]
        }>) {
            const {tagFrom, color, goal, archiveType, aliases = null} = action.payload;

            if (state.tags.tagTasks.hasOwnProperty(tagFrom)) {
                state.tags.tagTasks[tagFrom].tasks.map(task => {
                    const tagIndex = task.Tags.findIndex(el => el.title === tagFrom);
                    if (tagIndex !== -1) {
                        task.Tags[tagIndex].color = color;
                        task.Tags[tagIndex].Goal = goal;
                        if (aliases) task.Tags[tagIndex].aliases = aliases;
                        if (archiveType !== undefined) task.Tags[tagIndex].isArchived = archiveType;
                        if (task.Tags[tagIndex].hasOwnProperty('settings')) {
                            // @ts-ignore
                            task.Tags[tagIndex].settings.color = color;
                            // @ts-ignore
                            task.Tags[tagIndex].settings.Goal = goal;
                            // @ts-ignore
                            if (aliases) task.Tags[tagIndex].settings.aliases = aliases;
                            if (archiveType !== undefined) task.Tags[tagIndex].isArchived = archiveType;
                        }

                        return task;
                    }

                    return task;
                });
            }

            if (state.tags.tagList.length) {
                state.tags.tagList.map(tag => {
                    if (tag.title === tagFrom) {
                        tag.color = color;
                        tag.Goal = goal;
                        if (aliases) tag.aliases = aliases;
                        if (archiveType !== undefined) tag.isArchived = archiveType;
                        return tag;
                    }
                    return tag;
                });
            }

            state.days = initialState.days;
            state.planned = initialState.planned;
            state.recurrings = initialState.recurrings;
            state.overdue = initialState.overdue;
            state.completed = initialState.completed;
            state.deleted = initialState.deleted;
            state.tags = {
                fetched: state.tags.fetched,
                archiveFetched: state.tags.archiveFetched,
                tagList: state.tags.tagList,
                tagTasks: {
                    [tagFrom]: state.tags.tagTasks[tagFrom]
                }
            }
        },
        removeTag(state, action: PayloadAction<{
            date: string,
            index: number,
            task: ITask,
            tag: ITag,
            paramTag?: string
        }>) {
            const {date, index, task, tag, paramTag} = action.payload;
            const tagId = tag.id;

            if (task.isCompleted) {
                // remove tag from completed task
                if (state.completed.days.hasOwnProperty(date)) {
                    const taskIndex = state.completed.days[date].Tasks.findIndex(
                        completedTask => completedTask.id === task.id
                    );
                    if (taskIndex !== -1) {
                        state.completed.days[date].Tasks[taskIndex].Tags = state.completed.days[date].Tasks[taskIndex].Tags.filter(
                            tag => {
                                return tag.id !== tagId;
                            }
                        );
                    }
                }
                // remove tag from days
                if (state.days.hasOwnProperty(date)) {
                    const taskIndex = state.days[date].Tasks.findIndex(
                        dayTask => dayTask.id === task.id
                    );
                    if (taskIndex !== -1) {
                        state.days[date].Tasks[taskIndex].Tags = state.days[date].Tasks[taskIndex].Tags.filter(
                            tag => {
                                return tag.id !== tagId;
                            }
                        );
                    }
                }
            } else if (task.isDeleted) {
                // remove tag from deleted
                if (state.deleted.days.hasOwnProperty(date)) {
                    state.deleted.days[date].Tasks[index].Tags = state.deleted.days[date].Tasks[index].Tags.filter(
                        tag => {
                            return tag.id !== tagId;
                        }
                    );
                }
            } else if (new Date(date).getTime() < new Date().setUTCHours(0, 0, 0, 0)) {
                // remove tag from overdue
                if (state.overdue.days.hasOwnProperty(date)) {
                    const taskIndex = state.overdue.days[date].Tasks.findIndex(
                        overdueTask => overdueTask.id === task.id
                    );
                    if (taskIndex !== -1) {
                        state.overdue.days[date].Tasks[taskIndex].Tags = state.overdue.days[date].Tasks[taskIndex].Tags.filter(
                            tag => {
                                return tag.id !== tagId;
                            }
                        );
                    }
                }
                // remove tag from days
                if (state.days.hasOwnProperty(date)) {
                    const taskIndex = state.days[date].Tasks.findIndex(
                        dayTask => dayTask.id === task.id
                    );
                    if (taskIndex !== -1) {
                        state.days[date].Tasks[taskIndex].Tags = state.days[date].Tasks[taskIndex].Tags.filter(
                            tag => {
                                return tag.id !== tagId;
                            }
                        );
                    }
                }
            } else if (task.isPlanned) {
                // remove tag from planned
                const taskIndex = state.planned.tasks.findIndex(
                    plannedTask => plannedTask.id === task.id
                );
                if (taskIndex !== -1) {
                    state.planned.tasks[taskIndex].Tags = state.planned.tasks[taskIndex].Tags.filter(
                        tag => {
                            return tag.id !== tagId;
                        }
                    );
                }
            } else {
                // remove tag from days
                if (state.days.hasOwnProperty(date)) {
                    const taskIndex = state.days[date].Tasks.findIndex(
                        dayTask => dayTask.id === task.id
                    );
                    if (taskIndex !== -1) {
                        state.days[date].Tasks[taskIndex].Tags = state.days[date].Tasks[taskIndex].Tags.filter(
                            tag => {
                                return tag.id !== tagId;
                            }
                        );
                    }
                }
            }

            // upd tagTasks
            if (paramTag !== undefined) {
                if (state.tags.tagTasks.hasOwnProperty(paramTag)) {
                    if (paramTag === tag.title) {
                        state.tags.tagTasks[paramTag].tasks = state.tags.tagTasks[paramTag].tasks.filter(
                            stateTask => {
                                return stateTask.id !== task.id;
                            }
                        )
                    } else {
                        state.tags.tagTasks[paramTag].tasks[index].Tags = state.tags.tagTasks[paramTag].tasks[index].Tags.filter(
                            stateTag => {
                                return stateTag.id !== tagId;
                            }
                        )
                    }
                }

                for (let i = 0; i < task.Tags.length; i++) {
                    if (task.Tags[i].title !== paramTag && state.tags.tagTasks.hasOwnProperty(task.Tags[i].title)) {
                        state.tags.tagTasks[task.Tags[i].title] = {
                            observable: true,
                            page: 1,
                            tasks: []
                        }
                    }
                }

                state.days = initialState.days;
            } else {
                state.tags.tagTasks = initialState.tags.tagTasks;
            }
        },
        removeGoalFromTask(state, action: PayloadAction<{ date: string, goalId: number, task: ITask }>) {
            const {date, goalId, task} = action.payload;

            if (task.isPlanned) {
                //remove goal from planned
                const taskIndex = state.planned.tasks.findIndex(
                    plannedTask => plannedTask.id === task.id
                );
                if (taskIndex !== -1 && state.planned.tasks[taskIndex].hasOwnProperty('Goals')) {
                    // @ts-ignore
                    state.planned.tasks[taskIndex].Goals = state.planned.tasks[taskIndex].Goals.filter(
                        goal => {
                            return goal.id !== goalId;
                        }
                    );
                }
            } else {
                //remove goal from days
                if (state.days.hasOwnProperty(date)) {
                    const taskIndex = state.days[date].Tasks.findIndex(
                        dayTask => dayTask.id === task.id
                    );
                    if (taskIndex !== -1 && state.days[date].Tasks[taskIndex].hasOwnProperty('Goals')) {
                        // @ts-ignore
                        state.days[date].Tasks[taskIndex].Goals = state.days[date].Tasks[taskIndex].Goals.filter(
                            goal => {
                                return goal.id !== goalId;
                            }
                        );
                    }
                }
            }

            state.overdue = initialState.overdue;
            state.completed = initialState.completed;
            state.deleted = initialState.deleted;
        },
        setRecurring(state, action: PayloadAction<{ task: ITask, recurringEvent: IRecurring }>) {
            const {task, recurringEvent} = action.payload;

            const dayKey = Object.keys(state.days).find(key => state.days[key].id === task.dayId);
            if (dayKey !== undefined) {

                const taskIndex = state.days[dayKey].Tasks.findIndex(stateTask => stateTask.recurringId === task.recurringId);
                if (taskIndex !== -1) {
                    state.days[dayKey].Tasks[taskIndex] = {
                        ...state.days[dayKey].Tasks[taskIndex],
                        RecurringEvent: recurringEvent
                    }
                }
            }
        },
        setReordering(state, action: PayloadAction<boolean>) {
            state.reordering = action.payload;
        },
        resetTasks: () => initialState,
        resetDays(state) {
            state.days = initialState.days;
        },
        resetTags(state) {
            state.tags = initialState.tags;
        },
        resetCompleted(state) {
            state.completed = initialState.completed;
        },
        toggleEditable(state) {
            state.isEditable = !state.isEditable;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDayRecurringTasks.fulfilled, (state, action: PayloadAction<TGetTasksResponse>) => {
            const date: string = Object.keys(action.payload)[0];
            state.days[date] = action.payload[date];

            state.isTaskLoading = false;
            state.taskError = false;
        });
        builder.addCase(getRecurrings.fulfilled, (state, action: PayloadAction<IRecurring[]>) => {
            state.recurrings.tasks = [...state.recurrings.tasks, ...action.payload];

            state.recurrings.fetched = true;
            state.isTaskLoading = false;
            state.taskError = false;
        });
        builder.addCase(getAgendaTasks.fulfilled, (state, action: PayloadAction<TGetTasksResponse>) => {
            state.days = action.payload;

            state.isTaskLoading = false;
            state.taskError = false;
        });
        builder.addCase(addTask.fulfilled, (state, action: PayloadAction<TTaskActionsResponse>) => {
            const date: string = Object.keys(action.payload)[0];
            const payloadTask = action.payload[date];

            // upd tagTasks
            if (payloadTask.Tags.length) {
                state.tags = initialState.tags;
            }

            // add new task to planned
            if (payloadTask.isPlanned) {
                state.planned.tasks.unshift(payloadTask);
                state.reordering = false;
                state.isTaskSending = false;
                state.taskError = false;
                state.planned.totalCount = state.planned.totalCount + 1;
                return;
            }

            // upd day
            if (state.days.hasOwnProperty(date) && payloadTask.userDayId) {
                state.days[date].userDayId = payloadTask.userDayId;
            }

            if (!payloadTask.isRecurring && !payloadTask.isCompleted && !payloadTask.isDeleted) {
                // add task to days
                if (state.days.hasOwnProperty(date)) {
                    state.days[date].Tasks.push(payloadTask);
                }

                // add task to overdue if needed
                if (new Date(date).getTime() < new Date().setUTCHours(0, 0, 0, 0)) {
                    if (state.overdue.days.hasOwnProperty(date)) {
                        state.overdue.days[date].Tasks.push(payloadTask);
                    } else {
                        state.overdue.days[date] = {
                            id: payloadTask.dayId,
                            date: date,
                            Tasks: [payloadTask]
                        } as IDay;
                    }
                }
            } else if (payloadTask.isRecurring && (payloadTask.isCompleted || payloadTask.isDeleted)) {
                // add completed or deleted task from recurring
                const taskIndex = state.days[date].Tasks.findIndex(task => {
                    return task.frequencyId === payloadTask.frequencyId
                })
                state.days[date].Tasks[taskIndex] = payloadTask;
                if (payloadTask.isDeleted) {
                    if (state.deleted.days.hasOwnProperty(date)) {
                        state.deleted.days[date].Tasks.push(payloadTask);
                    } else {
                        if (payloadTask.dayId) {
                            state.deleted.days[date] = {
                                id: payloadTask.dayId,
                                date: date,
                                Tasks: [{...payloadTask, isDeleted: true}]
                            } as IDay;
                        }
                    }
                } else if (payloadTask.isCompleted) {
                    if (state.completed.days.hasOwnProperty(date)) {
                        state.completed.days[date].Tasks.push(payloadTask);

                        // sort completed tasks
                        state.completed.days[date].Tasks.sort((a, b) => {
                            return a.Order.position < b.Order.position ? -1 : 1;
                        });
                    } else {
                        if (payloadTask.dayId) {
                            state.completed.days[date] = {
                                id: payloadTask.dayId,
                                date: date,
                                Tasks: [{...payloadTask, isDeleted: true}]
                            } as IDay;
                        }
                    }
                }
            } else if (payloadTask.isRecurring && !payloadTask.isCompleted && !payloadTask.isDeleted) {
                // add task from recurring
                const taskIndex = state.days[date].Tasks.findIndex(task => {
                    return task.frequencyId === payloadTask.frequencyId
                })
                state.days[date].Tasks[taskIndex] = payloadTask;

                state.days[date].Tasks.sort((a, b) => {
                    if (a.id === null) return -1;
                    if (b.id === null) return 1;
                    if (a.Order.position === b.Order.position) return 0;

                    return a.Order.position < b.Order.position ? -1 : 1;
                });
            }

            state.recurrings = initialState.recurrings;

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(addTagTask.fulfilled, (state, action: PayloadAction<TTaskActionsResponse>) => {
            const tag: string = Object.keys(action.payload)[0];
            const payloadTask = action.payload[tag];

            if (state.tags.tagTasks.hasOwnProperty(tag)) {
                state.tags.tagTasks[tag].tasks.push(payloadTask);
            }

            for (let i = 0; i < payloadTask.Tags.length; i++) {
                if (payloadTask.Tags[i].title !== tag && state.tags.tagTasks.hasOwnProperty(payloadTask.Tags[i].title)) {
                    state.tags.tagTasks[payloadTask.Tags[i].title] = {
                        observable: true,
                        page: 1,
                        tasks: []
                    }
                }
            }

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(tagTaskAction.fulfilled, (state, action: PayloadAction<TTaskActionsResponse>) => {
            const tagFrom: string = Object.keys(action.payload)[0];
            const payloadTask = action.payload[tagFrom];
            const date = action.payload[tagFrom].Day?.date;

            // upd tagTask
            if (state.tags.tagTasks.hasOwnProperty(tagFrom)) {
                if (payloadTask.isDeleted) {
                    state.tags.tagTasks[tagFrom].tasks = state.tags.tagTasks[tagFrom].tasks.filter(
                        task => {
                            return task.id !== payloadTask.id;
                        }
                    )
                } else {
                    const taskIndex = state.tags.tagTasks[tagFrom].tasks.findIndex(task => task.id === payloadTask.id);
                    if (taskIndex !== -1) {
                        const oldOrder = {...state.tags.tagTasks[tagFrom].tasks[taskIndex].Order};
                        state.tags.tagTasks[tagFrom].tasks[taskIndex] = {...payloadTask, Order: oldOrder};
                    }
                }
            }
            for (let i = 0; i < payloadTask.Tags.length; i++) {
                if (payloadTask.Tags[i].title !== tagFrom && state.tags.tagTasks.hasOwnProperty(payloadTask.Tags[i].title)) {
                    state.tags.tagTasks[payloadTask.Tags[i].title] = {
                        observable: true,
                        page: 1,
                        tasks: []
                    }
                }
            }

            // add new task to planned
            if (payloadTask.isPlanned) {
                if (state.planned.tasks.length) {
                    state.planned.tasks.unshift(payloadTask);
                } else {
                    state.planned = initialState.planned;
                }
                state.reordering = false;
                state.isTaskSending = false;
                state.taskError = false;
                return;
            }

            // add task to completed
            if (date && payloadTask.isCompleted) {
                if (state.completed.days.hasOwnProperty(date)) {
                    state.completed.days[date].Tasks.push(payloadTask);

                    // sort completed tasks
                    state.completed.days[date].Tasks.sort((a, b) => {
                        return a.Order.position < b.Order.position ? -1 : 1;
                    });
                } else {
                    state.completed = initialState.completed;
                }
            }

            // add task to deleted
            if (date && payloadTask.isDeleted) {
                if (state.deleted.days.hasOwnProperty(date)) {
                    state.deleted.days[date].Tasks.push(payloadTask);
                } else {
                    state.deleted = initialState.deleted;
                }
            }

            // add task to days
            if (!payloadTask.isDeleted) {
                if (date && state.days.hasOwnProperty(date)) {
                    state.days[date].Tasks.push(payloadTask);
                }
            }

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(removeDateStatus.fulfilled, (state, action: PayloadAction<ITask>) => {
            const payloadTask = action.payload;

            for (let i = 0; i < Object.keys(state.tags.tagTasks).length; i++) {
                const key = Object.keys(state.tags.tagTasks)[i];
                if (state.tags.tagTasks[key].tasks.length) {
                    const taskIndex = state.tags.tagTasks[key].tasks.findIndex(task => task.id === payloadTask.id);
                    if (taskIndex !== -1) {
                        state.tags.tagTasks[key].tasks[taskIndex] = {
                            ...state.tags.tagTasks[key].tasks[taskIndex],
                            dayId: null,
                            isPlanned: false
                        }
                    }
                }
            }

            state.days = initialState.days;
            state.planned = initialState.planned;
            state.overdue = initialState.overdue;
            state.completed = initialState.completed;
        });
        builder.addCase(editTask.fulfilled, (state, action: PayloadAction<TTaskActionsResponse>) => {
            const date: string = Object.keys(action.payload)[0];
            const payloadTask = action.payload[date];

            if (payloadTask.isDeleted) {
                if (payloadTask.isPlanned) {
                    state.planned.tasks = state.planned.tasks.filter(task => task.id !== payloadTask.id);
                    state.days = initialState.days;
                } else if (state.days.hasOwnProperty(date)) {
                    state.days[date].Tasks = state.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                    for (const key in state.days) {
                        if (key !== date) {
                            const daysCopy = state.days
                            delete daysCopy[key];
                            state.days = daysCopy;
                        }
                    }
                }
                state.tags.tagTasks = initialState.tags.tagTasks;
                state.recurrings = initialState.recurrings;
                state.reordering = false;
                state.isTaskSending = false;
                return;
            }

            // edit planned task
            if (payloadTask.isPlanned) {
                const taskIndex = state.planned.tasks.findIndex(task => {
                    return task.id === payloadTask.id
                });
                state.planned.tasks[taskIndex] = payloadTask;

                state.reordering = false;
                state.isTaskSending = false;
                return;
            }

            // edit day task
            if (state.days.hasOwnProperty(date)) {
                const taskIndex = state.days[date].Tasks.findIndex(task => {
                    return task.id === payloadTask.id
                });
                state.days[date].Tasks[taskIndex] = payloadTask;

                if (payloadTask.isRecurring) {
                    state.days = {
                        [date]: state.days[date]
                    }
                }
            }

            // upd tagTasks
            if (payloadTask.Tags.length) {
                for (let i = 0; i < payloadTask.Tags.length; i++) {
                    if (state.tags.tagTasks.hasOwnProperty(payloadTask.Tags[i].title)) {
                        const taskIndex = state.tags.tagTasks[payloadTask.Tags[i].title].tasks.findIndex(
                            task => {
                                return task.id === payloadTask.id;
                            }
                        );
                        if (taskIndex !== -1) {
                            const oldOrder = {...state.tags.tagTasks[payloadTask.Tags[i].title].tasks[taskIndex].Order};
                            let oldDay = null;
                            if (state.tags.tagTasks[payloadTask.Tags[i].title].tasks[taskIndex].Day) {
                                oldDay = state.tags.tagTasks[payloadTask.Tags[i].title].tasks[taskIndex].Day;
                            }
                            state.tags.tagTasks[payloadTask.Tags[i].title].tasks[taskIndex] = {
                                ...payloadTask,
                                Day: oldDay,
                                Order: oldOrder
                            }
                        } else {
                            state.tags.tagTasks[payloadTask.Tags[i].title] = {
                                observable: true,
                                page: 1,
                                tasks: []
                            }
                        }
                    }
                }
                state.tags.fetched = false;
                state.tags.tagList = initialState.tags.tagList;
            }

            state.reordering = false;
            state.isTaskSending = false;
        });
        builder.addCase(completeTask.fulfilled, (state, action: PayloadAction<TTaskActionsResponse>) => {
            const date: string = Object.keys(action.payload)[0];
            const payloadTask = action.payload[date];

            if (payloadTask.isPlanned) {
                // remove completed task from planned
                state.planned.tasks = state.planned.tasks.filter(task => task.id !== payloadTask.id);
                state.planned.totalCount = state.planned.totalCount - 1;
            } else {
                // complete task in days
                if (state.days.hasOwnProperty(date)) {
                    const taskIndex = state.days[date].Tasks.findIndex(task => task.id === payloadTask.id);
                    if (taskIndex !== -1) {
                        state.days[date].Tasks[taskIndex] = payloadTask;
                    }
                }
                // complete task in overdue
                if (state.overdue.days.hasOwnProperty(date)) {
                    const taskIndex = state.overdue.days[date].Tasks.findIndex(task => task.id === payloadTask.id);
                    if (taskIndex !== -1) {
                        state.overdue.days[date].Tasks[taskIndex] = payloadTask;
                    }
                    // const overdueTasks = state.overdue.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                    // if (!overdueTasks.length) {
                    //     const overdueDays = state.overdue.days;
                    //     delete overdueDays[date];
                    //     state.overdue.days = overdueDays;
                    // } else {
                    //     state.overdue.days[date].Tasks = overdueTasks;
                    // }
                }
            }

            // add task to completed
            if (state.completed.days.hasOwnProperty(date)) {
                state.completed.days[date].Tasks.push(payloadTask);

                // sort completed tasks
                state.completed.days[date].Tasks.sort((a, b) => {
                    return a.Order.position < b.Order.position ? -1 : 1;
                });
            } else {
                if (payloadTask.dayId) {
                    state.completed.days[date] = {
                        id: payloadTask.dayId,
                        date: date,
                        Tasks: [payloadTask]
                    } as IDay;
                }
            }

            // upd tagTasks
            if (payloadTask.Tags.length) {
                for (let i = 0; i < payloadTask.Tags.length; i++) {
                    if (state.tags.tagTasks.hasOwnProperty(payloadTask.Tags[i].title)) {
                        const taskIndex = state.tags.tagTasks[payloadTask.Tags[i].title].tasks.findIndex(
                            task => {
                                return task.id === payloadTask.id;
                            }
                        );
                        if (taskIndex !== -1) {
                            state.tags.tagTasks[payloadTask.Tags[i].title].tasks[taskIndex].isCompleted = true;
                        }
                    }
                }
            }

            //upd related tasks to goal
            const relatedTaskIndex = state.tasksRelatedToGoal.tasks.findIndex(task => task.id === payloadTask.id);
            if (relatedTaskIndex !== -1) {
                state.tasksRelatedToGoal.tasks[relatedTaskIndex].isCompleted = true;
            }

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(uncompleteTask.fulfilled, (state, action: PayloadAction<TTaskActionsResponse>) => {
            const date: string = Object.keys(action.payload)[0];
            const payloadTask = action.payload[date];

            // uncomplete task in days
            if (state.days.hasOwnProperty(date)) {
                const taskIndex = state.days[date].Tasks.findIndex(task => task.id === payloadTask.id);
                if (taskIndex !== -1) {
                    state.days[date].Tasks[taskIndex].isCompleted = false;
                }
            }
            // remove task from completed
            if (state.completed.days.hasOwnProperty(date)) {
                const completedTasks = state.completed.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                if (!completedTasks.length) {
                    const completedDays = state.completed.days;
                    delete completedDays[date];
                    state.completed.days = completedDays;
                } else {
                    state.completed.days[date].Tasks = completedTasks;
                }
            }

            // uncomplete task in overdue
            if (state.overdue.days.hasOwnProperty(date)) {
                const taskIndex = state.overdue.days[date].Tasks.findIndex(task => task.id === payloadTask.id);
                if (taskIndex !== -1) {
                    state.overdue.days[date].Tasks[taskIndex] = payloadTask;
                }
            }

            // upd tagTasks
            if (payloadTask.Tags.length) {
                for (let i = 0; i < payloadTask.Tags.length; i++) {
                    if (state.tags.tagTasks.hasOwnProperty(payloadTask.Tags[i].title)) {
                        const taskIndex = state.tags.tagTasks[payloadTask.Tags[i].title].tasks.findIndex(
                            task => {
                                return task.id === payloadTask.id;
                            }
                        );
                        if (taskIndex !== -1) {
                            state.tags.tagTasks[payloadTask.Tags[i].title].tasks[taskIndex].isCompleted = false;
                        }
                    }
                }
            }

            //upd related tasks to goal
            const relatedTaskIndex = state.tasksRelatedToGoal.tasks.findIndex(task => task.id === payloadTask.id);
            if (relatedTaskIndex !== -1) {
                state.tasksRelatedToGoal.tasks[relatedTaskIndex].isCompleted = false;
            }

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(deleteTask.fulfilled, (state, action: PayloadAction<TTaskActionsResponse>) => {
            const date: string = Object.keys(action.payload)[0];
            const payloadTask = action.payload[date];

            if (payloadTask.isDeleted) {
                // remove task from deleted
                if (state.deleted.days.hasOwnProperty(date)) {
                    const deletedTasks = state.deleted.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                    if (!deletedTasks.length) {
                        const deletedDays = state.deleted.days;
                        delete deletedDays[date];
                        state.deleted.days = deletedDays;
                    } else {
                        state.deleted.days[date].Tasks = deletedTasks;
                    }
                }

                state.reordering = false;
                state.isTaskSending = false;
                state.taskError = false;
                return;
            }

            // upd tagTasks
            if (payloadTask.Tags.length) {
                for (let i = 0; i < payloadTask.Tags.length; i++) {
                    const currTag = payloadTask.Tags[i].title;
                    if (state.tags.tagTasks.hasOwnProperty(currTag)) {
                        state.tags.tagTasks[currTag].tasks = state.tags.tagTasks[currTag].tasks.filter(
                            task => {
                                return task.id !== payloadTask.id;
                            }
                        )
                    }
                }
            }

            if (payloadTask.isPlanned) {
                // remove task from planned
                state.planned.tasks = state.planned.tasks.filter(task => {
                    return task.id !== payloadTask.id
                });
                state.planned.totalCount = state.planned.totalCount - 1;

                if (!payloadTask.isDeleted) {
                    // add task to deleted
                    if (state.deleted.days.hasOwnProperty(date)) {
                        state.deleted.days[date].Tasks.push({...payloadTask, isDeleted: true});
                    } else {
                        if (payloadTask.dayId) {
                            state.deleted.days[date] = {
                                id: payloadTask.dayId,
                                date: date,
                                Tasks: [{...payloadTask, isDeleted: true}]
                            } as IDay;
                        }
                    }
                }
            } else {
                // remove task from days
                if (state.days.hasOwnProperty(date)) {
                    state.days[date].Tasks = state.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                }
                // remove task from overdue
                if (state.overdue.days.hasOwnProperty(date)) {
                    const overdueTasks = state.overdue.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                    if (!overdueTasks.length) {
                        const overdueDays = state.overdue.days;
                        delete overdueDays[date];
                        state.overdue.days = overdueDays;
                    } else {
                        state.overdue.days[date].Tasks = overdueTasks;
                    }
                }
                // add task to deleted
                if (state.deleted.days.hasOwnProperty(date)) {
                    state.deleted.days[date].Tasks.push({...payloadTask, isDeleted: true});
                } else {
                    if (payloadTask.dayId) {
                        state.deleted.days[date] = {
                            id: payloadTask.dayId,
                            date: date,
                            Tasks: [{...payloadTask, isDeleted: true}]
                        } as IDay;
                    }
                }
            }

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(deleteDayOverdueTasks.fulfilled, (state, action: PayloadAction<TTasksActionsResponse>) => {
            const date: string = Object.keys(action.payload)[0];
            const payloadTasks = action.payload[date];
            payloadTasks.map(payloadTask => {
                if (payloadTask.isDeleted) {
                    // remove task from deleted
                    if (state.deleted.days.hasOwnProperty(date)) {
                        const deletedTasks = state.deleted.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                        if (!deletedTasks.length) {
                            const deletedDays = state.deleted.days;
                            delete deletedDays[date];
                            state.deleted.days = deletedDays;
                        } else {
                            state.deleted.days[date].Tasks = deletedTasks;
                        }
                    }

                    state.reordering = false;
                    state.isTaskSending = false;
                    state.taskError = false;
                    return;
                }

                // upd tagTasks
                if (payloadTask.Tags.length) {
                    for (let i = 0; i < payloadTask.Tags.length; i++) {
                        const currTag = payloadTask.Tags[i].title;
                        if (state.tags.tagTasks.hasOwnProperty(currTag)) {
                            state.tags.tagTasks[currTag].tasks = state.tags.tagTasks[currTag].tasks.filter(
                                task => {
                                    return task.id !== payloadTask.id;
                                }
                            )
                        }
                    }
                }

                if (payloadTask.isPlanned) {
                    // remove task from planned
                    state.planned.tasks = state.planned.tasks.filter(task => {
                        return task.id !== payloadTask.id
                    });
                    if (!payloadTask.isDeleted) {
                        // add task to deleted
                        if (state.deleted.days.hasOwnProperty(date)) {
                            state.deleted.days[date].Tasks.push({...payloadTask, isDeleted: true});
                        } else {
                            if (payloadTask.dayId) {
                                state.deleted.days[date] = {
                                    id: payloadTask.dayId,
                                    date: date,
                                    Tasks: [{...payloadTask, isDeleted: true}]
                                } as IDay;
                            }
                        }
                    }
                } else {
                    // remove task from days
                    if (state.days.hasOwnProperty(date)) {
                        state.days[date].Tasks = state.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                    }
                    // remove task from overdue
                    if (state.overdue.days.hasOwnProperty(date)) {
                        const overdueTasks = state.overdue.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                        if (!overdueTasks.length) {
                            const overdueDays = state.overdue.days;
                            delete overdueDays[date];
                            state.overdue.days = overdueDays;
                        } else {
                            state.overdue.days[date].Tasks = overdueTasks;
                        }
                    }
                    // add task to deleted
                    if (state.deleted.days.hasOwnProperty(date)) {
                        state.deleted.days[date].Tasks.push({...payloadTask, isDeleted: true});
                    } else {
                        if (payloadTask.dayId) {
                            state.deleted.days[date] = {
                                id: payloadTask.dayId,
                                date: date,
                                Tasks: [{...payloadTask, isDeleted: true}]
                            } as IDay;
                        }
                    }
                }
                return payloadTask;
            });
            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(undeleteTask.fulfilled, (state, action: PayloadAction<TTaskActionsResponse>) => {
            const date: string = Object.keys(action.payload)[0];
            const payloadTask = action.payload[date];

            // remove task from deleted
            if (state.deleted.days.hasOwnProperty(date)) {
                const deletedTasks = state.deleted.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                if (!deletedTasks.length) {
                    const deletedDays = state.deleted.days;
                    delete deletedDays[date];
                    state.deleted.days = deletedDays;
                } else {
                    state.deleted.days[date].Tasks = deletedTasks;
                }
            }

            // add task to planned
            if (payloadTask.isPlanned) {
                state.planned.tasks.unshift(payloadTask);

                state.reordering = false;
                state.isTaskSending = false;
                state.taskError = false;
                return;
            }

            // add task to completed
            if (payloadTask.isCompleted) {
                if (state.completed.days.hasOwnProperty(date)) {
                    state.completed.days[date].Tasks.push(payloadTask);

                    // sort completed tasks
                    state.completed.days[date].Tasks.sort((a, b) => {
                        return a.Order.position < b.Order.position ? -1 : 1;
                    });
                } else {
                    state.completed = initialState.completed;
                }
            }

            // add task to days
            if (!payloadTask.isPlanned) {
                if (state.days.hasOwnProperty(date)) {
                    state.days[date].Tasks.push(payloadTask);
                }
            }

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
            return;
        });
        builder.addCase(deleteDayTasks.fulfilled, (state, action: PayloadAction<IDay>) => {
            const day = action.payload;

            if (state.deleted.days.hasOwnProperty(day.date)) {
                const deletedDays = state.deleted.days;
                delete deletedDays[day.date];
                state.deleted.days = deletedDays;
            }

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(deleteAllDeletedTasks.fulfilled, (state, action: PayloadAction<number>) => {
            state.deleted = {
                ...initialState.deleted,
                observable: false
            };

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(togglePlanned.fulfilled, (state, action: PayloadAction<TTaskActionsResponse>) => {
            const date: string = Object.keys(action.payload)[0];
            const payloadTask = action.payload[date];

            if (payloadTask.isPlanned) {
                // remove task from days
                if (state.days.hasOwnProperty(date)) {
                    state.days[date].Tasks = state.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                }
                // remove task from overdue
                if (state.overdue.days.hasOwnProperty(date)) {
                    const overdueTasks = state.overdue.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                    if (!overdueTasks.length) {
                        const overdueDays = state.overdue.days;
                        delete overdueDays[date];
                        state.overdue.days = overdueDays;
                    } else {
                        state.overdue.days[date].Tasks = overdueTasks;
                    }
                }
                // remove task from deleted
                if (state.deleted.days.hasOwnProperty(date)) {
                    const deletedTasks = state.deleted.days[date].Tasks.filter(task => task.id !== payloadTask.id);
                    if (!deletedTasks.length) {
                        const deletedDays = state.deleted.days;
                        delete deletedDays[date];
                        state.deleted.days = deletedDays;
                    } else {
                        state.deleted.days[date].Tasks = deletedTasks;
                    }
                }
                // add task to planned
                state.planned.tasks.unshift(payloadTask);
                state.planned.totalCount = state.planned.totalCount + 1;
            } else {
                // remove task from planned
                state.planned.tasks = state.planned.tasks.filter(task => {
                    return task.id !== payloadTask.id
                });

                state.planned.totalCount = state.planned.totalCount - 1;

                // add task to days
                if (state.days.hasOwnProperty(date)) {
                    state.days[date].Tasks.push(payloadTask);
                }
            }

            // upd tagTasks
            if (payloadTask.Tags.length) {
                for (let i = 0; i < payloadTask.Tags.length; i++) {
                    if (state.tags.tagTasks.hasOwnProperty(payloadTask.Tags[i].title)) {
                        const taskIndex = state.tags.tagTasks[payloadTask.Tags[i].title].tasks.findIndex(
                            task => {
                                return task.id === payloadTask.id;
                            }
                        );
                        if (taskIndex !== -1) {
                            state.tags.tagTasks[payloadTask.Tags[i].title].tasks[taskIndex] = {
                                ...state.tags.tagTasks[payloadTask.Tags[i].title].tasks[taskIndex],
                                dayId: payloadTask.dayId,
                                isPlanned: payloadTask.isPlanned
                            }
                        }
                    }
                }
            }

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(editRecurringTask.fulfilled, (state, action: PayloadAction<IRecurring>) => {
            state.days = initialState.days;

            const recurringEventIndex = state.recurrings.tasks.findIndex(event => event.id === action.payload.id);
            if (recurringEventIndex !== -1) {
                state.recurrings.tasks[recurringEventIndex] = action.payload;
            } else {
                state.recurrings = initialState.recurrings;
            }

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(addRecurringTask.fulfilled, (state, action: PayloadAction<{
            currDate?: string
        } & IRecurring>) => {
            state.days = initialState.days;
            state.recurrings = initialState.recurrings;

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(deleteRecurring.fulfilled, (state, action: PayloadAction<boolean>) => {
            state.recurrings = initialState.recurrings;

            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addCase(getCompletedTasks.fulfilled, (state, action: PayloadAction<TGetTasksResponse>) => {
            for (let i = 0; i < Object.keys(action.payload).length; i++) {
                const currKey = Object.keys(action.payload)[i];
                if (state.completed.days.hasOwnProperty(currKey)) {
                    if (action.payload[currKey].Tasks[0].title === state.completed.days[currKey].Tasks[state.completed.days[currKey].Tasks.length - 1].title) {
                        action.payload[currKey].Tasks.shift();
                    }
                    state.completed.days[currKey].Tasks.push(...action.payload[currKey].Tasks);
                } else {
                    state.completed.days[currKey] = action.payload[currKey];
                }
            }

            if (Object.keys(action.payload).length) {
                state.completed.page = state.completed.page + 1;
            } else {
                state.completed.observable = false;
            }

            state.isTaskLoading = false;
            state.taskError = false;
        });
        builder.addCase(getOverdueTasks.fulfilled, (state, action: PayloadAction<TGetTasksResponse>) => {
            for (let i = 0; i < Object.keys(action.payload).length; i++) {
                const currKey = Object.keys(action.payload)[i];
                if (state.overdue.days.hasOwnProperty(currKey)) {
                    if (action.payload[currKey].Tasks[0].title === state.overdue.days[currKey].Tasks[state.overdue.days[currKey].Tasks.length - 1].title) {
                        action.payload[currKey].Tasks.shift();
                    }
                    state.overdue.days[currKey].Tasks.push(...action.payload[currKey].Tasks);
                } else {
                    state.overdue.days[currKey] = action.payload[currKey];
                }
            }

            if (Object.keys(action.payload).length) {
                state.overdue.page = state.overdue.page + 1;
            } else {
                state.overdue.observable = false;
            }

            state.isTaskLoading = false;
            state.taskError = false;
        });
        builder.addCase(getPlannedTasks.fulfilled, (state, action: PayloadAction<IPlannedTasksResponse>) => {
            state.planned.tasks = [...state.planned.tasks, ...action.payload.items];

            if (action.payload.items.length) {
                state.planned.page = state.planned.page + 1;
            } else {
                state.planned.observable = false;
            }

            state.planned.totalCount = action.payload.totalCount;

            state.isTaskLoading = false;
            state.taskError = false;
        });
        builder.addCase(getDeletedTasks.fulfilled, (state, action: PayloadAction<TGetTasksResponse>) => {
            for (let i = 0; i < Object.keys(action.payload).length; i++) {
                const currKey = Object.keys(action.payload)[i];
                if (state.deleted.days.hasOwnProperty(currKey)) {
                    if (action.payload[currKey].Tasks[0].title === state.deleted.days[currKey].Tasks[state.deleted.days[currKey].Tasks.length - 1].title) {
                        action.payload[currKey].Tasks.shift();
                    }
                    state.deleted.days[currKey].Tasks.push(...action.payload[currKey].Tasks);
                } else {
                    state.deleted.days[currKey] = action.payload[currKey];
                }
            }

            if (Object.keys(action.payload).length) {
                state.deleted.page = state.deleted.page + 1;
            } else {
                state.deleted.observable = false;
            }

            state.isTaskLoading = false;
            state.taskError = false;
        });
        builder.addCase(getUserTags.fulfilled, (state, action: PayloadAction<{
            data: ITag[],
            url: string | undefined
        }>) => {
            const url = action.payload.url?.split('?isArchived=');

            if (url && url[url.length - 1] === 'true') {
                state.tags.tagList = [...state.tags.tagList, ...action.payload.data];
                state.tags.archiveFetched = true;
            } else {
                state.tags.tagList = action.payload.data;
                state.tags.fetched = true;
            }
        });
        builder.addCase(getUserTags.rejected, (state) => {
            state.tags.fetched = true;
        });
        builder.addCase(addTags.fulfilled, (state, action: PayloadAction<ITag[]>) => {
            // TODO remove duplicates
            state.tags.tagList = [...state.tags.tagList, ...action.payload];
            state.tags.fetched = true;
        });
        builder.addCase(getTagTasks.fulfilled, (state, action: PayloadAction<TGetTagTasksResponse>) => {
            const tag: string = Object.keys(action.payload)[0];

            if (state.tags.tagTasks.hasOwnProperty(tag)) {
                if (action.payload[tag].length) {
                    state.tags.tagTasks[tag].tasks = [...state.tags.tagTasks[tag].tasks, ...action.payload[tag]];
                    state.tags.tagTasks[tag].page += 1;
                } else {
                    state.tags.tagTasks[tag].observable = false;
                }
            } else {
                state.tags.tagTasks[tag] = {
                    page: 2,
                    observable: action.payload[tag].length ? true : false,
                    tasks: [...action.payload[tag]]
                }
            }

            state.isTaskLoading = false;
            state.taskError = false;
        });
        builder.addCase(getRelatedTasks.fulfilled, (state, action: PayloadAction<ITask[]>) => {
            state.tasksRelatedToGoal.tasks = action.payload;

            state.tasksRelatedToGoal.isTasksRelatedLoading = false;
            state.tasksRelatedToGoal.tasksRelatedError = false;
        });
        builder.addMatcher(isAnyOf(changeTasksDay.fulfilled, reorderTasks.fulfilled), (state) => {
            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = false;
        });
        builder.addMatcher(isAnyOf(changeTasksDay.rejected, reorderTasks.rejected), (state) => {
            state.planned = initialState.planned;
            state.days = initialState.days;
            state.reordering = false;
            state.isTaskSending = false;
        });
        builder.addMatcher(isAnyOf(getDayRecurringTasks.pending, getRecurrings.pending, getCompletedTasks.pending, getOverdueTasks.pending, getPlannedTasks.pending, getDeletedTasks.pending, getAgendaTasks.pending, getTagTasks.pending), state => {
            state.isTaskLoading = true;
        });
        builder.addMatcher(isAnyOf(addTask.pending, addTagTask.pending, tagTaskAction.pending, removeDateStatus.pending, editTask.pending, deleteRecurring.pending, completeTask.pending, uncompleteTask.pending, deleteTask.pending, undeleteTask.pending, deleteDayTasks.pending, deleteAllDeletedTasks.pending, togglePlanned.pending, addRecurringTask.pending, editRecurringTask.pending, changeTasksDay.pending), state => {
            state.reordering = true;
            state.isTaskSending = true;
        });
        builder.addMatcher(isAnyOf(getDayRecurringTasks.rejected, getRecurrings.rejected, getCompletedTasks.rejected, getOverdueTasks.rejected, getPlannedTasks.rejected, getDeletedTasks.rejected, getAgendaTasks.rejected, getTagTasks.rejected), state => {
            state.isTaskLoading = false;
            state.taskError = true;
        });
        builder.addMatcher(isAnyOf(addTask.rejected, addTagTask.rejected, tagTaskAction.rejected, removeDateStatus.rejected, editTask.rejected, deleteRecurring.rejected, completeTask.rejected, uncompleteTask.rejected, deleteTask.rejected, undeleteTask.rejected, deleteDayTasks.rejected, deleteAllDeletedTasks.rejected, togglePlanned.rejected, addRecurringTask.rejected, editRecurringTask.rejected), state => {
            state.reordering = false;
            state.isTaskSending = false;
            state.taskError = true;
        });
        builder.addMatcher(isAnyOf(getRelatedTasks.pending), state => {
            state.tasksRelatedToGoal.isTasksRelatedLoading = true;
        });
        builder.addMatcher(isAnyOf(getRelatedTasks.rejected), state => {
            state.tasksRelatedToGoal.isTasksRelatedLoading = false;
            state.tasksRelatedToGoal.tasksRelatedError = false;
        });
    }
});

export default taskSlice.reducer;
export const {
    changeTaskDay,
    changeTaskDayDnD,
    reorderDayTasks,
    reorderPlannedTasks,
    reorderTagTasks,
    changeDayType,
    updateTags,
    removeTag,
    removeGoalFromTask,
    setRecurring,
    setReordering,
    resetTasks,
    resetDays,
    resetTags,
    resetCompleted,
    toggleEditable
} = taskSlice.actions;