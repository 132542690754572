import React, {FC, Fragment, useState} from 'react';
import {
    Autocomplete,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {timezones} from "../../utils/constants";
import CircularProgress from "@mui/material/CircularProgress";
import {editProfile} from "../../store/auth/authSlice";
import {getOffset} from "../../utils/helpers";
import {resetTasks} from "../../store/task/taskSlice";
import {IWeekDay} from "../../models/days/IWeekDay";

export const ProfileDetails: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {user} = useAppSelector(state => state.auth);
    const {themeMode} = useAppSelector(state => state.utils);

    const [isSending, setIsSending] = useState<boolean>(false);
    const [isSendingWeekStartsOn, setIsSendingWeekStartsOn] = useState<boolean>(false);

    const getLanguageByCode = (lang: string) => {
        switch (lang) {
            case 'en' :
                return 'English';
            case 'pl':
                return 'Polski';
            case 'ru' :
                return 'Русский';
            case 'ua':
                return 'Українська';
            default:
                return '';
        }
    }

    const daysOfWeek = [
        {id: 1, title: t('Monday')},
        {id: 2, title: t('Tuesday')},
        {id: 3, title: t('Wednesday')},
        {id: 4, title: t('Thursday')},
        {id: 5, title: t('Friday')},
        {id: 6, title: t('Saturday')},
        {id: 0, title: t('Sunday')},
    ];

    const handleChangeTimezone = (e: any, newTimezone: string | null) => {
        if (newTimezone) {
            setIsSending(true);
            dispatch(editProfile({
                ...user,
                timezoneName: newTimezone,
                timezoneOffset: getOffset(newTimezone)
            }))
                .unwrap()
                .then(() => {
                    dispatch(resetTasks());
                    setIsSending(false);
                });
        }
    }

    const handleChangeWeekStartsOn = (e: any, weekStartsOnSelected: IWeekDay) => {
        if (weekStartsOnSelected) {
            setIsSendingWeekStartsOn(true);
            dispatch(editProfile({
                ...user,
                weekStartsOn: weekStartsOnSelected.id,
            }))
                .unwrap()
                .then(() => {
                    setIsSendingWeekStartsOn(false);
                });
        }
    }

    return (
        <Box sx={{mt: 3}}>
            <Card sx={{mt: 2, paddingLeft: '1rem', paddingRight: '1rem', borderRadius: '0.75rem'}}>
                <CardHeader
                    sx={{
                        display: "flex",
                        overflow: "hidden",
                        "& .MuiCardHeader-content": {
                            overflow: "hidden"
                        }
                    }}
                    title={t("Profile details")}
                    titleTypographyProps={{noWrap: true}}
                    action={
                        <IconButton>
                            <MoreVertIcon/>
                        </IconButton>
                    }
                />
                <Divider/>
                <CardContent>
                    <Grid container spacing={2} sx={{mt: 1}}>
                        <Grid item xs={12} md={4}>
                            <Typography
                                display="inline"
                                variant={'subtitle2'}>
                                {t('name')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography display="inline"
                                        sx={{
                                            fontWeight: '500 !important',
                                            marginRight: '0.25rem !important'
                                        }}>
                                {user.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography
                                display="inline"
                                variant={'subtitle2'}>
                                {t('email')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography display="inline"
                                        sx={{
                                            fontWeight: '500 !important',
                                            marginRight: '0.25rem !important'
                                        }}>
                                {user.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography
                                display="inline"
                                variant={'subtitle2'}>
                                {t('Language')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography display="inline"
                                        sx={{
                                            fontWeight: '500 !important',
                                            marginRight: '0.25rem !important'
                                        }}>
                                <img src={`/images/icons/languages/${user.lang}.png`} alt={user.lang}
                                     width={"20px"}/> &nbsp;{getLanguageByCode(user.lang)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography
                                display="inline"
                                variant={'subtitle2'}>
                                {t("Timezone")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography
                                display="inline"
                                sx={{
                                    fontWeight: '500 !important',
                                    marginRight: '0.25rem !important'
                                }}
                            >
                                {/*{user.timezoneName}*/}
                                <Autocomplete
                                    disableClearable
                                    id="combo-box-timezones"
                                    value={user.timezoneName}
                                    options={timezones}
                                    loading={isSending}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                sx: {
                                                    borderRadius: '0.625rem',
                                                    padding: '0px'
                                                },
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <Fragment>
                                                        {isSending ?
                                                            <CircularProgress color="inherit" size={20}/> : null}
                                                        {params.InputProps.endAdornment}
                                                    </Fragment>
                                                ),
                                            }}
                                            sx={{
                                                background: themeMode === 'light' ? '#F9F9F9' : 'transparent',
                                                marginTop: '0px',
                                                "& .MuiOutlinedInput-root": {
                                                    padding: "0px 0px 0px 8px"
                                                }
                                            }}
                                        />
                                    )}
                                    onChange={handleChangeTimezone}
                                    sx={{width: 300, padding: "0px"}}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography
                                display="inline"
                                variant={'subtitle2'}>
                                {t("The first day of the week")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography
                                display="inline"
                                sx={{
                                    fontWeight: '500 !important',
                                    marginRight: '0.25rem !important'
                                }}
                            >
                                <Autocomplete
                                    disableClearable
                                    id="combo-box-timezones"
                                    value={daysOfWeek.filter(item => item.id === user.weekStartsOn)[0]}
                                    options={daysOfWeek}
                                    getOptionLabel={(option) => option.title}
                                    loading={isSendingWeekStartsOn}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                sx: {
                                                    borderRadius: '0.625rem',
                                                    padding: '0px'
                                                },
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <Fragment>
                                                        {isSendingWeekStartsOn ?
                                                            <CircularProgress color="inherit" size={20}/> : null}
                                                        {params.InputProps.endAdornment}
                                                    </Fragment>
                                                ),
                                            }}
                                            sx={{
                                                background: themeMode === 'light' ? '#F9F9F9' : 'transparent',
                                                marginTop: '0px',
                                                "& .MuiOutlinedInput-root": {
                                                    padding: "0px 0px 0px 8px"
                                                }
                                            }}
                                        />
                                    )}
                                    onChange={handleChangeWeekStartsOn}
                                    sx={{width: 300, padding: "0px"}}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    )
};
