import React, { FC, useRef } from 'react';
import { Box } from '@mui/material';
import { ISet } from '../../../models/goals/ISet';
import type { Identifier, XYCoord } from 'dnd-core';
import { IDragSet } from '../../../models/dnd/IDragSet';
import { useDrag, useDrop } from 'react-dnd';
import { dndItemTypes } from '../../../models/dnd/dndItemTypes';
import { useAppDispatch } from '../../../hooks/redux';
import { reorderSetsDnD } from '../../../store/goal/goalSlice';

interface SetTabLabelProps {
    set: ISet,
    index: number,
    dragEndHandle: (item: IDragSet) => void,
}

export const SetTabLabel: FC<SetTabLabelProps> = ({ set, index, dragEndHandle, ...props }) => {

    const dispatch = useAppDispatch();

    const dragRef = useRef<HTMLSpanElement>(null); // drag handle
    const previewRef = useRef<HTMLLIElement>(null); // drag item

    const [{ isDragging }, drag, preview] = useDrag({
        type: dndItemTypes.SET,
        item: () => {
            return {
                set: set,
                oldIndex: index,
                index: index
            } as IDragSet
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging()
        }),
        end: item => {
            dragEndHandle(item);
        }
    })

    const [{ handlerId }, drop] = useDrop<IDragSet, void, { handlerId: Identifier | null }>({
        accept: dndItemTypes.SET,
        collect: monitor => {
            return { handlerId: monitor.getHandlerId() }
        },
        hover: (item, monitor) => {
            if (!previewRef.current) return;

            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) return;

            // Determine rectangle on screen
            const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

            // Time to actually perform the action
            dispatch(reorderSetsDnD({
                dragIndex: dragIndex,
                hoverIndex: hoverIndex
            }));

            item.index = hoverIndex;
        }
    });

    const opacity = isDragging ? 0 : 1;
    drag(dragRef);
    drop(preview(previewRef));

    return (
        <Box
            ref={previewRef}
            data-handler-id={handlerId}
            sx={{
                width: '100%',
                height: '100%',
                opacity: opacity
            }}
        >
            <Box
                ref={dragRef}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '12px 16px'
                }}
            >
                {set.title}
            </Box>
        </Box>
    )
}
