import React, {FC, Fragment, useEffect, useState} from 'react';
import {Alert, Box, Button, Card, CardContent, CircularProgress, Container, Typography} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {getUserTags} from '../../store/task/taskSlice';
import {useTranslation} from 'react-i18next';
import {TagAddInput} from '../../components/lists/TagAddInput';
import {TagList} from "../../components/UI/Tags/TagList";

export const TagLists: FC = () => {

    const {t} = useTranslation();

    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {tags} = useAppSelector(state => state.task);

    const [isShowArchive, setIsShowArchive] = useState<boolean>(false);

    const handleClickArchive = () => {
        if (!tags.archiveFetched) {
            dispatch(getUserTags(true));
        }
        setIsShowArchive(prevState => !prevState);
    }

    useEffect(() => {
        if (!tags.fetched) {
            dispatch(getUserTags(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth='lg' disableGutters={true}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    overflowY: 'auto',
                    paddingBottom: '16px',
                    // marginLeft: '5px',
                    // marginRight: '5px'
                }}
            >
                <Box sx={{
                    transition: 'all .5s !important',
                    width: '100%',
                    maxWidth: isMobile ? '96%' : '100%',
                }}>
                    <Box sx={{mt: isMobile ? 0 : 3}}>
                        <Card sx={{
                            mt: 2,
                            paddingLeft: isMobile ? '0rem' : '1rem',
                            paddingRight: isMobile ? '0rem' : '1rem',
                            borderRadius: '0.75rem'
                        }}
                        >
                            <CardContent sx={{height: isMobile ? 'calc(100vh - 88px)' : 'calc(100vh - 104px)'}}>
                                <Typography color='primary'
                                            variant='h5'
                                            sx={{
                                                marginBottom: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                fontSize: '1.5rem',
                                                fontWeight: '500 !important',
                                                lineHeight: '1.2',
                                            }}
                                >
                                    {t('Lists')}
                                </Typography>
                                <Box
                                    sx={{
                                        marginTop: isMobile ? 2 : 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        transition: 'all .25s !important'
                                    }}
                                >
                                    <Box sx={{
                                        transition: 'all .5s !important',
                                        width: '100%',
                                        maxWidth: isMobile ? '95%' : '100%',
                                    }}>

                                        <TagAddInput/>
                                        <Box
                                            sx={{
                                                height: 'calc(100vh - 230px)',
                                                overflow: 'auto',
                                            }}
                                        >
                                            <Box sx={{marginTop: '24px'}}>
                                                <TagList
                                                    grow
                                                    tags={tags.tagList.filter(tag => tag.isArchived === 0)}
                                                    size='medium'
                                                    withMargins
                                                    component='div'
                                                />
                                                {!tags.fetched &&
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        margin: '6px 0 18px 0'
                                                    }}>
                                                        <CircularProgress/>
                                                    </Box>
                                                }
                                                {!tags.tagList.filter(tag => tag.isArchived === 0) && tags.fetched &&
                                                    <Alert variant={"outlined"} severity="info">
                                                        {t('You don\'t have tags yet!')}
                                                    </Alert>
                                                }
                                            </Box>
                                            <Box sx={{marginTop: '8px'}}>
                                                <Box sx={{textAlign: 'end'}}>
                                                    <Button onClick={handleClickArchive}>
                                                        {t('Archive')}
                                                    </Button>
                                                </Box>
                                                {isShowArchive &&
                                                    <Fragment>
                                                        <TagList
                                                            grow
                                                            tags={tags.tagList.filter(tag => tag.isArchived !== 0)}
                                                            size='medium'
                                                            withMargins
                                                            component='div'
                                                        />
                                                        {!tags.tagList.filter(tag => tag.isArchived !== 0).length && tags.archiveFetched &&
                                                            <Alert variant={"outlined"} severity="info">
                                                                {t('You don\'t have archived lists yet!')}
                                                            </Alert>
                                                        }
                                                    </Fragment>
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Box>

        </Container>
    )
}
