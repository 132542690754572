import React, {FC, useEffect, useState} from 'react';
import {Box, Button, Card, CardHeader, CircularProgress, Container, Typography} from '@mui/material';
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {finalRegister} from "../../store/auth/authSlice";
import {getOffset} from "../../utils/helpers";
import i18next from "i18next";
import {IActivationAccount} from "../../models/IActivationAccount";
import CardContent from "@mui/material/CardContent";
import {getFromStorage, saveToStorage} from "../../utils/storageHelpers";

export const AccountSelectMode: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {accountForActivation, isAuthLoading} = useAppSelector(state => state.auth);
    const [buttonClicked, setButtonClicked] = useState<boolean>(false);
    const [referralCode, setReferralCode] = useState<string>('');

    useEffect(() => {
        if (!accountForActivation.id) {
            navigate(`/`, {replace: true});
        }
        const checkReferrals = async () => {
            await getFromStorage('referral')
                .then(referral => {
                    if (referral.value) {
                        setReferralCode(referral.value);
                    }
                });
        }
        checkReferrals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createNewAccount = () => {
        setButtonClicked(true);

        const defaultClientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        dispatch(finalRegister({
            id: accountForActivation.id,
            name: accountForActivation.name,
            provider: accountForActivation.provider,
            confirmHash: accountForActivation.confirmHash,
            timezoneName: defaultClientTimezone,
            timezoneOffset: getOffset(defaultClientTimezone),
            referralCode: referralCode,
            lang: i18next.language
        }))
            .unwrap()
            .then((originalPromiseResult) => {
                navigate(`/tasks`, {replace: true});
            })
            .catch((error) => {
                console.error(error.msg);
            });
    }

    const addRelatedAccount = async () => {
        setButtonClicked(true);
        await getFromStorage('relatedAccounts')
            .then(async storageRelatedAccounts => {
                if (storageRelatedAccounts.value) {
                    let relatedAccounts = JSON.parse(storageRelatedAccounts.value) as IActivationAccount[];
                    const hasAccount = relatedAccounts.filter(account => account.id === accountForActivation.id);
                    if (!hasAccount.length) {
                        relatedAccounts.push(accountForActivation);
                        await saveToStorage('relatedAccounts', JSON.stringify(relatedAccounts));
                    }
                } else {
                    await saveToStorage('relatedAccounts', JSON.stringify([accountForActivation]));
                }
            });


        navigate(`/login`, {replace: true});
    }

    return (
        <Container maxWidth="lg">
            <Box>
                <Card sx={{mt: 3, borderRadius: '0.625rem !important'}}>
                    <CardHeader
                        sx={{
                            padding: '2.5rem 2.5rem 0 2.5rem',
                            display: "flex",
                            overflow: "hidden",
                            "& .MuiCardHeader-content": {
                                overflow: "hidden"
                            }
                        }}
                        title={t('Registration of a new account')}
                        titleTypographyProps={{noWrap: true}}
                    />
                    <CardContent sx={{padding: '1rem 2.5rem 2.5rem 2.5rem',}}>
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                {t('I am a new user')}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {t('This option for users who first use the service. Or for existing users who want to start a new account not related to the existing one.')}
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                                disabled={buttonClicked}
                                onClick={createNewAccount}
                                endIcon={
                                    isAuthLoading
                                        ?
                                        <CircularProgress color='inherit' size={'1rem'}/>
                                        :
                                        undefined
                                }
                            >
                                {t('Create a new account')}
                            </Button>
                        </Box>
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                {t('I have an account (add to related account)')}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {t('This option for users who have an account on the service and want to get access to it in another way. It will be necessary to enter under the existing account.')}
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                                disabled={buttonClicked}
                                onClick={addRelatedAccount}
                                endIcon={
                                    buttonClicked
                                        ?
                                        <CircularProgress color='inherit' size={'1rem'}/>
                                        :
                                        undefined
                                }
                            >
                                {t('Add to the existing')}
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Container>
    )
};
