import React, {CSSProperties, FC, Fragment, useEffect, useState} from 'react'
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {deleteDayOverdueTasks, getOverdueTasks} from '../../store/task/taskSlice';
import {AppObserver} from '../utilities/AppObserver';
import {OverdueTask} from './OverdueTask';
import {NavLink} from 'react-router-dom';
import {TransitionGroup} from 'react-transition-group';
import {TGetTasksResponse} from '../../models/response/TGetTasksResponse';
import {changeTimezone, formatDateWithTimezone, getDayName, sortDaysByDate} from '../../utils/helpers';
import {DnDPicker} from '../UI/Calendar/DnDPicker';
import {DnDDays} from "../UI/Calendar/DnDDays";
import DeleteIcon from "@mui/icons-material/Delete";
import {ITask} from "../../models/ITask";
import {replaceTaskInGoal} from "../../store/goal/goalSlice";

interface IOverdueTasksListProps {
    isHideActions?: boolean,
    style?: CSSProperties,
    height?: CSSProperties['height']
}

let tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

export const OverdueTasksList: FC<IOverdueTasksListProps> = ({
                                                                 isHideActions = false,
                                                                 style,
                                                                 height,
                                                                 ...props
                                                             }) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {user} = useAppSelector(state => state.auth);
    const {overdue, isTaskLoading} = useAppSelector(state => state.task);
    const {isMobile} = useAppSelector(state => state.utils);

    const [days, setDays] = useState<TGetTasksResponse>({});
    const [dialogOptions, setDialogOptions] = useState<{
        isOpen: boolean,
        date: string | null
    }>({
        isOpen: false,
        date: null
    });

    useEffect(() => {
        if (JSON.stringify(days) !== JSON.stringify(overdue.days)) {
            setDays(overdue.days);
        }
    }, [overdue.days, days]);

    const intersect = () => {
        dispatch(getOverdueTasks({
            page: overdue.page,
            limit: 15,
            today: formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))
        }));
    }

    const handleOpenDialog = (e: React.MouseEvent<HTMLElement>, date: string) => {
        setDialogOptions({isOpen: true, date: date});
    }

    const handleCloseDialog = () => {
        setDialogOptions({isOpen: false, date: null});
    }

    const handleDeleteDayTasks = () => {
        if (dialogOptions.date && days.hasOwnProperty(dialogOptions.date)) {
            dispatch(deleteDayOverdueTasks(days[dialogOptions.date].id))
                .unwrap()
                .then(originalPromiseResult => {
                    let keys = Object.keys(originalPromiseResult);
                    let deletedTasks = originalPromiseResult[keys[0]] as ITask[];
                    deletedTasks.map(deletedTask => {
                        if (deletedTask.Goals?.length) {
                            const goalsIds = deletedTask.Goals.map(goal => goal.id);
                            dispatch(replaceTaskInGoal({goalIds: goalsIds, task: {...deletedTask, isDeleted: true}}));
                        }
                        return deletedTask;
                    })
                });
        }
        handleCloseDialog();
    }

    return (
        <div style={style}>
            <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <Typography
                    color={theme.palette.error.main}
                    variant='h5'
                    display="inline"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '12px',
                        fontSize: '1.5rem',
                        fontWeight: '500 !important',
                        lineHeight: '1.2',
                    }}
                >
                    <NavLink
                        to={'/tasks/overdue'}
                        style={{textDecoration: 'none', color: theme.palette.error.main}}
                    >
                        {t('Overdue')}
                    </NavLink>
                </Typography>
                {!isHideActions &&
                    <Box sx={{ml: isMobile ? '8px' : '16px', display: 'flex',}}>
                        {!isMobile
                            &&
                            <Fragment>
                                <DnDDays
                                    title={t('Today')}
                                    dateTo={formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName))}
                                    style={{
                                        fontSize: '21px',
                                        height: '30px',
                                    }}
                                />
                                <DnDDays
                                    title={t('Tomorrow')}
                                    dateTo={formatDateWithTimezone(changeTimezone(tomorrow, user.timezoneName))}
                                    style={{
                                        fontSize: '21px',
                                        height: '30px',
                                        marginLeft: '4px'
                                    }}
                                />
                            </Fragment>
                        }
                        <DnDPicker
                            isFullBorder
                            copy
                            style={{
                                marginLeft: '4px'
                            }}
                        />
                        <DnDPicker
                            isFullBorder
                            style={{
                                marginLeft: '4px'
                            }}
                        />
                    </Box>
                }
            </Box>

            <Box sx={{
                overflowY: 'auto',
                height: height ? height : '',
                maxHeight: !isMobile ? 'calc(100vh - 180px)' : 'calc(100vh - 180px)'
            }}>
                <List dense={true}>
                    <TransitionGroup>
                        {sortDaysByDate(Object.keys(days)).map(date => (
                            <Collapse key={date}>
                                <Fragment>
                                    <ListItem
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            padding: '8px 0px'
                                        }}
                                    >
                                        <Box>
                                            <ListItemIcon>
                                                <AccessAlarmIcon color='error'/>
                                            </ListItemIcon>
                                            <Tooltip title={t('Click to go') + ' ' + date} arrow>
                                                <Typography
                                                    component={NavLink}
                                                    to={`/tasks/${date}`}
                                                    color={theme.palette.error.main}
                                                    marginLeft='-16px'
                                                    fontSize='1rem'
                                                    fontWeight='500'
                                                    style={{
                                                        textDecoration: 'none',
                                                        textDecorationColor: theme.palette.error.main
                                                    }}
                                                >
                                                    {`${date} - ${t(getDayName(date))}`}
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                        <ListItemIcon
                                            sx={{
                                                justifyContent: 'flex-end',
                                                mr: '6px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={(e) => {
                                                handleOpenDialog(e, date)
                                            }}
                                        >
                                            <DeleteIcon sx={{
                                                "&:hover": {
                                                    opacity: '1',
                                                    color: theme.palette.error.main
                                                }
                                            }}/>
                                        </ListItemIcon>
                                    </ListItem>
                                    <Divider sx={{marginTop: '6px'}}/>
                                    <TransitionGroup>
                                        {
                                            days[date].Tasks.length >= user.level.tasksOverdueMaxCount ?
                                                days[date].Tasks.slice(0, user.level.tasksOverdueMaxCount).map((task, i) => (
                                                    <Collapse key={task.id}
                                                              sx={{
                                                                  marginLeft: isMobile ? '0px' : '24px',
                                                              }}
                                                    >
                                                        <Fragment>
                                                            <OverdueTask
                                                                day={days[date]}
                                                                task={task}
                                                                isHideActions={isHideActions}
                                                                index={i}
                                                            />
                                                            <Divider sx={{marginBottom: '6px'}}/>
                                                        </Fragment>
                                                    </Collapse>
                                                ))
                                                :
                                                days[date].Tasks.map((task, i) => (
                                                    <Collapse key={task.id}
                                                              sx={{
                                                                  marginLeft: isMobile ? '0px' : '24px',
                                                              }}
                                                    >
                                                        <Fragment>
                                                            <OverdueTask
                                                                day={days[date]}
                                                                task={task}
                                                                isHideActions={isHideActions}
                                                                index={i}
                                                            />
                                                            <Divider sx={{marginBottom: '6px'}}/>
                                                        </Fragment>
                                                    </Collapse>
                                                ))
                                        }
                                        {
                                            days[date].Tasks.length >= user.level.tasksOverdueMaxCount &&
                                            <Alert variant={"outlined"} severity="info"
                                                   sx={{
                                                       marginBottom: 2,
                                                       marginLeft: '24px'
                                                   }}
                                            >
                                                {t('You can see only _ elements of the list. To remove the restriction, change the subscription!', {
                                                    count: user.level.tasksOverdueMaxCount,
                                                })}
                                            </Alert>
                                        }
                                    </TransitionGroup>
                                </Fragment>
                            </Collapse>
                        ))}
                    </TransitionGroup>
                </List>
                {isTaskLoading &&
                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '6px 0 18px 0'}}>
                        <CircularProgress/>
                    </Box>
                }
                {!Object.keys(overdue.days).length && !isTaskLoading && !overdue.observable &&
                    <Alert variant={"outlined"} severity="info">
                        {t('You have no overdue tasks!')}
                    </Alert>
                }
                {!overdue.observable && Object.keys(overdue.days).length ?
                    <Alert variant={"outlined"} severity="info" sx={{marginBottom: 2}}>
                        {t('These are all overdue tasks!')}
                    </Alert>
                    :
                    ''
                }
                {overdue.observable &&
                    <AppObserver intersect={intersect} observing={overdue.observable} key={overdue.page}/>}

                <Dialog
                    open={dialogOptions.isOpen}
                    onClose={handleCloseDialog}
                    aria-labelledby="delete-day-dialog-title"
                    aria-describedby="delete-day-dialog-description"
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '0.75rem'
                        }
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        {t('Are you sure you want to delete all tasks in this day?')}
                    </DialogTitle>
                    <DialogActions>
                        <Button variant='contained' onClick={handleCloseDialog}>{t('Cancel')}</Button>
                        <Button variant='contained' onClick={handleDeleteDayTasks} autoFocus>
                            {t('Yes')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </div>
    )
}
