import React, {FC, Fragment, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grow,
    TextField,
    Typography
} from '@mui/material';
import {Note} from './Note';
import {NoteAddInput} from "./NoteAddInput";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useTranslation} from 'react-i18next';
import {AppObserver} from "../utilities/AppObserver";
import {editNote, getFavoriteNotes, getNote, getNotes} from "../../store/note/noteSlice";
import {INote} from "../../models/INote";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {TransitionGroup} from 'react-transition-group';
import {formatDate, formatTitle} from '../../utils/helpers';

export const NoteList: FC = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {notes, page, observable, isNoteLoading} = useAppSelector(state => state.note);

    const {
        notes: favoriteNotes,
        page: favoritePage,
        observable: favoriteObservable
    } = useAppSelector(state => state.note.favorite);

    const [onlyFavorite, setOnlyFavorite] = useState<boolean>(false);

    const [viewNoteDialog, setViewNoteDialog] = useState<boolean>(false);
    const [viewNote, setViewNote] = useState<{
        currentViewNote: INote
    }>({
        currentViewNote: {
            id: 0,
            userId: 0,
            shortTitle: '',
            title: '',
            favorite: false,
            createdAt: '',
            updatedAt: '',
            Tags: []
        }
    });

    const [editNoteDialog, setEditNoteDialog] = useState<boolean>(false);
    const [editNoteObj, setEditNoteObj] = useState<{
        currentEditNote: INote
    }>({
        currentEditNote: {
            id: 0,
            userId: 0,
            shortTitle: '',
            title: '',
            favorite: false,
            createdAt: '',
            updatedAt: '',
            Tags: []
        }
    });

    const intersect = () => {
        if (onlyFavorite) {
            dispatch(getFavoriteNotes({page: favoritePage, limit: 12}));
        } else {
            dispatch(getNotes({page: page, limit: 12}));
        }
    }

    const showViewDialog = (note: INote) => {
        setViewNoteDialog(true);

        if (!note.title) {
            dispatch(getNote({noteId: note.id}))
                .unwrap()
                .then(response => {
                    setViewNote({
                        currentViewNote: response
                    });
                });
        } else {
            setViewNote({
                currentViewNote: note
            });
        }
    }

    const showEditDialog = (note: INote) => {
        setEditNoteDialog(true);

        if (!note.title) {
            dispatch(getNote({noteId: note.id}))
                .unwrap()
                .then(response => {
                    setEditNoteObj({
                        currentEditNote: response
                    });
                });
        } else {
            setEditNoteObj({
                currentEditNote: note
            });
        }
    }

    const viewNoteCloseHandle = () => {
        setViewNoteDialog(false);
        setViewNote({
            currentViewNote: {
                id: 0,
                userId: 0,
                shortTitle: '',
                title: '',
                favorite: false,
                createdAt: '',
                updatedAt: '',
                Tags: []
            }
        })
    }

    const editNoteCloseHandle = () => {
        setEditNoteDialog(false);

        setEditNoteObj({
            currentEditNote: {
                id: 0,
                userId: 0,
                shortTitle: '',
                title: '',
                favorite: false,
                createdAt: '',
                updatedAt: '',
                Tags: []
            }
        });
    }

    const editNoteChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditNoteObj(prevState => {
            return {
                currentEditNote: {
                    id: prevState.currentEditNote.id,
                    userId: prevState.currentEditNote.userId,
                    shortTitle: e.target.value,
                    title: e.target.value,
                    favorite: prevState.currentEditNote.favorite,
                    createdAt: prevState.currentEditNote.createdAt,
                    updatedAt: prevState.currentEditNote.updatedAt,
                    Tags: prevState.currentEditNote.Tags
                }
            }
        });
    }

    const saveEditNoteHandle = () => {
        setEditNoteDialog(false);
        saveChangedNote(editNoteObj.currentEditNote);
    }

    const saveChangedNote = (note: INote) => {
        if (note.title) {
            const formattedTitle = formatTitle({title: note.title, addSpaseBefore: true});
            dispatch(editNote({note: note, tagTitles: formattedTitle.tagTitles}))
                .unwrap()
                .then(() => {
                    setEditNoteObj({
                        currentEditNote: {
                            id: 0,
                            userId: 0,
                            shortTitle: '',
                            title: '',
                            favorite: false,
                            createdAt: '',
                            updatedAt: '',
                            Tags: []
                        }
                    });
                });
        }
    }

    const handleFavoriteClick = () => {
        // dispatch(resetNotes());
        setOnlyFavorite(!onlyFavorite);
    }

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '12px'
                }}
            >
                <Typography
                    color='primary'
                    variant='h5'
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '12px',
                        fontSize: '1.5rem',
                        fontWeight: '500 !important',
                        lineHeight: '1.2',
                    }}
                >
                    {t('Notes')}
                </Typography>
                {onlyFavorite
                    ?
                    <StarBorderIcon
                        color='primary'
                        sx={{
                            ml: 2,
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '12px',
                            fontWeight: '500 !important',
                            lineHeight: '1.2',
                        }}
                        onClick={handleFavoriteClick}
                    />
                    :
                    <StarIcon
                        color='primary'
                        sx={{
                            ml: 2,
                            fontSize: '1.5rem',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '12px',
                            fontWeight: '500 !important',
                            lineHeight: '1.2',
                        }}
                        onClick={handleFavoriteClick}
                    />
                }
            </Box>
            <NoteAddInput/>
            <Fragment>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        maxHeight: !isMobile ? '62vh' : ''
                    }}
                    style={{
                        marginTop: '20px',
                        paddingLeft: isMobile ? '8px' : '2px',
                        paddingRight: isMobile ? '8px' : '2px',
                        paddingBottom: '2px',
                        paddingTop: '2px',
                        height: 'calc(100vh - 260px)'
                    }}
                >
                    <Grid container spacing={{xs: 1, md: 1}} columns={{xs: 12, sm: 8, md: 12}}>
                        {
                            onlyFavorite
                                ?
                                <TransitionGroup component={null} exit={false}>
                                    {favoriteNotes.map(note => (
                                        <Grow key={note.id}>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <Note
                                                    openViewModal={showViewDialog}
                                                    openEditModal={showEditDialog}
                                                    note={note}
                                                />
                                            </Grid>
                                        </Grow>
                                    ))}
                                </TransitionGroup>
                                :
                                <TransitionGroup component={null} exit={false}>
                                    {notes.map(note => (
                                        <Grow key={'f' + note.id}>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <Note
                                                    openViewModal={showViewDialog}
                                                    openEditModal={showEditDialog}
                                                    note={note}
                                                />
                                            </Grid>
                                        </Grow>
                                    ))}
                                </TransitionGroup>
                        }
                    </Grid>
                    <div style={{marginTop: "25px"}}>
                        {(favoriteObservable || observable) &&
                            <AppObserver intersect={intersect}
                                         observing={onlyFavorite ? favoriteObservable : observable}
                                         key={onlyFavorite ? 'f' + favoritePage : page}/>}
                    </div>

                </Box>

                <Dialog
                    open={viewNoteDialog}
                    sx={{
                        "& .MuiDialog-container": {
                            justifyContent: "center",
                            alignItems: "flex-start"
                        },
                        '& .MuiPaper-root': {
                            borderRadius: '0.75rem'
                        }
                    }}
                    PaperProps={{
                        sx: {
                            width: '80%',
                            height: '100%',
                        }
                    }}
                    scroll={'paper'}
                >
                    <DialogTitle>{t('View note')}</DialogTitle>
                    <DialogContent>

                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            {!viewNote.currentViewNote.title && 'Loading....'}
                            <Typography
                                align="left"
                                style={{whiteSpace: "pre-wrap"}}
                                component={'span'}
                            >
                                {viewNote.currentViewNote.title}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{justifyContent: "space-between", paddingRight: "24px", paddingLeft: "24px"}}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                            <p style={{
                                margin: "0px",
                                padding: "0px",
                                fontSize: "10px",
                                lineHeight: "1.3"
                            }}>{t('Created')}: {viewNote.currentViewNote.createdAt !== '' ? formatDate(viewNote.currentViewNote.createdAt) : '-'}</p>
                            <p style={{
                                margin: "0px",
                                padding: "0px",
                                fontSize: "10px",
                                lineHeight: "1.3"
                            }}>{t('Changed')}: {viewNote.currentViewNote.updatedAt !== '' ? formatDate(viewNote.currentViewNote.updatedAt) : '-'}</p>
                        </div>
                        <Button
                            onClick={viewNoteCloseHandle}
                            variant="contained"
                        >
                            {t('Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={editNoteDialog}
                    sx={{
                        "& .MuiFormControl-fullWidth": {
                            height: "100%",
                            marginTop: "0px",
                        },
                        "& .MuiInputBase-multiline": {
                            height: "100%",
                        },
                        "& .MuiInputBase-input": {
                            height: "100% !important",
                        },
                        '& .MuiPaper-root': {
                            borderRadius: '0.75rem'
                        }
                    }}
                    PaperProps={{
                        sx: {
                            width: '80%',
                            height: '100%',
                        }
                    }}
                    scroll={'paper'}>
                    <DialogTitle>{t('Edit note')}</DialogTitle>
                    <DialogContent sx={{width: isMobile ? '100%' : '600px'}}>
                        <TextField
                            fullWidth
                            multiline
                            minRows={8}
                            maxRows={80}
                            label={`${t('')}`}
                            size='small'
                            variant="outlined"
                            value={editNoteObj.currentEditNote.title}
                            onChange={editNoteChangeHandle}
                            sx={{mt: 1,}}
                            InputProps={{
                                autoComplete: 'off'
                            }}
                            className={'className'}
                        />
                    </DialogContent>
                    <DialogActions
                        sx={{
                            paddingRight: '24px',
                            paddingBottom: '16px'
                        }}
                    >
                        <Button
                            onClick={editNoteCloseHandle}
                            variant="contained"
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            onClick={saveEditNoteHandle}
                            variant="contained"
                        >
                            {t('Save')}
                        </Button>
                    </DialogActions>
                </Dialog>
                {isNoteLoading &&
                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '24px 0 18px 0'}}>
                        <CircularProgress/>
                    </Box>
                }
                {!onlyFavorite && !notes.length && !isNoteLoading
                    ?
                    <Alert variant={"outlined"} severity="info"
                           style={{marginTop: '20px'}}>{t('There is no notes here!')}</Alert>
                    :
                    ''
                }
                {onlyFavorite && !favoriteNotes.length && !isNoteLoading
                    ?
                    <Alert variant={"outlined"} severity="info"
                           style={{marginTop: '20px'}}>{t('There is no favorite notes here!')}</Alert>
                    :
                    ''
                }
            </Fragment>
        </div>

    )
}
