import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {Alert, Box, Collapse, Divider, List} from '@mui/material';
import {Task} from './Task';
import {TaskAddInput} from './TaskAddInput';
import {ITabDay} from '../../models/days/ITabDay';
import {getDayRecurringTasks, reorderDayTasks, reorderTasks} from '../../store/task/taskSlice';
import {ITask} from '../../models/ITask';
import {useTranslation} from 'react-i18next';
import {TransitionGroup} from 'react-transition-group';
import {FilterBar} from './FilterBar';
import {showTask} from '../../utils/helpers';

interface ITaskList {
    day: ITabDay
}

const showAlert = (tasks: ITask[], isTaskLoading: boolean): boolean => {
    const inProgressTasks = tasks.filter(task => !task.isCompleted && !task.isDeleted);
    if (!inProgressTasks.length && !isTaskLoading) return true;

    return false;
}

export const TaskList: FC<ITaskList> = ({day, ...props}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {days, isTaskLoading, reordering} = useAppSelector(state => state.task);

    const [tasks, setTasks] = useState<ITask[]>([]);
    const [copyTasks, setCopyTasks] = useState<ITask[]>([]);
    const [filter, setFilter] = useState<string>('0');

    const handleChangeFilter = (value: string) => {
        setFilter(value);
    }

    const moveTasks = (dragIndex: number, hoverIndex: number) => {
        const taskStateCopy = [...tasks];
        const [reorderedItem] = taskStateCopy.splice(dragIndex, 1);
        taskStateCopy.splice(hoverIndex, 0, reorderedItem);

        setTasks(taskStateCopy);
    }

    const dragEndHandle = (oldIndex: number, index: number) => {
        if (!reordering) {
            let newPosition = copyTasks[index].Order.position;
            if (newPosition > copyTasks[oldIndex].Order.position) {
                newPosition += 1;
            }

            dispatch(reorderDayTasks({oldIndex: oldIndex, newPosition: newPosition, date: day.date}));
            dispatch(reorderTasks({orderId: copyTasks[oldIndex].Order.id, newPosition: newPosition}));

            setCopyTasks(tasks);
        }
    }

    useEffect(() => {
        const currDay = days[day.date];
        if (!currDay) {
            dispatch(getDayRecurringTasks(day.date));
        } else {
            setTasks([...currDay.Tasks]);
            setCopyTasks([...currDay.Tasks]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [days]);

    return (
        <Box>
            <TaskAddInput day={day}/>
            <FilterBar
                day={day}
                filter={filter}
                handleChangeFilter={handleChangeFilter}
            />
            <Box sx={{overflowY: 'auto', height: isMobile ? 'calc(100vh - 380px)' : '60vh'}}>
                <List dense={true}>
                    <TransitionGroup>
                        {tasks.map((task, i) => (
                            showTask(filter, task) &&
                            <Collapse key={task.id + task.title}>
                                <Task
                                    task={task}
                                    day={day}
                                    index={i}
                                    moveTasks={moveTasks}
                                    dragEndHandle={dragEndHandle}
                                />
                            </Collapse>
                        ))}
                    </TransitionGroup>
                    <Divider/>
                </List>
                {showAlert(tasks, isTaskLoading) &&
                    <Alert variant={"outlined"} severity="info">
                        {t('There is no task here! Plan your day and rush to the success with us!')}
                    </Alert>
                }
            </Box>
        </Box>

    )
}
