import React, {FC, useRef, useState} from 'react';
import {Box, Button, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {ITask} from '../../models/ITask';
import {formatTitle} from '../../utils/helpers';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {addTagTask} from '../../store/task/taskSlice';

interface ITagTaskAddInput {
    tag: string
}

export const TagTaskAddInput: FC<ITagTaskAddInput> = ({tag, ...props}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const {isTaskSending} = useAppSelector(state => state.task);
    const {isMobile} = useAppSelector(state => state.utils);

    const [newTask, setNewTask] = useState<ITask>({title: ''} as ITask);
    const inputRef = useRef<HTMLDivElement>(null);

    const newTaskHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTask({...newTask, title: e.target.value});
    }

    const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') addNewTask();
    }

    const addNewTask = () => {
        if (newTask.title.trim() !== '') {
            const formattedTitle = formatTitle({title: newTask.title});

            dispatch(addTagTask({
                task: {...newTask, title: formattedTitle.title},
                tagTitles: [tag, ...formattedTitle.tagTitles]
            }))
                .unwrap()
                .then((originalPromiseResult) => {
                    setNewTask({...newTask, title: ''});

                    const node = inputRef.current as any;
                    node.focus();
                });
        } else {
            dispatch(setSnackbar({
                text: 'Task title cannot be empty',
                severity: 'error',
                open: true
            }));
        }
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <TextField
                inputRef={inputRef}
                autoFocus={isMobile ? false : true}
                fullWidth
                label={`${t('+ Add task with tag')} "${tag}"`}
                size='small'
                variant="outlined"
                sx={{
                    marginRight: '6px',
                    '& .MuiInputBase-root': {
                        borderRadius: '0.5rem'
                    }
                }}
                InputProps={{
                    autoComplete: 'off'
                }}
                value={newTask.title}
                onChange={newTaskHandle}
                onKeyPress={handleEnterPress}
            />
            <Button
                variant="contained"
                sx={{pointerEvents: isTaskSending ? 'none' : 'auto'}}
                onClick={addNewTask}
            >
                {t('Add')}
            </Button>
        </Box>
    )
}
