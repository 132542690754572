import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hooks/redux";
import { changePassword } from "../../store/auth/authSlice";
import { setSnackbar } from "../../store/utils/utilsSlice";
import { FormikHelpers } from 'formik';

interface IChangePasswordInitialValues {
    currentPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
}

export function useChangePasswordForm() {

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const changePasswordInitialValues: IChangePasswordInitialValues = {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirmation: ''
    }

    const changePasswordValidationSchema = yup.object({
        currentPassword: yup
            .string()
            .trim()
            .min(4, t('Password must be at least 4 and no more than 16 characters'))
            .max(16, t('Password must be at least 4 and no more than 16 characters'))
            .required(t('Password is required')),
        newPassword: yup
            .string()
            .trim()
            .min(4, t('Password must be at least 4 and no more than 16 characters'))
            .max(16, t('Password must be at least 4 and no more than 16 characters'))
            .required(t('Password is required')),
        newPasswordConfirmation: yup
            .string()
            .trim()
            .oneOf([yup.ref('newPassword'), null], t('Password must match'))
            .required(t('Password must match')),
    });

    const onSubmit = async (values: IChangePasswordInitialValues, onSubmitProps: FormikHelpers<IChangePasswordInitialValues>): Promise<void> => {
        await dispatch(changePassword(values))
            .unwrap()
            .then(() => {
                dispatch(setSnackbar({
                    text: 'Your password has been successfully changed!',
                    severity: 'success',
                    open: true
                }));
                onSubmitProps.resetForm();
            })
            .catch(error => {
                console.error(error.msg);
                if (error.param) onSubmitProps.setErrors({ [error.param]: error.msg });
            });
    }

    return {
        changePasswordInitialValues,
        changePasswordValidationSchema,
        onSubmit
    }
}