import React, {FC} from 'react';
import {Link as routerLink} from 'react-router-dom';
import {useFormik} from 'formik';
import {
    Alert,
    Avatar,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Link,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import {Box} from '@mui/system';
import {useLoginForm} from '../../validation/auth/login-form';
import {useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {SocialLoginButton} from "./social/SocialLoginButton";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

export const LoginForm: FC = () => {

    const {t} = useTranslation();
    const theme = useTheme();
    const {authError} = useAppSelector(state => state.auth);

    const {loginInitialValues, loginValidationSchema, onSubmit} = useLoginForm();

    const formik = useFormik({
        initialValues: loginInitialValues,
        validationSchema: loginValidationSchema,
        onSubmit: onSubmit
    });


    return (
        <Card sx={{maxWidth: '540px', borderRadius: '0.625rem !important'}}>
            <CardContent sx={{padding: '2.5rem !important'}}>
                <Avatar sx={{m: 'auto', bgcolor: theme.palette.primary.main}}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1"
                            variant="h5"
                            sx={{
                                textAlign: 'center',
                                fontWeight: '700 !important',
                                mt: 1,
                                marginBottom: '32px'
                            }}
                >
                    {t('Log in')}
                </Typography>
                <Box component="form" onSubmit={formik.handleSubmit} sx={{mt: 1}}>
                    {authError.showAlert
                        ?
                        <Alert
                            severity='error'
                        >
                            {authError.msg}
                        </Alert>
                        :
                        ''
                    }
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label={t("email")}
                        autoComplete="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        sx={{
                            marginTop: '0px',
                            marginBottom: '2rem !important',
                            "& .MuiOutlinedInput-root": {
                                padding: "0px 0px 0px 8px",
                                "&:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0 100px #000000 inset",
                                    WebkitTextFillColor: "default",
                                },
                            }
                        }}
                        InputProps={{
                            sx: {
                                borderRadius: '0.75rem',
                            },
                            inputProps: {
                                style: {
                                    padding: '0.775rem 1rem',

                                }
                            }
                        }}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="password"
                        label={t("Password")}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        sx={{
                            marginTop: '0px',
                            "& .MuiOutlinedInput-root": {
                                padding: "0px 0px 0px 8px",
                                "&:-webkit-autofill": {
                                    WebkitBoxShadow: "0 0 0 100px #000000 inset",
                                    WebkitTextFillColor: "default",
                                },
                            }
                        }}
                        InputProps={{
                            sx: {
                                borderRadius: '0.75rem',
                            },
                            inputProps: {
                                style: {
                                    padding: '0.775rem 1rem',

                                }
                            }
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={formik.isSubmitting}
                        endIcon={
                            formik.isSubmitting
                                ?
                                <CircularProgress color='inherit' size={'1rem'}/>
                                :
                                undefined
                        }
                        sx={{
                            mt: 3,
                            mb: 2,
                            borderRadius: '0.75rem',
                            padding: 'calc(0.775rem + 1px) calc(1.5rem + 1px)',
                            textTransform: 'none',
                            fontSize: '1.1rem',
                            lineHeight: '1'
                        }}
                    >
                        {t('SignIn')}
                    </Button>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Link component={routerLink}
                                  to="/forgot-password"
                                  variant="body2"
                                  sx={{
                                      fontWeight: '500 !important'
                                  }}
                            >
                                {t('Forgot password?')}
                            </Link>
                        </Grid>
                    </Grid>
                    <Typography sx={{
                        marginTop: '16px',
                        textAlign: 'center',
                        fontSize: '0.95rem !important',
                        color: theme.palette.textColorThird.main, // '#A1A5B7'
                    }}>
                        {t('Or with social campaigns')}
                    </Typography>
                    <Grid container spacing={1} sx={{mt: 1, mb: 1}}>
                        <Grid item xs={12} md={6}>
                            <SocialLoginButton provider={"google"} providerTitle={"Google"}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SocialLoginButton provider={"facebook"} providerTitle={"Facebook"}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SocialLoginButton provider={"linkedin"} providerTitle={"LinkedIn"}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SocialLoginButton provider={"apple"} providerTitle={"Apple"}/>
                        </Grid>
                    </Grid>

                </Box>
            </CardContent>
        </Card>
    )
};
