import React, { FC, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/redux';
import { Home } from '../../pages/Home';
import { routes } from '../../router/routes';
import { changeTimezone, formatDateWithTimezone } from '../../utils/helpers';
import { GuardedRoute } from './GuardedRoute';

const authExeptions = ['/login', '/register', '/forgot-password'];

export const AppRouter: FC = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const { isAuth, user } = useAppSelector(state => state.auth);

    const today = formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName));

    useEffect(() => {
        if (isAuth) {
            if (authExeptions.includes(location.pathname)) {
                navigate('/home');
            } else if (!user.isActivated && location.pathname !== '/profile') {
                navigate('/profile');
            } else if (user.isActivated && (location.pathname === '/' || location.pathname === '/tasks')) {
                navigate(`/tasks/${today}`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <Routes>
            {routes.map((route) => (
                route.protected
                    ?
                    <Route
                        path={route.path}
                        element={
                            <GuardedRoute path={route.path} element={route.element} />
                        }
                        key={route.id}
                    />
                    :
                    <Route
                        path={route.path}
                        element={<route.element />}
                        key={route.id}
                    />
            ))}
            <Route
                path='*'
                element={<Home />}
            />
        </Routes>
    )
};
