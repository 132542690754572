import React, {FC, Fragment, useState} from 'react';
import {getDashboardPageLink, getHomePageLink, getLinks} from '../../router/links';
import {CSSObject, styled, Theme, useTheme} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {SideBarLink} from '../UI/Links/SideBarLink';
import {IDragTask} from '../../models/dnd/IDragTask';
import {ILink} from '../../models/ILink';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
    addTask,
    changeTaskDayDnD,
    changeTasksDay,
    completeTask,
    deleteRecurring,
    deleteTask,
    getDayRecurringTasks,
    getPlannedTasks,
    resetTasks,
    tagTaskAction,
    togglePlanned,
    undeleteTask
} from '../../store/task/taskSlice';
import {DeleteRecurringDialog} from '../UI/DeleteAlert/DeleteRecurringDialog';
import {ITask} from '../../models/ITask';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {TagTaskActionType} from '../../models/Types';
import {changeTimezone, formatDateWithTimezone} from '../../utils/helpers';
import {Avatar} from "@mui/material";
import {setShowBuyPremiumDialogStatus} from "../../store/subscription/subscriptionSlice";

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
    backgroundColor: theme.palette.background.default,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '5%',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const deleteVariants: string[] = ['Delete this task', 'Delete this task and all subsequent ones', 'Delete all tasks'];

interface IProps {
    open: boolean,
    handleDrawerClose: () => void
}

export const SideBar: FC<IProps> = ({open, handleDrawerClose}) => {

    const theme = useTheme();
    const dispatch = useAppDispatch();

    const {user, isAuth} = useAppSelector(state => state.auth);
    const {days, planned} = useAppSelector(state => state.task);


    const [dialogOpt, setdialogOpt] = useState<{ open: boolean, task: ITask | null, date: string | null }>({
        open: false,
        task: null,
        date: null
    });
    const [selectedValue] = useState<string>('Cancel');

    const handleClose = (value: string) => {
        deleteRecurringHandle(dialogOpt.task, value, dialogOpt.date);

        setdialogOpt({open: false, task: null, date: null});
    }

    const dropHandle = (item: IDragTask, link: ILink) => {

        if (!user.level.tasksAllowDnD) {
            dispatch(setShowBuyPremiumDialogStatus(true));
            return;
        }

        const today = formatDateWithTimezone(changeTimezone(new Date(), user.timezoneName));
        if (link.name === 'Tasks') {
            const currDay = days[today];
            if (!currDay) {
                dispatch(getDayRecurringTasks(today))
                    .unwrap()
                    .then(() => {
                        tasksLinkMove(item, today);
                    });
            } else {
                tasksLinkMove(item, today);
            }
        }

        if (link.name === 'Planned') {
            if (planned.totalCount === 0) {
                dispatch(getPlannedTasks({page: 1, limit: 15}))
                    .unwrap()
                    .then(() => {
                        plannedLinkMove(item, today);
                    });
            } else {
                plannedLinkMove(item, today);
            }
        }

        if (link.name === 'Completed') {
            if (item.task.isDeleted) {
                dispatch(undeleteTask({
                    taskId: item.task.id,
                    isCompleted: true,
                    isPlanned: false
                }));
                return;
            }
            if (!item.task.isPlanned && !item.task.dayId && item.task.id && item.tagFrom) {
                dispatch(tagTaskAction({
                    taskId: item.task.id,
                    tagFrom: item.tagFrom,
                    actionType: TagTaskActionType.COMPLETE,
                    date: today
                }));
                return;
            }

            const editedTask = {...item.task, isCompleted: true};
            !item.task.id
                ?
                dispatch(addTask({
                    task: editedTask,
                    date: item.date,
                    tagTitles: editedTask.Tags.map(tag => tag.title)
                }))
                :
                dispatch(completeTask(item.task.id));

            return;
        }

        if (link.name === 'Deleted') {
            if (!item.task.isPlanned && !item.task.dayId && item.task.id && item.tagFrom) {
                dispatch(tagTaskAction({
                    taskId: item.task.id,
                    tagFrom: item.tagFrom,
                    actionType: TagTaskActionType.DELETE,
                    date: today
                }));
                return;
            }
            item.task.isRecurring
                ?
                setdialogOpt({open: true, task: item.task, date: item.date})
                :
                dispatch(deleteTask(item.task.id));

            return;
        }
    }

    const tasksLinkMove = (item: IDragTask, today: string) => {
        if (days[today].Tasks.length >= user.level.tasksPerDayMaxCount) {
            dispatch(setShowBuyPremiumDialogStatus(true));
            return;
        }

        if (!item.task.isPlanned && !item.task.dayId && item.task.id && item.tagFrom) {
            dispatch(tagTaskAction({
                taskId: item.task.id,
                tagFrom: item.tagFrom,
                actionType: TagTaskActionType.DAY,
                date: today
            }));
            return;
        }
        if (item.task.isDeleted) {
            dispatch(undeleteTask({
                taskId: item.task.id,
                isCompleted: false,
                isPlanned: false
            }));
            return;
        }
        if (item.task.isPlanned) {
            dispatch(togglePlanned({
                taskId: item.task.id,
                date: today
            }));
            return;
        }
        if (item.task.id) {
            if (item.date === today) return;
            dispatch(changeTaskDayDnD({
                oldDate: item.date,
                task: item.task,
                newDayDate: today,
            }));
            dispatch(changeTasksDay({
                date: today,
                taskId: item.task.id,
                orderId: item.task.Order.id,
                listType: item.tagFrom !== undefined ? 2 : 0
            }));
            return;
        }
    }
    const plannedLinkMove = (item: IDragTask, today: string) => {
        if (planned.totalCount >= user.level.tasksPlannedMaxCount) {
            dispatch(setShowBuyPremiumDialogStatus(true));
            return;
        }

        if (item.task.isDeleted) {
            dispatch(undeleteTask({
                taskId: item.task.id,
                isCompleted: false,
                isPlanned: true
            }));
            return;
        }
        if (!item.task.isPlanned && item.task.dayId) {
            if (item.task.isRecurring) {
                dispatch(setSnackbar({
                    text: 'You cannot make a recurring task planned',
                    severity: 'error',
                    open: true
                }));
            } else {
                dispatch(togglePlanned({taskId: item.task.id, date: item.date}));
            }
            return;
        } else if (!item.task.isPlanned && !item.task.dayId && item.task.id && item.tagFrom) {
            dispatch(tagTaskAction({
                taskId: item.task.id,
                tagFrom: item.tagFrom,
                actionType: TagTaskActionType.PLANNED,
                date: today
            }));
            return;
        }
    }

    const deleteRecurringHandle = (task: ITask | null, type: string, date: string | null) => {
        if (!task) return;

        const deletedTask = {...task, isDeleted: true};

        if (type === 'Delete this task') {
            if (date) {
                !task.id
                    ?
                    dispatch(addTask({task: deletedTask, date: date}))
                    :
                    dispatch(deleteTask(task.id));
            }
        }
        if (type === 'Delete this task and all subsequent ones') {
            dispatch(deleteRecurring({
                recurringId: task.recurringId,
                dayId: task.dayId,
                deleteTask: false
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    if (originalPromiseResult) {
                        dispatch(resetTasks());
                    }
                });
        }
        if (type === 'Delete all tasks') {
            dispatch(deleteRecurring({
                recurringId: task.recurringId,
                dayId: task.dayId,
                deleteTask: true
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    if (originalPromiseResult) {
                        dispatch(resetTasks());
                    }
                });
        }
    }

    const links = [
        isAuth ? getDashboardPageLink() : getHomePageLink(),
        ...getLinks(user.timezoneName)
    ];

    return (
        <Fragment>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <Avatar variant="square"
                            src={'/logo192.png'}
                            sx={{width: '48px', height: '48px'}}
                    />
                    <Typography variant="h6">
                        SetTasks
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    {links.map(link => (
                        <SideBarLink
                            link={link}
                            open={open}
                            dropHandle={dropHandle}
                            key={link.name}
                        />
                    ))}
                </List>
            </Drawer>
            <DeleteRecurringDialog
                variants={deleteVariants}
                open={dialogOpt.open}
                selectedValue={selectedValue}
                onClose={handleClose}
            />
        </Fragment>

    )
}
