import { FC } from "react";
import { Login } from "../pages/auth/Login";
import { Registration } from "../pages/auth/Registration";
import { Home } from "../pages/Home";
import { Tasks } from "../pages/tasks/Tasks";
import { Profile } from "../pages/user/Profile";
import { Notes } from "../pages/notes/Notes";
import { Goals } from "../pages/goals/Goals";
import { CompletedTasks } from "../pages/completed/CompletedTasks";
import { OverdueTasks } from "../pages/overdue/OverdueTasks";
import { PlannedTasks } from "../pages/planned/PlannedTasks";
import { DeletedTasks } from "../pages/deleted/DeletedTasks";
import { Agenda } from "../pages/agenda/Agenda";
import { Kicker } from "../pages/kicker/Kicker";
import { Journal } from "../pages/journal/Journal";
import { TagLists } from "../pages/lists/TagLists";
import { TagTasks } from "../pages/lists/TagTasks";
import { Recurrings } from "../pages/recurrings/Recurrings";
import { TagNotes } from "../pages/notes/TagNotes";
import { ForgotPassword } from "../pages/auth/ForgotPassword";
import { Subscription } from "../pages/subscription/Subscription";
import { SocialLogin } from "../pages/auth/SocialLogin";
import { RegistrationSuccess } from "../pages/auth/RegistrationSuccess";
import { AccountActivation } from "../pages/auth/AccountActivation";
import { AccountSelectMode } from "../pages/auth/AccountSelectMode";
import { RelatedAccountMode } from "../pages/auth/RelatedAccountMode";
import { GoalsView } from "../pages/goals/GoalsView";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { HabitsView } from "../pages/habits/HabitsView";

interface IRoute {
  id: number;
  protected: boolean;
  path: string;
  element: FC;
}

export const routes: IRoute[] = [
  {
    id: 1,
    protected: false,
    path: "/home",
    element: Home,
  },
  {
    id: 2,
    protected: false,
    path: "/login",
    element: Login,
  },
  {
    id: 3,
    protected: false,
    path: "/register",
    element: Registration,
  },
  {
    id: 4,
    protected: true,
    path: "/profile",
    element: Profile,
  },
  {
    id: 5,
    protected: true,
    path: "/tasks/:date",
    element: Tasks,
  },
  {
    id: 6,
    protected: true,
    path: "/notes",
    element: Notes,
  },
  {
    id: 7,
    protected: true,
    path: "/goals",
    element: Goals,
  },
  {
    id: 8,
    protected: true,
    path: "/tasks/completed",
    element: CompletedTasks,
  },
  {
    id: 9,
    protected: true,
    path: "/tasks/overdue",
    element: OverdueTasks,
  },
  {
    id: 10,
    protected: true,
    path: "/tasks/planned",
    element: PlannedTasks,
  },
  {
    id: 11,
    protected: true,
    path: "/tasks/deleted",
    element: DeletedTasks,
  },
  {
    id: 12,
    protected: true,
    path: "/agenda",
    element: Agenda,
  },
  {
    id: 13,
    protected: true,
    path: "/kicker",
    element: Kicker,
  },
  {
    id: 14,
    protected: true,
    path: "/journal",
    element: Journal,
  },
  {
    id: 15,
    protected: true,
    path: "/lists",
    element: TagLists,
  },
  {
    id: 16,
    protected: true,
    path: "/lists/:tag",
    element: TagTasks,
  },
  {
    id: 17,
    protected: true,
    path: "/recurrings",
    element: Recurrings,
  },
  {
    id: 18,
    protected: true,
    path: "/notes/:tag",
    element: TagNotes,
  },
  {
    id: 19,
    protected: false,
    path: "/forgot-password",
    element: ForgotPassword,
  },
  {
    id: 20,
    protected: true,
    path: "/subscription",
    element: Subscription,
  },
  {
    id: 21,
    protected: false,
    path: "/social-auth",
    element: SocialLogin,
  },
  {
    id: 22,
    protected: false,
    path: "/register-success",
    element: RegistrationSuccess,
  },
  {
    id: 23,
    protected: false,
    path: "/account-activation/:confirmHash",
    element: AccountActivation,
  },
  {
    id: 24,
    protected: false,
    path: "/account-select-mode",
    element: AccountSelectMode,
  },
  {
    id: 25,
    protected: true,
    path: "/related-accounts-mode",
    element: RelatedAccountMode,
  },
  {
    id: 26,
    protected: true,
    path: "/goals/:id",
    element: GoalsView,
  },
  {
    id: 27,
    protected: true,
    path: "/dashboard",
    element: Dashboard,
  },
  {
    id: 28,
    protected: true,
    path: "/goals/:id/:setId",
    element: GoalsView,
  },
  {
    id: 29,
    protected: true,
    path: "/habits",
    element: HabitsView,
  },
];
