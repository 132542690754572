import {IColorSchema} from "../models/colorSchema/IColorSchema";

export const colorsSchemas: IColorSchema[] = [
    {
        light: {
            background: {
                main: '#F3F6F9 !important',
                // main: '#053854 !important',
            },
            primary: {
                light: '#0095e8',
                main: '#0095e8',
                dark: '#0068a2',
                contrastText: '#ffffff',
            },
            secondary: {
                light: '#ba68c8',
                main: '#9c27b0',
                dark: '#7b1fa2',
            },
            error: {
                light: '#ef5350',
                main: '#d9214e',
                dark: '#c62828',
            },
            warning: {
                light: '#ffc700',
                main: '#ffc700',
                dark: '#ffc700',
            },
            info: {
                light: '#03a9f4',
                main: '#0288d1',
                dark: '#01579b',
            },
            success: {
                light: '#4caf50',
                main: '#2e7d32',
                dark: '#1b5e20',
            },
            alert: {
                info: {
                    backgroundColor: '#f1faff',
                    borderColor: 'rgba(0, 158, 247, 1) !important'
                },
                error: {
                    backgroundColor: 'rgb(251, 237, 240)',
                    borderColor: '#f1416c !important'
                }
            },
            custom: {
                light: '#ffffff',
                main: '#ffffff',
                dark: '#ffffff',
            },
            borderColor: {
                main: '#c4c4c4',
            },
            textColorThird: {
                main: '#A1A5B7',
                dark: '#5E6278'
            },
            filterColorAll: {
                main: '#009ef7'
            },
            filterColorInProgress: {
                main: '#e6a304'
            },
            filterColorCompleted: {
                main: '#14aa40'
            },
            subscriptionCardBackground: {
                main: '#f1faff'
            },
            subscriptionPriceColor: {
                main: '#3E97FF'
            },
            dragAndDropColor: {
                main: '#757575',
            },
            dragAndDropBackgroundColor: {
                main: '#d1d1d1',
            },
            dragAndDropBorderColor: {
                main: '#666666'
            },
            sideBarItemColor: {
                main: '#555868'
            },
            sideBarItemSelectedColor: {
                main: '#0095e8'
            },
            sideBarItemHoverBackgroundColor: {
                main: 'rgba(0, 149, 232, 0.08)'
            },
            sideBarItemSelectedBackgroundColor: {
                main: 'rgba(0, 149, 232, 0.2)'
            },
            sideBarItemIconColor: {
                main: '#555868'
            },
            sideBarItemIconSelectedColor: {
                main: '#0095e8'
            },
            goalLabelIsDreamTextColor: {
                main: '#f1416c'
            },
            goalLabelIsDreamBackgroundColor: {
                main: '#fff5f8'
            },
            goalLabelCompletedTextColor: {
                main: '#50cd89'
            },
            goalLabelCompletedBackgroundColor: {
                main: '#e8fff3'
            },
            goalLabelDateTextColor: {
                main: '#7239ea'
            },
            goalLabelDateBackgroundColor: {
                main: '#f8f5ff'
            },
            journalDayDateColor: {
                main: '#9DA0A4'
            },
            journalDayDateHoverColor: {
                main: '#000'
            },
            journalDayBlackColor: {
                main: '#000000'
            },
            journalDayTerribleColor: {
                main: '#d9214e'
            },
            journalDayBadColor: {
                main: '#BFBFBF'
            },
            journalDayTypicalColor: {
                main: '#535353'
            },
            journalDayGoodColor: {
                main: '#0095e8'
            },
            journalDayLuckyColor: {
                main: '#0288d1'
            },
            journalDayPerfectColor: {
                main: '#2e7d32'
            }
        },
        dark: {
            background: {
                main: '#121212 !important',
            },
            primary: {
                light: '#0788d0',
                main: '#0788d0',
                dark: '#0068a2',
                contrastText: 'rgba(255, 255, 255, 0.7)',
            },
            secondary: {
                light: '#ba68c8',
                main: '#9c27b0',
                dark: '#7b1fa2',
            },
            error: {
                light: '#ef5350',
                main: '#d9214e',
                dark: '#c62828',
            },
            warning: {
                light: '#ffc700',
                main: '#ffc700',
                dark: '#ffc700',
            },
            info: {
                light: '#03a9f4',
                main: '#0288d1',
                dark: '#01579b',
            },
            success: {
                light: '#4caf50',
                main: '#2e7d32',
                dark: '#1b5e20',
            },
            alert: {
                info: {
                    backgroundColor: '#212e48',
                    borderColor: 'rgba(0, 158, 247, 1) !important'
                },
                error: {
                    backgroundColor: '#3a2434',
                    borderColor: '#f1416c !important'
                }
            },
            custom: {
                light: '#ffffff',
                main: '#ffffff',
                dark: '#ffffff',
            },
            borderColor: {
                main: '#666666',
            },
            textColorThird: {
                main: '#A1A5B7',
                dark: '#5E6278'
            },
            filterColorAll: {
                main: '#009ef7'
            },
            filterColorInProgress: {
                main: '#e6a304'
            },
            filterColorCompleted: {
                main: '#14aa40'
            },
            subscriptionCardBackground: {
                main: 'rgba(43, 43, 64, 0.75) !important'
            },
            subscriptionPriceColor: {
                main: '#3E97FF'
            },
            dragAndDropColor: {
                main: '#ffffff',
            },
            dragAndDropBackgroundColor: {
                main: '#444444',
            },
            dragAndDropBorderColor: {
                main: '#b8b8b8'
            },
            sideBarItemColor: {
                main: 'rgba(255, 255, 255, 0.7)'
            },
            sideBarItemSelectedColor: {
                main: '#0788d0'
            },
            sideBarItemHoverBackgroundColor: {
                main: 'rgba(0, 149, 232, 0.08)'
            },
            sideBarItemSelectedBackgroundColor: {
                main: 'rgba(0, 149, 232, 0.2)'
            },
            sideBarItemIconColor: {
                main: 'rgba(255, 255, 255, 0.7)'
            },
            sideBarItemIconSelectedColor: {
                main: '#0788d0'
            },
            goalLabelIsDreamTextColor: {
                main: '#f1416c'
            },
            goalLabelIsDreamBackgroundColor: {
                main: '#3a2434'
            },
            goalLabelCompletedTextColor: {
                main: '#50cd89'
            },
            goalLabelCompletedBackgroundColor: {
                main: '#1c3238'
            },
            goalLabelDateTextColor: {
                main: '#7239ea'
            },
            goalLabelDateBackgroundColor: {
                main: '#2f264f'
            },
            journalDayDateColor: {
                main: '#9DA0A4'
            },
            journalDayDateHoverColor: {
                main: '#000'
            },
            journalDayBlackColor: {
                main: '#000000'
            },
            journalDayTerribleColor: {
                main: '#d9214e'
            },
            journalDayBadColor: {
                main: '#BFBFBF'
            },
            journalDayTypicalColor: {
                main: '#F3F6F9'
            },
            journalDayGoodColor: {
                main: '#0095e8'
            },
            journalDayLuckyColor: {
                main: '#0288d1'
            },
            journalDayPerfectColor: {
                main: '#2e7d32'
            }
        },
    },
    {
        light: {
            background: {
                main: '#F3F6F9 !important',
                // main: '#053854 !important',
            },
            primary: {
                light: '#059b1e',
                main: '#038318',
                dark: '#037917',
                contrastText: '#ffffff',
            },
            secondary: {
                light: '#ba68c8',
                main: '#9c27b0',
                dark: '#7b1fa2',
            },
            error: {
                light: '#ef5350',
                main: '#d9214e',
                dark: '#c62828',
            },
            warning: {
                light: '#ffc700',
                main: '#ffc700',
                dark: '#ffc700',
            },
            info: {
                light: '#03a9f4',
                main: '#0288d1',
                dark: '#01579b',
            },
            success: {
                light: '#4caf50',
                main: '#2e7d32',
                dark: '#1b5e20',
            },
            alert: {
                info: {
                    backgroundColor: '#f1faff',
                    borderColor: 'rgba(0, 158, 247, 1) !important'
                },
                error: {
                    backgroundColor: 'rgb(251, 237, 240)',
                    borderColor: '#f1416c !important'
                }
            },
            custom: {
                light: '#ffffff',
                main: '#ffffff',
                dark: '#ffffff',
            },
            borderColor: {
                main: '#c4c4c4',
            },
            textColorThird: {
                main: '#A1A5B7',
                dark: '#5E6278'
            },
            filterColorAll: {
                main: '#009ef7'
            },
            filterColorInProgress: {
                main: '#e6a304'
            },
            filterColorCompleted: {
                main: '#14aa40'
            },
            subscriptionCardBackground: {
                main: '#f1faff'
            },
            subscriptionPriceColor: {
                main: '#3E97FF'
            },
            dragAndDropColor: {
                main: '#757575',
            },
            dragAndDropBackgroundColor: {
                main: '#d1d1d1',
            },
            dragAndDropBorderColor: {
                main: '#666666'
            },
            sideBarItemColor: {
                main: '#555868'
            },
            sideBarItemSelectedColor: {
                main: '#0095e8'
            },
            sideBarItemHoverBackgroundColor: {
                main: 'rgba(0, 149, 232, 0.08)'
            },
            sideBarItemSelectedBackgroundColor: {
                main: 'rgba(0, 149, 232, 0.2)'
            },
            sideBarItemIconColor: {
                main: '#555868'
            },
            sideBarItemIconSelectedColor: {
                main: '#0095e8'
            },
            goalLabelIsDreamTextColor: {
                main: '#f1416c'
            },
            goalLabelIsDreamBackgroundColor: {
                main: '#fff5f8'
            },
            goalLabelCompletedTextColor: {
                main: '#50cd89'
            },
            goalLabelCompletedBackgroundColor: {
                main: '#e8fff3'
            },
            goalLabelDateTextColor: {
                main: '#7239ea'
            },
            goalLabelDateBackgroundColor: {
                main: '#f8f5ff'
            },
            journalDayDateColor: {
                main: '#9DA0A4'
            },
            journalDayDateHoverColor: {
                main: '#000'
            },
            journalDayBlackColor: {
                main: '#000000'
            },
            journalDayTerribleColor: {
                main: '#d9214e'
            },
            journalDayBadColor: {
                main: '#BFBFBF'
            },
            journalDayTypicalColor: {
                main: '#F3F6F9'
            },
            journalDayGoodColor: {
                main: '#0095e8'
            },
            journalDayLuckyColor: {
                main: '#0288d1'
            },
            journalDayPerfectColor: {
                main: '#2e7d32'
            }
        },
        dark: {
            background: {
                main: '#121212 !important',
            },
            primary: {
                light: '#059b1e',
                main: '#038318',
                dark: '#037917',
                contrastText: 'rgba(255, 255, 255, 0.7)',
            },
            secondary: {
                light: '#ba68c8',
                main: '#9c27b0',
                dark: '#7b1fa2',
            },
            error: {
                light: '#ef5350',
                main: '#d9214e',
                dark: '#c62828',
            },
            warning: {
                light: '#ffc700',
                main: '#ffc700',
                dark: '#ffc700',
            },
            info: {
                light: '#03a9f4',
                main: '#0288d1',
                dark: '#01579b',
            },
            success: {
                light: '#4caf50',
                main: '#2e7d32',
                dark: '#1b5e20',
            },
            alert: {
                info: {
                    backgroundColor: '#212e48',
                    borderColor: 'rgba(0, 158, 247, 1) !important'
                },
                error: {
                    backgroundColor: '#3a2434',
                    borderColor: '#f1416c !important'
                }
            },
            custom: {
                light: '#ffffff',
                main: '#ffffff',
                dark: '#ffffff',
            },
            borderColor: {
                main: '#666666',
            },
            textColorThird: {
                main: '#A1A5B7',
                dark: '#5E6278'
            },
            filterColorAll: {
                main: '#009ef7'
            },
            filterColorInProgress: {
                main: '#e6a304'
            },
            filterColorCompleted: {
                main: '#14aa40'
            },
            subscriptionCardBackground: {
                main: 'rgba(43, 43, 64, 0.75) !important'
            },
            subscriptionPriceColor: {
                main: '#3E97FF'
            },
            dragAndDropColor: {
                main: '#ffffff',
            },
            dragAndDropBackgroundColor: {
                main: '#444444',
            },
            dragAndDropBorderColor: {
                main: '#b8b8b8'
            },
            sideBarItemColor: {
                main: 'rgba(255, 255, 255, 0.7)'
            },
            sideBarItemSelectedColor: {
                main: '#0788d0'
            },
            sideBarItemHoverBackgroundColor: {
                main: 'rgba(0, 149, 232, 0.08)'
            },
            sideBarItemSelectedBackgroundColor: {
                main: 'rgba(0, 149, 232, 0.2)'
            },
            sideBarItemIconColor: {
                main: 'rgba(255, 255, 255, 0.7)'
            },
            sideBarItemIconSelectedColor: {
                main: '#0788d0'
            },
            goalLabelIsDreamTextColor: {
                main: '#f1416c'
            },
            goalLabelIsDreamBackgroundColor: {
                main: '#3a2434'
            },
            goalLabelCompletedTextColor: {
                main: '#50cd89'
            },
            goalLabelCompletedBackgroundColor: {
                main: '#1c3238'
            },
            goalLabelDateTextColor: {
                main: '#7239ea'
            },
            goalLabelDateBackgroundColor: {
                main: '#2f264f'
            },
            journalDayDateColor: {
                main: '#9DA0A4'
            },
            journalDayDateHoverColor: {
                main: '#000'
            },
            journalDayBlackColor: {
                main: '#000000'
            },
            journalDayTerribleColor: {
                main: '#d9214e'
            },
            journalDayBadColor: {
                main: '#BFBFBF'
            },
            journalDayTypicalColor: {
                main: '#F3F6F9'
            },
            journalDayGoodColor: {
                main: '#0095e8'
            },
            journalDayLuckyColor: {
                main: '#0288d1'
            },
            journalDayPerfectColor: {
                main: '#2e7d32'
            }
        },
    },
    {
        light: {
            background: {
                main: '#F3F6F9 !important',
                // main: '#053854 !important',
            },
            primary: {
                light: '#ee0404',
                main: '#c50606',
                dark: '#9b0303',
                contrastText: '#ffffff',
            },
            secondary: {
                light: '#ba68c8',
                main: '#9c27b0',
                dark: '#7b1fa2',
            },
            error: {
                light: '#ef5350',
                main: '#d9214e',
                dark: '#c62828',
            },
            warning: {
                light: '#ffc700',
                main: '#ffc700',
                dark: '#ffc700',
            },
            info: {
                light: '#03a9f4',
                main: '#0288d1',
                dark: '#01579b',
            },
            success: {
                light: '#4caf50',
                main: '#2e7d32',
                dark: '#1b5e20',
            },
            alert: {
                info: {
                    backgroundColor: '#f1faff',
                    borderColor: 'rgba(0, 158, 247, 1) !important'
                },
                error: {
                    backgroundColor: 'rgb(251, 237, 240)',
                    borderColor: '#f1416c !important'
                }
            },
            custom: {
                light: '#ffffff',
                main: '#ffffff',
                dark: '#ffffff',
            },
            borderColor: {
                main: '#c4c4c4',
            },
            textColorThird: {
                main: '#A1A5B7',
                dark: '#5E6278'
            },
            filterColorAll: {
                main: '#009ef7'
            },
            filterColorInProgress: {
                main: '#e6a304'
            },
            filterColorCompleted: {
                main: '#14aa40'
            },
            subscriptionCardBackground: {
                main: '#f1faff'
            },
            subscriptionPriceColor: {
                main: '#3E97FF'
            },
            dragAndDropColor: {
                main: '#757575',
            },
            dragAndDropBackgroundColor: {
                main: '#d1d1d1',
            },
            dragAndDropBorderColor: {
                main: '#666666'
            },
            sideBarItemColor: {
                main: '#555868'
            },
            sideBarItemSelectedColor: {
                main: '#0095e8'
            },
            sideBarItemHoverBackgroundColor: {
                main: 'rgba(0, 149, 232, 0.08)'
            },
            sideBarItemSelectedBackgroundColor: {
                main: 'rgba(0, 149, 232, 0.2)'
            },
            sideBarItemIconColor: {
                main: '#555868'
            },
            sideBarItemIconSelectedColor: {
                main: '#0095e8'
            },
            goalLabelIsDreamTextColor: {
                main: '#f1416c'
            },
            goalLabelIsDreamBackgroundColor: {
                main: '#fff5f8'
            },
            goalLabelCompletedTextColor: {
                main: '#50cd89'
            },
            goalLabelCompletedBackgroundColor: {
                main: '#e8fff3'
            },
            goalLabelDateTextColor: {
                main: '#7239ea'
            },
            goalLabelDateBackgroundColor: {
                main: '#f8f5ff'
            },
            journalDayDateColor: {
                main: '#9DA0A4'
            },
            journalDayDateHoverColor: {
                main: '#000'
            },
            journalDayBlackColor: {
                main: '#000000'
            },
            journalDayTerribleColor: {
                main: '#d9214e'
            },
            journalDayBadColor: {
                main: '#BFBFBF'
            },
            journalDayTypicalColor: {
                main: '#F3F6F9'
            },
            journalDayGoodColor: {
                main: '#0095e8'
            },
            journalDayLuckyColor: {
                main: '#0288d1'
            },
            journalDayPerfectColor: {
                main: '#2e7d32'
            }
        },
        dark: {
            background: {
                main: '#121212 !important',
            },
            primary: {
                light: '#ee0404',
                main: '#c50606',
                dark: '#9b0303',
                contrastText: 'rgba(255, 255, 255, 0.7)',
            },
            secondary: {
                light: '#ba68c8',
                main: '#9c27b0',
                dark: '#7b1fa2',
            },
            error: {
                light: '#ef5350',
                main: '#d9214e',
                dark: '#c62828',
            },
            warning: {
                light: '#ffc700',
                main: '#ffc700',
                dark: '#ffc700',
            },
            info: {
                light: '#03a9f4',
                main: '#0288d1',
                dark: '#01579b',
            },
            success: {
                light: '#4caf50',
                main: '#2e7d32',
                dark: '#1b5e20',
            },
            alert: {
                info: {
                    backgroundColor: '#212e48',
                    borderColor: 'rgba(0, 158, 247, 1) !important'
                },
                error: {
                    backgroundColor: '#3a2434',
                    borderColor: '#f1416c !important'
                }
            },
            custom: {
                light: '#ffffff',
                main: '#ffffff',
                dark: '#ffffff',
            },
            borderColor: {
                main: '#666666',
            },
            textColorThird: {
                main: '#A1A5B7',
                dark: '#5E6278'
            },
            filterColorAll: {
                main: '#009ef7'
            },
            filterColorInProgress: {
                main: '#e6a304'
            },
            filterColorCompleted: {
                main: '#14aa40'
            },
            subscriptionCardBackground: {
                main: 'rgba(43, 43, 64, 0.75) !important'
            },
            subscriptionPriceColor: {
                main: '#3E97FF'
            },
            dragAndDropColor: {
                main: '#ffffff',
            },
            dragAndDropBackgroundColor: {
                main: '#444444',
            },
            dragAndDropBorderColor: {
                main: '#b8b8b8'
            },
            sideBarItemColor: {
                main: 'rgba(255, 255, 255, 0.7)'
            },
            sideBarItemSelectedColor: {
                main: '#0788d0'
            },
            sideBarItemHoverBackgroundColor: {
                main: 'rgba(0, 149, 232, 0.08)'
            },
            sideBarItemSelectedBackgroundColor: {
                main: 'rgba(0, 149, 232, 0.2)'
            },
            sideBarItemIconColor: {
                main: 'rgba(255, 255, 255, 0.7)'
            },
            sideBarItemIconSelectedColor: {
                main: '#0788d0'
            },
            goalLabelIsDreamTextColor: {
                main: '#f1416c'
            },
            goalLabelIsDreamBackgroundColor: {
                main: '#3a2434'
            },
            goalLabelCompletedTextColor: {
                main: '#50cd89'
            },
            goalLabelCompletedBackgroundColor: {
                main: '#1c3238'
            },
            goalLabelDateTextColor: {
                main: '#7239ea'
            },
            goalLabelDateBackgroundColor: {
                main: '#2f264f'
            },
            journalDayDateColor: {
                main: '#9DA0A4'
            },
            journalDayDateHoverColor: {
                main: '#000'
            },
            journalDayBlackColor: {
                main: '#000000'
            },
            journalDayTerribleColor: {
                main: '#d9214e'
            },
            journalDayBadColor: {
                main: '#BFBFBF'
            },
            journalDayTypicalColor: {
                main: '#F3F6F9'
            },
            journalDayGoodColor: {
                main: '#0095e8'
            },
            journalDayLuckyColor: {
                main: '#0288d1'
            },
            journalDayPerfectColor: {
                main: '#2e7d32'
            }
        },
    },
];