import {IGoal} from "../models/goals/IGoal";
import {ISet} from "../models/goals/ISet";
import {ITask} from "../models/ITask";
import {dayNames, MAX_REMINDERS_COUNT} from "./constants";
import {IRemindOptions} from "../models/notifications/IRemindOptions";

export const getDayName = (date: string): string => {
    const day = new Date(date).getDay();
    if (day === 0) return dayNames[6];
    return dayNames[day - 1];
}

export const sortDaysByDate = (array: string[]): string[] => {
    array.sort((a, b) => {
        return new Date(b).valueOf() - new Date(a).valueOf();
    });

    return array;
}

export const formatTitle = ({title, addSpaseBefore = false}: { title: string; addSpaseBefore?: boolean }) => {
    let titleWithTags;
    if (addSpaseBefore) {
        titleWithTags = title.trim().split(/(?=\s#)/g);
    } else {
        titleWithTags = title.trim().split(/(?=#)/g);
    }

    let formattedTitle: string = '';
    let tagTitles: string[] = [];
    let goalTitles: string[] = [];
    let isImportant = false;
    for (let i = 0; i < titleWithTags.length; i++) {
        const temp = titleWithTags[i].trim().split(' ');
        if (temp[0][0] === '#') {
            const tagTitle = temp.shift();
            if (tagTitle && tagTitle !== '#') tagTitles.push(tagTitle.trim().split('#')[1]);
        }
        if (temp.length) formattedTitle += ' ' + temp.join(' ').trim();
    }

    const titleWithGoals = formattedTitle.trim().split(/(?=@)/g);
    formattedTitle = '';
    for (let j = 0; j < titleWithGoals.length; j++) {
        const temp = titleWithGoals[j].trim().split(' ');
        if (temp[0][0] === '@') {
            const goalTitle = temp.shift();
            if (goalTitle && goalTitle !== '@') goalTitles.push(goalTitle.substring(1).trim().replaceAll('-', ' '));
        }
        if (temp.length) formattedTitle += ' ' + temp.join(' ').trim();
    }

    if (formattedTitle.includes('!')) isImportant = true;

    return {
        title: formattedTitle.trim(),
        tagTitles: tagTitles,
        goalTitles,
        isImportant: isImportant
    }
}

export const formatNoteTitle = (title: string) => {
    const titleWithTags = title.trim().split(/(?=#)/g);

    let formattedTitle: string = '';
    let tagTitles: string[] = [];
    for (let i = 0; i < titleWithTags.length; i++) {
        const temp = titleWithTags[i].trim().split(' ');
        if (temp[0][0] === '#') {
            const tagTitle = temp.shift();
            if (tagTitle && tagTitle !== '#') tagTitles.push(tagTitle.trim().split('#')[1]);
        }
        if (temp.length) formattedTitle += ' ' + temp.join(' ').trim();
    }

    return {
        title: formattedTitle,
        tagTitles: tagTitles,
    }
}

export const showTask = (filter: string, task: ITask): boolean => {
    if (filter === '1') return !task.isCompleted && !task.isDeleted;
    if (filter === '2') return task.isCompleted;

    return true;
}

export const pickColor = (hex: string | null | undefined): string => {
    if (!hex || hex === '') return 'inherit';

    const c = hex.substring(1);    // strip #
    const rgb = parseInt(c, 16);   // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff;  // extract red
    const g = (rgb >> 8) & 0xff;   // extract green
    const b = (rgb >> 0) & 0xff;   // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    if (luma < 40) {
        return '#ffffff'
    }

    return '#000000';
}

export const formatDate = (date: string) => {
    var m = new Date(date);
    return m.getUTCFullYear() + "-" +
        ("0" + (m.getUTCMonth() + 1)).slice(-2) + "-" +
        ("0" + m.getUTCDate()).slice(-2) + " " +
        ("0" + m.getUTCHours()).slice(-2) + ":" +
        ("0" + m.getUTCMinutes()).slice(-2);
}

export const changeTimezone = (date: Date, timeZone: string) => {
    return new Date(
        new Date(date).toLocaleString('en-US', {
            timeZone,
        }),
    );
}

export const formatDateWithTimezone = (timezonedDate: Date) => {
    return timezonedDate.getFullYear() + '-' +
        ('0' + (timezonedDate.getMonth() + 1)).slice(-2) + '-' +
        ('0' + timezonedDate.getDate()).slice(-2);
}

export const getGoalIds = (goal: IGoal): number[] => {
    const ids = [goal.id];

    const recursivelyThroughSubGoals = (goal: IGoal) => {
        if (goal.hasOwnProperty('SubGoals') && goal.SubGoals.length) {
            for (let i = 0; i < goal.SubGoals.length; i++) {
                ids.push(goal.SubGoals[i].id);
                recursivelyThroughSubGoals(goal.SubGoals[i]);
            }
        }
    }

    recursivelyThroughSubGoals(goal);

    return ids;
}

export const addTaskToGoals = (goal: IGoal, goalIds: number[], task: ITask): IGoal => {
    const resultGoal = goal;
    if (goalIds.includes(goal.id)) {
        resultGoal.Tasks.push(task);
    }
    const recursivelyThroughSubGoals = (goal: IGoal, goalIds: number[], task: ITask): IGoal[] => {
        let resSubGoals: IGoal[] = [];
        if (goal.hasOwnProperty('SubGoals') && goal.SubGoals.length) {
            for (let i = 0; i < goal.SubGoals.length; i++) {
                let subGoal = goal.SubGoals[i];
                if (goalIds.includes(subGoal.id)) {
                    subGoal.Tasks.push(task);
                }
                let subGoals = recursivelyThroughSubGoals(subGoal, goalIds, task);
                if (subGoals) {
                    subGoal.SubGoals = subGoals;
                }
                resSubGoals.push(subGoal);
            }
        }
        return resSubGoals;
    }

    let subGoals = recursivelyThroughSubGoals(goal, goalIds, task);
    if (subGoals) {
        resultGoal.SubGoals = subGoals;
    }

    return resultGoal;
}

export const replaceTaskInGoals = (goal: IGoal, goalIds: number[], task: ITask): IGoal => {
    const resultGoal = goal;
    if (goalIds.includes(goal.id)) {
        resultGoal.Tasks.map((mapTask, taskIndex) => {
            if (mapTask.id === task.id) {
                resultGoal.Tasks[taskIndex] = task;
            }
            return mapTask;
        });
    }
    const recursivelyThroughSubGoals = (goal: IGoal, goalIds: number[], task: ITask): IGoal[] => {
        let resSubGoals: IGoal[] = [];
        if (goal.hasOwnProperty('SubGoals') && goal.SubGoals.length) {
            for (let i = 0; i < goal.SubGoals.length; i++) {
                let subGoal = goal.SubGoals[i];
                if (goalIds.includes(subGoal.id)) {
                    subGoal.Tasks.push(task);
                    subGoal.Tasks.map((mapTask, taskIndex) => {
                        if (mapTask.id === task.id) {
                            subGoal.Tasks[taskIndex] = task;
                        }
                        return mapTask;
                    });
                }
                let subGoals = recursivelyThroughSubGoals(subGoal, goalIds, task);
                if (subGoals) {
                    subGoal.SubGoals = subGoals;
                }
                resSubGoals.push(subGoal);
            }
        }
        return resSubGoals;
    }

    let subGoals = recursivelyThroughSubGoals(goal, goalIds, task);
    if (subGoals) {
        resultGoal.SubGoals = subGoals;
    }

    return resultGoal;
}
export const removeTaskInGoal = (goal: IGoal, goalIds: number[], taskId: number): IGoal => {
    const resultGoal = goal;
    if (goalIds.includes(goal.id)) {
        resultGoal.Tasks.map((mapTask, taskIndex) => {
            if (mapTask.id === taskId) {
                resultGoal.Tasks.splice(taskIndex, 1);
            }
            return mapTask;
        });
    }
    const recursivelyThroughSubGoals = (goal: IGoal, goalIds: number[], taskId: number): IGoal[] => {
        let resSubGoals: IGoal[] = [];
        if (goal.hasOwnProperty('SubGoals') && goal.SubGoals.length) {
            for (let i = 0; i < goal.SubGoals.length; i++) {
                let subGoal = goal.SubGoals[i];
                if (goalIds.includes(subGoal.id)) {
                    subGoal.Tasks.map((mapTask, taskIndex) => {
                        if (mapTask.id === taskId) {
                            subGoal.Tasks.splice(taskIndex, 1);
                        }
                        return mapTask;
                    });
                }
                let subGoals = recursivelyThroughSubGoals(subGoal, goalIds, taskId);
                if (subGoals) {
                    subGoal.SubGoals = subGoals;
                }
                resSubGoals.push(subGoal);
            }
        }
        return resSubGoals;
    }

    let subGoals = recursivelyThroughSubGoals(goal, goalIds, taskId);
    if (subGoals) {
        resultGoal.SubGoals = subGoals;
    }

    return resultGoal;
}

export const findNestedObj = (entireObj: Object, keyToFind: string, valToFind: any): {
    [key: string]: any
} | undefined => {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
        if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
        }
        return nestedValue;
    });
    return foundObj;
};

export const flatArrayOfObjects = (array: object[], key: string) => {
    let result: object[] = [];
    array.forEach(el => {
        result.push(el);
        if (el.hasOwnProperty(key) && Array.isArray(el[key as keyof typeof el])) {
            result = result.concat(flatArrayOfObjects(el[key as keyof typeof el], key));
        }
    });
    return result;
}

export const getOffset = (timeZone = 'UTC', date = new Date()) => {
    const MINUTES_OFFSET = 60 * 1000;

    const utcDate = new Date(date.toLocaleString('en-US', {timeZone: 'UTC'}));
    const tzDate = new Date(date.toLocaleString('en-US', {timeZone}));

    return (tzDate.getTime() - utcDate.getTime()) / MINUTES_OFFSET;
}

export const getTimeSchedule = (): string[] => {
    const result: string[] = [];

    const date = new Date();
    date.setHours(0, 0, 0, 0);

    result.push(date.toLocaleTimeString('en-GB', {hour: '2-digit', minute: '2-digit'}));
    for (let i = 0; i < 95; i++) {
        date.setHours(date.getHours(), date.getMinutes() + 15, 0, 0);
        result.push(date.toLocaleTimeString('en-GB', {hour: '2-digit', minute: '2-digit'}));
    }

    return result;
}

export const calculateNearestTimeFromSchedule = (timeZone = 'UTC', timeSchedule = ['00:00']) => {
    const now = changeTimezone(new Date(), timeZone).toLocaleTimeString();
    const nowInSeconds = Number(now.slice(0, 2)) * 3600 + Number(now.slice(3, 5)) * 60;

    let nearestIndex = 0;
    let nearestInSeconds = null;
    for (let i = 0; i < timeSchedule.length; i++) {
        const timeScheduleInSeconds = Number(timeSchedule[i].slice(0, 2)) * 3600 + Number(timeSchedule[i].slice(3, 5)) * 60;
        if (!nearestInSeconds || Math.abs(nowInSeconds - timeScheduleInSeconds) < nearestInSeconds) {
            nearestIndex = i;
            nearestInSeconds = Math.abs(nowInSeconds - timeScheduleInSeconds);
        }
    }

    return timeSchedule[nearestIndex];
}

export const hasDuplicates = (array: any) => {
    return (new Set(array)).size !== array.length;
}

export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop()!.split(';').shift();
    }
}

export const checkIfGoalsExists = (sets: ISet[]): boolean => {
    let exist = false;

    let iterator = 0;
    while (sets.length && iterator < sets.length) {
        if (sets[iterator].Goals.length) {
            exist = true;
        }

        iterator++;
    }

    return exist;
}

export const getInitialRemindOptions = (notifications: any, nearestTimeFromSchedule: string, isEvent: boolean, date?: string): IRemindOptions => {
    const remindersActive = [];
    const notifyAt: string[] = [];
    const dates = [];
    const dayDiff: number[] = [];
    const notificationMethods: number[] = [];

    if (notifications && notifications.length) {
        if (isEvent && notifications[0].hasOwnProperty("notifyTime") && notifications[0].hasOwnProperty("dayDiff")) {
            for (let i = 0; i < notifications.length; i++) {
                const duplicate = notifyAt.find((time, index) => {
                    return (time + dayDiff[index]) === (notifications[i].notifyTime.slice(0, 5) + notifications[i].dayDiff)
                });
                if (duplicate === undefined) {
                    remindersActive.push(remindersActive.length + 1);
                    notifyAt.push(notifications[i].notifyTime.slice(0, 5));
                    dayDiff.push(notifications[i].dayDiff)
                }
            }
        } else {
            const duplicatesCheck = [];
            for (let i = 0; i < notifications.length; i++) {
                const duplicate = duplicatesCheck.find(time => {
                    return time === notifications[i].notifyAt;
                });
                if (duplicate === undefined) {
                    remindersActive.push(i + 1);
                    duplicatesCheck.push(new Date(notifications[i].notifyAt).toISOString());
                    notifyAt.push(new Date(notifications[i].notifyAt).toTimeString().slice(0, 5));
                    dates.push(new Date(notifications[i].notifyAt.split('T')[0]));
                }
            }
        }
    }

    if (notifications) {
        notifications.map((notification: any) => {
            return notificationMethods.push(notification.notificationMethodId);
        });
    }

    while (notifyAt.length !== MAX_REMINDERS_COUNT) {
        notifyAt.push(nearestTimeFromSchedule);
    }

    while (dates.length !== MAX_REMINDERS_COUNT) {
        if (date) {
            dates.push(new Date(date));
        } else {
            dates.push(new Date());
        }
    }

    while (dayDiff.length !== MAX_REMINDERS_COUNT) {
        dayDiff.push(0);
    }

    return {
        isRemind: notifications && notifications.length ? true : false,
        remindMethodId: notificationMethods.length ? Array.from(new Set(notificationMethods)) : [2],
        remindersActive: remindersActive.length ? remindersActive : [1],
        notifyAt: notifyAt,
        date: dates,
        daysDiff: dayDiff.length ? dayDiff : [0, 0, 0]
    }
}