import React, {FC, useEffect, useState} from 'react';
import {Alert, Box, Collapse, List} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {reorderGoals, toggleEditable} from '../../store/goal/goalSlice';
import {Goal} from './Goal';
import {GoalAddInput} from './GoalAddInput';
import {useTranslation} from 'react-i18next';
import {TransitionGroup} from 'react-transition-group';
import {IDragGoal} from '../../models/dnd/IDragGoal';
import {IGoal} from '../../models/goals/IGoal';
import {findNestedObj} from '../../utils/helpers';

interface IGoalListProps {
    setId: number,
}

export const GoalList: FC<IGoalListProps> = ({setId, ...props}) => {

    const {t} = useTranslation();

    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {sets, goalAction, isEditable, isGoalLoading, isGoalSending} = useAppSelector(state => state.goal);

    const [copyGoals, setCopyGoals] = useState<IGoal[]>(sets.find(set => set.id === setId)?.Goals || []);

    const dragEndHandle = (item: IDragGoal) => {
        const {oldIndex, index, goal} = item;

        if (oldIndex === index) return;

        let newOrderId = goal.orderId;
        if (goal.parentId === 0) {
            newOrderId = copyGoals[index].orderId;
            if (newOrderId > copyGoals[oldIndex].orderId) {
                newOrderId += 1;
            }
        } else {
            const parent = findNestedObj(copyGoals, 'id', goal.parentId);

            if (parent && parent.hasOwnProperty('SubGoals')) {
                newOrderId = parent.SubGoals[index].orderId;
                if (newOrderId > parent.SubGoals[oldIndex].orderId) {
                    newOrderId += 1;
                }
            }
        }

        dispatch(reorderGoals({
            goalId: goal.id,
            newOrderId: newOrderId
        }));
    }

    const handleSwitchEditMode = () => {
        dispatch(toggleEditable());
    }

    useEffect(() => {
        const settedGoals = sets.find(set => set.id === setId)?.Goals;
        if (settedGoals !== undefined) {
            setCopyGoals(settedGoals);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [goalAction]);

    return (
        <Box sx={{mt: '12px'}}>
            <Collapse in={isEditable}>
                <GoalAddInput setId={setId}/>
            </Collapse>
            <List dense={true}
                  key={goalAction}
                  sx={{
                      overflowY: isMobile ? '' : 'auto',
                      height: isEditable ? '64vh' : '70vh'
                  }}
            >
                <TransitionGroup>
                    {sets.find(set => set.id === setId)?.Goals.map((goal, i) => (
                        <Collapse key={goal.id}>
                            <Goal
                                goal={goal}
                                isEditable={isEditable}
                                isSubGoal={false}
                                isGoalSending={isGoalSending}
                                dragEndHandle={dragEndHandle}
                                index={i}
                            />
                        </Collapse>
                    ))}
                </TransitionGroup>
                {!sets.find(set => set.id === setId)?.Goals.length && !isGoalLoading
                    ?
                    <Alert variant={"outlined"} severity="info" sx={{marginTop: '12px'}}>
                        {t('There is no goal here! Plan your future and rush to the success with us!')}
                        <span
                            style={{textDecoration: 'underline', cursor: 'pointer'}}
                            onClick={handleSwitchEditMode}
                        >
                            {t(' Switch ')}
                        </span>
                        {t('to edit mode to set new goals')}
                    </Alert>
                    :
                    ''
                }
            </List>
        </Box>
    )
}
