import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ISnackbar} from "../../models/utils/ISnackbar";
import {TMode} from "../../models/utils/TMode";

interface IUtilsState {
    isMobile: boolean,
    mode: TMode,
    snackbar: ISnackbar,
    themeMode: string,
    colorSchema: number

}

const initialState: IUtilsState = {
    isMobile: false,
    mode: 'auto',
    snackbar: {
        text: '',
        severity: 'error',
        open: false
    },
    themeMode: '',
    colorSchema: 0
}

export const utilsSlice = createSlice({
    name: 'utils',
    initialState: initialState,
    reducers: {
        setIsMobile(state, action: PayloadAction<boolean>) {
            state.isMobile = action.payload;
        },
        setMode(state, action: PayloadAction<TMode>) {
            state.mode = action.payload;
        },
        setSnackbar(state, action: PayloadAction<ISnackbar>) {
            state.snackbar = action.payload;
        },
        setThemeMode(state, action: PayloadAction<string>) {
            state.themeMode = action.payload;
        },
        setColorSchema(state, action: PayloadAction<number>) {
            state.colorSchema = action.payload;
        },
    }
})

export default utilsSlice.reducer;
export const {
    setIsMobile,
    setMode,
    setSnackbar,
    setThemeMode,
    setColorSchema
} = utilsSlice.actions;