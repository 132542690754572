import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";
import {notificationAddToken, notificationSaveSettings, notificationSettings} from "../../services/NotificationService";
import {INotificationSettings} from "../../models/notifications/INotificationSettings";

interface INotificationState {
    token: string,
    isNotificationLoading: boolean,
    notificationError: boolean,
    notificationSettings: {
        settings?: INotificationSettings,
        isLoading: boolean,
        error: boolean,
    }
}

const initialState: INotificationState = {
    token: '',
    isNotificationLoading: false,
    notificationError: false,
    notificationSettings: {
        settings: undefined,
        isLoading: true,
        error: false
    }
}

export const saveNotificationToken = createAsyncThunk(
    'notification/saveToken',
    async ({token, platform}: { token: string, platform: string }, {rejectWithValue}) => {
        try {
            const response = await notificationAddToken(token, platform);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const getNotificationSettings = createAsyncThunk(
    'notification/getSettings',
    async (__, {rejectWithValue}) => {
        try {
            const response = await notificationSettings();
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const saveNotificationSettings = createAsyncThunk(
    'notification/saveSettings',
    async ({settings}: { settings: INotificationSettings }, {rejectWithValue}) => {
        try {
            const response = await notificationSaveSettings(settings);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
)

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        resetNotification: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(saveNotificationToken.fulfilled, (state, action: PayloadAction<boolean>) => {
            state.isNotificationLoading = false;
        });
        builder.addCase(getNotificationSettings.fulfilled, (state, action: PayloadAction<INotificationSettings>) => {
            state.notificationSettings.error = false;
            state.notificationSettings.isLoading = false;
            state.notificationSettings.settings = action.payload;
        });
        builder.addCase(saveNotificationSettings.fulfilled, (state, action: PayloadAction<INotificationSettings>) => {
            state.notificationSettings.error = false;
            state.notificationSettings.isLoading = false;
            state.notificationSettings.settings = action.payload;
        });
        builder.addMatcher(isAnyOf(saveNotificationToken.pending, saveNotificationSettings.pending), state => {
            state.isNotificationLoading = true;
        });
        builder.addMatcher(isAnyOf(saveNotificationToken.rejected, saveNotificationSettings.rejected), state => {
            state.isNotificationLoading = false;
            state.notificationError = true;
        });
        builder.addMatcher(isAnyOf(getNotificationSettings.pending,), state => {
            state.notificationSettings.isLoading = true;
        });
        builder.addMatcher(isAnyOf(getNotificationSettings.rejected), state => {
            state.notificationSettings.isLoading = false;
            state.notificationSettings.error = true;
        });
    }
})

export default notificationSlice.reducer;
export const {
    resetNotification,
} = notificationSlice.actions;