import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";
import {IUserDay} from "../../models/days/IUserDay";
import {IJournalResponse} from "../../models/response/IJournalResponse";
import {IYearUserDaysResponse} from "../../models/response/IYearUserDaysResponse";
import {getUserDayAPI, journalAPI, userDaysAddAPI, userDaysEditAPI, yearUserDaysAPI} from "../../services/TaskService";

interface IInitialState {
    userDays: IYearUserDaysResponse,
    journal: {
        page: number,
        observable: boolean,
        days: IJournalResponse
    },
    isKickerLoading: boolean,
    isKickerError: boolean
}

const initialState: IInitialState = {
    userDays: {} as IYearUserDaysResponse,
    journal: {
        page: 1,
        observable: true,
        days: {} as IJournalResponse
    },
    isKickerLoading: false,
    isKickerError: false
}

export const getYearUserDays = createAsyncThunk(
    'kicker/getYearUserDays',
    async (year: string, {rejectWithValue}) => {
        try {
            const response = await yearUserDaysAPI(year);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const getUserDay = createAsyncThunk(
    'kicker/getUserDay',
    async (date: string, {rejectWithValue}) => {
        try {
            const response = await getUserDayAPI(date);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const addUserDay = createAsyncThunk(
    'kicker/addUserDay',
    async ({date, type, comment}: { date: string, type: number, comment: string }, {rejectWithValue}) => {
        try {
            const response = await userDaysAddAPI(date, type, comment);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const editUserDay = createAsyncThunk(
    'kicker/editUserDay',
    async ({id, dayType, comment}: { id: number, dayType: number, comment: string }, {rejectWithValue}) => {
        try {
            const response = await userDaysEditAPI(id, dayType, comment);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const getJournal = createAsyncThunk(
    'kicker/getJournal',
    async ({page, limit}: { page: number, limit: number }, {rejectWithValue}) => {
        try {
            const response = await journalAPI(page, limit);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const kickerSlice = createSlice({
    name: 'kicker',
    initialState: initialState,
    reducers: {
        deleteJournalDay(state, action: PayloadAction<{ date: string }>) {
            const daysCopy = state.journal.days;
            delete daysCopy[action.payload.date];
            state.journal.days = daysCopy;
        },
        resetKicker: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getYearUserDays.fulfilled, (state, action: PayloadAction<IYearUserDaysResponse>) => {
            const year: string = Object.keys(action.payload)[0];

            state.userDays[year] = action.payload[year];

            state.isKickerLoading = false;
            state.isKickerError = false;
        });
        builder.addCase(addUserDay.fulfilled, (state, action: PayloadAction<{ [date: string]: IUserDay }>) => {
            const date: string = Object.keys(action.payload)[0];
            const year: string = date.split('-')[0];

            if (state.userDays.hasOwnProperty(year)) {
                state.userDays[year][date] = action.payload[date];
            }

            state.journal.days[date] = {
                date: date,
                id: action.payload[date].dayId,
                UserDays: {
                    id: action.payload[date].id,
                    dayId: action.payload[date].dayId,
                    type: action.payload[date].type,
                    comment: action.payload[date].comment,
                    Tasks: action.payload[date].Tasks
                }
            }

            state.isKickerError = false;
        });

        builder.addCase(getUserDay.fulfilled, (state, action: PayloadAction<{ [date: string]: IUserDay }>) => {
            const date: string = Object.keys(action.payload)[0];
            const year: string = date.split('-')[0];

            if (state.userDays.hasOwnProperty(year)) {
                state.userDays[year][date] = action.payload[date];
            }

            state.journal.days[date] = {
                date: date,
                id: action.payload[date].dayId,
                UserDays: {
                    id: action.payload[date].id,
                    dayId: action.payload[date].dayId,
                    type: action.payload[date].type,
                    comment: action.payload[date].comment,
                    Tasks: action.payload[date].Tasks
                }
            }

            state.isKickerError = false;
        });
        builder.addCase(editUserDay.fulfilled, (state, action: PayloadAction<{ [date: string]: IUserDay }>) => {
            const date: string = Object.keys(action.payload)[0];
            const year: string = date.split('-')[0];

            if (state.userDays.hasOwnProperty(year)) {
                state.userDays[year][date] = action.payload[date];
            }
            if (state.journal.days.hasOwnProperty(date)) {
                state.journal.days[date].UserDays.comment = action.payload[date].comment;
                state.journal.days[date].UserDays.type = action.payload[date].type;
            } else {
                state.journal.days[date] = {
                    date: date,
                    id: action.payload[date].dayId,
                    UserDays: {
                        id: action.payload[date].id,
                        dayId: action.payload[date].dayId,
                        type: action.payload[date].type,
                        comment: action.payload[date].comment,
                        Tasks: action.payload[date].Tasks
                    }
                }
            }

            state.isKickerError = false;
        });
        builder.addCase(getJournal.fulfilled, (state, action: PayloadAction<IJournalResponse>) => {
            const d: IJournalResponse = JSON.parse(JSON.stringify(state.journal.days)) as IJournalResponse;
            state.journal.days = {...d, ...action.payload};

            if (Object.keys(action.payload).length) {
                state.journal.page = state.journal.page + 1;
            } else {
                state.journal.observable = false;
            }

            state.isKickerLoading = false;
            state.isKickerError = false;
        });
        builder.addMatcher(isAnyOf(getYearUserDays.pending, getJournal.pending), state => {
            state.isKickerLoading = true;
        });
        builder.addMatcher(isAnyOf(getYearUserDays.rejected, addUserDay.rejected, editUserDay.rejected, getJournal.rejected), state => {
            state.isKickerLoading = false;
            state.isKickerError = true;
        });
    }
});

export default kickerSlice.reducer;
export const {
    deleteJournalDay,
    resetKicker
} = kickerSlice.actions;