import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { IRecurringFormFields } from "../../models/validation/IRecurringFormFields";
import { useAppSelector } from "../../hooks/redux";
import { changeTimezone } from "../../utils/helpers";

export function useRecurringForm(date?: Date) {

    const { t } = useTranslation();

    const { user } = useAppSelector(state => state.auth);

    const timezonedDate = changeTimezone(date || new Date(), user.timezoneName);

    const recurringInitialValues: IRecurringFormFields = {
        isRecurring: false,
        recurringType: '0',
        dayOfWeek: [timezonedDate.getDay()],
        dayOfMonth: [timezonedDate.getDate()],
        monthOfYear: [timezonedDate.getMonth()],
        startDate: timezonedDate,
        endDate: null
    }

    const recurringValidationSchema = yup.object({
        isRecurring: yup.boolean(),
        recurringType: yup.string(),
        dayOfWeek: yup
            .array()
            .when('recurringType', {
                is: (recurringType: string) => {
                    return recurringType === '1';
                },
                then: yup
                    .array()
                    .min(1, t('Day of week is required'))
                    .required(t('Day of week is required'))
            }),
        dayOfMonth: yup
            .array()
            .when('recurringType', {
                is: (recurringType: string) => {
                    return recurringType === '2' || recurringType === '3';
                },
                then: yup
                    .array()
                    .min(1, t('Day of month is required'))
                    .required(t('Day of month is required'))
            }),
        monthOfYear: yup
            .array()
            .when('recurringType', {
                is: (recurringType: string) => {
                    return recurringType === '3'
                },
                then: yup
                    .array()
                    .min(1, t('Month of year is required'))
                    .required(t('Month of year is required'))
            }),
        startDate: yup
            .date()
            .typeError(t('Start date is required'))
            .required(t('Start date is required'))
    });

    return {
        recurringInitialValues,
        recurringValidationSchema
    }
}