import React, {FC, useEffect} from 'react';
import {Box, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {getSubscriptions} from "../../store/subscription/subscriptionSlice";
import {SubscriptionsPlansListItem} from "./SubscriptionsPlansListItem";

export const SubscriptionsPlansList: FC = () => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {
        subscriptions,
        isFetchSubscriptions,
    } = useAppSelector(state => state.subscription.subscriptionsList);

    const [priceType, setPriceType] = React.useState('month');

    useEffect(() => {
        if (!isFetchSubscriptions) {
            dispatch(getSubscriptions());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newPriceType: string,
    ) => {
        setPriceType(newPriceType);
    };

    return (
        <Box sx={{mt: 2}} id={'subscriptionListSection'}>
            <Stack direction='row' justifyContent='center'>
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontSize: '2.5rem !important',
                        fontWeight: '600 !important',
                        marginBottom: '1.25rem !important'
                    }}
                >
                    {t('Change subscription')}
                </Typography>
            </Stack>
            <Stack direction='row' justifyContent='center' sx={{paddingBottom: '2rem !important'}}>
                <Typography
                    sx={{
                        textAlign: 'center',
                        fontWeight: '500 !important',
                        fontSize: '1.15rem !important',
                        color: theme.palette.textColorThird.main,
                    }}
                >
                    {t('If you need more info about our pricing, please write to support@settasks.com')}
                </Typography>
            </Stack>
            <Stack direction='row' justifyContent='center' sx={{paddingBottom: '2rem !important'}}>
                <ToggleButtonGroup
                    color="primary"
                    value={priceType}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                    sx={{
                        border: '2px solid #F4F4F4',
                        padding: '8px',
                        borderRadius: '0.8rem',
                        '& .MuiToggleButtonGroup-grouped': {
                            marginLeft: '8px',
                            marginRight: '8px',
                            border: 0,
                            '&.Mui-disabled': {
                                border: 0,
                            },
                            '&:not(:first-of-type)': {
                                borderRadius: '0.75rem',
                            },
                            '&:first-of-type': {
                                borderRadius: '0.75rem',
                            },
                        },
                    }}
                >
                    <ToggleButton value="month"
                                  sx={{
                                      border: 'none',
                                      borderRadius: '0.75rem',
                                      textTransform: 'none',
                                      lineHeight: '1'
                                  }}
                    >
                        {t('Month')}
                    </ToggleButton>
                    <ToggleButton value="year"
                                  sx={{
                                      border: 'none',
                                      borderRadius: '0.75rem',
                                      textTransform: 'none',
                                      lineHeight: '1'
                                  }}
                    >
                        {t('Year')}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Stack>

            <Grid container spacing={2} sx={{mt: 1}}>
                {subscriptions.map((level) => (
                    <SubscriptionsPlansListItem level={level} priceType={priceType} key={level.id}/>
                ))}
            </Grid>
            <Typography sx={{mt: 2, fontSize: '0.875rem'}}>
                *Your subscription will automatically renew at the end of your term, unless you turn off
                auto-renew from your account. Renewal rates are subject to change, but we will always notify you of any
                rate change beforehand.
            </Typography>
        </Box>
    )
};
