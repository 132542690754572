import React, {FC, useState} from 'react';
import {
    Box,
    Checkbox,
    CircularProgress,
    Collapse,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
    useTheme
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {ITask} from '../../models/ITask';
import {deleteTask, removeTag, removeTagFromTask, resetTasks, undeleteTask} from '../../store/task/taskSlice';
import {ITag} from '../../models/tags/ITag';
import {TagList} from '../UI/Tags/TagList';
import {IDay} from '../../models/days/IDay';
import {TaskGoalsList} from '../UI/TaskGoals/TaskGoalsList';
import {resetKicker} from '../../store/kicker/kickerSlice';
import {dndItemTypes} from '../../models/dnd/dndItemTypes';
import {useDrag} from 'react-dnd';
import {IDragTask} from '../../models/dnd/IDragTask';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import RestoreIcon from "@mui/icons-material/Restore";
import {DeleteAlert} from '../UI/DeleteAlert/DeleteAlert';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {useTranslation} from "react-i18next";
import RepeatIcon from '@mui/icons-material/Repeat';
import {RestoreAlert} from '../UI/DeleteAlert/RestoreAlert';

interface IProps {
    day: IDay,
    task: ITask,
    index: number
}

const deleteVariants: string[] = ['Delete this task', 'Delete this task and all subsequent ones', 'Delete all tasks'];

export const DeletedTask: FC<IProps> = ({day, task, index, ...props}) => {

    const dispatch = useAppDispatch();
    const theme = useTheme();
    const {t} = useTranslation();
    const {isMobile, themeMode} = useAppSelector(state => state.utils);

    const [isCompliting, setIsCompliting] = useState<boolean>(false);
    const [deleteCounter, setDeleteCounter] = useState<number>(0);
    const [restoreCounter, setRestoreCounter] = useState<number>(0);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [{isDragging}, drag, preview] = useDrag({
        type: dndItemTypes.TASK,
        item: () => {
            return {
                task: task,
                date: day.date,
                tagFrom: undefined,
                oldIndex: index,
                index: index
            } as IDragTask
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging()
        })
    });

    const handleCheck = () => {
        setIsCompliting(true);

        dispatch(undeleteTask({
            taskId: task.id,
            isCompleted: true,
            isPlanned: false
        }))
            .unwrap()
            .finally(() => {
                setIsCompliting(false);
            });
    }

    const deleteAlertHandle = () => {
        setDeleteCounter(0);
    }

    const restoreAlertHandle = () => {
        setRestoreCounter(0);
    }

    const deleteTaskHandle = () => {
        handleMenuClose();

        if (deleteCounter < 1) {
            setDeleteCounter(deleteCounter + 1);
        } else {
            dispatch(deleteTask(task.id))
                .unwrap()
                .then(() => {
                    dispatch(resetKicker());
                });
        }
    }

    const restoreTaskHandle = () => {
        handleMenuClose();

        if (restoreCounter < 1) {
            setRestoreCounter(restoreCounter + 1);
        } else {
            dispatch(undeleteTask({
                taskId: task.id,
                isCompleted: false,
                isPlanned: task.isPlanned
            }))
                .unwrap()
                .then(() => {
                    dispatch(resetTasks());
                    dispatch(resetKicker());
                });
        }
    }

    const tagDelete = (tag: ITag) => {
        dispatch(removeTag({
            date: day.date,
            index: index,
            task: task,
            tag: tag
        }));
        dispatch(removeTagFromTask({
            foreignKey: task.id ? task.id : task.recurringId,
            tagId: tag.id,
            model: task.id ? 'Task' : 'Event'
        }));
    }

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const opacity = isDragging ? 0 : 1

    return (
        <Box ref={preview} sx={{height: '48px !important', opacity: opacity}}>
            <ListItem sx={{padding: '0'}}>
                <Collapse in={(deleteCounter || restoreCounter) ? true : false} sx={{width: 'inherit'}}>
                    {
                        restoreCounter > 0 &&
                        <RestoreAlert
                            restoreCounter={restoreCounter}
                            restoreAlertHandle={restoreAlertHandle}
                            restoreTaskHandle={restoreTaskHandle}
                        />
                    }
                    {
                        deleteCounter > 0 &&
                        <DeleteAlert
                            deleteCounter={deleteCounter}
                            isRecurring={false}
                            deleteVariants={deleteVariants}
                            deleteAlertHandle={deleteAlertHandle}
                            deleteRecurringHandle={() => {
                            }}
                            deleteTaskHandle={deleteTaskHandle}
                        />
                    }

                </Collapse>
            </ListItem>
            {/*<ListItem sx={{padding: '0'}}>*/}
            {/*    <Collapse in={restoreCounter ? true : false} sx={{width: 'inherit'}}>*/}
            {/*        <RestoreAlert*/}
            {/*            restoreCounter={restoreCounter}*/}
            {/*            restoreAlertHandle={restoreAlertHandle}*/}
            {/*            restoreTaskHandle={restoreTaskHandle}*/}
            {/*        />*/}
            {/*    </Collapse>*/}
            {/*</ListItem>*/}
            {(!deleteCounter && !restoreCounter) &&
                <ListItem
                    sx={{
                        paddingRight: '0px',
                        paddingLeft: '0px'
                    }}
                >
                    <Box
                        ref={drag}
                        component={'span'}
                        sx={{paddingTop: '6px', paddingRight: '16px'}}
                    >
                        <OpenWithIcon color='action'
                                      sx={{
                                          cursor: 'grab',
                                          opacity: '0.4',
                                          "&:hover": {
                                              opacity: '1'
                                          }
                                      }}/>
                    </Box>
                    {isCompliting
                        ?
                        <CircularProgress sx={{color: '#a6a6a6', marginRight: '6px'}} size={'1.5rem'}/>
                        :
                        <Checkbox
                            sx={{padding: '0', margin: '0px', marginRight: '6px'}}
                            checked={task.isCompleted}
                            onChange={handleCheck}
                        />
                    }
                    <ListItemText sx={{display: 'grid'}}>
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontWeight: task.isImportant ? 'bold' : '400'
                            }}
                        >
                            {task.title}
                        </Typography>
                    </ListItemText>
                    {task.isRecurring &&
                        <RepeatIcon color='action'/>
                    }
                    {!isMobile &&
                        <TagList
                            tags={task.Tags}
                            handleDelete={tagDelete}
                        />
                    }
                    {!isMobile && task.Goals && task.Goals.length
                        ?
                        <TaskGoalsList goals={task.Goals}/>
                        :
                        ''
                    }
                    {
                        isMobile ?
                            <div>
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={open ? 'long-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleMenuClick}
                                >
                                    <MoreVertIcon/>
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleMenuClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                backgroundColor: themeMode === 'light' ? 'rgb(255, 255, 255)' : "#121212",
                                                backgroundImage: themeMode === 'light' ? '' : "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                            backgroundColor: themeMode === 'light' ? '' : "#121212",
                                            backgroundImage: themeMode === 'light' ? '' : "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                                            borderRadius: '0.75rem',
                                            '& .MuiMenuItem-root': {
                                                minWidth: '120px',
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem
                                        key={0}
                                        onClick={restoreTaskHandle}
                                        sx={{
                                            "&:hover": {
                                                opacity: '1',
                                                color: theme.palette.primary.main,
                                                '& .MuiListItemIcon-root': {
                                                    color: theme.palette.primary.main,
                                                }
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            <RestoreIcon/>
                                        </ListItemIcon>
                                        <Typography textAlign="center">{t('Restore')}</Typography>
                                    </MenuItem>
                                    <MenuItem
                                        key={1}
                                        onClick={deleteTaskHandle}
                                        sx={{
                                            "&:hover": {
                                                opacity: '1',
                                                color: theme.palette.error.main,
                                                '& .MuiListItemIcon-root': {
                                                    color: theme.palette.error.main,
                                                }
                                            }
                                        }}
                                    >
                                        <ListItemIcon>
                                            <DeleteIcon/>
                                        </ListItemIcon>
                                        <Typography textAlign="center">{t('Delete')}</Typography>
                                    </MenuItem>
                                </Menu>
                            </div>
                            :
                            <div>
                                <RestoreIcon
                                    color='action'
                                    sx={{
                                        marginLeft: '6px',
                                        cursor: 'pointer',
                                        marginRight: '6px',
                                        opacity: '0.4',
                                        "&:hover": {
                                            opacity: '1',
                                            color: theme.palette.success.main
                                        }
                                    }}
                                    onClick={restoreTaskHandle}
                                />
                                <DeleteIcon
                                    color='action'
                                    sx={{
                                        marginLeft: '6px',
                                        cursor: 'pointer',
                                        marginRight: '6px',
                                        opacity: '0.4',
                                        "&:hover": {
                                            opacity: '1',
                                            color: theme.palette.error.main
                                        }
                                    }}
                                    onClick={deleteTaskHandle}
                                />
                            </div>
                    }
                </ListItem>
            }
        </Box>
    )
}
