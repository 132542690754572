import React, {FC, Fragment, useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grow,
    Link,
    TextField,
    Typography
} from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {useTranslation} from 'react-i18next';
import {NavLink, useParams} from 'react-router-dom';
import {AppObserver} from '../utilities/AppObserver';
import {formatDate, formatTitle} from '../../utils/helpers';
import {TransitionGroup} from 'react-transition-group';
import {NoteAddInput} from "./NoteAddInput";
import Grid from "@mui/material/Grid";
import {Note} from "./Note";
import {INote} from "../../models/INote";
import {editNote, getTagNotes, resetTagNotes} from "../../store/note/noteSlice";

export const TagNotesList: FC = () => {

    const {t} = useTranslation();
    const {tag} = useParams();
    const dispatch = useAppDispatch();

    const {isMobile} = useAppSelector(state => state.utils);
    const {notes, page, observable} = useAppSelector(state => state.note.tags);
    const {isNoteLoading} = useAppSelector(state => state.note);

    const [viewNote, setViewNote] = useState<{
        open: boolean,
        currentViewNote: INote
    }>({
        open: false,
        currentViewNote: {
            id: 0,
            userId: 0,
            shortTitle: '',
            title: '',
            favorite: false,
            createdAt: '',
            updatedAt: '',
            Tags: []
        }
    });

    const [editNoteObj, setEditNoteObj] = useState<{
        open: boolean,
        currentEditNote: INote
    }>({
        open: false,
        currentEditNote: {
            id: 0,
            userId: 0,
            shortTitle: '',
            title: '',
            favorite: false,
            createdAt: '',
            updatedAt: '',
            Tags: []
        }
    });


    const intersect = () => {
        if (tag) {
            dispatch(getTagNotes({tag: tag, page: page, limit: 10}));
        }
    }

    const showViewDialog = (note: INote) => {
        setViewNote({
            open: true,
            currentViewNote: note
        })
    }

    const showEditDialog = (note: INote) => {
        setEditNoteObj({
            open: true,
            currentEditNote: note
        })
    }

    const viewNoteCloseHandle = () => {
        setViewNote(prevState => {
            return {...prevState, open: !prevState.open,}
        });
    }

    const editNoteCloseHandle = () => {
        setEditNoteObj(prevState => {
            return {...prevState, open: !prevState.open,}
        });
    }

    const editNoteChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditNoteObj(prevState => {
            return {
                open: prevState.open,
                currentEditNote: {
                    id: prevState.currentEditNote.id,
                    userId: prevState.currentEditNote.userId,
                    shortTitle: e.target.value,
                    title: e.target.value,
                    favorite: prevState.currentEditNote.favorite,
                    createdAt: prevState.currentEditNote.createdAt,
                    updatedAt: prevState.currentEditNote.updatedAt,
                    Tags: prevState.currentEditNote.Tags
                }
            }
        });
    }

    const saveEditNoteHandle = () => {
        setEditNoteObj(prevState => {
            return {...prevState, open: !prevState.open,}
        });
        saveChangedNote(editNoteObj.currentEditNote);
    }

    const saveChangedNote = (note: INote) => {
        if (note.title) {
            const formattedTitle = formatTitle({title: note.title});
            dispatch(editNote({note: note, tagTitles: formattedTitle.tagTitles}))
                .unwrap()
                .then(originalPromiseResult => {
                    //TODO For future
                    // const savedNote: INote = originalPromiseResult[0];
                });
        }
    }

    useEffect(() => {
        if (tag) {
            dispatch(resetTagNotes());
            dispatch(getTagNotes({tag: tag, page: page, limit: 10}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag]);

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '12px'
                }}
            >
                <Typography
                    color='primary'
                    variant='h5'
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '12px'
                    }}>
                    <Link
                        component={NavLink}
                        to='/notes'
                        color='inherit'
                        underline='none'
                    >
                        {t(' Notes')}
                    </Link>
                    <EastIcon/>
                    {'#' + tag}
                </Typography>
            </Box>
            <NoteAddInput/>

            <Fragment>
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        maxHeight: !isMobile ? '65vh' : ''
                    }}
                    style={{
                        marginTop: '20px',
                        paddingLeft: isMobile ? '8px' : '2px',
                        paddingRight: isMobile ? '8px' : '2px',
                        paddingBottom: '2px',
                        paddingTop: '2px'
                    }}
                >
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 12, sm: 8, md: 12}}>

                        <TransitionGroup component={null} exit={true}>
                            {notes.map(note => (
                                <Grow key={'f' + note.id}>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Note
                                            openViewModal={showViewDialog}
                                            openEditModal={showEditDialog}
                                            note={note}
                                        />
                                    </Grid>
                                </Grow>
                            ))}
                        </TransitionGroup>

                    </Grid>
                    <div style={{marginTop: "25px"}}>
                        {observable &&
                            <AppObserver intersect={intersect} observing={observable} key={page}/>}
                    </div>

                </Box>

                <Dialog open={viewNote.open}
                        sx={{
                            "& .MuiDialog-container": {
                                justifyContent: "center",
                                alignItems: "flex-start"
                            },
                            '& .MuiPaper-root': {
                                borderRadius: '0.75rem'
                            }
                        }}
                        PaperProps={{
                            sx: {
                                width: '80%',
                                height: '100%',
                            }
                        }}
                        scroll={'paper'}
                >
                    <DialogTitle>{t('View note')}</DialogTitle>
                    <DialogContent>

                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                        >
                            <Typography
                                align="left"
                                style={{whiteSpace: "pre-wrap"}}
                                component={'span'}
                            >
                                {viewNote.currentViewNote.title}
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{justifyContent: "space-between", paddingRight: "24px", paddingLeft: "24px"}}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                            <p style={{
                                margin: "0px",
                                padding: "0px",
                                fontSize: "10px",
                                lineHeight: "1.3"
                            }}>{t('Created')}: {formatDate(viewNote.currentViewNote.createdAt)}</p>
                            <p style={{
                                margin: "0px",
                                padding: "0px",
                                fontSize: "10px",
                                lineHeight: "1.3"
                            }}>{t('Changed')}: {formatDate(viewNote.currentViewNote.updatedAt)}</p>
                        </div>
                        <Button variant="contained" onClick={viewNoteCloseHandle}>{t('Close')}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={editNoteObj.open}
                        sx={{
                            "& .MuiFormControl-fullWidth": {
                                height: "100%",
                                marginTop: "0px",
                            },
                            "& .MuiInputBase-multiline": {
                                height: "100%",
                            },
                            "& .MuiInputBase-input": {
                                height: "100% !important",
                            },
                            '& .MuiPaper-root': {
                                borderRadius: '0.75rem'
                            }
                        }}
                        PaperProps={{
                            sx: {
                                width: '80%',
                                height: '100%',
                            }
                        }}
                        scroll={'paper'}
                >
                    <DialogTitle>{t('Edit note')}</DialogTitle>
                    <DialogContent sx={{width: isMobile ? '100%' : '600px'}}>
                        <TextField
                            fullWidth
                            multiline
                            minRows={8}
                            maxRows={80}
                            label={`${t('')}`}
                            size='small'
                            variant="outlined"
                            value={editNoteObj.currentEditNote.title}
                            onChange={editNoteChangeHandle}
                            sx={{mt: 1}}
                            InputProps={{
                                autoComplete: 'off'
                            }}
                            className={'className'}
                        />
                    </DialogContent>
                    <DialogActions
                        sx={{
                            paddingRight: '24px',
                            paddingBottom: '16px'
                        }}
                    >
                        <Button variant="contained" onClick={editNoteCloseHandle}>{t('Cancel')}</Button>
                        <Button variant="contained" onClick={saveEditNoteHandle}>{t('Save')}</Button>
                    </DialogActions>
                </Dialog>
                {isNoteLoading &&
                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '24px 0 18px 0'}}>
                        <CircularProgress/>
                    </Box>
                }
                {!notes.length && !isNoteLoading
                    ?
                    <Alert variant={"outlined"} severity="info"
                           style={{marginTop: '20px'}}>{t('There is no notes here!')}</Alert>
                    :
                    ''
                }
            </Fragment>
        </div>
    )
}
