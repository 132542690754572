import React, {FC, useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    List,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from 'react-i18next';
import {IJournalResponse} from '../../models/response/IJournalResponse';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {TransitionGroup} from 'react-transition-group';
import {JournalDay} from './JournalDay';
import {changeTimezone, formatDateWithTimezone, sortDaysByDate} from '../../utils/helpers';
import {addUserDay, deleteJournalDay, editUserDay, getJournal, getUserDay} from '../../store/kicker/kickerSlice';
import {changeDayType} from '../../store/task/taskSlice';
import {AppObserver} from "../utilities/AppObserver";
import {setShowBuyPremiumDialogStatus} from "../../store/subscription/subscriptionSlice";

interface IJournalDaysList {
    days: IJournalResponse
}

type JournalDayOptions = {
    isOpen: boolean,
    id: number | null,
    date: Date | null,
    comment: string,
    dayType: string
}

export const JournalDaysList: FC<IJournalDaysList> = ({days, ...props}) => {

    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const theme = useTheme();

    const {user} = useAppSelector(state => state.auth);
    const {isMobile} = useAppSelector(state => state.utils);
    const timezonedDate = changeTimezone(new Date(), user.timezoneName);
    const {journal, isKickerLoading} = useAppSelector(state => state.kicker);

    const [newJournalDayOptions, setNewJournalDayOptions] = useState<JournalDayOptions>({
        isOpen: false,
        id: null,
        date: timezonedDate,
        comment: '',
        dayType: '0'
    });
    const [locale, setLocale] = useState<Locale>();

    const handleOpenDialog = (formattedDate?: string, selectedDayType?: string) => {
        const date = newJournalDayOptions.date ? formatDateWithTimezone(newJournalDayOptions.date) : null;

        if (formattedDate && days.hasOwnProperty(formattedDate)) {
            return setNewJournalDayOptions(prevState => {
                return {
                    ...prevState,
                    isOpen: true,
                    id: days[formattedDate].UserDays.id,
                    dayType: String(days[formattedDate].UserDays.type),
                    comment: days[formattedDate].UserDays.comment,
                    date: new Date(formattedDate)
                }
            });
        } else {
            if (formattedDate) {
                dispatch(getUserDay(formattedDate))
                    .unwrap()
                    .then(userDay => {
                        return setNewJournalDayOptions(prevState => {
                            if (selectedDayType) {
                                return {
                                    ...prevState,
                                    isOpen: true,
                                    id: Number(userDay[formattedDate].id),
                                    dayType: selectedDayType,
                                    comment: userDay[formattedDate].comment,
                                    date: new Date(formattedDate)
                                }
                            } else {
                                return {
                                    ...prevState,
                                    isOpen: true,
                                    id: Number(userDay[formattedDate].id),
                                    dayType: String(userDay[formattedDate].type),
                                    comment: userDay[formattedDate].comment,
                                    date: new Date(formattedDate)
                                }
                            }
                        });
                    });
            }
        }

        if (date && days.hasOwnProperty(date)) {
            setNewJournalDayOptions(prevState => {
                return {
                    ...prevState,
                    isOpen: true,
                    id: days[date].UserDays.id,
                    dayType: String(days[date].UserDays.type),
                    comment: days[date].UserDays.comment,
                    date: newJournalDayOptions.date
                }
            });
        } else {
            setNewJournalDayOptions(prevState => {
                return {
                    ...prevState,
                    isOpen: true
                }
            });
        }

    }

    const handleCloseDialog = () => {
        setNewJournalDayOptions({
            isOpen: false,
            id: null,
            date: timezonedDate,
            comment: '',
            dayType: '0'
        });
    }

    const handleChangeDayType = (e: SelectChangeEvent) => {
        setNewJournalDayOptions(prevState => {
            return {
                ...prevState,
                dayType: e.target.value
            }
        });
    }

    const handleChangeNewDayComment = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewJournalDayOptions(prevState => {
            return {
                ...prevState,
                comment: e.target.value
            }
        });
    }

    const handleChangeDate = (value: Date | null) => {
        const formattedDate = value ? formatDateWithTimezone(value) : null;
        if (formattedDate && days.hasOwnProperty(formattedDate)) {
            setNewJournalDayOptions(prevState => {
                return {
                    ...prevState,
                    id: days[formattedDate].UserDays.id,
                    dayType: String(days[formattedDate].UserDays.type),
                    comment: days[formattedDate].UserDays.comment,
                    date: value
                }
            });
        } else {
            if (formattedDate) {
                dispatch(getUserDay(formattedDate))
                    .unwrap()
                    .then(userDay => {
                        return setNewJournalDayOptions(prevState => {
                            return {
                                ...prevState,
                                isOpen: true,
                                id: Number(userDay[formattedDate].id),
                                dayType: String(userDay[formattedDate].type),
                                comment: userDay[formattedDate].comment,
                                date: new Date(formattedDate)
                            }
                        });
                    });
            }
        }
    }

    const handleSaveDay = () => {
        if (newJournalDayOptions.id) {

            if (newJournalDayOptions.comment.length >= user.level.journalMessageMaxLength) {
                dispatch(setShowBuyPremiumDialogStatus(true));
                return;
            }

            dispatch(editUserDay({
                id: newJournalDayOptions.id,
                dayType: Number(newJournalDayOptions.dayType),
                comment: newJournalDayOptions.comment
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    const date: string = Object.keys(originalPromiseResult)[0];
                    dispatch(changeDayType({
                        userDayId: originalPromiseResult[date].id,
                        date: date,
                        type: originalPromiseResult[date].type,
                        comment: originalPromiseResult[date].comment
                    }));
                    if (originalPromiseResult[date].comment === '') dispatch(deleteJournalDay({date: date}));
                })
                .finally(() => setNewJournalDayOptions({
                    isOpen: false,
                    id: null,
                    date: timezonedDate,
                    comment: '',
                    dayType: '0'
                }));
        } else if (newJournalDayOptions.date) {

            if (newJournalDayOptions.comment.length >= user.level.journalMessageMaxLength) {
                dispatch(setShowBuyPremiumDialogStatus(true));
                return;
            }

            dispatch(addUserDay({
                date: formatDateWithTimezone(newJournalDayOptions.date),
                type: Number(newJournalDayOptions.dayType),
                comment: newJournalDayOptions.comment
            }))
                .unwrap()
                .then(originalPromiseResult => {
                    const date: string = Object.keys(originalPromiseResult)[0];
                    dispatch(changeDayType({
                        userDayId: originalPromiseResult[date].id,
                        date: date,
                        type: originalPromiseResult[date].type,
                        comment: originalPromiseResult[date].comment
                    }));
                })
                .finally(() => setNewJournalDayOptions({
                    isOpen: false,
                    id: null,
                    date: timezonedDate,
                    comment: '',
                    dayType: '0'
                }));
        }
    }

    useEffect(() => {
        switch (user.lang) {
            case 'en':
                import('date-fns/locale/en-US/index').then(locale => updateLocale(locale.default));
                break;
            case 'ru':
                import('date-fns/locale/ru/index').then(locale => updateLocale(locale.default));
                break;
            case 'pl':
                import('date-fns/locale/pl/index').then(locale => updateLocale(locale.default));
                break;
            case 'ua':
                import('date-fns/locale/uk/index').then(locale => updateLocale(locale.default));
                break;
            default:
                import('date-fns/locale/en-US/index').then(locale => updateLocale(locale.default));
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.lang, user.weekStartsOn]);

    const updateLocale = (locale: Locale) => {
        let loc = locale;
        if (loc && loc.options) {
            loc.options.weekStartsOn = user.weekStartsOn as 0 | 1 | 2 | 3 | 4 | 5 | 6;
        }
        setLocale(loc);
    }


    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const date = queryParams.get('date');
        const dayType = queryParams.get('dayType');
        if (date && dayType) {
            handleOpenDialog(date, dayType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const intersect = () => {
        dispatch(getJournal({page: journal.page, limit: 30}));
    }

    return (
        <div>
            <Box sx={{display: 'flex', alignItems: 'center', mb: '12px'}}>
                <Typography
                    color='primary'
                    variant='h5'
                    sx={{
                        marginLeft: isMobile ? '6px' : '0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '12px',
                        fontSize: '1.5rem',
                        fontWeight: '500 !important',
                        lineHeight: '1.2',
                    }}
                >
                    {t('Journal')}
                </Typography>
                <AddIcon
                    color='primary'
                    sx={{
                        ml: 2,
                        fontSize: '1.5rem',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '12px',
                        fontWeight: '500 !important',
                        lineHeight: '1.2',
                    }}
                    onClick={() => handleOpenDialog()}
                />
            </Box>
            <Box sx={{height: 'calc(100vh - 180px)', overflow: 'auto'}}>
                <List dense={true}>
                    <TransitionGroup>
                        {sortDaysByDate(Object.keys(days))
                            .filter(date => days[date].UserDays.comment !== null)
                            .filter(date => days[date].UserDays.comment !== '')
                            .map(date => (
                                <Collapse key={date}>
                                    <JournalDay day={days[date]} handleOpenEdit={handleOpenDialog}/>
                                </Collapse>
                            ))}
                    </TransitionGroup>
                </List>
                {isKickerLoading &&
                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '6px 0 18px 0'}}>
                        <CircularProgress/>
                    </Box>
                }
                {!Object.keys(journal.days).length && !isKickerLoading && !journal.observable &&
                    <Alert variant={"outlined"} severity="info">
                        {t('You have no commented days!')}
                    </Alert>
                }
                {!journal.observable && Object.keys(journal.days).length ?
                    <Alert variant={"outlined"} severity="info" sx={{marginBottom: 2}}>
                        {t('These are all commented days!')}
                    </Alert>
                    :
                    ''
                }
                {journal.observable &&
                    <AppObserver intersect={intersect} observing={journal.observable}
                                 key={journal.page}/>}
            </Box>
            <Dialog
                fullWidth
                maxWidth='md'
                open={newJournalDayOptions.isOpen}
                onClose={handleCloseDialog}
                aria-labelledby="journal-list-dialog-title"
                aria-describedby="journal-list-dialog-description"
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '0.75rem'
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {t('Describe your day')}
                </DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            my: '12px',
                            gap: '6px'
                        }}
                    >
                        <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        fullWidth
                                        size='small'
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '0.75rem'
                                            },
                                        }}
                                    >

                                        <DesktopDatePicker
                                            disableHighlightToday
                                            label={t('Date')}
                                            inputFormat="yyyy/MM/dd"
                                            value={newJournalDayOptions.date}
                                            onChange={handleChangeDate}
                                            renderInput={(params) => (
                                                <TextField
                                                    name='date'
                                                    size='small'
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id='day-type'>{t('Day type')}</InputLabel>
                                        <Select
                                            value={newJournalDayOptions.dayType}
                                            onChange={handleChangeDayType}
                                            variant={'outlined'}
                                            size='small'
                                            label={t('Day type')}
                                            labelId='day-type'
                                            inputProps={{'aria-label': 'Without label'}}
                                            sx={{
                                                borderRadius: '0.75rem'
                                            }}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        borderRadius: '0.5rem',
                                                        color: theme.palette.textColorThird.dark,
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                value={'-3'}
                                                sx={{'&:hover': {color: theme.palette.journalDayBlackColor.main}}}
                                            >
                                                {t('Black day')}
                                            </MenuItem>
                                            <MenuItem
                                                value={'-2'}
                                                sx={{'&:hover': {color: theme.palette.journalDayTerribleColor.main}}}
                                            >
                                                {t('Awful day')}
                                            </MenuItem>
                                            <MenuItem
                                                value={'-1'}
                                                sx={{'&:hover': {color: theme.palette.journalDayBadColor.main}}}
                                            >
                                                {t('Bad day')}
                                            </MenuItem>
                                            <MenuItem
                                                value={'0'}
                                                sx={{'&:hover': {color: theme.palette.journalDayTypicalColor.main}}}
                                            >
                                                {t('Usual day')}
                                            </MenuItem>
                                            <MenuItem
                                                value={'1'}
                                                sx={{'&:hover': {color: theme.palette.journalDayGoodColor.main}}}
                                            >
                                                {t('Good day')}
                                            </MenuItem>
                                            <MenuItem
                                                value={'2'}
                                                sx={{'&:hover': {color: theme.palette.journalDayLuckyColor.main}}}
                                            >
                                                {t('Successful day')}
                                            </MenuItem>
                                            <MenuItem
                                                value={'3'}
                                                sx={{'&:hover': {color: theme.palette.journalDayPerfectColor.main}}}
                                            >
                                                {t('Perfect day')}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    </Box>
                    <TextField
                        multiline
                        size='small'
                        id="comment"
                        label={t('Comment')}
                        type="text"
                        fullWidth
                        minRows={10}
                        maxRows={Infinity}
                        variant="outlined"
                        value={newJournalDayOptions.comment}
                        onChange={handleChangeNewDayComment}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '0.75rem'
                            }
                        }}
                        InputProps={{
                            autoComplete: 'off',
                        }}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        paddingRight: '24px',
                        paddingBottom: '16px'
                    }}
                >
                    <Button
                        onClick={handleCloseDialog}
                        variant="contained"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        onClick={handleSaveDay}
                        variant="contained"
                    >
                        {t('Save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
