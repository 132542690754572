import {createAsyncThunk, createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";
import {
    goalsAddAPI,
    goalsAddSetAPI,
    goalsAPI,
    goalsDeleteAPI,
    goalsEditAPI,
    goalsEditSetAPI,
    goalsRemoveSetAPI,
    goalsRemoveTaskAPI,
    goalsReorderAPI,
    goalsReorderSetsAPI,
    lastModifiedGoalsAPI
} from "../../services/GoalService";
import {IGoal} from "../../models/goals/IGoal";
import {addTaskToGoals, findNestedObj, removeTaskInGoal, replaceTaskInGoals} from "../../utils/helpers";
import {ITask} from "../../models/ITask";
import {ISet} from "../../models/goals/ISet";
import {IGoalRemoveTaskResponse} from "../../models/response/IGoalRemoveTaskResponse";

interface IGoalState {
    sets: ISet[],
    goals: IGoal[],
    lastModifiedGoals: {
        goals: IGoal[],
        isFetched: boolean
    },
    goalAction: number,
    isEditable: boolean,
    isGoalLoading: boolean,
    isGoalSending: boolean,
    goalError: boolean,
}

const initialState: IGoalState = {
    sets: [],
    goals: [],
    lastModifiedGoals: {
        goals: [],
        isFetched: false
    },
    goalAction: 0,
    isEditable: false,
    isGoalLoading: false,
    isGoalSending: false,
    goalError: false,
}

export const getGoals = createAsyncThunk(
    'goal/getGoals',
    async (_, {rejectWithValue}) => {
        try {
            const response = await goalsAPI();
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const getLastModifiedGoals = createAsyncThunk(
    'goal/getLastModifiedGoals',
    async ({limit}: { limit: number }, {rejectWithValue}) => {
        try {
            const response = await lastModifiedGoalsAPI(limit);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const addGoal = createAsyncThunk(
    'goal/addGoal',
    async ({
               setId,
               parentId,
               goal,
               date
           }: { setId: number, parentId: number | undefined, goal: IGoal, date: string }, {rejectWithValue}) => {
        try {
            const response = await goalsAddAPI(setId, parentId, goal, date);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const editGoal = createAsyncThunk(
    'goal/editGoal',
    async ({
               goal,
               tasks,
               tagTitles,
               removedTags
           }: { goal: IGoal, tasks?: ITask[], tagTitles?: string[], removedTags?: number[] }, {rejectWithValue}) => {
        try {
            const response = await goalsEditAPI(goal, tasks, tagTitles, removedTags);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const deleteGoals = createAsyncThunk(
    'goal/deleteGoals',
    async (goalIds: number[], {rejectWithValue}) => {
        try {
            const response = await goalsDeleteAPI(goalIds);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const removeTaskFromGoal = createAsyncThunk(
    'goal/removeTaskFromGoal',
    async ({goalId, taskId}: { goalId: number, taskId: number }, {rejectWithValue}) => {
        try {
            const response = await goalsRemoveTaskAPI(goalId, taskId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const reorderGoals = createAsyncThunk(
    'goal/reorderGoals',
    async ({goalId, newOrderId}: { goalId: number, newOrderId: number }, {rejectWithValue}) => {
        try {
            const response = await goalsReorderAPI(goalId, newOrderId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const addSet = createAsyncThunk(
    'goal/addSet',
    async ({title, copySetId}: { title: string, copySetId?: number }, {rejectWithValue}) => {
        try {
            const response = await goalsAddSetAPI(title, copySetId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const editSet = createAsyncThunk(
    'goal/editSet',
    async ({setId, title}: { setId: number, title: string }, {rejectWithValue}) => {
        try {
            const response = await goalsEditSetAPI(setId, title);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const deleteSet = createAsyncThunk(
    'goal/deleteSet',
    async ({setId}: { setId: number }, {rejectWithValue}) => {
        try {
            const response = await goalsRemoveSetAPI(setId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const reorderSets = createAsyncThunk(
    'goal/reorderSets',
    async ({setId, newOrderId}: { setId: number, newOrderId: number }, {rejectWithValue}) => {
        try {
            const response = await goalsReorderSetsAPI(setId, newOrderId);
            return response.data;
        } catch (e: any) {
            return rejectWithValue(e.response.data.errors || 'Unexpected error');
        }
    }
);

export const goalSlice = createSlice({
    name: 'goal',
    initialState: initialState,
    reducers: {
        incrementGoalAction(state) {
            state.goalAction += 1;
        },
        toggleEditable(state) {
            state.isEditable = !state.isEditable;
        },
        reorderDnD(state, action: PayloadAction<{
            setId: number,
            parentId: number,
            dragIndex: number,
            hoverIndex: number
        }>) {
            const {setId, parentId, dragIndex, hoverIndex} = action.payload;

            const setIndex = state.sets.findIndex(set => set.id === setId);

            if (setIndex !== -1) {
                if (parentId === 0) {
                    const [reorderedItem] = state.sets[setIndex].Goals.splice(dragIndex, 1);
                    state.sets[setIndex].Goals.splice(hoverIndex, 0, reorderedItem);
                } else {
                    const parent = findNestedObj(state.sets[setIndex].Goals, 'id', parentId);

                    if (parent && parent.hasOwnProperty('SubGoals')) {
                        const [reorderedItem] = parent.SubGoals.splice(dragIndex, 1);
                        parent.SubGoals.splice(hoverIndex, 0, reorderedItem);
                    }
                }
            }
        },
        reorderSetsDnD(state, action: PayloadAction<{ dragIndex: number, hoverIndex: number }>) {
            const {dragIndex, hoverIndex} = action.payload;

            const [reorderedItem] = state.sets.splice(dragIndex, 1);
            state.sets.splice(hoverIndex, 0, reorderedItem);
        },
        removeSet(state, action: PayloadAction<{ setId: number }>) {
            const {setId} = action.payload;

            state.sets = state.sets.filter(set => set.id !== setId);
            state.goals = state.goals.filter(goal => goal.setId !== setId);
        },
        addTaskToGoal(state, action: PayloadAction<{ goalIds: number[], task: ITask }>) {
            const {goalIds, task} = action.payload;
            let setsLocal: ISet[] = JSON.parse(JSON.stringify(state.sets)) as ISet[];

            setsLocal.map((set, setIndex) => {
                set.Goals.map((goal, goalIndex) => {
                    setsLocal[setIndex].Goals[goalIndex] = addTaskToGoals(goal, goalIds, task);
                    return goal;
                });
                return set;
            });
            state.sets = setsLocal;
            state.goals = setsLocal.map(set => set.Goals).flat();
        },
        replaceTaskInGoal(state, action: PayloadAction<{ goalIds: number[], task: ITask }>) {
            const {goalIds, task} = action.payload;
            let setsLocal: ISet[] = JSON.parse(JSON.stringify(state.sets)) as ISet[];

            setsLocal.map((set, setIndex) => {
                set.Goals.map((goal, goalIndex) => {
                    setsLocal[setIndex].Goals[goalIndex] = replaceTaskInGoals(goal, goalIds, task);
                    return goal;
                });
                return set;
            });
            state.sets = setsLocal;
            state.goals = setsLocal.map(set => set.Goals).flat();
        },
        resetGoals: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(addSet.fulfilled, (state, action: PayloadAction<ISet>) => {
            state.sets.push(action.payload);

            state.isGoalSending = false;
            state.isGoalLoading = false;
            state.goalError = false;
        });
        builder.addCase(editSet.fulfilled, (state, action: PayloadAction<ISet>) => {
            const setIndex = state.sets.findIndex(set => set.id === action.payload.id);

            if (setIndex !== -1) {
                state.sets[setIndex] = {
                    ...action.payload,
                    Goals: state.sets[setIndex].Goals
                }
            }

            state.isGoalSending = false;
            state.isGoalLoading = false;
            state.goalError = false;
        });
        builder.addCase(getLastModifiedGoals.fulfilled, (state, action: PayloadAction<IGoal[]>) => {
            state.lastModifiedGoals.goals = action.payload;
            state.lastModifiedGoals.isFetched = true;

            state.goalAction += 1;
            state.isGoalSending = false;
            state.isGoalLoading = false;
            state.goalError = false;
        });
        builder.addCase(removeTaskFromGoal.fulfilled, (state, action: PayloadAction<IGoalRemoveTaskResponse>) => {
            const {result, goalId, taskId} = action.payload;
            if (result) {
                let setsLocal: ISet[] = JSON.parse(JSON.stringify(state.sets)) as ISet[];

                setsLocal.map((set, setIndex) => {
                    set.Goals.map((goal, goalIndex) => {
                        setsLocal[setIndex].Goals[goalIndex] = removeTaskInGoal(goal, [goalId], taskId);
                        return goal;
                    });
                    return set;
                });
                state.sets = setsLocal;
                state.goals = setsLocal.map(set => set.Goals).flat();
            }
            state.isGoalSending = false;
            state.isGoalLoading = false;
            state.goalError = false;
        });
        builder.addMatcher(isAnyOf(reorderGoals.rejected, reorderSets.rejected), () => initialState);
        builder.addMatcher(isAnyOf(getGoals.fulfilled, addGoal.fulfilled, editGoal.fulfilled, deleteGoals.fulfilled, reorderGoals.fulfilled, reorderSets.fulfilled), (state, action: PayloadAction<ISet[]>) => {
            state.sets = action.payload;
            state.goals = action.payload.map(set => set.Goals).flat();
            state.lastModifiedGoals = {
                goals: [],
                isFetched: false
            };

            state.goalAction += 1;
            state.isGoalSending = false;
            state.isGoalLoading = false;
            state.goalError = false;
        });
        builder.addMatcher(isAnyOf(getGoals.pending, getLastModifiedGoals.pending), state => {
            state.isGoalLoading = true;
        });
        builder.addMatcher(isAnyOf(addGoal.pending, editGoal.pending, deleteGoals.pending, removeTaskFromGoal.pending, reorderGoals.pending, reorderSets.pending, addSet.pending, editSet.pending), state => {
            state.isGoalSending = true;
        });
        builder.addMatcher(isAnyOf(getGoals.rejected, getLastModifiedGoals.rejected, addGoal.rejected, editGoal.rejected, deleteGoals.rejected, removeTaskFromGoal.rejected, addSet.rejected, editSet.rejected), state => {
            state.isGoalSending = false;
            state.isGoalLoading = false;
            state.goalError = true;
        });
    }
});

export default goalSlice.reducer;
export const {
    incrementGoalAction,
    toggleEditable,
    reorderDnD,
    reorderSetsDnD,
    removeSet,
    resetGoals,
    addTaskToGoal,
    replaceTaskInGoal
} = goalSlice.actions;