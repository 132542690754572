import React, {FC, Fragment, useEffect, useState} from 'react';
import {Alert, Box, Button, Typography} from '@mui/material';
import {DeleteRecurringDialog} from './DeleteRecurringDialog';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from '../../../hooks/redux';

interface IProps {
    deleteCounter: number,
    isRecurring: boolean,
    deleteVariants: string[],
    deleteAlertHandle: () => void,
    deleteRecurringHandle: (type: string) => void,
    deleteTaskHandle: () => void
}

export const DeleteAlert: FC<IProps> = ({
                                            deleteCounter,
                                            isRecurring,
                                            deleteVariants,
                                            deleteAlertHandle,
                                            deleteRecurringHandle,
                                            deleteTaskHandle,
                                            ...props
                                        }) => {

    const {t} = useTranslation();

    const {isMobile} = useAppSelector(state => state.utils);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>('Cancel');

    const handleClickOpen = () => {
        if (deleteCounter && isRecurring) {
            setOpenDialog(true);
        }
    };

    const handleClose = (value: string) => {
        setOpenDialog(false);
        setSelectedValue(value);
    };

    const confirmHandle = () => {
        if (isRecurring) {
            handleClickOpen();
        } else {
            setIsDeleting(true);
            deleteTaskHandle();
        }
    }

    useEffect(() => {
        if (selectedValue !== 'Cancel') {
            deleteRecurringHandle(selectedValue);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    if (isMobile) {
        return (
            <Fragment>
                <Alert
                    variant={"outlined"}
                    severity='error'
                    sx={{
                        width: '100%',
                        maxHeight: '40px',
                        alignItems: 'center',
                        marginTop: '4px',
                    }}
                    action={
                        <Fragment>
                            <Button
                                disabled={isDeleting}
                                color="inherit"
                                size="small"
                                onClick={deleteAlertHandle}
                            >
                                {t('Cancel')}
                            </Button>
                            <Button
                                disabled={isDeleting}
                                color="inherit"
                                size="small"
                                onClick={confirmHandle}
                            >
                                {t('Ok')}
                            </Button>
                        </Fragment>
                    }
                >
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            cursor: 'text',
                            paddingRight: '12px',
                        }}
                    >
                        {t('Are you sure?')}
                    </Typography>
                </Alert>
                <DeleteRecurringDialog
                    variants={deleteVariants}
                    open={openDialog}
                    selectedValue={selectedValue}
                    onClose={handleClose}
                />
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Alert
                variant={"outlined"}
                severity='error'
                sx={{
                    width: '100%',
                    maxHeight: '40px',
                    alignItems: 'center',
                    marginTop: '4px',
                    justifyContent: 'space-between',
                    paddingRight: '0px'
                }}
            >
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            cursor: 'text',
                            paddingRight: '12px',
                        }}
                    >
                        {t('Are you sure?')}
                    </Typography>
                    <Button
                        disabled={isDeleting}
                        color="inherit"
                        size="small"
                        onClick={deleteAlertHandle}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={isDeleting}
                        color="inherit"
                        size="small"
                        onClick={confirmHandle}
                    >
                        {t('Confirm')}
                    </Button>
                </Box>
            </Alert>
            <DeleteRecurringDialog
                variants={deleteVariants}
                open={openDialog}
                selectedValue={selectedValue}
                onClose={handleClose}
            />
        </Fragment>
    )
}
