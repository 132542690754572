import React, {CSSProperties, FC} from 'react';
import {Box, useTheme} from '@mui/material';
import {IDragTask} from '../../../models/dnd/IDragTask';
import {useDrop} from 'react-dnd';
import {dndItemTypes} from '../../../models/dnd/dndItemTypes';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {
    changeTaskDayDnD,
    changeTasksDay,
    getDayRecurringTasks,
    togglePlanned,
    undeleteTask
} from '../../../store/task/taskSlice';
import {setShowBuyPremiumDialogStatus} from "../../../store/subscription/subscriptionSlice";

interface IDnDDaysProps {
    title: string,
    dateTo: string,
    style?: CSSProperties
}

export const DnDDays: FC<IDnDDaysProps> = ({title, dateTo, style = {}, ...props}) => {

    const theme = useTheme();
    const dispatch = useAppDispatch();

    const {user} = useAppSelector(state => state.auth);
    const {days} = useAppSelector(state => state.task);

    const [{isOver, canDrop}, drop] = useDrop<IDragTask, void, any>({
        accept: dndItemTypes.TASK,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: () => {
            return true;
        },
        drop: (item) => {
            if (!item.task.id) return;

            if (!user.level.tasksAllowDnD) {
                dispatch(setShowBuyPremiumDialogStatus(true));
                return;
            }

            const currDay = days[dateTo];
            if (!currDay) {
                dispatch(getDayRecurringTasks(dateTo))
                    .unwrap()
                    .then(() => {
                        dropContinue(item);
                    });
            } else {
                dropContinue(item);
            }
        }
    });

    const dropContinue = (item: IDragTask) => {
        if (!item.task.id) return;

        if (dateTo && days[dateTo] && days[dateTo].Tasks.length >= user.level.tasksPerDayMaxCount) {
            dispatch(setShowBuyPremiumDialogStatus(true));
            return;
        }

        if (item.task.isPlanned) {
            dispatch(togglePlanned({
                taskId: item.task.id,
                date: dateTo
            }));
        } else {
            if (item.task.isDeleted) {
                dispatch(undeleteTask({
                    taskId: item.task.id,
                    isCompleted: false,
                    isPlanned: false,
                    removeRecurring: true
                }))
                    .unwrap()
                    .then((res) => {
                        dispatch(changeTaskDayDnD({
                            oldDate: item.date,
                            task: res[item.date],
                            newDayDate: dateTo,
                        }));
                        dispatch(changeTasksDay({
                            date: dateTo,
                            taskId: Number(item.task.id),
                            orderId: item.task.Order.id,
                            listType: item.tagFrom !== undefined ? 2 : 0
                        }));
                    });
            } else {
                dispatch(changeTaskDayDnD({
                    oldDate: item.date,
                    task: item.task,
                    newDayDate: dateTo,
                }));
                dispatch(changeTasksDay({
                    date: dateTo,
                    taskId: item.task.id,
                    orderId: item.task.Order.id,
                    listType: item.tagFrom !== undefined ? 2 : 0
                }));
            }

        }
    }

    let opacity = 0;
    let backgroundColor = 'inherit';
    let border = `2px dashed transplarent`;
    if (isOver && canDrop) {
        backgroundColor = theme.palette.dragAndDropBackgroundColor.main;
    }
    if (canDrop) {
        opacity = 1;
        border = `2px dashed ${theme.palette.dragAndDropBorderColor.main}`;
    }

    return (
        <Box
            ref={drop}
            sx={{
                borderRadius: '0.75rem',
                border: border,
                backgroundColor: backgroundColor,
                color: theme.palette.dragAndDropColor.main,
                opacity: opacity,
                px: '6px',
                ...style
            }}
        >
            {title}
        </Box>
    )
}
