import React, { FC, Fragment, useEffect, useState } from 'react';
import { Button, Paper, Slide, Typography } from '@mui/material';
import { getCookie } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

interface IProps {
    message: string
}

export const CookieConsent: FC<IProps> = ({ message, ...rest }) => {

    const { t } = useTranslation();

    const [consentGiven, setConsentGiven] = useState<boolean>(true);

    const handleConsent = () => {
        setConsentGiven(true);
        document.cookie = 'SetTasksCookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT';
    }

    useEffect(() => {
        const cookieConsent = getCookie('SetTasksCookieConsent');
        if (cookieConsent) {
            setConsentGiven(true);
        } else {
            setConsentGiven(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Slide
                mountOnEnter
                unmountOnExit
                direction='up'
                timeout={400}
                in={!consentGiven}
            >
                <Paper
                    elevation={12}
                    sx={{
                        position: 'absolute',
                        bottom: '60px',
                        right: '60px',
                        left: '60px',
                        padding: '25px',
                        zIndex: '9999'
                    }}
                >
                    <Typography>
                        {t(message)}
                    </Typography>
                    <Button
                        variant='contained'
                        sx={{ mt: '25px', float: 'right' }}
                        onClick={handleConsent}
                    >
                        {t('Accept')}
                    </Button>
                </Paper>
            </Slide>
        </Fragment>
    )
}
