import React, {FC, useEffect, useRef, useState} from 'react';
import {
    Box,
    ClickAwayListener,
    Grow,
    ListItemText,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TextField,
    Typography
} from '@mui/material';
import {ITask} from '../../models/ITask';
import {addTask, editRecurringTask, editTask, getUserTags} from '../../store/task/taskSlice';
import {ITabDay} from '../../models/days/ITabDay';
import {setSnackbar} from '../../store/utils/utilsSlice';
import {
    calculateNearestTimeFromSchedule,
    formatTitle,
    getInitialRemindOptions,
    getTimeSchedule
} from '../../utils/helpers';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {replaceTaskInGoal} from "../../store/goal/goalSlice";
import {TagAddList} from "../UI/Tags/TagAddList";
import {recurringAPI} from "../../services/TaskService";
import {resetKicker} from '../../store/kicker/kickerSlice';

interface IProps {
    disabled?: boolean,
    value: ITask,
    day: ITabDay
}

export const TaskEditInput: FC<IProps> = ({disabled = false, value, day, ...props}) => {

    const dispatch = useAppDispatch();
    const {user} = useAppSelector(state => state.auth);
    const {tags} = useAppSelector(state => state.task);

    const [editable, setEditable] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(value.title);

    const [newTags, setNewtags] = useState<string[]>([]);
    const [fTags, setFTags] = useState<string[]>([]);
    const [selectedInputTag, setSelectedInputTag] = useState<string>('');

    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isPopoverOpen = Boolean(anchorEl);

    const timeSchedule = getTimeSchedule();
    const nearestTimeFromSchedule = calculateNearestTimeFromSchedule(user.timezoneName, timeSchedule);

    const handleEditable = () => {
        if (!disabled) setEditable(true);
    }

    const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    }

    const handleOpen = () => {
        setAnchorEl(ref.current);
        setSelectedInputTag('');
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClose22 = () => {
        setEditable(false);
        taskTitleEdit();
    };

    const handleOpenOptions = () => {
        if (!tags.fetched) {
            dispatch(getUserTags(false));
        }
    }

    const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (title.includes('#')) {
                handleClose();
                selectTagsListClick(selectedInputTag);
            } else {
                taskTitleEdit();
            }
        }

        if (e.key === '#') {
            handleOpenOptions();
            handleOpen();
        }

        if (e.key === ' ') {
            handleClose();
            extractTag();
        }
    }

    const extractTag = () => {
        let lastWord = getLastWord(title);
        if (lastWord.includes('#')) {
            if (!lastWord.includes(' #')) {
                let replacedTaskTitle = title.trim().replace('#', ' #');
                lastWord = getLastWord(replacedTaskTitle);
                removeLastWordInTask();

                let lastIndex: number = replacedTaskTitle.trim().lastIndexOf(" ");
                let nTaskTitle = replacedTaskTitle.trim().substring(0, lastIndex);
                setTitle(nTaskTitle);

                lastWord = lastWord.substring(1);
                if (lastWord.length) {
                    let selectedTags: string[] = newTags;
                    selectedTags.push(lastWord);
                    setNewtags(selectedTags);
                }
            } else {
                removeLastWordInTask();
                lastWord = lastWord.substring(1);
                if (lastWord.length) {
                    let selectedTags: string[] = newTags;
                    selectedTags.push(lastWord);
                    setNewtags(selectedTags);
                }
            }
        }
    }

    const removeLastWordInTask = () => {
        let lastIndex: number = title.trim().lastIndexOf(" ");
        let nTaskTitle = title.trim().substring(0, lastIndex);
        setTitle(nTaskTitle);
    }

    const getLastWord = (text: string) => {
        let n = text.split(" ");
        return n[n.length - 1];
    }

    const taskTitleEdit = async () => {
        handleClose();

        if (title !== value.title) {
            if (title.trim() !== '') {
                extractTag();

                const formattedTitle = formatTitle({title: title});
                let tagTitles = value.Tags.map(tag => tag.title);
                tagTitles = [...tagTitles, ...newTags];

                if (value.Goals) {
                    let goalTitles = value.Goals.map(goal => goal.title);
                    if (formattedTitle.goalTitles.some(s => goalTitles.includes(s))) {
                        setTitle(value.title);
                        dispatch(setSnackbar({
                            text: 'This task already have this goals',
                            severity: 'error',
                            open: true
                        }));
                        return;
                    }
                }

                // TODO ask for recurring
                if (value.isRecurring) {
                    recurringAPI(value.recurringId)
                        .then(originalPromiseResult => {
                            if (originalPromiseResult.data) {
                                const remindOptions = getInitialRemindOptions(originalPromiseResult.data.EventNotifications, nearestTimeFromSchedule, true, day.date);
                                if (originalPromiseResult.data.Frequencies && originalPromiseResult.data.Frequencies[0]) {
                                    dispatch(editRecurringTask({
                                        task: {...value, title: formattedTitle.title, recurringId: value.recurringId},
                                        date: day.date,
                                        recurringConfig: {
                                            isRecurring: true,
                                            recurringType: String(originalPromiseResult.data.Frequencies[0].type),
                                            dayOfWeek: originalPromiseResult.data.Frequencies[0].dayOfWeek ? [originalPromiseResult.data.Frequencies[0].dayOfWeek] : [],
                                            dayOfMonth: originalPromiseResult.data.Frequencies[0].dayOfMonth ? [originalPromiseResult.data.Frequencies[0].dayOfMonth] : [],
                                            monthOfYear: originalPromiseResult.data.Frequencies[0].monthOfYear ? [originalPromiseResult.data.Frequencies[0].monthOfYear] : [],
                                            startDate: new Date(originalPromiseResult.data.startDate),
                                            endDate: originalPromiseResult.data.endDate ? new Date(originalPromiseResult.data.endDate) : null,
                                        },
                                        tagTitles: tagTitles,
                                        goalTitles: undefined,
                                        removedTags: undefined,
                                        removedGoals: undefined,
                                        remindConfig: remindOptions.isRemind ? {...remindOptions} : undefined,
                                    }))
                                        .unwrap()
                                        .then(() => {
                                            dispatch(resetKicker());
                                        });
                                }
                            }
                        });
                    return;
                }

                !value.id
                    ?
                    dispatch(addTask({
                        task: {...value, title: formattedTitle.title},
                        date: day.date,
                        tagTitles: tagTitles
                    }))
                    :
                    dispatch(editTask({
                        task: {
                            ...value,
                            title: formattedTitle.title,
                            isImportant: formattedTitle.isImportant
                        },
                        tagTitles: tagTitles,
                        goalTitles: formattedTitle.goalTitles
                    })).unwrap()
                        .then(originalPromiseResult => {
                            let keys = Object.keys(originalPromiseResult);
                            let savedTask = originalPromiseResult[keys[0]] as ITask;
                            if (savedTask.Goals?.length) {
                                const goalsIds = savedTask.Goals.map(goal => goal.id);
                                dispatch(replaceTaskInGoal({goalIds: goalsIds, task: savedTask}));
                            }
                            setNewtags([]);
                        });
                setTitle(formattedTitle.title.trim());
            } else {
                setTitle(value.title);
                dispatch(setSnackbar({
                    text: 'Task title cannot be empty',
                    severity: 'error',
                    open: true
                }));
            }
        }

        setEditable(false);
    }

    useEffect(() => {
        if (tags.tagList) {
            setFTags(tags.tagList.filter(tag => tag.isArchived === 0).map(tag => tag.title));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags.tagList]);

    const tagDelete = (tag: string) => {
        let selectedTags: string[] = newTags.filter(tagInList => tagInList !== tag);
        setNewtags(selectedTags);
    }

    const selectTagsListClick = (tagTitle: string) => {
        if (!newTags.includes(tagTitle)) {
            let selectedTags: string[] = newTags;
            selectedTags.push(tagTitle);
            setNewtags(selectedTags);

            let lastWord = getLastWord(title);
            if (lastWord.includes('#')) {
                if (!lastWord.includes(' #')) {
                    let replacedTaskTitle = title.trim().replace('#', ' #');
                    removeLastWordInTask();

                    let lastIndex: number = replacedTaskTitle.trim().lastIndexOf(" ");
                    let nTaskTitle = replacedTaskTitle.trim().substring(0, lastIndex);
                    setTitle(nTaskTitle);
                } else {
                    removeLastWordInTask();
                }
            }

            handleClose();
        }
    }

    const newTaskTitleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        let lastWord = getLastWord(title);
        if (lastWord.includes('#')) {
            handleOpen();
            let replacedTaskTitle = title.trim().replace('#', ' #');
            lastWord = getLastWord(replacedTaskTitle).replace('#', '');
            let matchedTags = matchTags(lastWord);
            if (!matchedTags.includes(lastWord)) {
                if (lastWord) {
                    matchedTags.unshift(lastWord);
                }
            }
            setFTags(matchedTags);
            setSelectedInputTag(lastWord);
        }

        if (e.key === 'ArrowUp') {
            const index = fTags.findIndex(tag => tag === selectedInputTag);
            if (index > 0) {
                if (index < fTags.length + 1) {
                    let selectedTag = fTags[index - 1];
                    setSelectedInputTag(selectedTag);
                }
            } else {
                setSelectedInputTag(fTags[fTags.length - 1]);
            }
        }
        if (e.key === 'ArrowDown') {
            const index = fTags.findIndex(tag => tag === selectedInputTag);
            if (index < fTags.length - 1) {
                let selectedTag = fTags[index + 1];
                setSelectedInputTag(selectedTag);
            } else {
                setSelectedInputTag(fTags[0]);
            }
        }
    }

    const matchTags = (input: string) => {
        let reg = new RegExp(input.split('').join('\\w*').replace(/\W/, ""), 'i');
        return tags.tagList.filter(tag => tag.isArchived === 0).map(tag => tag.title).filter(tag => {
            if (tag.match(reg)) {
                return tag;
            }
        });
    }

    return (
        <ListItemText sx={{display: 'grid'}} onClick={handleEditable}>
            {editable
                ?
                <Box>
                    <ClickAwayListener onClickAway={handleClose22}>
                        <Box>
                            <TextField
                                ref={ref}
                                inputRef={inputRef}
                                disabled={disabled}
                                variant="filled"
                                fullWidth
                                autoFocus
                                value={title}
                                inputProps={{
                                    style: {
                                        padding: 4,
                                        paddingLeft: 0,
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <Box sx={{display: "flex"}}>
                                            {newTags.map((data, index) => {
                                                return (
                                                    <TagAddList tag={data} key={data} handleDelete={tagDelete}/>
                                                );
                                            })}
                                        </Box>
                                    ),
                                }}
                                onChange={handleTitle}
                                onKeyPress={handleEnterPress}
                                onKeyUp={newTaskTitleKeyUp}
                                // onBlur={taskTitleEdit}
                            />
                            <ClickAwayListener onClickAway={handleClose}>
                                <Popper
                                    aria-describedby='tags-goals-autocomplete'
                                    open={isPopoverOpen}
                                    anchorEl={anchorEl}
                                    sx={{zIndex: 9999}}
                                    transition
                                >
                                    {({TransitionProps}) => (
                                        <Grow {...TransitionProps} exit={false}>
                                            <Paper
                                                sx={{
                                                    width: ref.current ? ref.current.offsetWidth : '',
                                                    maxHeight: '300px',
                                                    p: 1,
                                                    overflow: 'auto'
                                                }}
                                            >
                                                <MenuList dense={false}>
                                                    {
                                                        fTags.map(tagTitle => {
                                                            return <MenuItem
                                                                value={tagTitle}
                                                                key={tagTitle}
                                                                onClick={() => selectTagsListClick(tagTitle)}
                                                                selected={selectedInputTag === tagTitle}
                                                            >
                                                                {'#' + tagTitle}
                                                            </MenuItem>
                                                        })
                                                    }
                                                </MenuList>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </ClickAwayListener>
                        </Box>
                    </ClickAwayListener>


                </Box>
                :
                <Typography
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        cursor: 'text',
                        color: disabled ? '#b6b6b6' : '',
                        fontWeight: value.isImportant ? 'bold' : '400',
                        textDecoration: disabled ? 'line-through' : 'none'
                    }}
                >
                    {title}
                </Typography>
            }
        </ListItemText>
    )
}
